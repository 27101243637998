BaseView = require 'views/base'
auth = require 'models/auth'
RuleModel = require 'models/rule-firebase'


module.exports = class BaseEagerView extends BaseView
    __name__: 'BaseEagerView'
    className: 'base-eager'

    isEditable: null

    resizeEventName: null

    initialize: (options={}) ->
        @model ?= options.model
        # Look for passed in rules and just use that.
        @rule ?= options.rule
        @viewState = options.viewState

        onSync = ->
            @rule ?= new RuleModel {id: @model.id}
            @listenToOnce @rule, 'sync', @render

            # Wait for Eager to first be added to the page before binding events.
            @once 'eager:initialize', ->
                @listenTo @rule, 'change:previewUrl', @_updateFrameSrc
            @on 'eager:navigate', (evt) ->
                @rule.set {previewUrl: evt.shownUrl}, {silentEager: true}

            @resizeEventName = "resize.eagerview#{@model.get 'id'}"
            $(window).on @resizeEventName, _.debounce(_.bind(@_updateFrameHeight, @), 100)

        if @model.syncd
            onSync.call @
        else
            @rule ?= new Backbone.Model
            @listenToOnce @model, 'sync', onSync

        @isEditable = options.isEditable

    render: ->
        @removeChildViews()

        # Skip rendering if the rule isn't syncd yet. We'll render again when it does.
        if @rule.syncd
            super
            @_initializeEagerPreview()
            @_updateFrameHeight()
            @renderChildren()

        return @

    getContext: -> {isEditable: @isEditable}

    # Subclasses can listen for the eager:initialize event
    _initializeEagerPreview: ->
        if @eagerPreview
            @eagerPreview.off 'navigate'

        @eagerPreview = new EagerPreview {
            partnerId: 'appcues'
            el: @$('.frame-wrapper').get(0)
            showURLBar: false
            # FIX: Set a better default.
            frameSrc: @rule.getPreviewUrl()
        }

        console.log "Initializing eager."
        @eagerPreview.once 'load', => @trigger 'eager:initialize'
        @eagerPreview.on 'navigate', _.bind(@_triggerNavigate, @)

    # Subclasses can listen for the eager:navigate event
    _updateFrameSrc: (model, value, options) ->
        unless options.silentEager
            @eagerPreview.navigate @rule.getPreviewUrl()

    _updateFrameHeight: ->
        @$('.preview-area').css 'min-height', $(window).outerHeight()

    _triggerNavigate: (e) ->
        console.log "About to trigger eager:navigate"
        @trigger 'eager:navigate', e

    reload: -> @eagerPreview?.reload()

    undelegateEvents: ->
        $(window).off @resizeEventName
        @eagerPreview?.off 'navigate'
        super

    renderChildren: -> throw new Error "#{@__name__}.renderChildren() is not implemented."

