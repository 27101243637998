auth = require 'models/auth'
AppView = require 'views/app'

module.exports = class StepAnalyticsView extends AppView
    __name__: 'StepAnalyticsView'
    className: 'step-analytics'
    template: require 'views/templates/analytics'

    initialize: (options) ->
        {@flowId} = options

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderStepAnalytics auth.currentAccount.id, @flowId, "apc-react-root"
        else
            setTimeout(() =>
                @render()
            , 300)