ModalView = require 'views/modal'
auth = require 'models/auth'
{firebase} = require 'lib/firebase'
{userRoles} = require 'lib/helpers'
executeCopy = require('lib/copy')
trackEvent = require 'lib/track-event'

defaultOptions =
    backdrop: true
    keyboard: true
    show: false
    remote: false

module.exports = class InviteUsersModalView extends ModalView
    __name__: 'InviteUsersModalView'
    template: require 'views/templates/invite-modal'

    events:
        'click .invite-btn': 'handleClickInviteUser'
        'change .invite-form select[name=role]': 'checkForRole'
        'blur .invite-form input[name=name]': 'validateName'
        'blur .invite-form input[name=email]': 'validateEmail'
        'click .copy': 'copy'
        'click .transfer-user-btn': 'handleClickTransferUser'

    $name: -> @$('.invite-form .name-field')
    $email: -> @$('.invite-form input[name=email]')
    $role: -> @$('.invite-form select[name=role]')
    $missingDataAlert: -> @$('.invite-form .alert.invite-form-missing-alert')

    getFirstName: ->
        @$('.invite-form input[name=firstname]').val()
    getLastName: ->
        @$('.invite-form input[name=lastname]').val()
    getName: ->
        firstName = @getFirstName()
        lastName = @getLastName()
        return "#{firstName} #{lastName}".trim()

    copy: (e) ->
        # duplicate code in code-snippet.coffee
        $el = @$ e.currentTarget
        $link = $el[0].previousSibling
        executeCopy($link)

    initialize: (options={}) ->
        @modalOptions = _.extend {}, defaultOptions, options
        @onSuccess = options.onSuccess ? ->
        Backbone.$('body').append @el
        @$el.on 'hidden.bs.modal', _.bind(@remove, @)

        @viewState = new Backbone.Model
            name: ""
            email: ""
            role: ""
            isNameValid: true
            isEmailValid: true
            userIsAlreadyOnTeam: false
            permalink: "#{window.__env.SITE_ORIGIN}/install/#{auth.currentAccount.id}"

        @listenTo @viewState, 'change:isNameValid', (model, value) =>
            if value
                @$name().removeClass 'has-error'
                if @viewState.get('isNameValid') and @viewState.get('isEmailValid')
                    show = =>
                        @$missingDataAlert().addClass 'hidden'
                    _.delay show, 500
            else
                @$name().addClass 'has-error'
                @$missingDataAlert().removeClass 'hidden'

        @listenTo @viewState, 'change:isEmailValid', (model, value) =>
            if value
                @$email().parents('.form-group').removeClass 'has-error'
                if @viewState.get('isNameValid') and @viewState.get('isEmailValid')
                    show = =>
                        @$missingDataAlert().addClass 'hidden'
                    _.delay show, 500
            else
                @$email().parents('.form-group').addClass 'has-error'
                @$missingDataAlert().removeClass 'hidden'

        @listenTo @viewState, 'change:inviteSuccess change:isEmailTaken change:inviteError change:emailError change:userIsAlreadyOnTeam', (model, value) =>
            @renderModalBody()

    handleClickInviteUser: (e) ->
        name = @getName().trim()
        email = @$email().val().trim()
        role = @$role().val()

        @validateName()
        @validateEmail()
        @checkForRole()

        if @viewState.get('isNameValid') and @viewState.get('isEmailValid') and @viewState.get('role')
            # Disable this button so they don't double click.
            @$(e.currentTarget).addClass 'disabled'
            # Reset state of errors
            state =
                firstname: @getFirstName()
                lastname: @getLastName()
                email: email
                role: role
                isEmailTaken: false
                inviteError: false
                emailError: false
                userIsAlreadyOnTeam: false
            @viewState.set state, silent: true

            # If valid inputs do the invite.
            console.log "Inviting #{email} to account."

            inviter = auth.user.get('fullname') or auth.user.get('email')

            onInviteSuccess = () => @inviteSuccess(name, email, role)
            onFail = () => @viewState.set 'inviteError', true
            onEmailSendFail = () => @viewState.set 'emailError', true
            onEmailTakenFail = () => @viewState.set 'isEmailTaken', true

            auth.inviteUser(inviter, name, email, role, "", onInviteSuccess, onFail, onEmailSendFail, onEmailTakenFail)

    handleClickTransferUser: (e)  ->
        @$(e.currentTarget).addClass 'disabled'

        name = @getName().trim()
        email = @$email().val().trim()
        role = @$role().val()

        @validateName()
        @validateEmail()
        @checkForRole()

        if @viewState.get('isNameValid') and @viewState.get('isEmailValid') and @viewState.get('role')

            onTransferSuccess = () => @inviteSuccess(name, email, role)
            onFail = () =>
                @viewState.set 'isEmailTaken', false
                @viewState.set 'inviteError', true

            onAlreadyOnTeamFail = () =>
                @viewState.set 'isEmailTaken', false
                @viewState.set 'userIsAlreadyOnTeam', true

            auth.transferUser(email, onTransferSuccess, onFail, onAlreadyOnTeamFail)

    inviteSuccess: (name, email, role) =>
        @viewState.set 'inviteSuccess', true
        @viewState.set 'isEmailTaken', false
        _.delay =>
            @hide()
            @trackInvite(name, email, role)
            @onSuccess()
        , 5000

    trackInvite: (name, email, role) ->
        trackEvent('Invited a user', {name, email, role, team_name: role})

    render: ->
        @renderModalBody().modal(@modalOptions)

    renderModalBody: ->
        context = @getContext()
        @$el.html(@template(context))

    getContext: ->
        context = @viewState.toJSON()
        if @modalOptions.role
            context.userRoles = @modalOptions.role
        else
            context.userRoles = userRoles
        context

    validateName: ->
        @viewState.set 'name', @getName()
        @viewState.set 'isNameValid', @getName() isnt ""

    validateEmail: ->
        @viewState.set 'email', @$email().val()
        @viewState.set 'isEmailValid', /.+@.+\..+/.test @$email().val()

    checkForRole: (e) ->
        @viewState.set 'role', @$role().val()

        validRole = @$role()[0].selectedIndex
        selectorEl = @$role()[0].parentElement
        if validRole
            selectorEl.classList.remove('has-error')
        else
            selectorEl.classList.add('has-error')
