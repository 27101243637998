StepActionView = require 'views/step-actions/step-action'
editor = require 'lib/editor'

module.exports = class StepActionSocialView extends StepActionView
    __name__: 'StepActionSocialView'
    template: require 'views/templates/step-actions/social'
    buttonsTemplate: require 'views/templates/step-actions/social-buttons'
    editorTarget: ".redactor"

    initialize: (attrs={}) ->
        super
        @listenTo @model, 'change:shareUrl change:shareText', @setContent

    defaultContent: ->
        $(@template()).find('.step-action-content > textarea').html()

    getContext: ->
        # Rip out the social buttons if they exist.
        $content = $('<div></div>')
        $content.html(@model.get('content'))
                .find('.appcues-social-buttons')
                .remove()

        _.extend(super, {
            buttons: @getButtons()
            contentWithoutButtons: $content.html()
        })

    render: ->
        # Remove pre-existing editor.
        editor.remove @$(@editorTarget)

        super

        save = _.bind(@setContent, @)

        editor.standard @$(@editorTarget), {
            # Save changes in content.
            changeCallback: save
            codeKeyupCallback: -> save @code.get()
            sourceCallback: save
            visualCallback: save

            toolbarExternal: '#step-editor-toolbar'
        }

        return @

    getButtons: (data) ->
        data ?=  @model.toJSON()
        encodedLink = encodeURIComponent data.shareUrl
        encodedText = encodeURIComponent data.shareText

        [{
            text: 'Share on Twitter'
            className: 'appcues-button-twitter'
            link: "https://twitter.com/share?url=#{encodedLink}&text=#{encodedText}"
        }, {
            text: 'Share on Facebook'
            className: 'appcues-button-facebook'
            link: "https://www.facebook.com/sharer/sharer.php?u=#{encodedLink}"
        }]

    setContent: (value) ->
        unless _(value).isString()
            value = @$(@editorTarget).val()

        # Add social buttons back in at the end.
        buttons = @getButtons()
        value += @buttonsTemplate {buttons}

        @model.set 'content', value

    remove: ->
        # Remove redactor editor if it exists.
        editor.remove @$(@editorTarget)
        super
