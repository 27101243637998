BaseView = require 'views/base'
{renderComponent} = require 'lib/component-renderer'
{confirm} = require 'views/alerts'
auth = require 'models/auth'


module.exports = class ComponentPreviewView extends BaseView
    __name__: 'ComponentPreviewView'
    className: 'component-preview'
    template: require 'views/templates/components/content'

    componentViews: {
        editor: require 'views/components/editor'
        image: require 'views/components/image'
        video: require 'views/components/video'
        form: require 'views/components/form'
        hero: require 'views/components/hero'
        pacman: require 'views/components/pacman'
    }

    events: ->
        'mouseenter': 'addHoverClass'
        'mouseleave': 'removeHoverClass'
        'dblclick': 'edit'
        'click .empty-component': 'edit'
        'dblclick .component-editor, .component-editor-mask': 'stopEvent'
        'click [data-action=setType]': 'setType'
        'click [data-action=edit]': 'edit'
        'click [data-action=delete]': 'delete'

    initialize: (options) ->
        {@isEditable} = options
        @listenTo @model, 'change:html change:src change:embedCode change:width change:position', (model, options={}) ->
            @render()

    getContent: (attrs) ->
        renderComponent((attrs ? @model), {isEditable: @isEditable})

    getContext: ->
        componentAttrs = @model.toJSON()
        {componentType} = componentAttrs

        if componentType in ['image', 'hero']
            componentAttrs.src = componentAttrs.rawSrc || componentAttrs.src

        content = @getContent(componentAttrs)

        return {componentType, content}

    render: ->
        @removeChildViews()
        if @isEditable then @$el.addClass('editable') else @$el.removeClass('editable')
        if @getContent() then @$el.removeClass('empty') else @$el.addClass('empty')
        super

    setType: (evt) ->
        # Render manually so we can easily switch to editing right after
        @model.set {componentType: @$(evt.currentTarget).attr('data-component-type')}, {skipRender: true}
        @render()
        @edit()

    edit: ->
        return unless @isEditable
        @removeHoverClass()
        @$el.append('<div class="component-editor-mask"></div>')
        @editView = new @componentViews[@model.get('componentType')](model: @model)
        @renderChild @editView
        @$el.append(@editView.el)
        @editView.$el.css {
            minHeight: @$el.outerHeight() + 20
        }
        auth.user.set('firstUsedComponents', auth.user.get('firstUsedComponents') ? Date.now())
        lytics?.trackWithUser 'Edited a flow step component', {componentType: @model.get('componentType')}
        @listenToOnce @editView, 'remove', -> @editView = null
        return @editView

    delete: ->
        if renderComponent(@model)
            confirm('Are you sure you want to remove this content from your step?').then (res) =>
                if res
                    @model.destroy()
                    @remove()

        else
            @model.destroy()
            @remove()

    addHoverClass: ->
        @$el.addClass('hover') unless @editView?

    removeHoverClass: ->
        @$el.removeClass('hover')

    stopEvent: (evt) ->
        evt.stopPropagation()

