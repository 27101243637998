{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'
hubspot = require 'lib/hubspot'
FlowModel = require 'models/flow-firebase'
RuleModel = require 'models/rule-firebase'
FormsCollection = require 'collections/forms'
forms = new FormsCollection
helpers = require 'lib/helpers'
sls = require 'lib/sls-helpers'

module.exports = class FlowsCollection extends Backbone.Firebase.Collection
    model: require 'models/flow'

    initialize: (models, options) ->
        @accountId = options?.accountId ? auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{@accountId}/flows"

        @once 'sync', ->
            @syncd = true

        @on 'remove', (flow) ->
            rule = new RuleModel(id: flow.id)
            rule.destroy()

    hasPublishedFlows: () ->
        @where({published: true}).length

    cloneModel: (flowId, options={}) ->
        {fromAccountId, flowName, noCopyOf} = options
        if fromAccountId?
            # fromAccountId is used to clone template flows from the `sample` account on `/start`
            flow = new FlowModel {id: flowId}, {accountId: fromAccountId}
        else
            flow = @get(flowId)
        return unless flowId and flow

        sls.clone(flow, fromAccountId)
            .done(({new_content_id}) => @trigger 'clone', @, new_content_id)
