{APPCUES_API_URL} = window.__env

# Something could go here I guess.
HOTSPOT_WARNING_PERCENTAGE = 0.05
HOTSPOT_WARNING_THRESHOLD = 20

defaultComparator = (a, b, sortProperty) ->
    if (a instanceof Backbone.Model) and (b instanceof Backbone.Model)
        aNum = a.get sortProperty
        bNum = b.get sortProperty
    else
        aNum = a[sortProperty]
        bNum = b[sortProperty]

    # Try to sort by step number.
    if aNum? and bNum? and aNum isnt bNum
        return 0 if aNum is bNum
        return if aNum < bNum then -1 else 1
    # Otherwise sort by ID.
    else
        aId = a.id
        bId = b.id
        return 0 if aId is bId
        return if aId < bId then -1 else 1


refreshToken = (idToken) ->
    return fetch("#{APPCUES_API_URL}/v1/web/auth?jwt_token=#{idToken}", { method: "POST" })
        .then((response) -> response.json())
        .then (response) ->
            return response.jwt_token

getJwtToken = (authModel) ->
    if authModel.customToken
        return refreshToken(authModel.customToken)
    else
        return authModel.getIdToken().then(refreshToken)

module.exports =
    userRoles: [
        'Product'
        'Growth'
        'Marketing'
        'Engineering'
        'UX/Design'
        'Customer Success/Support'
        'Executive'
        'Other'
    ]

    patternLookup:
        fullscreen:
            type: 'fullscreen'
            desc: 'Full Screen'
        modal:
            type: 'modal'
            desc: 'Modal'
        left:
            type: 'left'
            desc: 'Slideout'

    fieldLookup:
        text:
            type: 'text'
            desc: 'Text Input'
        choice:
            type: 'choice'
            desc: 'Multiple Choice'

    # Tries to convert an exact true/false string to a boolean.
    stringToBool: (str) ->
        switch str.toLowerCase()
            when 'true'
                return true
            when 'false'
                return false
            else
                return str

    stepComparator: (a, b) ->
        defaultComparator a, b, 'stepNumber'

    hotspotComparator: (a, b) ->
        defaultComparator a, b, 'index'

    objToQueryStr: (obj) ->
        return unless _.isObject obj

        result = ''
        _(obj).each (v, k) ->
            str = v
            if _.isObject(v) or _.isArray(v)
                str = encodeURIComponent JSON.stringify(v)
            result += '&' if result
            result += "#{k}=#{str}"

        return result

    queryStrToObj: (str) ->
        str ?= window.location.search
        obj = {}

        params = str.replace(/^\?/, '').split '&'
        for param in params
            parts = param.split '='
            obj[parts[0]] = decodeURIComponent(parts[1])

        return obj

    updateQueryString: (queryString) ->
        baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
        window.history.replaceState {}, "", baseUrl + '?' + queryString

    lastNDays: (n) ->
        today = new Date()
        before = new Date()
        before.setDate today.getDate() - n
        year = before.getFullYear()
        month = before.getMonth() + 1
        month = '0' + month if month < 10
        day = before.getDate()
        day = '0' + day if day < 10
        [year, month, day].join '-'

    cloneRule: (fromId, toId, fromAccountId=null) ->
        RulesCollection = require 'collections/rules'
        # Collection of rules for the current account
        targetRules = new RulesCollection

        if fromAccountId
            sourceRules = new RulesCollection [], {accountId: fromAccountId}
        else
            sourceRules = targetRules

        cloneFn = ->
            from = sourceRules.get fromId
            to = from.pick(from.cloneKeys)
            to.id = toId
            targetRules.add to

        if sourceRules.syncd
            cloneFn()
        else
            sourceRules.once 'sync', cloneFn

    getFlowPreviewUrl: (steps={}) ->
        if _.size(steps) > 0
            for stepId of steps
                step = steps[stepId]
                if step?.content?
                    content = step.content
                    break

            if content and (images = content.match(/<img.*?>/))
                img = $.parseHTML(images[0])[0]
                return img.src
        return

    buildPreviewUrl: (content, rule) ->
        try
            url = encodeURIComponent(JSON.stringify({content, rule}))
            if rule.previewUrl
                url += '&url=' + encodeURIComponent(rule.previewUrl)

            return "/preview?data=#{url}"
        catch e
            return

    getHotspotWarning: (shown, errors) ->
        (errors / shown ) > HOTSPOT_WARNING_PERCENTAGE and shown > HOTSPOT_WARNING_THRESHOLD

    filterOutArchived: (collection) ->
        filteredFlows = []

        collection.forEach (model) ->
            if model.get('state') isnt 'ARCHIVED'
                filteredFlows.push model.attributes
            return

        return filteredFlows

    spoofAsAccountId: (spoofAccountId) ->
        existingSpoofAccountId = localStorage?.getItem('authWithAccountId') or ""
        if existingSpoofAccountId isnt spoofAccountId
            localStorage?.setItem 'authWithAccountId', spoofAccountId
            if window.top is window
                window.location.reload()
            else
                if not spoofAccountId
                    window.parent.postMessage(JSON.stringify({
                        type: "reload"
                    }), "*")
        return false

    switchUser: (element) ->
        $form = element
        data = $form.serializeObject()

        localStorage?.setItem 'authWithAccountId', data.accountId.replace /(\D)/g, ''
        window.location.href = '/'
        return false


    processQueryParamsForSignup: (id, queryStr) ->
        hubspot = require 'lib/hubspot'

        processClearbitData = (resp) ->
            data = {
                company: {}
                employment: {}
                person: {}
            }

            data.company = hubspot.mapClearbitField('company', resp.company)
            data.employment = hubspot.mapClearbitField('person_employment', resp.employment)
            data.person = hubspot.mapClearbitField('person_name', resp.name)

            return data

        queryParams = @queryStrToObj(queryStr)

        if queryParams.email
            @updateQueryString ""
            localStorage.removeItem("signupQueryParams")

            try
                # Grab stored clearbit data from signup
                clearbitData = localStorage?.getItem('clearbit')

                if clearbitData && clearbitData != "undefined" && clearbitData != "null"
                    parsedClearbitData = JSON.parse(clearbitData)
                    processedClearbitData = processClearbitData(parsedClearbitData)

                    _.each processedClearbitData.company, (val, key) ->
                        userData[key] = val

                    _.each processedClearbitData.employment, (val, key) ->
                        userData[key] = val

                    _.each processedClearbitData.person, (val, key) ->
                        userData[key] = val

                    localStorage.removeItem('clearbit')


        return queryParams

    getJwtToken: getJwtToken

    sendAuthToParentFrame: (authModel, accountId) ->
        { USE_AUTH_DOT_APPCUES } = window.__env
        unless USE_AUTH_DOT_APPCUES
            getJwtToken(authModel).then((jwt_token) ->
                message = { type: "send_auth", token: jwt_token, accountId: accountId }
                window.parent.postMessage(JSON.stringify(message), "*")
            )
