{stepLookup} = require 'lib/helpers'
ComponentsCollection = require 'collections/components'
StepButtonsCollection = require 'collections/step-buttons'
{renderComponents} = require 'lib/component-renderer'

module.exports = class StepModel extends Backbone.Model
    defaults:
        stepType: null
        content: ''

        # Type-specific options.
        shareUrl: null
        shareText: null
        shareButtons: []

        formId: null
        goalId: null

        # Where this exists in a list.
        stepNumber: null

    validate: ->
        stepType = @get 'stepType'
        unless stepType and stepType isnt 'default'
            return "Invalid step type"

    clear: (options={}) ->
        # Don't clear the id because Firebase needs it.
        attrs = {}
        for key of @attributes
            unless key is 'id'
                attrs[key] = undefined

        @set attrs, _.extend({}, options, {unset: true})

    getComponents: ->
        if @get('stepType') is 'components'
            @components = new ComponentsCollection(@get('components') || [])
            @listenTo @components, 'add remove change update', ->
                @set 'components', @components.toJSON()
            return @components
        else
            return null

    createButton: (type, alignment, defaultText) ->
        @stepButtons.add
            type: type
            align: alignment
            text: defaultText

        @set('stepButtons', @stepButtons.toJSON())

    setButtonVisible: (cID, visibility) ->
        button = @stepButtons.get(cID)
        button.set('isVisible', visibility)
        @set('stepButtons', @stepButtons.toJSON())

    setButtonStyle: (cID, style) ->
        button = @stepButtons.get(cID)
        button.set('style', style)
        @set('stepButtons', @stepButtons.toJSON())

    setButtonText: (cID, text) ->
        changeset = {}
        button = @stepButtons.get(cID)
        switch button.get('type')
            when 'NEXT'
                changeset['nextText'] = text
            when 'BACK'
                changeset['prevText'] = text

        button.set('text', text)
        changeset['stepButtons'] = @stepButtons.toJSON()

        @set(changeset)

    getButtons: ->
        return @stepButtons if @stepButtons?
        if @get('stepButtons')?.length
            _baseButtons = @get('stepButtons')
        else
            _baseButtons = [
                {
                    type: "NEXT"
                    text: @get('nextText')
                },
                {
                    type: "BACK"
                    text: @get('prevText')
                }
            ]

        @stepButtons = new StepButtonsCollection(_baseButtons)

        @listenTo @stepButtons, 'add remove', ->
            @set 'stepButtons', @stepButtons.toJSON()

        return @stepButtons

    _splitContent: (htmlContent) ->
        elements = []
        newComponents = []
        $('<div></div>').append(htmlContent).contents().each (i, el) ->
            # Video embeds are wrapped in this .appcues-responsive-embed class
            if $(el).hasClass('appcues-responsive-embed')
                # If we have any non-video elements saved up, combine them into
                # an "editor" component and reset the list.
                if elements.length
                    newComponents.push {componentType: 'editor', html: $('<div></div>').append(elements).html(), migrated: true}
                    elements = []
                # Add a video component
                newComponents.push {componentType: 'video', embedCode: $(el).html()}
            else
                # If it isn't a video, just add to the list and keep going
                elements.push(el)
        # Add any remaining elements as one more editor component
        if elements.length
            newComponents.push {componentType: 'editor', html: $('<div></div>').append(elements).html(), migrated: true}
        return newComponents

    _migrateToStepButtons: ->
        return if @get('stepButtons')
        _defaultButtons = [
            {
                type: "NEXT"
                text: @get('nextText')
            },
            {
                type: "BACK"
                text: @get('prevText')
            }
        ]

        @stepButtons = new StepButtonsCollection(_defaultButtons)
        @set 'stepButtons', @stepButtons.toJSON()

    _migrateToComponents: ->
        stepType = @get('stepType')
        return if stepType is 'components'

        components = new ComponentsCollection []
        switch stepType
            when 'form'
                formHtml = $(@get('content')).find('.fields').parent().html()
                formComponent = {componentType: 'form', formId: @get('formId'), html: formHtml} if @get('formId')
                components.add [@_splitContent(@get('description'))..., formComponent]
            else
                components.add @_splitContent(@get('content'))

        @set {
            stepType: 'components'
            components: components.toJSON()
            formId: null
            description: null
            content: renderComponents(components, isEditable: false)
            beforeMigration: {
                stepType: @get('stepType')
                content: @get('content')
                formId: @get('formId') || null
                description: @get('description') || null
            }
        }
