ContentItemView = require 'views/content-list/item'

module.exports = class HotspotGroupItemView extends ContentItemView
    __name__: 'HotspotGroupItemView'
    className: -> super + ' hotspot-group-item'
    template: require 'views/templates/content-list/hotspot-group'
    labels: {
        model: 'these hotspots'
        cloneEvent: 'Cloned a Hotspot Group'
        deleteEvent: 'Deleted a Hotspot Group'
        archiveEvent: 'Archived a Hotspot Group'
        unarchiveEvent: 'Unarchived a Hotspot Group'
    }

    getElementId: -> "hotspot-group-#{@model.id}"

    attributes: ->
        'id': @getElementId()
        'data-hotspot-group-id': @model.id

