BaseView = require 'views/base'
executeCopy = require('lib/copy')

module.exports = class CodeSnippetView extends BaseView
    __name__: 'CodeSnippetView'
    className: 'code-snippet'
    template: require 'views/templates/code-snippet'

    events:
        'click button.partner-button': 'selectPartner'
        'click .copy': 'copy'

    initialize: (options={}) ->
        {accountId} = options
        @meta = new Backbone.Model
            accountId: accountId or '<YOUR_APPCUES_ID>'
            cdnDomain: window.__env.CDN_DOMAIN

            @partners = new Backbone.Collection [
                id: 'traditional'
                type: 'platform'
                name: 'Generic'
                userId: '"12345"'
                userEmail: '"john.doe@example.com"'
                userName: '"John Doe"'
                createdAt: '1234567890'
                plan: '"enterprise"'
                isTrial: 'false'
                selected: true
            ,
                id: 'ruby'
                type: 'platform'
                name: 'Ruby'
                userId: '"<%= current_user.id %>"'
                userEmail: '"<%= current_user.email %>"'
                userName: '"<%= current_user.full_name %>"'
                createdAt: '<%= current_user.created_at %>'
                plan: '"<%= current_user.plan %>"'
                isTrial: '"<%= current_user.is_trial %>"'
            ,
                id: 'python'
                type: 'platform'
                name: 'Python'
                userId: '"${user.id}"'
                userEmail: '"${user.email}"'
                userName: '"${user.name}"'
                createdAt: '${user.created_at}'
                plan: '"${user.plan}"'
                isTrial: '"${user.is_trial}"'
            ,
                id: 'php'
                type: 'platform'
                name: 'PHP'
                userId: '"<?php echo $user->id; ?>"'
                userEmail: '"<?php echo $user->email; ?>"'
                userName: '"<?php echo $user->name; ?>"'
                createdAt: '<?php echo $user->created_at; ?>'
                plan: '"<?php echo $user->plan; ?>"'
                isTrial: '"<?php echo $user->is_trial; ?>"'
        ]

        @listenTo @partners, 'change:selected', @render

    render: ->
        selected = @partners.findWhere({selected: true})

        partners = @partners.toJSON()

        context =
            meta: @meta.toJSON()
            platforms: _.where(partners, {type: 'platform'})
            integrations: _.where(partners, {type: 'integration'})
            selectedPartner: if selected then selected.toJSON()

        @$el.html @template(context)

        @$('pre code').each (i, block) ->
            Prism.highlightElement(block)

    selectPartner: (e) ->
        $el = @$ e.currentTarget
        partnerId = $el.data('partner-id').toLowerCase()

        if partnerId
            lytics.trackWithUser 'Selected Installation Instructions', {platformName: partnerId}

            @partners.map (model) ->
                model.set({selected: false}, {silent: true})

            @partners.get(partnerId)
                .set 'selected', true

    copy: (e) ->
        $el = @$ e.currentTarget
        $link = $el[0].previousSibling
        executeCopy($link)
