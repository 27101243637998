auth = require 'models/auth'
AppView = require 'views/app'
crxHelperModel = require 'models/crx-helper'

module.exports = class LaunchCrxView extends AppView
    __name__: 'LaunchCrxView'
    className: 'launch-crx'
    template: require 'views/templates/build-steps/launch-crx'

    initialize: (options) ->
        {@ruleId, @rule, @content, @contentType, @flow, @params} = options
        crxHelperModel.designAtUrlOrShowInstallModal (@content.get('previewUrl') or @rule.get('previewUrl')), @ruleId, @params
