{url} = require 'lib/firebase'
auth = require 'models/auth'
BaseJourneyModel = require 'models/journey-base'


JourneyModel = _.extend {}, BaseJourneyModel, {
    initialize: (attributes, options={}) ->
        BaseJourneyModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        "/accounts/#{accountId}/journeys"
}

module.exports = Backbone.Firebase.Model.extend JourneyModel
