AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class DiagnosticsView extends AppView
    __name__: 'DiagnosticsView'
    className: 'diagnostics'
    template: require 'views/templates/diagnostics'

    afterInitialize: () ->

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderDiagnostics auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)