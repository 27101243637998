AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class GoalsCreateView extends AppView
    __name__: 'GoalsCreateView'
    className: 'goals-create'
    template: require 'views/templates/goals-create'

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderGoalsCreate auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
