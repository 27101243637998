PreviewFrameView = require 'views/preview-frame'
auth = require 'models/auth'


module.exports = class BuildHotspotGroupFrameView extends PreviewFrameView
    __name__: 'BuildHotspotGroupFrameView'
    className: 'build-hotspot-group-frame'

    render: ->
        super

        @once 'eager:initialize extension:initialize', ->
            $.when(@model.whenSyncd(), @collection.whenSyncd()).then =>
                _inject = =>
                    if @previewFrame
                        @_injectIntoEager {
                            'data-account-id': auth.currentAccount.id
                            'data-hotspot-group-id': @model.id
                            'data-hotspot-group-published': @model.get 'published'
                        }
                        _.result @previewFrame, 'checkScriptProtocols'
                        _.result @previewFrame, 'injectRecorder'

                _inject()
                @on 'eager:navigate', _inject
                @startBuilderChecks()

                @_writeHotspotsToLocalStorage()

                @listenTo @model, 'change:skippable change:sequential', (model, value, options) ->
                    @_writeHotspotsToLocalStorage() unless options.silentLocalStorage

                # Backdoor for writing changes to localStorage.
                @listenTo @model, '_update', -> @_writeHotspotsToLocalStorage()

                @listenTo @collection, 'add remove', (model, collection, options) ->
                    @_writeHotspotsToLocalStorage() unless options.silentLocalStorage
                @listenTo @collection, 'change:expanded change:index', (model, value, options) ->
                    @_writeHotspotsToLocalStorage() unless options.silentLocalStorage

                @listenTo @model, 'change:style', (model, value, options) ->
                    @_writeStyleToLocalStorage(value) unless options.silentLocalStorage

                window.addEventListener 'storage', @_onLocalStorageEvent
