{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'
BaseStyleModel = require 'models/style-base'


DefaultStyleModel = _.extend {}, BaseStyleModel, {
    initialize: (attrs={}) ->
        accountId = attrs?.accountId or auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{accountId}/styling"
        @on 'sync', =>
            @syncd = true
        BaseStyleModel.initialize.call @, attrs
}

module.exports = Backbone.Firebase.Model.extend DefaultStyleModel
