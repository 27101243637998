CONTENT_STATES = require 'lib/content-states'


module.exports = BaseGoalModel = {
    defaults:
        state: CONTENT_STATES.DRAFT
        published: false

        sourceType: null
        sourceId: null
        where: null
        isRegex: false

    initialize: (options={}) ->
        @once 'sync', ->
            @syncd = true
}

