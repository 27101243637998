AppView = require 'views/app'
FlowModel = require 'models/flow-firebase'
FlowFunnelView = require 'views/analytics/flow-funnel'
FlowUsersView = require 'views/analytics/flow-users'

page = require 'models/page'


module.exports = class FlowAnalyticsView extends AppView
    __name__: 'FlowAnalyticsView'
    className: 'content-analytics flow-analytics'
    template: require 'views/templates/analytics/flow'

    initialize: (options={}) ->
        super
        unless options.flow
            throw new Error('FlowAnalyticsView initialized without a flow')

        @model = options.flow
        onSync = =>
            name = @model.get('name') or 'Untitled'
            page.set 'title', "#{name}"
            @render()

        if @model.syncd
            onSync()
        else
            @listenToOnce @model, 'sync', onSync

    render: ->
        @removeChildViews()

        @$el.html(@template(model: @model.toJSON()))

        funnelView = new FlowFunnelView(model: @model)
        @$('.main-content').append(funnelView.el)
        @renderChild funnelView

        usersView = new FlowUsersView(model: @model)
        @$('.main-content').append(usersView.el)
        @renderChild usersView


