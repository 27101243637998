CoachmarkGroupModel = require 'models/coachmark-group'
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'
sls = require 'lib/sls-helpers'

module.exports = class CoachmarkGroupsCollection extends Backbone.Firebase.Collection
    model: CoachmarkGroupModel

    initialize: (models=[], options={}) ->
        {@accountId} = options
        @accountId ?= auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{@accountId}/coachmark-groups"

        @once 'sync', ->
            @syncd = true

    cloneModel: (coachmarkGroupId, options={}) ->
        {fromAccountId, coachmarkGroupName} = options

        if fromAccountId?
            # fromAccountId is used to clone template flows from the `sample` account on `/start`
            coachmarkGroup = new CoachmarkGroupModel {id: coachmarkGroupId}, {accountId: fromAccountId}
        else
            coachmarkGroup = @get(coachmarkGroupId)

        sls.clone(coachmarkGroup, fromAccountId)
            .done(({new_content_id}) => @trigger 'clone', @, new_content_id)
