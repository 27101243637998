BaseView = require 'views/base'
JourneyItemView = require 'views/content-list/journey'
FlowItemView = require 'views/content-list/flow'
AnnouncementItemView = require 'views/content-list/announcement'
HotspotGroupItemView = require 'views/content-list/hotspot-group'
CoachmarkView = require 'views/content-list/coachmark-group'
auth = require 'models/auth'
{confirmPublishGroup, confirmUnpublishGroup} = require 'lib/build-helpers'


module.exports = class ContentGroupView extends BaseView
    __name__: 'ContentGroupView'
    className: 'content-group'
    template: require 'views/templates/content-list/group'

    events:
        'click .toggle-collapse > h4': 'toggleCollapse'
        'click [data-action=batch-publish]': 'publishGroup'
        'click [data-action=batch-unpublish]': 'unpublishGroup'

    initialize: (options={}) ->
        {
            @filterState
            @isFiltered
            @journeysCollection
            @announcementsCollection
            @flowsCollection
            @hotspotGroupsCollection
            @coachmarkGroupsCollection
            @rulesCollection
            @accountMembersCollection
            @categoryId
        } = options

    getContext: ->
        group = @model.toJSON()
        if group.totals?.total?
            totalHidden = group.totals.total - @collection.length

        return {
            cannotPublish: auth.user.get('cannotPublish')
            group: group
            total: @collection.length
            totalHidden: totalHidden ? null
            filterState: @filterState.toJSON()
            isFiltered: @isFiltered
            isEmpty: @collection.isEmpty()
            isCollapsed: @isCollapsed()
        }

    render: ->
        @removeChildViews()
        super

        @collection.each (model) =>
            rule = @rulesCollection.get(model.id)
            createdById = model.get('createdBy')
            createdBy = @accountMembersCollection.get(createdById) if createdById
            lastEditedById = model.get('updatedBy')
            lastEditedBy = @accountMembersCollection.get(lastEditedById) if lastEditedById

            switch model.__type__
                when 'journey'
                    view = new JourneyItemView {model, rule, createdBy, lastEditedBy, collection: @journeysCollection}
                    break
                when 'flow'
                    view = new FlowItemView {model, rule, createdBy, lastEditedBy, collection: @flowsCollection}
                    break
                when 'hotspotGroup'
                    view = new HotspotGroupItemView {model, rule, createdBy, lastEditedBy, collection: @hotspotGroupsCollection}
                    break
                when 'coachmarkGroup'
                    view = new CoachmarkView {model, rule, createdBy, lastEditedBy, collection: @coachmarkGroupsCollection}
                    break
                when 'announcement'
                    view = new AnnouncementItemView {model, rule, createdBy, lastEditedBy, collection: @announcementsCollection}
                    break
            if view
                @renderChild view
                @$('table.content-table').append view.el

        @makeSortable() unless @isCollapsed() or @collection.isEmpty()
        return @

    makeSortable: ->
        new Sortable @$('tbody').get(0), {
            group: 'content-categories'
            draggable: 'tr'
            ignore: ''
            sort: false
            onStart: (evt) ->
                _.delay ->
                    $('.content-group').hide()
                    $('.category-areas').show()
        }
        @$('tbody tr').on('dragstart', (evt) ->
            _.delay =>
                this.style.display = 'table'
                this.style.width = getComputedStyle(this.parentElement).width
                evt.dataTransfer?.setDragImage(null, 0, 0)
        ).on('dragend', (evt) ->
            this.style.display = null
            this.style.width = null
            evt.dataTransfer?.setDragImage(null, 0, 0)
        )

    isCollapsed: ->
        _.indexOf(@toggledGroups(), @categoryId) > -1

    toggledGroups: ->
        JSON.parse(localStorage.getItem('toggledGroups')) or []

    toggleCollapse: ->
        # if the group is collapsed
        # remove the categoryId from localStorage
        groups = @toggledGroups()

        if @isCollapsed()
            groups = _.without(groups, @categoryId)

        else
            groups.push @categoryId

        localStorage.setItem('toggledGroups', JSON.stringify(groups))
        @render()

    unpublishGroup: ->
        confirmUnpublishGroup(@model, @collection)

    publishGroup: ->
        confirmPublishGroup(@model, @collection)

