AppView = require 'views/app'
auth = require 'models/auth'
{sendAuthToParentFrame} = require 'lib/helpers'

module.exports = class LaunchQuickstartView extends AppView
    __name__: 'LaunchQuickstartView'
    template: require 'views/templates/quickstart'

    initialize: (options = {}) ->
        if !auth.currentAccount.id
            window.location.href = '/register'
        else
            if window isnt window.top
                sendMessage = () ->
                    sendAuthToParentFrame(auth.get('firebaseUser'), auth.currentAccount.id)

                # Give analytics.track enough time to complete.
                # TODO: remove this by moving tracking completely
                # into reactcues
                window.setTimeout sendMessage, 2000

