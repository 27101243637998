BaseView = require 'views/base'
HotspotsSummaryView = require 'views/build-steps/design/hotspot-group/hotspots-summary'
auth = require 'models/auth'

module.exports = class BuildHotspotGroupSettingsView extends BaseView
    __name__: 'BuildHotspotGroupSettingsView'
    className: 'build-hotspot-group-settings'
    template: require 'views/templates/build-steps/design/hotspot-group/settings'

    events:
        'click [data-action=toggleHotspots]': '_toggleHotspots'
        'change [name=skippable]': '_updateSkippable'
        'change [name=style]': '_updateStyle'

    initialize: (options) ->
        {@rule, @hotspotGroup, @hotspots, @useEager, @styles} = options

        #toggled hotspot editor open when there are errors
        if @hotspotGroup.get('hotspotWarning')
            @viewState = new Backbone.Model {hotspotsOpen: true}
        else
            @viewState = new Backbone.Model {hotspotsOpen: false}

        @listenTo @hotspots, 'add remove', @render
        @listenTo @viewState, 'change:hotspotsOpen', @render

        @listenToOnce @styles, 'sync', =>
            @_verifyStyleExistence()
            @render()

    getContext: ->
        styles = @styles.toJSON() || []
        unless hotspotsStyleModel = @styles.get(@hotspotGroup.get("style"))?.toJSON()
            hotspotsStyleModel = @styles.getDefault()?.toJSON()

        {
            styles
            hotspotsStyle: hotspotsStyleModel
            rule: @rule.toJSON()
            hotspotGroup: @hotspotGroup.toJSON()
            hotspotCount: @hotspots.length
            hotspotsOpen: @viewState.get('hotspotsOpen')
            useEager: @useEager
            hotspotType: if @hotspotGroup.get('sequential') then 'tooltip' else 'hotspot'
        }

    render: ->
        @removeChildViews()
        super

        @hotspotsSummaryView = new HotspotsSummaryView
            hotspotGroup: @hotspotGroup
            collection: @hotspots
            isEditable: not @rule.get('published')
        @$('#build-hotspots .container').append @hotspotsSummaryView.el
        @renderChild @hotspotsSummaryView

    _toggleHotspots: (evt) ->
        @$(evt.currentTarget).toggleClass('open').toggleClass('closed')
        @viewState.set('hotspotsOpen', !@viewState.get('hotspotsOpen'))

    _updateSkippable: ->
        $checkedInput = @$('[name=skippable]:checked')
        @hotspotGroup.set('skippable', $checkedInput.attr('value') is 'true')
        @$('[data-setting=skippable]').text($checkedInput.data('display'))
        if $checkedInput.attr('value') is 'true'
            lytics.trackWithUser 'Made Flow required',
                hotspotGroupId: @hotspotGroup.id

    _updateStyle: ->
        $checkedInput = @$('[name=style]:checked')
        styleId = $checkedInput.attr('value')
        if styleId && @styles.get(styleId)
            if @styles.get(styleId).get("isDefault")
                @hotspotGroup.set 'style', null
            else
                @hotspotGroup.set 'style', styleId
        @$('[data-setting=style]').text($checkedInput.data('display'))
        lytics.trackWithUser 'Chose Flow style',
            hotspotGroupId: @hotspotGroup.id
            style: styleId
        @render()

    _verifyStyleExistence: ->
        if styleId = @hotspotGroup.get("style")
            unless @styles.get(styleId)
                @hotspotGroup.set "style", null
