CoachmarkModel = require 'models/coachmark'
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'


module.exports = class CoachmarksCollection extends Backbone.Firebase.Collection
    model: CoachmarkModel

    LIMIT: 5

    initialize: (models=[], options={}) ->
        {@accountId, @coachmarkGroupId} = options
        @accountId ?= auth.currentAccount.id
        @once 'sync', -> @syncd = true
        @url = firebaseRef.child "accounts/#{@accountId}/coachmark-groups/#{@coachmarkGroupId}/hotspots"

