BaseView = require 'views/base'
Cocoon = require 'lib/cocoon'

module.exports = class SandboxContainer extends BaseView
    __name__: 'SandboxContainer'
    tagName: 'appcues-container'

    initialize: (options={}) ->
        {src, attrs, modelId, collectionId, cocoonOptions, styleId} = options

        @cocoon = new Cocoon _.extend {
            css: {border: 'none', background: 'transparent'}
            class: 'build-frame'
            src: src
        }, cocoonOptions

        # Once the inner frame is ready, send it the init message.
        @cocoon.ready =>
            @cocoon.trigger 'appcues:init', { collectionId, modelId, styleId, attrs }

    render: ->
        @$el.html('')
        @$el.append @cocoon.$frame

    updateModel: (modelId, attrs) ->
        @cocoon.ready =>
            @cocoon.trigger 'appcues:update-model', { modelId, attrs}

    updateStyle: (styleId, attrs) ->
        @cocoon.ready =>
            @cocoon.trigger 'appcues:update-style', { styleId, attrs}
