auth = require 'models/auth'
AppView = require 'views/app'
StylesCollection = require 'collections/styles'
HotspotsCollection = require 'collections/hotspots'
BuildHotspotGroupFrameView = require 'views/build-steps/design/hotspot-group/frame'
BuildHotspotGroupSettingsView = require 'views/build-steps/design/hotspot-group/settings'
HotspotsSummaryView = require 'views/build-steps/design/hotspot-group/hotspots-summary'
ShareModalView = require 'views/share-modal'
page = require 'models/page'
helpers = require 'lib/helpers'
{confirmUnpublishContent} = require 'lib/build-helpers'


module.exports = class BuildHotspotGroupView extends AppView
    __name__: 'BuildHotspotGroupView'
    className: 'build-hotspot-group'
    template: require 'views/templates/build-steps/design/hotspot-group'

    events:
        'click [data-action=initExtensionBuilder]': 'initExtensionBuilder'
        'click [data-action=unpublishContent]': '_unpublishContent'
        'click [data-action=share]': 'share'

    initialize: (options={}) ->
        {@ruleId, @rule, @content, @hotspotGroup} = options
        @listenTo @rule, 'change:published', @render

        @useEager = if localStorage.getItem('useEager') is 'false' then false else true

        @hotspots = new HotspotsCollection [], id: @ruleId

        @styles = if options.styles then options.styles else new StylesCollection

        page.set
            title: @content.get('name')
            isEditable: true
        , skipContent: true
        @listenTo page, 'change:title', (page, model, opts) ->
            if opts.skipContent isnt true
                @content.set 'name', page.get('title')

    getContext: ->
        rule = @rule.toJSON()
        hotspotGroup = @hotspotGroup.toJSON()

        return {
            buildStep: 'design'
            @ruleId
            rule
            hotspotGroup
            urlPrefix: @content.urlPrefix()
            isAdmin: auth.user.get 'isAdmin'
            cannotPublish: auth.user.get 'cannotPublish'
        }

    render: ->
        @removeChildViews()
        super

        @hotspotSettingsView = new BuildHotspotGroupSettingsView
            hotspotGroup: @hotspotGroup
            hotspots: @hotspots
            rule: @rule
            useEager: @useEager
            styles: @styles

        @renderChild @hotspotSettingsView
        @$('#build-hotspot-group-settings').html @hotspotSettingsView.el

        @previewFrameView = new BuildHotspotGroupFrameView
            model: @hotspotGroup
            collection: @hotspots
            rule: @rule
            useEager: @useEager
            styles: @styles

        @$('#build-preview-frame').html @previewFrameView.el
        @renderChild @previewFrameView

    initExtensionBuilder: ->
        @previewFrameView?.initExtensionBuilder()

    _unpublishContent: confirmUnpublishContent

    share: ->
        shareModel = new Backbone.Model({
            flowId: @content.id
            previewUrl: @rule.get('previewUrl')
        })
        v = new ShareModalView
            show: true
            model: shareModel

        @renderChild(v)
