ModalView = require 'views/modal'


module.exports = class StepModalView extends ModalView
    __name__: 'StepModalView'
    template: require 'views/templates/step-modal'

    getContext: ->
        model: @model.toJSON()

