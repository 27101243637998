BaseComponentView = require 'views/components/base'
FormsCollection = require 'collections/forms'
FormFieldsCollection = require 'collections/form-fields'
FormFieldsView = require 'views/form-fields'


module.exports = class FormComponentView extends BaseComponentView
    __name__: 'FormComponentView'
    className: 'component-editor form-component'
    template: require 'views/templates/components/form'

    initialize: ->
        super
        @forms = new FormsCollection
        @forms.whenSyncd =>
            @formId = @model.get('formId')
            if @formId
                @form = @forms.get(@formId)
            else
                formAttrs = @forms.create {}
                @model.set {formId: @formId = formAttrs.id}, {skipRender: true}

            @fields = new FormFieldsCollection [], {formId: @formId}
            @fields.whenSyncd =>
                @fields.add {} if @fields.length is 0
                @listenTo @fields, 'add remove', @render
                @render()

    getContext: ->
        _.extend({}, super,
            form: @form?.toJSON() ? {}
            fields: @fields?.toJSON() ? []
        )

    render: ->
        @removeChildViews()
        super
        if @fields?.syncd
            v = new FormFieldsView
                collection: @fields
                isEditable: true
            @renderChild v
            @$('.build-form-fields').append(v.el)
        @

    updateValue: ->
        v = new FormFieldsView
            collection: @fields
            isEditable: false
        v.render()
        parent = document.createElement('div')
        form = document.createElement('form')
        form.className = 'step-action-form'
        form.setAttribute 'data-form-id', @formId
        parent.appendChild(form).appendChild(v.el)
        @model.set('html', parent.innerHTML)

