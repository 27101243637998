AppView = require 'views/app'
hubspot = require 'lib/hubspot'
{firebase} = require 'lib/firebase'


module.exports = class PasswordResetView extends AppView
    __name__: 'PasswordResetView'
    template: require 'views/templates/password-reset'
    model: require 'models/auth'

    initialize: (attrs={}) ->
        {@isNewUser, @email, @password} = attrs

        # Login successful.
        @listenTo @model, 'login', ->
            req = hubspot.updateContact @model.get('email'), {
                last_login: hubspot.getTimestamp()
            }, {timeout: 300}

            req.always ->
                window.location.href = '/'

        # Failed to login.
        @listenTo @model, 'error', (error) ->
            @$('form [type=submit]').removeClass 'disabled'
            @$('.validation-error').removeClass 'hidden'

    events:
        'submit form.reset-form': 'setPassword'

    setPassword: ->
        @$('form [type=submit]').addClass 'disabled'
        data = @$('form.reset-form').serializeObject()

        # Change password and immediately log in.
        firebase.changePassword {
            email: data['email'],
            oldPassword: data['oldPassword'],
            newPassword: data['password']
        }, (error, succeeded) ->
            unless error
                model.login 'password', data

        return false

    render: ->
        super
        _.defer => @$('input[name="password"]').focus()

    getContext: -> {
        email: @email
        password: @password
        isNewUser: @isNewUser
    }
