BaseView = require 'views/base'

defaultOptions =
    backdrop: true
    keyboard: true
    show: false
    remote: false

class BaseAlert extends BaseView
    className: 'modal fade'
    template: require 'views/templates/modal'

    initialize: (options={}) ->
        @_options = _.extend {}, defaultOptions, options
        Backbone.$('body').append @el
        @$el.on 'hidden.bs.modal', _.bind(@remove, @)

    events:
        'click [data-event]': 'handle'

    show: ->
        @$el.modal 'show'

    hide: ->
        @$el.modal 'hide'

    handle: (e) ->
        eventName = @$(e.currentTarget).data('event')
        @trigger eventName
        @hide()

    render: ->
        @$el.html(@template(@getContext()))
        return @

    getContext: ->
        @model.toJSON()


class Confirm extends BaseAlert
    template: require 'views/templates/alerts/confirm'


class ConfirmAndWait extends Confirm
    handle: (e) ->
        eventName = @$(e.currentTarget).data('event')
        @trigger eventName

class Alert extends BaseAlert
    template: require 'views/templates/alerts/alert'


module.exports = {
    confirm: (title, content) ->
        deferred = $.Deferred()

        if _.isObject(content)
            attrs = content
        else
            attrs = {}

        opts = {model: new Backbone.Model(_.extend attrs, {title})}

        view = new Confirm(opts)
        view.once 'confirm', -> deferred.resolve(true)
        view.once 'cancel', -> deferred.resolve(false)

        view.render().show()

        return deferred.promise()

    confirmAndWait: (title1, content1, title2, content2) ->
        deferred = $.Deferred()

        if _.isObject(content1)
            attrs1 = content1
        else
            attrs1 = {}

        opts = { model: new Backbone.Model(_.extend attrs1, title: title1)}

        view = new ConfirmAndWait(opts)
        view.once 'confirm', ->
            if _.isObject(content2)
                attrs2 = content2
            else
                attrs2 = {}
            view.model.set(_.extend(attrs2, title: title2, hideFooter: true ))
            view.render()
            deferred.resolve(-> view.hide())
        view.once 'cancel', -> deferred.resolve(false)

        view.render().show()

        return deferred.promise()

    alert: (content, options) ->
        deferred = $.Deferred()

        if _.isObject(content)
            attrs = content
        else
            attrs = {title: content}

        opts = _.extend {
            model: new Backbone.Model(attrs)
        }, options

        view = new Alert(opts)
        view.once 'confirm', -> deferred.resolve()

        view.render().show()

        return deferred.promise()
}
