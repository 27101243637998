auth = require 'models/auth'
AppView = require 'views/app'
{firebase} = require 'lib/firebase'
FeedbackCollection = require 'collections/feedback'
auth = require 'models/auth'
trackEvent = require 'lib/track-event'

module.exports = class TeaserView extends AppView
    template: require 'views/templates/teasers/example-teaser'

    events:
        'submit form': 'submit'

    initialize: (options={}) ->
        {@teaserId} = options

        if @teaserId
            @collection = new FeedbackCollection([], {id: @teaserId})
        else
            @collection = new Backbone.Collection

        @listenTo @collection, 'add remove', @render

    submit: ->
        {body} = @$('form').serializeObject()

        # Track the response.
        try
            trackEvent 'Submitted feedback', {
                feedbackId: @teaserId
                text: body
            }

        @collection.add {
            createdAt: firebase.database.ServerValue.TIMESTAMP
            createdBy: auth.id
            createdByName: auth.user.get('fullname')
            createdByEmail: auth.user.get('email')
            createdByAvatar: auth.user.avatarUrl()
            createdByAdmin: auth.user.get('isAdmin') is true
            body: body
        }

        return false

    getContext: ->
        feedback = @collection.toJSON()

        # Group feedback from the same person into a single thread.
        threads = []
        hasCollaborators = feedback.reduce((memo, next) ->
            return memo or next.createdBy isnt auth.id
        , false)

        while feedback.length > 0
            thread = feedback.shift()
            if thread.createdBy is auth.id
                thread.createdByDisplay = 'You'
            else
                thread.createdByDisplay = thread.createdByName or thread.createdByEmail

            thread.messages = [thread.body]
            while feedback.length and feedback[0].createdBy is thread.createdBy
                last = feedback.shift()
                thread.createdAt = last.createdAt
                thread.messages.push(last.body)

            thread.updatedAtDisplay = moment(thread.createdAt).twitter()
            threads.push(thread)

        return {
            feedback: threads
            hasCollaborators: hasCollaborators
        }
