BaseView = require 'views/base'
PreviewFrame = require 'lib/preview-frame'
StylesCollection = require 'collections/styles'
auth = require 'models/auth'
ENV = window.__env

POWERED_BY_PLAN_IDS = ["99-bootstrap"]
SUBSCRIPTION_LINK = "https://my.appcues.com/subscription"


module.exports = class PreviewFrameView extends BaseView
    __name__: 'PreviewFrameView'
    className: 'preview-frame'

    events:
        'click [data-action=initExtensionBuilder]': 'initExtensionBuilder'

    initialize: (options) ->
        {@rule, @contentType, @useEager, @flow} = options
        unless @useEager?
            @useEager = true

        @listenTo @rule, 'change:previewUrl', @_navigateFrame
        if @flow
            @listenTo @flow, 'change:patternType', @render

        @styles = options.styles || new StylesCollection
        @styles.whenSyncd =>
            @_writeStyleToLocalStorage

        $(window).on "resize", _.debounce(_.bind(@_updateFrameHeight, @), 100)

    render: ->
        super

        contentTypeisFlow = @contentType is 'flows'
        textualBadge = @flow && @flow.attributes && @flow.attributes.patternType == "shorty"

        @$el.empty()
        if @useEager
            @previewFrame = new EagerPreview
                partnerId: 'appcues'
                el: @$el.get(0)
                showURLBar: false
                # FIX: Set a better default.
                frameSrc: @rule.getPreviewUrl()
        else
            @previewFrame = new PreviewFrame
                el: @$el.get(0)
                rule: @rule
                frameSrc: @rule.getPreviewUrl()
            @previewFrame.initialize()
        @_updateFrameHeight()

        # include 'useable' css from sdk.  this is the only part that's not insulated by the iframe
        @injectUseableCss()

        # show poweredby if user is in a trial or in POWERED_BY_PLANS_IDS
        # only show poweredby for modals
        if contentTypeisFlow and not textualBadge
            @renderPoweredByBadge() if @shouldShowPoweredByBadge()

        @previewFrame.on 'load', (evt) => @trigger 'eager:initialize', evt
        @previewFrame.on 'navigate', (evt) =>
            if /#appcues-init-error$/.test(evt.shownUrl)
                @_removePreviewFrame()
                @showExtensionBuilderForm() unless contentTypeisFlow
            else
                @trigger 'eager:navigate', evt

    undelegateEvents: ->
        super
        @off 'eager:initialize eager:navigate'
        @previewFrame?.off('load')
        @previewFrame?.off('navigate')
        $(window).off("resize.previewFrame#{@rule?.id}")
        window.clearTimeout @builderCheckTimeout if @builderCheckTimeout?
        window.removeEventListener 'storage', @_onLocalStorageEvent
        localStorage.removeItem @model.id if @model?

    _updateFrameHeight: ->
        topPadding = $('#banner').outerHeight() +
                     $('#build-published-warning').outerHeight() +
                     $('#build-navigation').outerHeight() +
                     $('#build-flow-settings').outerHeight()
        @$el.css('top', topPadding)
        @$el.css('height', $(window).height() - topPadding)

    _navigateFrame: ->
        @previewFrame?.navigate(@rule.getPreviewUrl())

    remove: ->
        if @previewFrame and _.isFunction @previewFrame.remove
            @previewFrame.remove()
        super

    _buildLoaderScript: ->
        return (scriptAttrs, cssAttrs) ->
            script = document.createElement('script')
            link = document.createElement 'link'

            for key, value of scriptAttrs
                script.setAttribute key, value

            for key, value of cssAttrs
                link.setAttribute key, value

            unless window.__APPCUES_HOTSPOT_BUILDER_INITIALIZED
                document.head.appendChild script
                document.head.appendChild link
                window.__APPCUES_HOTSPOT_BUILDER_INITIALIZED = true
                return

    _injectIntoEager: (scriptData={}) ->
        console.log "Injecting into eager."
        # Build a loader function that will insert
        # JS and CSS tags into the page.
        script = @_buildLoaderScript()
        # Convert this function into a string and make it
        # execute itself.
        _.extend scriptData, {
            id: 'appcues-reloaded'
            type: 'application/javascript'
            src: ENV.HOTSPOTS_EMBED_SCRIPT_URL
        }, scriptData
        scriptAttrs = JSON.stringify(scriptData)
        cssAttrs = JSON.stringify
            rel: 'stylesheet'
            type: 'text/css'
            href: ENV.HOTSPOTS_EMBED_CSS_URL
        selfExecutingFuncString = "(#{script.toString()})(#{scriptAttrs},#{cssAttrs})"
        @previewFrame?.inject
            type: 'application/javascript'
            content: selfExecutingFuncString

        # Inject our screen recording script.
        # We need to do this here so it works for the Chrome ext previews.
        @injectRecorder()

    _writeHotspotsToLocalStorage: ->
        console.log "Writing hotspots data to localStorage."
        # Only write data that matters to the builder to localStorage. That way
        # we don't have to worry about keeping unnecessary things in sync.
        omitted = ['hotspots', 'createdAt', 'createdBy', 'name', 'updatedAt', 'updatedBy']
        data = _.extend {}, @model.omit(omitted), {
            hotspots: @collection.toJSON()
        }
        localStorage.setItem @model.id, JSON.stringify(data)

    _writeStyleToLocalStorage: (styleId) ->
        style = if styleId then @styles.get(styleId) else @styles.getDefault()
        localStorage.setItem 'apc_globalStylingData', JSON.stringify(style.toJSON())

    _onLocalStorageEvent: (evt) =>
        if evt.key is @model.id
            data = JSON.parse evt.newValue
            groupAttrs = _.omit data, 'hotspots'
            # Don't trigger another localStorage update.
            @model.set groupAttrs, silentLocalStorage: true

            # Manually handle adding a new hotspot to the group. Firebase
            # only syncs on collection.create(), not collection.add().
            if data.hotspots?
                try
                    old = JSON.parse(evt.oldValue)
                    if old.hotspots.length < data.hotspots.length
                        # Get the newly added one.
                        added = _.find data.hotspots, (hotspot) ->
                            not hotspot.id?
                        @collection.create added, silentLocalStorage: true
                    else
                        @collection.set data.hotspots, silentLocalStorage: true
                catch e
                    console.warn 'Unable to add a new collection'
            else
                @collection.set data.hotspots, silentLocalStorage: true

    startBuilderChecks: ->
        # Return if we're already running this on a repeated timeout
        return if @builderCheckTimeout?
        sendBuilderCheck = =>
            if @previewFrame.sendMessage?
                @previewFrame.sendMessage { action: 'appcues:builder-check' }
            else
                # Eager, inject a script to postMessage it's own window :/
                @previewFrame.inject {
                    type: 'application/javascript'
                    content: """
                        (function () {
                            window.postMessage({action: 'appcues:builder-check'}, '*');
                        })();
                    """
                }

            @builderCheckTimeout = window.setTimeout sendBuilderCheck, 1000
        @builderCheckTimeout = window.setTimeout sendBuilderCheck, 1000

    initExtensionBuilder: ->
        if _.isFunction window.chrome?.runtime?.sendMessage
            # Make sure we don't still have a preview frame on the page before we pop up the new builder window.
            @_removePreviewFrame() if @previewFrame
            @trigger('extension:initialize')
            window.chrome.runtime.sendMessage ENV.CHROME_EXTENSION_ID, {
                action: 'appcues:init-builder-tab'
                data: {
                    contentType: @rule.get('contentType')
                    contentId: @rule.id
                    contentPublished: @rule.get('published')
                    previewUrl: @rule.getPreviewUrl()
                }
            }

    _removePreviewFrame: ->
        # Remove all the actual iframe setup
        @previewFrame.remove()
        @previewFrame = null
        $(window).off "resize.previewFrame#{@rule.id}"
        @stopListening @rule, 'change:previewUrl'
        window.clearTimeout @builderCheckTimeout if @builderCheckTimeout?

    showExtensionBuilderForm: ->
        template = require 'views/templates/preview-frame-launch-extension'
        @$el.html template @rule.toJSON()

    injectUseableCss: ->
        $link = $("<link/>").attr
            rel: "stylesheet"
            type: "text/css"
            href: ENV.SDK_URL + "/useable.min.css"
        @$el.prepend $link

    # Powered by container
    renderPoweredByBadge: (options={})->
        if @flow.get('position').toLowerCase().lastIndexOf('left') > -1
            style = "'right: 16px; left: initial;'"

        html = """<div class="appcues-powered-by-wrapper #{if options.hidden then 'hidden' else ''}">
            <a href="#{SUBSCRIPTION_LINK}" target="_blank">
                <div class="powered-by-container" #{if style then "style=" + style}>
                    <div class="subscription-info">Upgrade to hide branding</div>
                    <div class="powered-by-content">
                        <div class="logo-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.71 45.567"><polygon style="fill: #bebebe" points="15.939 25.197 28.904 45.567 35.71 45.567 35.71 0 15.939 25.197"/><polygon style="fill: #bebebe" points="0 45.567 12.516 45.567 12.516 29.466 0 45.567"/></svg>
                        </div>
                        <div class="poweredby-text-top">Powered by</div>
                        <div class="poweredby-text-bottom">Appcues</div>
                    </div>
                </div>
            </a>
        </div>"""

        wrapper = @$el.find '.appcues-powered-by-wrapper'

        if wrapper.length is 0
            @$el.append html
        else
            wrapper.replaceWith html

    shouldShowPoweredByBadge: ->
        account = auth.currentAccount.attributes
        (account.stripePlanId in POWERED_BY_PLAN_IDS and not account.hidePoweredBy) or
            (account.isTrial and account.stripeId is '')

    ###*
     * Adds in screen recording software that we can leverage.
    ###
    injectRecorder: ->
        # Require a FullStory ID.
        if ENV.FULLSTORY_ID and @previewFrame?
            @previewFrame.inject {
                type: 'application/javascript'
                content: _.result(PreviewFrame, 'buildRecorderScript')
            }
