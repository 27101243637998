BaseView = require 'views/base'
FormFieldModel = require 'models/form-field'
{confirm} = require 'views/alerts'

module.exports = class FormFieldChoiceView extends BaseView
    __name__: 'FormFieldChoiceView'
    className: 'form-field form-field-choice'
    model: new FormFieldModel
    template: require 'views/templates/form-fields/choice'

    initialize: (options={}) ->
        super
        @isEditable = options.isEditable

    attributes: ->
        'data-appcues-required': @model.get 'required'
        'data-appcues-validation': @model.get 'validationType'

    events: ->
        'click .add-field-option': 'addFieldOption'
        'click .remove-field-option': 'removeFieldOption'
        'blur input': 'updateModel'

    getContext: ->
        context = @model.toJSON()
        context.isEditable = @isEditable
        context

    addFieldOption: ->
        @$el.find('.field-option-template').clone().appendTo(@$('div.field-options')).removeClass('hidden field-option-template').find('input.option-value').focus()

    removeFieldOption: (e) ->
        confirm('Are you sure you want to remove this option?').then (res) =>
            if res
                $el = @$(e.currentTarget)
                $formOption = $el.closest('.field-option').parent('div')
                $formOption.remove()
                @updateModel()

    render: ->
        super
        if @isEditable
            new Sortable(@$('div.field-options').get(0), {
                draggable: '.field-option-wrapper'
                handle: '.option-drag-handle'
                onUpdate: (evt) =>
                    @updateModel()
            })
        @

    updateModel: ->
        @model.set('label', @$el.find('input.label-value').val())
        fieldOptions = @$('div.field-option-wrapper').not('.field-option-template').map((i, fieldOption)->
            input = $(fieldOption).find('input.option-value')
            input.val($.trim(input.val()))
            input.val()
        ).toArray()
        @model.set('fieldOptions', fieldOptions)

