helpers = require 'lib/helpers'

module.exports = class CustomRouter extends Backbone.Router
    initialize: ->
        $(window).bind 'beforeunload', ->
            routerBeforeNavigationMessage = localStorage?.getItem('routerBeforeNavigationMessage')
            if routerBeforeNavigationMessage
                return routerBeforeNavigationMessage
            return

    beforeRoute: (route, name) ->
    afterRoute: (route, name) ->
    beforeNavigate: (fragment) ->
    afterNavigate: (fragment) ->

    navigate: (fragment, options) ->
        @beforeNavigate?.call @, fragment
        routerBeforeNavigationMessage = localStorage?.getItem('routerBeforeNavigationMessage')

        if routerBeforeNavigationMessage
            routerBeforeNavigationCTA = localStorage?.getItem('routerBeforeNavigationCTA')
            return @ if !window.confirm(routerBeforeNavigationMessage + ' ' + routerBeforeNavigationCTA)
            localStorage?.setItem('routerBeforeNavigationMessage', '')
            localStorage?.setItem('routerBeforeNavigationCTA', '')

        Backbone.history.navigate fragment, options
        @afterNavigate?.call @, fragment
        return @

    route: (route, name, callback) ->
        wrappedCallback = ->
            callback ?= @[name]

            @beforeRoute?.call @, route, name
            callback.apply @, arguments
            @afterRoute?.call @, route, name

        super route, name, wrappedCallback

    trackRoute: (routeName) ->
        window.ga? 'send', 'pageview', location.pathname

        pageName = @metadata[routeName]?.title or routeName

        queryStringObject = helpers.queryStrToObj()
        options = if Object.keys(queryStringObject)[0] then queryStringObject or {}
        options = Object.assign({}, options, { pageName: pageName, userAgent: window.navigator.userAgent })

        _.defer ->
            try
                analytics.page("App", options)
