BaseView = require 'views/base'
StepsSummaryView = require 'views/build-steps/design/flow/steps-summary'

auth = require 'models/auth'

module.exports = class BuildFlowSettingsView extends BaseView
    __name__: 'BuildFlowSettingsView'
    className: 'build-flow-settings'
    template: require 'views/templates/build-steps/design/flow/settings'

    events:
        'click [data-action=toggleFlowSteps]': '_toggleFlowSteps'
        'change [name=patternType]': '_updatePatternType'
        'change [name=position]': '_updatePosition'
        'change [name=skippable]': '_updateSkippable'
        'change [name=style]': '_updateStyle'
        'click [data-action=toggleBackdrop]': '_toggleBackdrop'

    initialize: (options) ->
        {@rule, @flow, @steps, @styles} = options

        @viewState = new Backbone.Model {stepsOpen: false}
        @isBetaUser = auth.currentAccount?.isInGeneralBeta?()

        @listenTo @steps, 'add remove', @render
        @listenTo @viewState, 'change:stepsOpen', @render

        @listenToOnce @styles, 'sync', =>
            @_verifyStyleExistence()
            @render()

    getContext: ->
        styles = @styles.toJSON() || []
        unless flowStyleModel = @styles.get(@flow.get("style"))?.toJSON()
            flowStyleModel = @styles.getDefault()?.toJSON()

        {
            styles
            flowStyle: flowStyleModel
            rule: @rule.toJSON()
            flow: @flow.toJSON()
            isBetaUser: @isBetaUser
            stepCount: @steps.length
            stepsOpen: @viewState.get('stepsOpen')
        }

    render: ->
        @removeChildViews()
        super

        @stepsSummaryView = new StepsSummaryView
            flow: @flow
            collection: @steps
            isEditable: not @rule.get('published')
        @$('#build-flow-steps .container').append @stepsSummaryView.el
        @renderChild @stepsSummaryView

    _toggleFlowSteps: (evt) ->
        @$(evt.currentTarget).toggleClass('open').toggleClass('closed')
        @viewState.set('stepsOpen', !@viewState.get('stepsOpen'))
        $('#build-preview-frame').trigger('resize')

    _toggleBackdrop: () ->
        @flow.set('backdrop', !@flow.get('backdrop'))
        @render()

    # when the patternType changes, we should 'forget' position data
    _resetCustomAttributes: () =>
        flowIsShorty = (@flow.get('patternType') == 'shorty')
        @flow.set('position', (if flowIsShorty then 'bottomLeft' else 'top'))
        @flow.set('backdrop', !flowIsShorty)

    _updatePatternType: ->
        $checkedInput = @$('[name=patternType]:checked')
        @flow.set('patternType', $checkedInput.attr('value'))
        @$('[data-setting=pattern]').text($checkedInput.data('display'))
        @_resetCustomAttributes()
        lytics.trackWithUser 'Chose Flow pattern',
            flowId: @flow.id
            pattern: $checkedInput.attr('value')
        @render()

    _updatePosition: ->
        $checkedInput = @$('[name=position]:checked')
        @flow.set('position', $checkedInput.attr('value'))
        @$('[data-setting=position]').text($checkedInput.data('display'))
        lytics.trackWithUser 'Chose Flow position',
            flowId: @flow.id
            pattern: $checkedInput.attr('value')
        @render()

    _updateSkippable: ->
        $checkedInput = @$('[name=skippable]:checked')
        @flow.set('skippable', $checkedInput.attr('value') is 'true')
        @$('[data-setting=skippable]').text($checkedInput.data('display'))
        if $checkedInput.attr('value') is 'true'
            lytics.trackWithUser 'Made Flow required',
                flowId: @flow.id
        @render()

    _updateStyle: ->
        $checkedInput = @$('[name=style]:checked')
        styleId = $checkedInput.attr('value')
        if styleId && @styles.get(styleId)
            if @styles.get(styleId).get("isDefault")
                @flow.set 'style', null
            else
                @flow.set 'style', styleId
        @$('[data-setting=style]').text($checkedInput.data('display'))
        lytics.trackWithUser 'Chose Flow style',
            flowId: @flow.id
            style: styleId
        @render()

    _verifyStyleExistence: ->
        if styleId = @flow.get("style")
            unless @styles.get(styleId)
                @flow.set "style", null
