PreviewFrameView = require 'views/preview-frame'
auth = require 'models/auth'


module.exports = class CoachmarksFrameView extends PreviewFrameView
    __name__: 'CoachmarksFrameView'
    className: 'coachmarks-frame'

    initialize: ->
        super

        # Keep only one coachmark expanded at a time.
        @listenTo @collection, 'change:expanded', (model, expanded, options) =>
            if model.get('expanded')
                models = @collection.map (item) ->
                    attrs = item.toJSON()
                    if item.get('expanded') and item.id isnt model.id
                        attrs.expanded = false
                    attrs
                _.defer =>
                    @collection.set(models)

    render: ->
        super

        @once 'eager:initialize extension:initialize', ->
            $.when(@model.whenSyncd(), @collection.whenSyncd()).then =>
                _inject = =>
                    @_injectIntoEager {
                        'data-account-id': auth.currentAccount.id
                        'data-coachmark-group-id': @model.id
                        'data-coachmark-group-published': @model.get 'published'
                    }
                    _.result @previewFrame, 'checkScriptProtocols'
                    _.result @previewFrame, 'injectRecorder'

                _inject()
                @on 'eager:navigate', _inject
                @startBuilderChecks()

                @_writeHotspotsToLocalStorage()

                @listenTo @collection, 'add remove', (model, collection, options) ->
                    @_writeHotspotsToLocalStorage() unless options.silentLocalStorage
                @listenTo @collection, 'change:expanded', (model, value, options) ->
                    @_writeHotspotsToLocalStorage() unless options.silentLocalStorage
                window.addEventListener 'storage', @_onLocalStorageEvent

