module.exports =
  traditional: {
      id: 'traditional'
      type: 'platform'
      name: 'Generic HTML'
      userId: '<<INSERT_YOUR_UNIQUE_USER_ID_HERE>>'
      userEmail: '"john.doe@example.com"'
      userName: '"John Doe"'
      createdAt: '1234567890'
      plan: '"enterprise"'
      isTrial: 'false'
  },
  ruby: {
      id: 'ruby'
      type: 'platform'
      name: 'Ruby'
      userId: '"<%= current_user.id %>"'
      userEmail: '"<%= current_user.email %>"'
      userName: '"<%= current_user.full_name %>"'
      createdAt: '<%= current_user.created_at %>'
      plan: '"<%= current_user.plan %>"'
      isTrial: '"<%= current_user.is_trial %>"'
  },
  python: {
      id: 'python'
      type: 'platform'
      name: 'Python'
      userId: '"${user.id}"'
      userEmail: '"${user.email}"'
      userName: '"${user.name}"'
      createdAt: '${user.created_at}'
      plan: '"${user.plan}"'
      isTrial: '"${user.is_trial}"'
  },
  php: {
      id: 'php'
      type: 'platform'
      name: 'PHP'
      userId: '"<?php echo $user->id; ?>"'
      userEmail: '"<?php echo $user->email; ?>"'
      userName: '"<?php echo $user->name; ?>"'
      createdAt: '<?php echo $user->created_at; ?>'
      plan: '"<?php echo $user->plan; ?>"'
      isTrial: '"<?php echo $user->is_trial; ?>"'
  },
  angular: {
      id: 'angular'
      type: 'platform'
      name: 'Angular'
      userId: '"{{ request.user.id }}"'
      userEmail: '"{{ request.user.email }}"'
      userName: '"{{ request.user.full_name }}"'
      createdAt: '{{ request.user.created_at }}'
      plan: '"{{ request.user.plan }}"'
      isTrial: '{{ request.user.is_trial }}'
  },
  ember: {
      id: 'ember'
      type: 'platform'
      name: 'Ember'
      userId: '<<INSERT_YOUR_UNIQUE_USER_ID_HERE>>'
      userEmail: '"john.doe@example.com"'
      userName: '"John Doe"'
      createdAt: '1234567890'
      plan: '"enterprise"'
      isTrial: 'false'
  },
  react: {
      id: 'react'
      type: 'platform'
      name: 'React'
      userId: '<<INSERT_YOUR_UNIQUE_USER_ID_HERE>>'
      userEmail: '"john.doe@example.com"'
      userName: '"John Doe"'
      createdAt: '1234567890'
      plan: '"enterprise"'
      isTrial: 'false'
  },
  other: {
      id: 'other'
      type: 'platform'
      name: 'Other'
      userId: '"<?php echo $user->id; ?>"'
      userEmail: '"<?php echo $user->email; ?>"'
      userName: '"<?php echo $user->name; ?>"'
      createdAt: '<?php echo $user->created_at; ?>'
      plan: '"<?php echo $user->plan; ?>"'
      isTrial: '<?php echo $user->is_trial; ?>'
  }
