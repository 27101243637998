AppView = require 'views/app'
auth = require 'models/auth'


module.exports = class BillingStory extends AppView
    __name__: 'BillingStory'
    template: require 'views/templates/billing-story'

    render: ->
        super

        if window.apcInjectable?
            window.apcInjectable.renderSubscriptionStory(auth.currentAccount.id, "apc-react-root")
        else
            window.setTimeout(@render.bind(@), 300)
