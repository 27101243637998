auth = require 'models/auth'


module.exports = class SegmentModel extends Backbone.Model
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        name: 'Unnamed segment'
        properties: null

    initialize: (attrs={}) ->
        @once 'sync', -> @syncd = true

