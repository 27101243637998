{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class SegmentsCollection extends Backbone.Firebase.Collection
    model: require 'models/segment'

    url: ->
        accountId = @accountId ? auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/segments"

    initialize: (attrs={}) ->
        {@accountId} = attrs
        @once 'sync', -> @syncd = true

    comparator: 'name'

