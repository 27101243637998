BaseView = require 'views/base'


module.exports = class BaseComponentView extends BaseView
    events: ->
        'click [data-action=close]': 'close'
        'click [data-action=saveAndClose]': 'saveAndClose'
        'click [data-action=delete]': 'delete'

    getContext: ->
        @model.toJSON()

    render: ->
        super
        _.delay => @focus()
        @

    focus: ->

    updateValue: ->

    close: ->
        $($('.component-editor-mask'), @el).fadeOut(100, =>
            @remove()
            $('.component-editor-mask').remove()
        )

    remove: ->
        @trigger 'remove'
        super

    saveAndClose: ->
        @updateValue()
        @close()

    delete: ->
        @close()
        @model.destroy()
