BaseView = require 'views/base'


module.exports = class GoalWhereView extends BaseView
    __name__: 'GoalWhereView'
    className: 'goal-where'
    template: require 'views/templates/build-steps/settings/goal-where'

    initialize: (options={}) ->
        {@rule, @goal} = options
        @viewState = new Backbone.Model(hasGoal: (@goal.isRegex && @goal.where))
        @listenTo @goal, 'change:isRegex change:where', @render
        @listenTo @goal, 'change:isRegex change:where', (goal, value)->
            @viewState.set(hasGoal: true) if value

        @listenTo @viewState, 'change:hasGoal', @render

    events:
        'change [name=isRegex]': '_updateRegex'
        'change [name=where]': '_updateWhere'
        'click [data-action=addGoal]': '_addGoal'
        'click [data-action=clearGoal]': '_clearGoal'

    _updateRegex: (evt) ->
        @goal.set('isRegex', @$(evt.currentTarget).val())

    _updateWhere: (evt) ->
        @goal.set('where', @$(evt.currentTarget).val())

    _addGoal: ->
        @viewState.set('hasGoal', true)
        lytics.trackWithUser 'Added a goal',
            ruleId: @rule.id

    _clearGoal: (evt) ->
        @goal.set {
            isRegex: null
            where: null
        }
        @viewState.set('hasGoal', false)
        lytics.trackWithUser 'Removed a goal',
            ruleId: @rule.id

    getContext: ->
        rule: @rule.toJSON()
        goal: @goal.toJSON()
        hasGoal: @viewState.get('hasGoal')

    render: ->
        super
        if @rule.get('isRegex')
            @$('[name=where]').attr('placeholder', 'e.g. /campaigns/.*/')
        else
            @$('[name=where]').attr('placeholder', 'e.g. /dashboard')
