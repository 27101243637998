{INTEGRATION_SERVICE_URL} = window.__env
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'
{confirm} = require 'views/alerts'
bundler = require 'lib/bundler'

AppView = require 'views/app'
IntegrationsTemplate = require 'views/templates/integrations'
Integrations = require 'collections/integrations'
AccountIntegrations = require 'collections/account-integrations'
helpers = require 'lib/helpers'

# Gets all available integrations.
class IntegrationTypes extends Backbone.Firebase.Collection
    url: -> firebaseRef.child('integration-types')
    initialize: ->
        @once 'sync', -> @syncd = true

module.exports = class IntegrationsView extends AppView
    __name__: 'IntegrationsView'
    template: IntegrationsTemplate

    events:
        'submit .integration-form': 'saveSettings'
        'click .remove-integration': 'disableIntegration'
        'click .activate-integration': 'activateIntegration'
        'click .cancel-configuration': 'cancelConfiguration'

    initialize: ->
        @collection = new Backbone.Collection
        @integrations = new Integrations

        # Get integrations that this user has activated.
        integrationTypes = new IntegrationTypes
        accountIntegrations = new AccountIntegrations

        combine = _.bind @combine, @, @collection, integrationTypes, accountIntegrations
        afterSync = _.after 2, combine

        for col in [integrationTypes, accountIntegrations]
            if col.syncd
                afterSync()
            else
                @listenToOnce col, 'sync', -> afterSync()

        @listenTo accountIntegrations, 'add change', -> combine()
        @listenTo accountIntegrations, 'remove', ->
            @collection.reset()
            combine()

        @listenTo @collection, 'merge', @render
        @listenTo @collection, 'change', @render

        # After the initial sync, start listening for
        # add/remove events, to trigger rebundles.
        # We don't want the initial adds to trigger rebundles.
        @listenToOnce accountIntegrations, 'sync', ->
            @listenTo accountIntegrations, 'add remove', ->
                bundler.sync auth.currentAccount.id

    # Merges one or more collections.
    combine: (col1, otherCols...) ->
        options = {remove: false, silent: true}
        for col in otherCols
            col1.set col.toJSON(), options
        col1.trigger 'merge'

    getContext: ->
        {
            integrations: @collection.toJSON()
            integrationUrl: INTEGRATION_SERVICE_URL
        }

    saveSettings: (e) ->
        $el = @$ e.currentTarget
        data = $el.serializeObject()
        provider = $el.parents('.integration').data 'provider'
        userId = auth.get 'id'

        # Update the view to show the integration is in progress.
        @collection.get(provider).set {status: 'pending'}, {silent: true}

        @integrations.createIntegration _.extend({provider}, data)

    disableIntegration: (e) ->
        $el = @$ e.currentTarget
        id = $el.parents('.integration').data 'id'
        provider = $el.parents('.integration').data 'provider'
        confirm('Are you sure you want to deactivate?', {}).then((res) =>

            if res
                @integrations.removeIntegration {id, provider}
        )

    activateIntegration: (e) ->
        $el = @$ e.currentTarget
        provider = $el.parents('.integration').data 'provider'
        integration = @collection.get(provider)

        # Check to see if configuration is required (has settings); if so show the form
        if integration.get('settings') != undefined
            integration.set {status: 'configuring'}
        else if integration.get('oauthUrl') != undefined

            oauthUrl = integration.get('oauthUrl')
            redirectUri = integration.get('redirectUrl')
            scope = integration.get('scope')
            clientId = integration.get('clientId')
            accountId = auth.currentAccount.id

            window.open(
                encodeURI("#{oauthUrl}?reponse_type=code&redirect_uri=#{redirectUri}&scope=#{scope}&client_id=#{clientId}&state=#{accountId}"),
                "_blank"
            )
        else
            this.doActivate(provider)

    cancelConfiguration: (e) ->
        $el = @$ e.currentTarget
        provider = $el.parents('.integration').data 'provider'
        integration = @collection.get(provider)

        if integration.get('status') == 'configuring'
            integration.set {status: ''}

    doActivate: (provider) ->
        @integrations.createIntegration {provider}
        # Instill confidence for 3 to 6 seconds.
        @collection.get(provider).set {status: 'pending'}
        _.delay (=> @collection.get(provider).set {status: ''}), Math.random() * 3000 + 1000

