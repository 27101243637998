ModalView = require 'views/modal'


module.exports = class ManageCategoryModalView extends ModalView
    __name__: 'ManageCategoryModalView'
    className: 'manage-category modal fade'
    template: require 'views/templates/manage-category'


    events:
        'click [data-action=done]': 'done'
        'click [data-action=newCategory]': 'newCategory'
        'click [data-action=removeCategory]': 'removeCategory'
        'change [name=categoryName]': 'changeName'

    initialize: (options={}) ->
        super
        {@categories} = options

        @listenTo @categories, 'add remove', @render

    getContext: ->
        categories: @categories.toJSON()

    render: ->
        super

    done: ->
        blankCategories =
            @categories.filter (category) ->
                not category.get('name')? or category.get('name') == ''

        @categories.remove blankCategories

        @hide()

    newCategory: ->
        index = @categories.length
        @categories.add { index: index }

    removeCategory: (evt) ->
        categoryId = $(evt.currentTarget).data("id")
        @categories.remove { id: categoryId }

    changeName: (evt) ->
        newName = evt.currentTarget.value
        oldName = evt.currentTarget.defaultValue
        category = @categories.get(evt.currentTarget.id)

        if newName isnt oldName
            category.set('name', newName)
