auth = require 'models/auth'
CONTENT_STATES = require 'lib/content-states'


BaseAnnouncementModel =
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        state: CONTENT_STATES.DRAFT
        published: false

        name: 'Untitled Announcement'
        description: ''

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true

    urlPrefix: ->
        'announcements'

module.exports = _.extend BaseAnnouncementModel
