firebase = require 'lib/firebase'
auth = require 'models/auth'
BaseHotspotGroupModel = require 'models/hotspot-group-base'

HotspotGroupModel = _.extend {}, BaseHotspotGroupModel, {
    initialize: (attributes, options={}) ->
        BaseHotspotGroupModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        firebase.url + "/accounts/#{accountId}/hotspot-groups"

    extraPaths: ->
        return [
            "/__/hotspot-groups/#{@id}"
        ]
}

module.exports = Backbone.Firebase.Model.extend HotspotGroupModel
