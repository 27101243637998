auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'

module.exports = {

    # Filter events to the ones we want to track.
    ROUTE_TO_ACTIVITY:
        createFlow: 'create_content'
        createHotspotGroup: 'create_content'
        createCoachmarkGroup: 'create_content'

    trackRoute: (routeName) ->
        eventName = @ROUTE_TO_ACTIVITY[routeName]
        if eventName?
            @track eventName

    # Record that an event was completed.
    track: (eventName) ->
        accountId = auth.currentAccount.id
        firebaseRef.child("accounts/#{accountId}/setup-activities/#{eventName}").update {
            status: 'completed'
        }
}
