{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class FormsCollection extends Backbone.Firebase.Collection
    model: require 'models/form'

    initialize: (models, options={}) ->
        {@accountId} = options

    url: ->
        accountId = @accountId ? auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/forms"

