IMG_URL = '/images/inspiration/'

module.exports =

    inspiration: {
        bynder: {
            title: "Bynder - User Onboarding"
            link: "https://www.appcues.com/blog/bynder-self-service-demo-example/?utm_source=inspiration&utm_medium=appcues&utm_campaign=bynder",
            blurb: "Bynder greets their new users with a friendly welcome modal containing a video introduction from their CEO, setting expectations on their trial and emphasizing customer support.",
            pics: [
                IMG_URL + 'bynder-user-onboarding.png',
                IMG_URL + 'bynder-demo1.png',
                IMG_URL + 'bynder-demo2.png'
            ]
        },
        cmap: {
            title: "CMAP - Feature Announcements"
            link: "https://www.appcues.com/blog/how-cmap-improves-feature-adoption-with-appcues/?utm_source=inspiration&utm_medium=appcues&utm_campaign=cmap",
            blurb: "CMAP increased feature adoption by 300% compared to email announcements alone by implementing Appcues modal announcements.",
            pics: [
                IMG_URL + 'cmap-announcement.png',
                IMG_URL + 'cmap-announcement2.png',
                IMG_URL + 'cmap-product-tour.png',
                IMG_URL + 'cmap-feature-modal.png'
            ]
        },
        adroll: {
            title: "Adroll - Feature Announcements"
            link: "https://www.appcues.com/blog/adrolls-growth-teams-secret-weapon-to-drive-conversions/?utm_source=inspiration&utm_medium=appcues&utm_campaign=adroll",
            blurb: "Before Appcues, only a few hundred Adroll users adopted new features. By empowering their product and product marketing managers with Appcues to create their own in-app announcements, they are now able to drive thousands of users to adopt new features.",
            pics: [
                IMG_URL + 'adroll-new-ad.jpg',
                IMG_URL + 'adroll-hubspot.jpg',
                IMG_URL + 'adroll-beta-program.jpg',
                IMG_URL + 'adroll-mailchimp.png'
            ]
        },
        amplitudeSurvey: {
            title: "Amplitude - NPS Surveys"
            link: "https://www.appcues.com/blog/how-amplitude-bypassed-engineering-bottlenecks-by-using-appcues?utm_source=inspiration&utm_medium=appcues&utm_campaign=amplitude",
            blurb: "In less time than it takes to set up a blog post or an email, Amplitude targets users who have visited at least three of their pages with an NPS survey using slideouts and modals.",
            pics: [
                IMG_URL + 'amplitude-survey-slideout.png',
                IMG_URL + 'amplitude-survey-slideout2.png',
                IMG_URL + 'amplitude-survey-slideout3.png',
                IMG_URL + 'amplitude-survey-slideout4.png'
            ]
        },
        prodpad: {
            title: "ProdPad - Product Tours"
            link: "https://www.appcues.com/blog/how-prodpad-used-code-free-user-onboarding-to-decrease-trial-to-paid/?utm_source=inspiration&utm_medium=appcues&utm_campaign=prodpad",
            blurb: "ProdPad uses tooltips in their user-driven product tour, steering trialers towards their product's 'Aha!' moment. They developed followup targeted messages with modals and slideouts, helping to cut down their average trial period from 8 weeks to 2 weeks.",
            pics: [
                IMG_URL + 'prodpad-onboarding.gif',
                IMG_URL + 'prodpad-feature-release.jpg',
                IMG_URL + 'prodpad-slideout.jpg'
            ]
        },
        amplitudeDemo: {
            title: "Amplitude - Product Tours"
            link: "https://www.appcues.com/blog/how-amplitude-bypassed-engineering-bottlenecks-by-using-appcues?utm_source=inspiration&utm_medium=appcues&utm_campaign=amplitude",
            blurb: "Amplitude uses modals and tooltips in their self-service product demo, converting 18% of visitors to their sales pipeline.",
            pics: [
                IMG_URL + 'amplitude-product-demo.png',
                IMG_URL + 'amplitude-product-demo2.png',
                IMG_URL + 'amplitude-product-demo3.png',
                IMG_URL + 'amplitude-product-demo4.png'
            ]
        }
    }

