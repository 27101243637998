AppView = require 'views/app'
page = require 'models/page'
urls = require 'lib/urls'
auth = require 'models/auth'
{confirmUnpublishContent} = require 'lib/build-helpers'
PreviewFrame = require 'lib/preview-frame'

module.exports = class BuildLocationView extends AppView
    __name__: 'BuildLocationView'
    className: 'build-location'
    template: require 'views/templates/build-steps/location'

    events:
        'change [name=previewUrl]': '_updatePreviewUrlFromInput'
        'keyup [name=previewUrl]': '_urlKeyup'
        'click [data-action=navigateFrame]': '_updatePreviewUrlFromInput'
        'click [data-action=unpublishContent]': '_unpublishContent'
        'click [data-action=switchPreviewMethod]': '_switchPreviewMethod'

    initialize: (options) ->
        {@ruleId, @rule, @content, @contentType, @useEager} = options
        unless @useEager?
            @useEager = true

        @viewState = new Backbone.Model
        @listenTo @rule, 'change:published', @render

        page.set
            title: @content.get('name')
            isEditable: true
        @listenTo page, 'change:title', (page, model, opts) ->
            if opts.skipContent isnt true
                @content.set 'name', page.get('title')

        @on 'eager:navigate', (evt) ->
            if /#appcues-init-error$/.test(evt.shownUrl)
                @_showPreviewUrlForm()
            else
                @rule.set({'previewUrl': evt.shownUrl}, {silentEager: true})
                @$('input[name=previewUrl]').val(evt.shownUrl)
                @$('.indicator').removeClass('loading navigate')
        @listenTo @rule, 'change:previewUrl', @_setRuleFromPreviewUrl
        @listenTo @rule, 'change:previewUrl', @_updateFrameFromRule

    getContext: ->
        buildStep: 'location'
        ruleId: @ruleId
        rule: @rule.toJSON()
        contentType: @contentType
        content: @content.toJSON()
        urlPrefix: @content.urlPrefix()
        previewUrl: @rule.getPreviewUrl()
        useEager: @useEager

    render: ->
        super
        # Clean up existing @previewFrame if any.
        if @previewFrame and _.isFunction @previewFrame.remove
            @previewFrame.remove()
        @_initializeEagerPreview()

    undelegateEvents: ->
        super
        @previewFrame?.off('load')
        @previewFrame?.off('navigate')

    remove: ->
        if @previewFrame and _.isFunction @previewFrame.remove
            @previewFrame.remove()
        super

    _initializeEagerPreview: ->
        if @useEager
            @previewFrame = new EagerPreview
                partnerId: 'appcues'
                el: @$('#build-preview-frame').get(0)
                showURLBar: false
                # FIX: Set a better default.
                frameSrc: @rule.getPreviewUrl()
        else
            @previewFrame = new PreviewFrame
                el: @$('#build-preview-frame').get(0)
                frameSrc: @rule.getPreviewUrl()
            @previewFrame.initialize()

        @previewFrame.on 'navigate', (e) =>
            @trigger 'eager:navigate', e

            console.log 'Injecting recorder script.'
            @previewFrame.inject {
                type: 'application/javascript'
                content: _.result(PreviewFrame, 'buildRecorderScript')
            }

            _.result @previewFrame, 'checkScriptProtocols'

        @_updateFrameHeight()

    _updateFrameHeight: ->
        # Leave space to keep the url bar on the screen.
        @$('#build-preview-frame').css('min-height', $(window).outerHeight() - 60)

    _updateFrameFromRule: (model, value, options) ->
        unless options.silentEager
            @$('.indicator').removeClass('navigate').addClass('loading')
            @previewFrame?.navigate @rule.getPreviewUrl()

    _updatePreviewUrlFromInput: (evt) ->
        if evt.type is 'click' or evt.type is 'change' or (evt.type is 'keyup' and evt.which is 13)
            @$('.indicator').removeClass('navigate').addClass('loading')
            url = Fuzzyurl.fromString @$('input[name=previewUrl]').val()
            url.protocol = 'http' unless url.protocol?
            @rule.set 'previewUrl', url.toString()

    _urlKeyup: (evt) ->
        if @$(evt.currentTarget).val() is @rule.getPreviewUrl()
            @$('.indicator').removeClass('loading navigate')
        else
            @$('.indicator').removeClass('loading').addClass('navigate')

    _setRuleFromPreviewUrl: (rule, previewUrl) ->
        unless @rule.get('published')
            location = urls.toLocation(previewUrl)
            # Add host to whitelisted domains
            auth.currentAccount.addDomain(location.host)
            # Set where unless the rule uses regular expression matching
            @rule.set('where', location.fullpath) unless @rule.get('isRegex')

    _unpublishContent: confirmUnpublishContent

    _switchPreviewMethod: ->
        @useEager = not @useEager
        localStorage.setItem 'useEager', @useEager
        @render()
        unless @useEager
            lytics.trackWithUser "Switched to Chrome Extension Preview"

    _showPreviewUrlForm: ->
        @previewFrame.remove()
        @previewFrame = null
        @$('#build-location-bar').remove()
        template = require 'views/templates/preview-frame-url-form'
        @$el.append template _.extend {designStepUrl: window.location.href.replace('location', 'design')}, @rule.toJSON()

