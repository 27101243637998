{firebaseRef} = require 'lib/firebase'
helpers = require 'lib/helpers'
auth = require 'models/auth'

module.exports = class HotspotsCollection extends Backbone.Firebase.Collection
    model: require 'models/hotspot'

    initialize: (models, options={}) ->
        @id = options.id
        @once 'sync', => @syncd = true

    LIMIT: 10

    comparator: helpers.hotspotComparator

    url: ->
        accountId = auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/hotspot-groups/#{@id}/hotspots"

    _selectedId: null

    select: (id) ->
        @_selectedId = id

        # Expand only the selected hotspot.
        @each (model) ->
            model.set 'expanded', model.id is id

        @trigger 'select'

    getSelectedId: ->
        @_selectedId or @at(0)?.get('id')

