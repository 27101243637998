StyleBaseModel =
    defaults:
        version: 0

        # The manually input CSS.
        customCss: ''

        # Basic styling properties.
        primaryColor: ''
        secondaryColor: ''
        buttonTextColor: ''
        buttonTextSecondaryColor: ''
        buttonBorderStyle: ''
        defaultFont: ''
        titleFont: ''
        customFontUrl: ''
        defaultFontUrl: ''
        titleFontUrl: ''
        defaultFontColor: ''
        maskColor: ''
        maskOpacity: ''
        modalBackgroundColor: ''
        modalTextColor: ''
        tooltipColor: ''
        tooltipTextColor: ''
        tooltipArrowColor: ''
        tooltipRadius: ''
        tooltipShadow: ''
        typekitId: ''
        hideProgressBar: false

        # These are specific hotspot properties that shouldn't be bundled into
        # the globalCss value, since they apply to the beacon which is outside
        # of the iframe in which styling is loaded.
        # beaconColor is passed as a raw value to the beacon view since the color is in-lined.
        beaconColor: ''
        beaconStyle: ''

        # This is the CSS that is built to style the hotspot beacon.
        globalHotspotCss: ''

        # This is the combination of the customCss and the basic styling properties.
        globalCss: ''

    initialize: (attrs={}) ->
        changeAttrs = _.chain(@defaults).keys().without('globalCss', 'globalHotspotCss', 'beaconColor', 'beaconStyle').map((value) -> "change:#{value}").value().join(' ')
        @on changeAttrs, ->
            @set 'globalCss', @_buildGlobalCss()

    deprecate: ->
        if @get('hideBeacon')
            @set {
                globalHotspotCss: ''
                beaconStyle: 'hidden'
                hideBeacon: null
            }

    _buildGlobalCss: ->
        appcues_theme_utilities.getGlobalCSSFromStyleObject(@toJSON())


module.exports = StyleBaseModel