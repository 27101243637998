BaseView = require 'views/base'
InstallMyselfView = require 'views/install-myself'
SegmentView = require 'views/install-guide-step-segment'
AccountModel = require 'models/account'
SendInstallView = require 'views/send-install'
auth = require 'models/auth'
trackEvent = require 'lib/track-event'

module.exports = class InstallSimpleView extends BaseView
    __name__: 'InstallSimpleView'
    className: 'install-simple'
    template: require 'views/templates/install-simple'

    events:
        'click [data-action=isInviteOption]': 'setInviteDeveloper'
        'click [data-action=isDeveloperOption]': 'setInstallGuide'
        'click [data-action=enableWithSegment]': 'enableWithSegment'

    initialize: (options={}) ->
        {@accountId} = options
        @account = new AccountModel(id: @accountId)
        @model = new Backbone.Model {
            isOptionSelected: false
            isInstallGuide: false
            isInviteOption: false
            isSegmentOption: false
        }
        @listenTo @account, 'sync', @render
        @listenTo @account, 'change:isInstalled', @render
        @listenTo @model, 'change', @render

    getContext: ->
        _.extend @model.toJSON(), {
            accountId: @accountId || '<YOUR_APPCUES_ID>'
            apiKey: @account.get('uuid')
            isInstalled: @account.get('isInstalled')
            hasAuth: auth.currentAccount.id?
            hideInviteUser: auth.currentAccount.id? and auth.currentAccount?.hasFeatureGate("hideInviteUser")
        }

    render: ->
        @removeChildViews()

        super()

        context = @getContext()

        {apiKey, isInstallGuide, isInviteOption, isSegmentOption} = context

        if isInstallGuide or not context.hasAuth
            v = new InstallMyselfView {@accountId}
        else if context.isOptionSelected and isInviteOption
            v = new SendInstallView
        else if context.isOptionSelected and isSegmentOption
            v = new SegmentView {@accountId, apiKey}

        if v?
            @$('.guide').append v.el
            @renderChild(v)

    setInstallGuide: ->
        trackEvent 'Clicked Not Developer on Install Page'
        @model.set {
            isInstallGuide: true
            isOptionSelected: true
            isSegmentOption: false
            isInviteOption: false
        }

    setInviteDeveloper: ->
        trackEvent 'Clicked Developer on Install Page'
        @model.set {
            isInstallGuide: false
            isOptionSelected: true
            isSegmentOption: false
            isInviteOption: true
        }

    enableWithSegment: ->
        trackEvent 'Clicked Segment on Install Page'
        @model.set {
            isInstallGuide: false
            isOptionSelected: true
            isSegmentOption: true
            isInviteOption: false
        }

