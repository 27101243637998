auth = require 'models/auth'

Errors =
    embedScript: ->
        message: 'Install Appcues embed script'
        description: "Follow the <a href=\"/install/#{auth.currentAccount.id}\" target=\"_blank\">installation instructions</a> to set up your script before publishing."
    emptyWhere: ->
        message: 'Choose a target page'
        description: 'Target a page by URL by clicking in the sentnce below.'
    noStepType: (step) ->
        message: "Choose a type for step #{step}"
        description: 'Select the step from the list below and choose a type to start adding content.'
    noStepContent: (step) ->
        message: "Add content to step #{step}"
        description: 'Select the step from the list below and add some content before publishing.'
    noHotspots: ->
        message: 'Add a hotspot'
        description: 'Add at least one hotspot by clicking in the frame below to get started.'
    missingElement: (count) ->
        message: "Missing target element for #{count} #{if count is 1 then 'hotspot' else 'hotspots'}"
        description: 'To remove invalid hotspots, hover over the yellow indicators in the toolbar below and click "Remove".'
    noHotspotContent: (count) ->
        message: "Add content to #{count} #{if count is 1 then 'hotspot' else 'hotspots'}"
        description: 'Check your hotspots in the frame below and make sure they all have content before publishing.'


_checkAccount = (account, properties) ->
    if _.isEmpty(properties.attributes)
        [Errors.embedScript()]
    else
        []

_checkRule = (rule) ->
    if rule.get('where')
        []
    else
        [Errors.emptyWhere()]

_checkStep = (step, stepNumber) ->
    if not step.get('stepType')
        [Errors.noStepType(stepNumber)]
    else if not $(step.get('content')).text()
        [Errors.noStepContent(stepNumber)]
    else
        []

_checkSteps = (steps) ->
    stepNumber = 1
    _.flatten(steps.map (step) ->
        _checkStep(step, stepNumber++)
    , true)

_checkHotspots = (hotspots) ->
    if hotspots.length is 0
        [Errors.noHotspots()]
    else
        invalidCount = 0
        blankCount = 0
        hotspots.each (hotspot) ->
            if hotspot.get('nodeMissing')
                invalidCount++
            else if not $(hotspot.get('content')).text()
                blankCount++
        errors = []
        errors.push(Errors.missingElement(invalidCount)) if invalidCount > 0
        errors.push(Errors.noHotspotContent(blankCount)) if blankCount > 0
        errors


module.exports = {
    checkFlow: (account, properties, flow, rule, steps) ->
        [].concat(
            _checkAccount(account, properties),
            _checkRule(rule),
            _checkSteps(steps)
        )

    checkHotspotGroup: (account, properties, hotspotGroup, rule, hotspots) ->
        [].concat(
            _checkAccount(account, properties),
            _checkRule(rule),
            _checkHotspots(hotspots)
        )
}
