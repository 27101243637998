{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class PublicFlowsCollection extends Backbone.Firebase.Collection
    model: require 'models/flow'

    initialize: (options={}) ->
        @accountId = options.accountId or auth.currentAccount.id
        @once 'sync', ->
            @syncd = true
    url: ->
        firebaseRef.child "public/users/#{@accountId}/flows"
