{firebaseRef} = require 'lib/firebase'
urls = require 'lib/urls'

module.exports = class FlowStatsModel extends Backbone.Firebase.Model

    # we won't ever be changing the flow-stats model, the lambda owns that.  so short circuit doing so to avoid...
    # FIREBASE WARNING: update at /accounts/${accountId}/flow-stats/${flowId} failed: permission_denied
    _listenLocalChange: () ->

    initialize: (attributes) ->
        {@accountId, @id} = attributes
        @once 'sync', ->
            @syncd = true

    url: ->
        firebaseRef.child "accounts/#{@accountId}/flow-stats/#{@id}"
