{INTEGRATION_SERVICE_URL, FIREBASE_URL} = window.__env
CustomRouter = require 'routers/custom'
auth = require 'models/auth'
trackActivities = require 'lib/setup-activities'
page = require 'models/page'
helpers = require 'lib/helpers'
{getModelForLegacyStepType, getModelForRouterContentType, getRouterContentTypeForStepType} = require 'lib/flow-helpers'
Integrations = require 'collections/integrations'
RuleModel = require 'models/rule-firebase'
FlowStatsModel = require 'models/flow-stats'

# Removing this will break lazy account creation, so please don't
requireUserAndAccount = ->
    if auth.authChecked and not (auth.get('provider') and auth.user?.syncd and auth.currentAccount?.syncd)
        next = window.location.pathname
        query = ''
        query = "?next=#{next}" if next isnt '/'
        window.location.href = "/login#{query}"

class MainRouter extends CustomRouter
    initialize: ->
        @_isRouterReady = false
        super

    isRouterReady: ->
        @_isRouterReady

    afterNavigate: (fragment) ->
        _.defer ->
            event = new CustomEvent('navigated', { detail: { fragment: fragment }})
            document.body.dispatchEvent(event)
            # Re-initialize taco.
            window.appcuesWidgetInit?()

            try
                window['optimizely'].push(['activate'])

    beforeRoute: (route, name) ->
        # Always reset this.
        page.set 'isEditable', false

        return if name in @publicRoutes

        callback = _.bind requireUserAndAccount, @
        if auth.authChecked
            callback()
        else
            auth.once 'check', callback

    afterRoute: (route, name) ->
        @_isRouterReady = true
        @trackRoute name

        # Update the page title and set the category.
        title = @metadata[name]?.title
        page.set 'title', title, skipContent: true if title

        category = @metadata[name]?.category
        page.set 'category', category if category

        # Keep track of user/account activity.
        if auth.user?.updateTimestamps?
            auth.user.updateTimestamps {accountId: auth.currentAccount.id}
        if not auth.get('spoofing') and auth.currentAccount?.updateTimestamps?
            auth.currentAccount.updateTimestamps()

        # Track routing as an activity.
        trackActivities.trackRoute route

    routes:
        '': 'allContent'
        'flows': 'redirectToHome'

        'announcements/:announcementId/edit': 'editAnnouncement'

        'experiences/:experienceId/settings': 'experienceSettings'

        'try-nps': 'registerNps'
        'nps': 'satisfactionSurveys'
        'nps/:satisfactionId/edit': 'editSatisfaction'
        'nps/:ruleId/settings': 'editSatisfactionCustomConditions'

        'create': 'createContentWithDomain'
        ':contentType/create': 'createContentWithDomain'
        'build': 'createContentWithDomain'
        'build?*': 'createContentWithDomain'

        'quickstart': 'launchQuickstartWithDomain'
        'quickstart?': 'launchQuickstartWithDomain'

        'segments': 'segments'
        'segments/:segmentId/edit': 'editSegments'

        'goals': 'goals'
        'goals/new': 'createGoals'
        'goals/:goalId': 'showGoals'

        'checklists': 'checklists'
        'checklists/:checklistId/edit': 'editChecklists'
        'checklists/:checklistId/analytics': 'checklistAnalytics'

        ':contentType/:ruleId/edit': 'editContent'
        ':contentType/:ruleId/location': 'editLocation'
        ':contentType/:ruleId/design': 'editDesign'
        ':contentType/:ruleId/crx': 'editCrx'
        ':contentType/:ruleId/settings': 'editSettings'
        ':contentType/:ruleId/settings/beta': 'editSettingsBeta'
        ':contentType/:ruleId/conditions': 'editConditions'
        ':contentType/:ruleId/preview': 'viewPreview'

        ':contentType/:flowId/stats': 'analytics'
        ':contentType/:flowId/export': 'export'
        ':contentType/:flowId/analytics': 'analytics'
        'edit-in-chrome/stepGroup/:stepGroupId': 'editInChrome'
        'edit-in-chrome/stepGroup/:stepGroupId/stepChild/:stepChildId': 'editInChrome'

        'integrations': 'integrations'
        'diagnostics': 'diagnostics'
        'events-attributes': 'eventsAttributes'

        'account': 'account'
        'subscription/story': 'billingStory'
        'subscription/feedback': 'billingFeedback'
        'subscription/canceled': 'billingCanceled'
        'subscription': 'billing'

        'account/extend-trial': 'extendTrial'
        'team': 'team'

        # Support the old link, just redirects to login.
        'invite/a/:accountId/e/:email/n/:name': 'login'
        'register': 'register'
        'login': 'login'
        'inspiration': 'inspiration'
        'welcome/:email/:password': 'welcome'
        'blank': 'blank'
        'logout': 'logout'
        'setup': 'start'
        'setup/installed': 'setupInstalled'
        'start': 'start'

        'install/status/:accountId': 'installationStatus'

        # support old link
        'install/a(/:accountId)': 'install'

        # install guide routes
        'install/traditional(/:accountId)': 'installGuideTraditional'
        'install/spa(/:accountId)': 'installGuideSPA'
        'install/segment(/:accountId)': 'installGuideSegment'
        'install(/:accountId)': 'install'

        #uninstall survey and analytics routes
        'uninstall/crx(/:userId)': 'uninstallCRX'

        'experiments': 'experiments'
        'installed': 'setupInstalled'

        'dashboard': 'dashboard'
        'styling': 'styling'
        'themes': 'styling'

        'auth/:provider': 'authProvider'
        'auth/:provider/callback': 'saveAuth'

        'segment-enabled': 'segmentEnabled'

        'opt-in/components': 'componentsOptIn'
        'opt-out/components': 'componentsOptOut'

        '404': '404'
        '*notFound': '404'

    publicRoutes: [
        'register'
        'registerNps'
        'login'
        'logout'
        'welcome'
        'setup'
        'install'
        'installGuideSPA'
        'installGuideTraditional'
        'installGuideSegment'
        'uninstallCRX'
        'start'
        'inspiration'
        'blank'
    ]

    # Keys: title, category(flows, design, learn, settings, none)
    metadata:
        setupInstalled:
            category: 'flows'

        allContent:
            title: 'Your Flows'
            category: 'flows'

        editLocation:
            category: 'flows'

        editDesign:
            category: 'flows'

        editCrx:
            category: 'flows'

        editSettings:
            category: 'flows'

        editSettingsBeta:
            category: 'flows'

        editConditions:
            category: 'flows'

        viewPreview:
            category: 'flows'

        index:
            title: 'Dashboard'
            category: 'flows'

        exportFlowAsCSV:
            title: 'CSV Export'
            category: 'flows'

        export:
            title: 'CSV Export'
            category: 'flows'

        analytics:
            title: 'Step Analytics'
            category: 'flows'

        integrations:
            title: 'Integrations'
            category: 'settings'

        account:
            title: 'Account Settings'
            category: 'settings'

        editAnnouncement:
            title: 'Edit Announcement'
            category: 'flow'

        experienceSettings:
            title: 'Flow Settings'
            category: 'settings'

        editSatisfaction:
            title: 'Edit NPS Survey'
            category: 'flow'

        editSatisfactionCustomConditions:
            title: 'Edit NPS Survey Audience'
            category: 'flow'

        team:
            title: 'Team Settings'
            category: 'settings'

        billing:
            title: 'Subscription'
            category: 'settings'

        billingStory:
            title: 'Subscription Story'
            category: 'settings'

        billingFeedback:
            title: 'Subscription Feedback'
            category: 'settings'

        billingCanceled:
            title: 'Subscription Canceled'
            category: 'settings'

        extendTrial:
            title: 'Extend Trial'
            category: 'settings'

        register:
            title: 'Register Your Account'
            category: 'none'

        registerNps:
            title: 'Register Your Account for NPS'
            category: 'none'

        welcome:
            title: 'Welcome to Appcues'
            category: 'flows'

        blank:
            title: 'Appcues'

        login:
            title: 'Login'
            category: 'none'

        logout:
            title: 'Logout'
            category: 'none'

        installationStatus:
            title: 'Installation Status'
            category: 'settings'

        install:
            title: 'Installation Guide'
            category: 'settings'

        uninstallCRX:
            title: 'We\'re sorry to see you go'

        styling:
            title: 'Styles'
            category: 'styles'

        satisfactionSurveys:
            title: 'NPS'
            category: 'satisfactionSurveys'

        segments:
            title: 'Segments'
            category: 'segments'

        editSegments:
            title: 'Edit Segment'
            category: 'editSegments'

        goals:
            title: 'Goals'
            category: 'goals'

        createGoals:
            title: 'Define a New Goal'
            category: 'goals'

        showGoals:
            title: 'View a Goal'
            category: 'goals'

        checklists:
            title: 'Checklists'
            category: 'checklists'

        editChecklists:
            title: 'Edit Checklists'
            category: 'checklists'

        checklistAnalytics:
            title: 'Checklist Analytics'
            category: 'checklists'

        mobile:
            title: 'Mobile'
            category: 'mobile'

        start:
            title: 'Create'
            category: 'flows'

        quickstart:
            title: 'Create'
            category: 'flows'

        inspiration:
            title: 'Ideas & Inspiration'
            category: 'none'

        experiments:
            title: 'Experiments'
            category: 'experiments'

        diagnostics:
            title: 'Diagnostics'
            category: 'diagnostics'

        eventsAttributes:
            title: 'Events and Attributes'
            category: 'eventsAttributes'

        editInChrome:
            title: 'Edit In Chrome'
            category: 'flows'

        dashboard:
            title: 'Your Appcues Dashboard'
            category: 'none'

    _showView: (view, options={}) ->
        isPublic = Backbone.history.fragment.replace(/\?.*$/, '') in @publicRoutes
        showChrome = if isPublic then false else true
        withNav = options?.withNav ? showChrome

        showInviteModal = options.showInviteModal ? Backbone.history.location.search.indexOf('invite_user') > 0

        if withNav
            if not @navView?
                @_renderNav {showInviteModal}
            else if showInviteModal
                @navView.showInviteModal = true
                @navView.render()
        else
            @_removeNav()

        window.scrollTo(0, 0)

        @currentView?.remove()
        @currentView = view
        $('#app').append view.el

        view.render()

    _renderNav: (options={}) ->
        queryParams = helpers.queryStrToObj()

        @_removeNav() if @navView?

        AuthView = require('views/auth')
        @navView = view = new AuthView {showInviteModal: options.showInviteModal}
        $('#banner').append view.el
        view.render()
        view.listenTo @, 'route', view.render

    _removeNav: ->
        @navView?.remove()
        @navView = null

    index: ->
        # TODO: Use this to route people depending on auth status.
        v = new (require('views/index'))
        @_showView v

    allContent: (options={}) ->
        ListAllContentView = require 'views/list-all'
        isAnnouncementsBeta = auth.currentAccount?.hasFeatureGate?('isAnnouncementsBeta')

        # This flag is applied when a user signs up for the NPS Product under the /try-nps route.
        # It will turn false once they purchase a plan or start their trial for
        # our core product (which includes NPS).
        isNPSProductTrial = auth.currentAccount?.get('isNPSProductTrial')
        # This flag is applied when a user subscribes to an NPS-only plan. This will turn false
        # if they upgrade to a regular Appcues plan.
        isNPSProductOnlyUser = auth.currentAccount?.get("isNPSProductOnlyUser")

        listAllContentView = new ListAllContentView { installed: options.installed, isAnnouncementsBeta, isNPSProductTrial, isNPSProductOnlyUser }

        noChecklist = isNPSProductTrial or isNPSProductOnlyUser
        @_showView listAllContentView, { noChecklist }

    redirectToHome: () ->
        @navigate("/", {
            trigger: true,
            replace: true
        })

    launchQuickstartWithDomain: (params) ->

        queryStr = if window isnt window.top then window.localStorage.getItem("signupQueryParams")
        id = auth.get('id') || auth.get('uid')

        queryParams = helpers.processQueryParamsForSignup(id, queryStr)

        LaunchQuickstartView = require('views/quickstart')

        v = new LaunchQuickstartView(queryParams)
        @_showView v, {withNav: false}

    createContentWithDomain: (params) ->
        if params
            installed = params.split("=").pop()
        else
            installed = false

        v = new (require 'views/list-all')
        @_showView v
        v.createContentWithDomain(installed)

    editContent: (contentType, ruleId) ->
        @navigate "#{contentType}/#{ruleId}/design", {replace: true, trigger: true}

    editLocation: (contentType, ruleId) ->
        BuildLocationView = require('views/build-steps/location')
        @loadContentForRuleId(contentType, ruleId).then((data) =>
            useEager = if localStorage.getItem('useEager') is 'false' then false else true
            _.extend data, {useEager}
            v = new BuildLocationView data
            @_showView v
        )

    editDesign: (contentType, ruleId) ->
        View = {
            flows: require('views/build-steps/design/flow')
            slideouts: require('views/build-steps/design/flow')
            hotspots: require('views/build-steps/design/hotspot-group')
            tooltips: require('views/build-steps/design/hotspot-group')
            coachmarks: require('views/build-steps/design/coachmark-group')
        }[contentType]

        @loadContentForRuleId(contentType, ruleId).then (data) =>
            v = new View data
            @_showView v

    editCrx: (contentType, ruleId) ->

        @loadContentForRuleId(contentType, ruleId).then (data) =>

            if contentType == 'coachmarks'
                @.navigate("/#{contentType}/#{ruleId}/design", true)
            else
                if data.content.get("parentId")
                    # if this is the settings page for a step/child of a journey, redirect to journey rules
                    @navigate("/journeys/#{data.content.get("parentId")}/crx", {
                        trigger: true,
                        replace: true
                    })
                else
                    data.params = helpers.queryStrToObj()
                    LaunchCrxView = require('views/build-steps/crx')
                    v = new LaunchCrxView data
                    @_showView v

    editSettings: (contentType, ruleId) ->
        BuildSettingsView = require('views/build-steps/settings')
        @loadContentForRuleId(contentType, ruleId).then (data) =>
            if data.content.get("parentId")
                # if this is the settings page for a step/child of a journey, redirect to journey rules
                @navigate("/journeys/#{data.content.get("parentId")}/settings#{window.location.search}", {
                    trigger: true,
                    replace: true
                })
            else
                # in the post-push-publishing era, there is no longer a reason to allow
                # users to view the old settings page... so just redirect them
                @navigate("/#{contentType}/#{ruleId}/conditions#{window.location.search}", {
                    trigger: true,
                    replace: true
                })

    editSettingsBeta: (contentType, ruleId) ->
        @.navigate("/#{contentType}/#{ruleId}/conditions", true)

    viewPreview: (contentType, ruleId) ->
        BuildPreviewView = require('views/build-steps/preview')
        @loadContentForRuleId(contentType, ruleId).then (data) =>
            if data.content.get("parentId")
                # if this is the settings page for a step/child of a journey, redirect to journey preview
                @navigate("/journeys/#{data.content.get("parentId")}/preview", {
                    trigger: true,
                    replace: true
                })
            else
                v = new BuildPreviewView data
                @_showView v

    editConditions: (contentType, ruleId) ->
        BuildConditionsView = require('views/build-steps/conditions')
        @loadContentForRuleId(contentType, ruleId).then (data) =>
            if data.content.get("parentId")
                # if this is the settings page for a step/child of a journey, redirect to journey rules
                @navigate("/journeys/#{data.content.get("parentId")}/settings#{window.location.search}", {
                    trigger: true,
                    replace: true
                })
            else
                v = new BuildConditionsView data
                @_showView v

    exportFlowAsCSV: (contentType, flowId) ->
        @loadContentForRuleId(contentType, flowId).then ({content}) =>
            step = content
            routerContentType = contentType

            step.whenSyncd().then =>
                stepId = step.id
                @.navigate("/#{routerContentType}/#{stepId}/analytics", {trigger: true, replace: true})

    export: (contentType, flowId) ->
        CSVExportView = require 'views/csv-export'
        @loadContentForRuleId(contentType, flowId).then ({content}) =>
            v = new CSVExportView(content)
            @_showView v

    analytics: (contentType, flowId) ->
        AnalyticsView = require('views/analytics')
        options = _.extend {flowId}, helpers.queryStrToObj()
        v = new AnalyticsView(options)
        @_showView v

    integrations: ->
        v = new (require('views/integrations'))
        @_showView v

    account: ->
        v = new (require('views/account'))
        @_showView v

    editAnnouncement: (announcementId) ->
        isAnnouncementsBeta = auth.currentAccount?.hasFeatureGate('isAnnouncementsBeta')
        if isAnnouncementsBeta
            AnnouncementsEditView = require 'views/announcement'
            v = new AnnouncementsEditView { announcementId }
            @_showView v
        else
            window.location.href = '/'
            return

    experienceSettings: (experienceId) ->
        ExperienceSettingsView = require 'views/experienceSettings'
        v = new ExperienceSettingsView { experienceId }
        @_showView v


    editSatisfaction: (satisfactionId) ->
        SatisfactionEditView = require 'views/satisfaction-edit'
        v = new SatisfactionEditView { satisfactionId }
        @_showView v, { noChecklist: true }


    editSatisfactionCustomConditions: (ruleId) ->
        SatisfactionCustomConditions = require 'views/satisfaction-custom-conditions'
        v = new SatisfactionCustomConditions { ruleId }
        @_showView v, { noChecklist: true }


    team: ->
        v = new (require('views/team'))
        @_showView v

    billing: ->
        v = new (require('views/billing'))
        @_showView v

    billingStory: ->
        v = new (require('views/billing-story'))
        @_showView v

    billingFeedback: ->
        v = new (require('views/billing-feedback'))
        @_showView v

    billingCanceled: ->
        v = new (require('views/billing-canceled'))
        @_showView v

    extendTrial: ->
        v = new (require 'views/extend-trial')
        @_showView v

    register: ->
        options = helpers.queryStrToObj()

        # If auth session is detected and
        # user hits /register with no query for google,
        # send them to dashboard.
        if auth.get('uid') and !options.google
            window.location.href = '/'
            return
        if analytics
            loadedRegisterPage = false
            loadRegisterPage = () =>
                return if loadedRegisterPage

                sessionId = window.amplitude?.getInstance()?.getSessionId?() or 0
                loadedRegisterPage = true
                if ((sessionId % 2) is 0)
                    RegisterBTestView = require('views/registerBTest')
                    v = new RegisterBTestView(options)
                    return @_showView v
                else
                    RegisterView = require('views/register')
                    v = new RegisterView(options)
                    return @_showView v
            analytics.ready(loadRegisterPage)
            window.setTimeout(loadRegisterPage, 2000)
        else
            RegisterView = require('views/register')
            v = new RegisterView(options)
            return @_showView v

    registerNps: ->
        options = helpers.queryStrToObj()
        options.product = 'nps-product'

        # If auth session is detected and
        # user hits /register with no query for google,
        # send them to dashboard.
        if auth.get('uid') and !options.google
            window.location.href = '/nps'
            return

        RegisterBTestView = require('views/registerBTest')
        v = new RegisterBTestView(options)

        return @_showView v, {withNav: false}

    inspiration: ->
        InspirationView = require 'views/inspiration'

        v = new InspirationView()
        @_showView v

    login: ->
        LoginView = require 'views/login'
        queryParams = helpers.queryStrToObj()

        # Bypass if already logged in.
        if auth.id
            if window is window.top
                window.location.href = queryParams.next or '/'
                return

            helpers.sendAuthToParentFrame(auth.get('firebaseUser'), auth.currentAccount.id)
            return

        if queryParams.ajs_uid?
            uid = queryParams.ajs_uid

        if queryParams.ajs_event?
            wasInvited = true

        v = new LoginView
            email: queryParams.email
            redirectUrl: queryParams.next
            auth: queryParams.auth
            uid: uid
            wasInvited: wasInvited
        @_showView v

    welcome: (email, password) ->
        PasswordResetView = require 'views/password-reset'
        v = new PasswordResetView {
            email
            password
            isNewUser: true
        }
        @_showView v

    blank: ->
        if auth.id
            if window isnt window.top
                helpers.sendAuthToParentFrame(auth.get('firebaseUser'), auth.currentAccount.id)
        @

    setupInstalled: ->
        @allContent({ installed: true })

    start: ->
        queryParams = helpers.queryStrToObj()

        StartView = require('views/start')
        v = new StartView(queryParams)
        @_showView v

    logout: ->
        auth.once 'logout', ->
            if window.top is window
                window.location = "/login"
            else
                window.parent.postMessage(JSON.stringify({
                    type: "logout"
                }), "*")

        auth.logout()
        Raven.setUser()

    uninstallCRX: (userId) ->
        UninstallCRXView = require 'views/uninstall/uninstallCRX'
        v = new UninstallCRXView {userId}
        @_showView v

    install: (accountId) ->
        unless accountId?
            accountId = auth.currentAccount.id
            if accountId?
                @navigate "/install/#{accountId}", {trigger: true}

        InstallSimpleView = require 'views/install-simple'
        v = new InstallSimpleView {accountId}
        @_showView v

    installGuideTraditional: (accountId) ->
        unless accountId?
            accountId = auth.currentAccount.id
            if accountId?
                @navigate "/install/traditional/#{accountId}", {replace: true}

        InstallGuideTraditionalView = require 'views/install-guide-traditional'
        v = new InstallGuideTraditionalView {accountId}
        @_showView v

    installGuideSPA: (accountId) ->
        unless accountId?
            accountId = auth.currentAccount.id
            if accountId?
                @navigate "/install/spa/#{accountId}", {replace: true}

        InstallGuideSPAView = require 'views/install-guide-spa'
        v = new InstallGuideSPAView {accountId}
        @_showView v

    installGuideSegment: (accountId) ->
        unless accountId?
            accountId = auth.currentAccount.id
            if accountId?
                @navigate "/install/segment/#{accountId}", {replace: true}

        InstallGuideSegmentView = require 'views/install-guide-segment'
        v = new InstallGuideSegmentView {accountId, apiKey: auth.currentAccount.get('uuid')}
        @_showView v

    installationStatus: (accountId) ->
        InstallationStatusView = require 'views/installation-status'
        v = new InstallationStatusView {accountId}
        @_showView v

    segmentEnabled: ->
        auth.currentAccount.set('segmentEnabled', firebase.database.ServerValue.TIMESTAMP)

        if window.top is window
            @navigate "/#{window.location.search}", {replace: true, trigger: true}

    styling: ->
        StylingView = require 'views/styling'
        v = new StylingView()
        @_showView v

    satisfactionSurveys: ->
        queryObject = helpers.queryStrToObj()
        if queryObject.email and queryObject.provider
            id = auth.get('id') || auth.get('uid')
            helpers.processQueryParamsForSignup(id)
        isNPSBeta = auth.currentAccount?.hasFeatureGate('isNPSBeta')
        options = {
            isNPSBeta,
            product: queryObject.product
        }

        SatisfactionSurveysView = require 'views/satisfaction-surveys'
        v = new SatisfactionSurveysView(options)
        @_showView v, { noChecklist: true }

    segments: ->
        SegmentsView = require 'views/segments'
        v = new SegmentsView
        @_showView v

    editSegments: (segmentId) ->
        SegmentsEditView = require 'views/segments-edit'
        v = new SegmentsEditView { segmentId }
        @_showView v

    dashboard: ->
        DashboardView = require 'views/dashboard'
        v = new DashboardView
        @_showView v

    goals: ->
        GoalsView = require 'views/goals'
        v = new GoalsView
        @_showView v

    showGoals: (goalId) ->
        GoalsShowView = require 'views/goals-show'
        v = new GoalsShowView { goalId }
        @_showView v

    createGoals: ->
        GoalsCreateView = require 'views/goals-create'
        v = new GoalsCreateView
        @_showView v

    checklists: ->
        ChecklistsView = require 'views/checklists'
        v = new ChecklistsView
        @_showView v

    editChecklists: (checklistId) ->
        ChecklistsEditView = require 'views/checklists-edit'
        v = new ChecklistsEditView { checklistId }
        @_showView v

    checklistAnalytics: (checklistId) ->
        ChecklistAnalyticsView = require 'views/checklist-analytics'
        v = new ChecklistAnalyticsView { checklistId }
        @_showView v

    experiments: ->
        TeaserView = require('views/teaser')
        ExperimentTeaserView = TeaserView.extend({
            template: require('views/templates/teasers/experiments')
        })

        @_showView(new ExperimentTeaserView({
            teaserId: 'teaser_experiments'
        }))

    diagnostics: ->
        DiagnosticsView = require 'views/diagnostics'
        v = new DiagnosticsView
        @_showView v

    eventsAttributes: ->
        EventsAttributesView = require 'views/events-attributes'
        v = new EventsAttributesView
        @_showView v

    authProvider: (provider) ->
        # NOTE: This should probably be a env variable when we have a QA
        # env for our integration server.
        if provider
            loc = window.location
            redirectUrl = "#{INTEGRATION_SERVICE_URL}/auth/#{provider}"
            extra = loc.href.split(loc.origin + location.pathname)[1]

            # Redirect to the integration server and pass along query and
            # hash params.
            loc.href = redirectUrl + extra
        else
            e = new Error("Invalid provider given for integration: #{provider}")
            Raven.captureException e, {
                extra: {
                    integration: provider
                }
            }
            @navigate '/', {replace: true, trigger: true}

    saveAuth: (provider) ->
        # Get the stuff we need to save.
        queryParams = helpers.queryStrToObj()
        whitelist = ['access_token', 'refresh_token']

        # TODO: Convert expiresIn (which is useless) to an expiresAt.
        attrs = _.pick queryParams, whitelist
        userId = auth.get 'id'
        _.extend attrs, {
            # Store this in a consistent location that we can easily look up.
            provider: provider
        }

        integrations = new Integrations

        # On success, navigate to the list of integrations page.
        # TODO: Handle failure state.
        navigate = _.bind @navigate, @

        # FIX: This blows away the portalId that's set.
        # The better solution is to fix this in BackboneFire so their update
        # method doesn't suck so much.
        integration = integrations.createIntegration attrs, {
            success: ->
                navigate '/integrations', {replace: true, trigger: true}
        }
        return

    '404': ->
        window.location.href = '/404'

    checkContentExists: (id, type, callback) ->
        # Check if the model exists before routing.
        path = getModelForRouterContentType(type)::urlRoot() + "/#{id}"
        @check404 path, callback

    check404: (uri, callback) ->
        @getResource uri, (data) =>
            if data
                callback(data)
            else
                @navigate '404', {trigger: true, replace: true}

    getResource: (uri, callback) ->
        path = uri.replace(FIREBASE_URL, "")
        firebase.database().ref(path).once 'value', (snap) ->
            callback snap.val()

    loadContentForRuleId: (contentType, ruleId) ->
        promise = new $.Deferred
        @checkContentExists ruleId, contentType, ->
            Model = getModelForRouterContentType(contentType)
            data =
                ruleId: ruleId
                contentType: contentType
                content: new Model { id: ruleId }

            if contentType is 'journeys'
                data.journey = data.content
            else if contentType in ['flows', 'slideouts']
                data.flow = data.content
            else if contentType in ['hotspots', 'tooltips']
                data.hotspotGroup = data.content
            else if contentType is 'coachmarks'
                data.coachmarkGroup = data.content

            data.rule = new RuleModel(id: ruleId)
            $.when(data.content.whenSyncd(), data.rule.whenSyncd()).then ->
                promise.resolve(data)
        promise

    editInChrome: (stepGroupId, stepChildId) ->
        EditInChromeView = require 'views/edit-in-chrome'
        v = new EditInChromeView({ stepGroupId: stepGroupId, stepChildId: stepChildId })
        @_showView v


main = new MainRouter()
module.exports = main
