BaseView = require 'views/base'
GoalModel = require 'models/goal'
analytics = require 'lib/analytics'
auth = require 'models/auth'
helpers = require 'lib/helpers'


module.exports = class FlowFunnelView extends BaseView
    __name__: 'FlowFunnelView'
    className: 'flow-funnel'
    template: require 'views/templates/analytics/flow-funnel'

    initialize: ->
        @viewState = new Backbone.Model(keenUpdating: false)
        @listenTo @viewState, 'change:keenUpdating', @render

        if @model.syncd and @model.get('goalId')?
            @goal = new GoalModel(source: @model)
            @listenTo @goal, 'sync', @render
        @listenTo @model, 'change:stepsAnalytics', @render
        @_refreshAnalytics()

    render: ->
        @$el.html(@template({
            model: @model.toJSON()
            goal: if @goal then @goal.toJSON() else {}
            user: auth.user.pick(['fullname', 'email'])
            keenUpdating: @viewState.get('keenUpdating')
            funnelUpdated: moment(@model.get('funnelUpdated')).fromNow()
            versionPublished: moment(@model.get('updatedAt')).fromNow()
        }))

    _refreshAnalytics: ->
        @viewState.set 'keenUpdating', true
        flowAttrs = @model.toJSON()
        return unless flowAttrs.steps?
        flowAttrs.steps = _.values(flowAttrs.steps).sort helpers.stepComparator

        @fetchFlowFunnel = analytics.fetchFlowFunnel(auth.currentAccount.id, flowAttrs)
        @fetchGoalFunnel = analytics.fetchGoalFunnel(auth.currentAccount.id, flowAttrs)

        $.when(@fetchFlowFunnel.then(analytics.buildFunnelAnalysis), @fetchGoalFunnel).then (analysis, goalAnalysis) =>
            if analysis
                @model.set _.extend(analysis, {funnelUpdated: Date.now()})
            if goalAnalysis
                @model.set 'goalAnalytics', goalAnalysis
            @viewState.set('keenUpdating', false)
        .fail (response) -> # TODO

