BaseView = require 'views/base'
module.exports = class AppView extends BaseView
    __name__: 'AppView'
    template: require 'views/templates/two-column'

    render: ->
        super
        affixChildren @$el

        @$el

affixChildren = ($el) ->
    $el.find('[data-spy="affix"]').each ->
        $spy = $ @
        data = $spy.data()

        data.offset ?= {}
        data.offset.bottom = data.offsetBottom if data.offsetBottom
        data.offset.top = data.offsetTop if data.offsetTop

        $spy.affix data
