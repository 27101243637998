JourneyModel = require 'models/journey-firebase'
FlowModel = require 'models/flow-firebase'
HotspotGroupModel = require 'models/hotspot-group-firebase'
CoachmarkGroupModel = require 'models/coachmark-group-firebase'

# maps `:contentType` used in backbone router to `legacyStepType` (`firebaseContentType` in crx)
routerContentTypeLegacyStepTypeLookup =
    journeys: 'journey'
    flows: 'flow'
    slideouts: 'flow'
    hotspots: 'hotspot-group'
    tooltips: 'hotspot-group'
    coachmarks: 'coachmark-group'

# maps journey `stepType` (`id` in crx) to `:contentType` used in backbone router
stepTypeRouterContentTypeLookup =
    'modal': 'flows'
    'hotspot-group': 'hotspots'
    'tooltip-group': 'tooltips'
    'shorty': 'slideouts'
    'flow': 'journeys'

# maps `legacyStepType` (`firebaseContentType` in crx) to backbone-fire models
legacyStepTypeModelLookup =
    'journey': JourneyModel
    'flow': FlowModel
    'hotspot-group': HotspotGroupModel
    'coachmark-group': CoachmarkGroupModel

module.exports =
    getModelForLegacyStepType: (contentType) ->
        legacyStepTypeModelLookup[contentType]
    getModelForRouterContentType: (routerContentType) ->
        legacyStepTypeModelLookup[routerContentTypeLegacyStepTypeLookup[routerContentType]]
    getRouterContentTypeForStepType: (stepType) ->
        stepTypeRouterContentTypeLookup[stepType]