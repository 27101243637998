{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class PublicHotspotGroupsCollection extends Backbone.Firebase.Collection
    model: require 'models/hotspot-group'

    initialize: (options={}) ->
        @once 'sync', ->
            @syncd = true

    url: ->
        accountId = auth.currentAccount.id
        firebaseRef.child "public/users/#{accountId}/hotspot-groups"
