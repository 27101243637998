AppView = require 'views/app'
AccountModel = require 'models/account'
PropertiesModel = require 'models/properties-api'
UserModel = require 'models/user'
AccountMembershipsCollection = require 'collections/account-memberships'
FlowsCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'
{getFlowPreviewUrl} = require 'lib/helpers'


module.exports = class InstallationStatusView extends AppView
    __name__: 'InstallationStatusView'
    className: 'installation-status'
    template: require 'views/templates/installation-status'

    initialize: (options={}) ->
        {@accountId} = options
        @account = new AccountModel(id: @accountId)
        @listenTo @account, 'sync', @render
        @flows = new FlowsCollection([], accountId: @accountId)
        @listenTo @flows, 'sync', @render
        @hotspotGroups = new HotspotGroupsCollection([], accountId: @accountId)
        @listenTo @hotspotGroups, 'sync', @render
        @users = new Backbone.Collection
        @listenTo @users, 'sync add', @render
        @accountMemberships = new AccountMembershipsCollection([], accountId: @accountId)
        @listenTo @accountMemberships, 'sync', (accountMemberships) ->
            @accountMemberships.each((accountMembership) =>
                @users.add(new UserModel(id: accountMembership.get('id')))
            )
        @properties = new PropertiesModel({}, accountId: @accountId)
        @listenTo @properties, 'sync', @render
        @properties.fetch()
        @userHistory = firebaseRef.child("/public/users/#{@accountId}/userhistory")
        @hasHistory = false
        @userHistory.once('value', (snap) =>
            @hasHistory = !_.isEmpty(snap.val())
            @render()
        )
        @viewAs = if auth.currentAccount.get('id') is @accountId then '' else "?view_as=#{@accountId}"

    render: ->
        flows = @flows.map((flow) ->
            _.extend({}, flow.toJSON(), {
                previewUrl: getFlowPreviewUrl(flow.get('steps')) or '/images/integrations-bg.png'
                steps: _.values(flow.get('steps') or {})
            })
        )
        publishedFlows = _.select(flows, (flow) -> flow.published)
        hotspotGroups = @hotspotGroups.map((hotspotGroup) ->
            hotspots = _.values(hotspotGroup.get('hotspots') or {})
            _.extend({}, hotspotGroup.toJSON(), {
                previewUrl: (if hotspots.length > 0 and hotspots.length <= 5 then "/images/placeholders/hotspots-#{hotspots.length}.jpg" else '/images/integrations-bg.png')
                hotspots: hotspots
            })
        )

        properties = @properties.toJSON()
        userKeys = _.filter(Object.keys(properties), (key) ->
            key[0] != '_'
        )

        parsedProperties = _.map(userKeys, (key) ->

            propertyValue = properties[key]

            if _.isObject(propertyValue)
                propertyValue = Object.keys(propertyValue)[0]

            {
                key: key,
                value: propertyValue
            }

        )

        @$el.html @template {
            currentUserId: auth.user.get('id')
            currentAccountId: auth.currentAccount.get('id')
            accountId: @account.id
            account: @account.toJSON()
            properties: parsedProperties
            users: @users.toJSON()
            flows: flows
            publishedFlows: publishedFlows
            hotspotGroups: hotspotGroups
            hasHistory: @hasHistory
            viewAs: @viewAs
        }
