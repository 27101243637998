{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class FormFieldsCollection extends Backbone.Firebase.Collection
    model: require 'models/form-field'

    initialize: (models, options={}) ->
        accountId = auth.currentAccount.id
        unless (formId = options.formId)?
            throw new Error('Please provide a valid form id.')

        @url = firebaseRef.child "accounts/#{accountId}/forms/#{formId}/fields"

        @once 'sync', ->
            @syncd = true


    comparator: (a, b) ->
        # TODO - DRY with helpers.stepComparator
        if (a instanceof Backbone.Model) and (b instanceof Backbone.Model)
            aNum = a.get 'fieldNumber'
            bNum = b.get 'fieldNumber'
        else
            aNum = a.fieldNumber
            bNum = b.fieldNumber

        # Try to sort by field number.
        if aNum? and bNum? and aNum isnt bNum
            return 0 if aNum is bNum
            return if aNum < bNum then -1 else 1
        # Otherwise sort by ID.
        else
            aId = a.id
            bId = b.id
            return 0 if aId is bId
            return if aId < bId then -1 else 1

