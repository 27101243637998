AppView = require 'views/app'
auth = require 'models/auth'


module.exports = class BillingView extends AppView
    __name__: 'BillingView'
    template: require 'views/templates/billing'

    events:
        'click .add-card': 'addCard'
        'click .update-card': 'updateCard'
        'click .purchase': 'purchase'
        'click .panel-plan .btn': 'track'

    render: ->
        super

        if window.apcInjectable?
            window.apcInjectable.renderSubscription(auth.currentAccount.id, "apc-react-root")
        else
            window.setTimeout(@render.bind(@), 300)
