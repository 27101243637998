BaseView = require 'views/base'
{languageLookup} = require 'lib/rule-helpers'

module.exports = class RuleLanguagesView extends BaseView
    __name__: 'RuleLanguagesView'
    className: 'rule-languages'
    template: require 'views/templates/build-steps/settings/rule-languages'

    events: ->
        'change [name=toggleLanguages]': 'toggleAllLanguages'

    initialize: ->
        @viewState = new Backbone.Model(showLanguageDropdown: false)
        @listenTo @viewState, 'change:showLanguageDropdown', @render

    getContext: ->
        rule: @model.toJSON()
        showLanguageDropdown: @viewState.get('showLanguageDropdown') or not _.isEmpty(@getRuleLanguages())
        isPublished: @model.get('published')

    getRuleLanguages: ->
        return [] unless @model.get('languages')
        _.uniq(@model.get('languages')?.split(',') ? [])

    render: ->
        super
        @initializePlugins()

    initializePlugins: ->
        @showLanguages()

    toggleAllLanguages: ->
        showLanguageDropdown = @$('[name=toggleLanguages]:checked').attr('value') isnt 'all'
        if showLanguageDropdown is false
            @model.set 'languages', ''
            @showLanguages()

        @viewState.set 'showLanguageDropdown', showLanguageDropdown

    showLanguages: ->
        selectize_field = @$('#select-to').selectize
            persist: false
            maxItems: null
            valueField: 'code'
            labelField: 'desc'
            searchField: [
                'code'
                'desc'
            ]
            options: languageLookup

            onChange: (value) =>
                if _.isArray(value)
                    @model.set 'languages', value.toString()
                else
                    @model.set 'languages', value

        if @getRuleLanguages()[0]
            _.each(@getRuleLanguages(), (language) ->
                _.find(languageLookup, (lookup) ->
                    if lookup.code is language
                        selectize_field[0].selectize.addItem language
                )
            )
        else if @getRuleLanguages()[0] is undefined
            selectize_field[0].selectize.clear(true)
