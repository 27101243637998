AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class GoalsShowView extends AppView
    __name__: 'GoalsShowView'
    className: 'goals-show'
    template: require 'views/templates/goals-show'

    initialize: (options) ->
        {@goalId} = options


    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderGoalsShow auth.currentAccount.id, @goalId, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
