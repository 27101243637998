InstallGuideView = require 'views/install-guide'
CodeSnippetViewIG = require 'views/code-snippet-ig'

module.exports = class InstallGuideSPAView extends InstallGuideView
    __name__: 'InstallGuideSPAView'
    className: 'install-guide-spa'
    template: require 'views/templates/install-guide-spa'

    events:
        'click #next' : 'showVerify'
        'click a.choose-install-type' : 'selectInstallType'
        'click .panel-choose-install' : 'chooseInstallType'
        'click [data-action=inviteUser]' : 'showInviteUserModal'
      
    render: ->
        super
        @installType = 'Single-page web app'
        view = new CodeSnippetViewIG {@accountId}
        @renderChild view
        @$('.code-snippet-ig').append view.el
        @$('#instructions').show()
        @$('#installChoice').removeClass('in')
