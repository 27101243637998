{firebaseRef} = require 'lib/firebase'

module.exports = class UserAccountsCollection extends Backbone.Collection
    firebasePath: ""

    initialize: (models, options) ->
        userId = options?.userId
        unless userId
            return

        @firebasePath = "users/#{userId}/accounts"

        @on 'sync', ->
            @syncd = true

        # Fetch data here to simulate Backbone-Firebase behavior
        firebaseRef.child(@firebasePath).once 'value', (snap) =>
            if snap.val() isnt null
                # firebase may try to turn this into an array, let's counteract that
                results = _.reduce snap.val(), (memo, val, index) ->
                    if val?
                        memo[index] = val
                    return memo
                , {}

                @set @parseResults(results), silent: true
            @trigger 'sync'

        @on 'add', (accountObject) =>
            @syncd = false
            accountId = accountObject.get 'accountId'
            enabledValue = accountObject.get 'enabled'
            firebaseRef.child("#{@firebasePath}/#{accountId}").set enabledValue, (err) =>
                @trigger 'sync', err

        @on 'remove',(accountObject) =>
            @syncd = false
            accountId = accountObject.get 'accountId'
            firebaseRef.child("#{@firebasePath}/#{accountId}").set null, (err) =>
                @trigger 'sync', err

        @on 'change:enabled', (model, value) ->
            @syncd = false
            accountId = model.get 'accountId'
            firebaseRef.child("#{@firebasePath}/#{accountId}").set value, (err) =>
                @trigger 'sync', err


    parseResults: (results) ->
        mappedResults = _.map results, (val, key) ->
            accountId: key
            enabled: val

        _.filter mappedResults, (a) -> a.enabled == true

