ModalView = require 'views/modal'

offsetProps = ['offsetXPercentage', 'offsetYPercentage']


module.exports = class HotspotModalView extends ModalView
    __name__: 'HotspotModalView'
    template: require 'views/templates/hotspot-modal'

    initialize: (options={}) ->
        {@isPublished} = options
        super

    events:
        'click .modal-save': 'submitForm'
        'change input[name=_tooltipAlignment]': 'toggleTooltipAlignment'

    submitForm: ->
        data = @$el.find('form').serializeObject()

        # Transform offset percentages back to decimals.
        offsetProps.forEach (key) ->
            data[key] = data[key] / 100

        # Handle manual tooltip positioning.
        if data._tooltipAlignment is 'false'
            data.tooltipAlignment = null

        # Remove private _tooltipAlignment field.
        delete data._tooltipAlignment

        @model.set data
        @trigger('save', @$el, @).hide()

    toggleTooltipAlignment: (evt) ->
        val = evt.currentTarget.value
        # FYI manipulating the DOM directly like this sucks, but because it's a form
        # that we may not have saved yet, we can't just rerender.
        @$('.radio-reveal .radio-content').toggleClass 'hidden', (val is 'false')

    getContext: ->
        context = @model.toJSON()
        context.isPublished = @isPublished

        context.edgeCombos = [
            {name: '&#8615; Top', value: 'top'}
            {name: '&#11022; Top left', value: 'top-left'}
            {name: '&#11024; Top right', value: 'top-right'}
            {name: '&#8613; Bottom', value: 'bottom'}
            {name: '&#11023; Bottom left', value: 'bottom-left'}
            {name: '&#11025; Bottom right', value: 'bottom-right'}
        ]

        # Transform offset percentages to full numbers.
        offsetProps.forEach (key) ->
            context[key] = context[key] * 100

        return context
