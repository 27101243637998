BaseComponentView = require 'views/components/base'
editor = require 'lib/editor'
virtualize = require('lib/snabbdom-virtualize').default

module.exports = class EditorComponentView extends BaseComponentView
    __name__: 'EditorComponentView'
    className: 'component-editor editor-component'
    template: require 'views/templates/components/editor'
    editorIsDirtyMessage: "You have unsaved changes."
    editorIsDirtyCTA: "Are you sure you want to leave this page?"

    render: ->
        @_removeEditor()

        super
        @_initializeEditor()
        @

    close: ->
        $($('.component-editor-mask'), @el).fadeOut(100, =>
            @remove()
            $('.component-editor-mask').remove()
        )

    focus: ->
        _.delay =>
            if @$editor and @$editor.data('redactor')
                @$editor.redactor('focus.setEnd')

    remove: ->
        @_removeEditor()
        super

    saveAndClose: ->
        try
            virtualize(@$editor.redactor('code.get'))
            @updateValue()
            @close()
        catch error
            @_showHtmlErrorMessage()


    updateValue: =>
        @_emptyNavigationMessage()
        @model.set({html: @$editor.redactor('code.get')}, {skipRender: true}) if @$editor

    _emptyNavigationMessage: ->
        localStorage?.setItem('routerBeforeNavigationMessage', '')
        localStorage?.setItem('routerBeforeNavigationCTA', '')

    _initializeEditor: ->
        @$editor = @$('.content-editor')
        initialText = @model.get('html')

        editorIsDirtyMessage = @editorIsDirtyMessage
        editorIsDirtyCTA = @editorIsDirtyCTA
        _emptyNavigationMessage = @_emptyNavigationMessage

        options =
            changeCallback: ->
                if @code.get() != initialText
                    localStorage?.setItem('routerBeforeNavigationMessage', editorIsDirtyMessage)
                    localStorage?.setItem('routerBeforeNavigationCTA',  editorIsDirtyCTA)
                else
                    _emptyNavigationMessage()
            codeKeydownCallback: (e) =>
                if $('.warning-message').css('opacity') == '1'
                    @_hideHtmlErrorMessage()

        if @model.get('migrated')
            editor.video @$editor, {}
        else
            editor.simple @$editor, options

    _removeEditor: ->
        @_emptyNavigationMessage()
        if @$editor?.length
            editor.remove @$editor
            @$editor = null

    _showHtmlErrorMessage: ->
        $('.warning-message').css('opacity', '1')

    _hideHtmlErrorMessage: ->
        $('.warning-message').css('opacity', '0')
