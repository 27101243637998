# Class for managing protected iframed environments.
module.exports = class Cocoon

    constructor: (attrs={}) ->
        id = attrs.id ? _.uniqueId('cocoon')
        _.defaults attrs, {
            id: id
            name: id  # Ensure name and ID are the same.
        }
        @id = id

        # NOTE: You need to first add this to the DOM in order to inject stuff.
        @$frame = @constructor.create attrs

        # Add our default class.
        @$frame.addClass 'cocoon cocoon-frame'

        # Wait for this to be injected into the DOM to set the body, otherwise
        # it won't actually exist yet.
        @initialize attrs

        @_isReady = false
        @_readyCallbacks = []
        @addListener @$frame[0]

        @$frame.on 'load', _.bind(@onFrameLoad, @)

        return @

    initialize: (attrs) ->  # Implement me.

    addListener: (frame) ->
        # Listen for the frame to report that it's ready to talk.
        window.addEventListener 'message', ({data, source}) =>
            # Only listen to this frame.
            return unless source is frame.contentWindow

            if typeof data is 'string'
                try
                    data = JSON.parse data
                catch e
                    # Got junk in the message, ignore.
                    data = {}

            if data.action is 'ready'
                while @_readyCallbacks.length
                    @_readyCallbacks.shift().call @
                @_isReady = true

    onFrameLoad: ->
        @window = @$frame[0].contentWindow
        return

    ready: (callback) ->
        if @_isReady
            callback()
        else
            @_readyCallbacks.push callback

    rerendering: ->
        @_isReady = false

    on: (action, callback) =>
        window.addEventListener 'message', ({data, source}) =>
            return unless source is @$frame[0].contentWindow

            if typeof data is 'string'
                try
                    data = JSON.parse data
                catch e
                    # Got junk in the message, ignore.
                    data = {}

            if data.action is action
                callback(data)

    off: ->  # FIX

    trigger: (action, data) =>
        data.action =  action
        @$frame[0].contentWindow.postMessage JSON.stringify(data), '*'

    navigate: (fragment) ->
        @$frame[0].contentWindow.location.hash = '#' + fragment

    @create: (attrs={}) ->
        _.defaults attrs, {
            seamless: true
            width: '100%'
            height: '100%'
            src: 'javascript:0'
            tabindex: '-1'
        }

        # Create the frame.
        $ '<iframe/>', attrs

    $: (selector) ->
        @$frame.contents().find selector

    # Resize to fit the contents of the frame.
    fit: =>
        @transform {
            height: @$frame.contents().innerHeight()
            width: @$frame.contents().innerWidth()
        }

    transform: (attrs) =>
        # Temporarily disable pointer events to make resizing smooth.
        orig = attrs['pointer-events'] ? ''

        @$frame
            .css 'pointer-events', 'none'
            .css attrs
            .css 'pointer-events', orig

    css: (path, callback) ->
        # We can't just use jQuery, we need to use the frame's
        # document to create this element.
        el = @window.document.createElement 'link'
        el.href = path
        el.rel = 'stylesheet'
        el.type = 'text/css'

        if callback
            callback = _.bind callback, @
            el.addEventListener 'load', ->
                callback()
                el.removeEventListener 'load', arguments.callee
            , false
        @window.document.head.appendChild el

    js: (path, callback) ->
        # Append to the head so we don't get wiped out when inserting HTML
        # into the body.
        el = @window.document.createElement 'script'
        el.src = path
        el.type = 'text/javascript'

        if callback
            callback = _.bind callback, @
            el.addEventListener 'load', ->
                callback()
                el.removeEventListener 'load', arguments.callee
            , false
        @window.document.head.appendChild el

    destroy: ->
        @$frame.remove()
