BaseView = require 'views/base'
HotspotModalView = require 'views/hotspot-modal'
{confirm} = require 'views/alerts'


module.exports = class HotspotsSummaryView extends BaseView
    __name__: 'HotspotsSummaryView'
    className: 'content-thumbnails'
    template: require 'views/templates/build-steps/design/hotspot-group/hotspots-summary'

    events:
        'click .list-group-item': 'select'
        'click .edit-hotspot': 'edit'
        'click .delete-hotspot': 'delete'

    initialize: (options={}) ->
        {@hotspotGroup} = options
        @isEditable = options.isEditable ? true
        @collection ?= new Backbone.Collection

        @listenTo @hotspotGroup, 'change:sequential', @render

        @listenTo @collection, 'remove', @resetSelected
        @listenTo @collection, 'add remove sort', @render
        # TODO: Keep selection when beacons are collapsed/expanded.
        @listenTo @collection, 'add', (model) ->
            @collection.select model.id
        @listenTo @collection, 'select', @updateSelected

        # Update the preview frame when individual hotspots change.
        @listenTo @collection, 'change:offsetXPercentage change:offsetYPercentage change:selector change:tooltipAlignment', ->
            @hotspotGroup.trigger '_update'

    render: ->
        super

        if @isEditable
            $hotspotsContainer = @$ '.thumbnails-list'
            new Sortable $hotspotsContainer.get(0), {
                draggable: '.draggable-list-group-item'
                onUpdate: (evt) =>
                    # Get the current sort state.
                    $hotspots = $hotspotsContainer.find '.draggable-list-group-item'
                    $hotspots.each (i, hotspot) =>
                        # Update the hotspot numbers.
                        hotspotId = $(hotspot).data 'id'
                        @collection.get(hotspotId)
                            .set 'index', i

                    @collection.sort()
            }
        return @

    getContext: ->
        hotspots = @collection.toJSON()
        # Add the unused hotspots.
        for i in _.range(@collection.LIMIT - hotspots.length)
            hotspots.push {}

        return {
            isSequential: @hotspotGroup.get('sequential')
            hotspots: hotspots
            selectedId: @collection.getSelectedId()
            isEditable: @isEditable
        }

    resetSelected: ->
        @collection.select(@collection.at(0)?.get('id'))

    updateSelected: ->
        # Do it this way so we get nice animations.
        @$('li.list-group-item.selected').removeClass('selected')
        @$('li.list-group-item[data-id=' + @collection.getSelectedId() + ']').addClass('selected')

    select: (e) ->
        el = e.currentTarget
        if el.hasAttribute 'data-id'
            @collection.select el.getAttribute('data-id')

    edit: (e) ->
        parentEl = @$(e.currentTarget).parents('.thumbnail-item').get(0)
        if parentEl
            id = parentEl.getAttribute 'data-id'
            model = @collection.get id

            if model
                v = new HotspotModalView
                    model: model
                    isPublished: @hotspotGroup.get('published')
                    show: true
                @renderChild v
            else
                return false

    delete: (e) ->
        hotspotType = if @hotspotGroup.get('sequential') then 'tooltip' else 'hotspot'
        # Get the ID.
        parentEl = @$(e.currentTarget).parents('.thumbnail-item').get(0)
        if parentEl
            id = parentEl.getAttribute 'data-id'
            model = @collection.get id

            confirm("Are you sure you want to delete this #{hotspotType}?").then (res) =>
                if model and res
                    @collection.remove model
