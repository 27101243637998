{fieldLookup} = require 'lib/helpers'

module.exports = class FormFieldModel extends Backbone.Model
    defaults:
        fieldType: null
        required: false
        validationType: null

    getFieldTypeInfo: ->
        fieldType = @get('fieldType') ? 'default'
        _.clone fieldLookup[fieldType]

