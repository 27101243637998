BaseView = require 'views/base'
InviteUsersModalView = require 'views/invite-modal'
AccountModel = require 'models/account'
auth = require 'models/auth'
trackEvent = require 'lib/track-event'

module.exports = class InstallGuideView extends BaseView
    __name__: 'InstallGuideView'
    className: 'install-guide'
    template: require 'views/templates/install-guide'

    events:
        'click a.choose-install-type' : 'selectInstallType'
        'click .panel-choose-install' : 'chooseInstallType'
        'click [data-action=inviteUser]': 'showInviteUserModal'

    initialize: (options={}) ->
        {@accountId, @hideTitle} = options
        @account = new AccountModel(id: @accountId)
        @listenTo @account, 'sync', @render
        @listenTo @account, 'change:isInstalled', @render

    getContext: ->
        accountId: @accountId || false
        hideTitle: @hideTitle || false
        isInstalled: @account.get('isInstalled')
        hasAuth: auth.currentAccount.id?

    render: ->
        super
        @$('#verifyInstall').removeClass('in')

    openPanel: (panelId) ->
        @$('#installChoice').collapse('hide') unless panelId == "install"
        @$('#instructionsBody').collapse('hide') unless panelId == "instructions"
        @$('#verifyInstall').collapse('hide') unless panelId == "verify"
        if panelId
            @$("##{panelId} .panel-heading").next(".collapse").collapse('show')
            @$("##{panelId} .panel-heading").addClass('panel-heading-active')

    selectInstallType: (event) ->
        @openPanel('instructions')
        @render()
        trackEvent 'Clicked Installation Type',
            name: event.target.innerText


    chooseInstallType: ->
        @openPanel('install')

    showVerify: ->
        @openPanel('verify')
        trackEvent 'Clicked Installation Verify'

    showInviteUserModal: ->
        v = new InviteUsersModalView
            show: true
            role: ['Developer']
        @renderChild(v)
        trackEvent 'Clicked Installation Invite User'
