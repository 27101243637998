BaseView = require 'views/base'
auth = require 'models/auth'
{APPCUES_API_URL, APPCUES_API_KEY, APPCUES_API_SECRET} = window.__env


module.exports = class RuleEventsView extends BaseView
    __name__: 'RuleEventsView'
    className: 'rule-events'
    template: require 'views/templates/build-steps/settings/rule-events'

    events:
        'change [name=toggleEvents]': '_updateToggleEvents'
        'change [name=targetEventName]': '_updateTargetEventName'

    _updateToggleEvents: (evt) =>
        matchValue = @$(evt.currentTarget).val()
        if matchValue == 'negativeMatch'
            @viewState.set('negativeMatch', true)
            @viewState.set('positiveMatch', false)
            @viewState.set('showTargetEvents', true)
        else if matchValue == 'positiveMatch'
            @viewState.set('negativeMatch', false)
            @viewState.set('positiveMatch', true)
            @viewState.set('showTargetEvents', true)
        else # all
            @viewState.set('negativeMatch', false)
            @viewState.set('positiveMatch', false)
            @viewState.set('showTargetEvents', false)
        @_updateRuleState()

    _updateTargetEventName: =>
        eventName = @$('#targetEventName').val()
        if eventName and eventName != ""
            @viewState.set('ruleTargetEventName', eventName)
        else
            @viewState.set('ruleTargetEventName', null)
        @_updateRuleState()

    _updateRuleState: =>
        target = @viewState.get('ruleTargetEventName')
        negative = @viewState.get('negativeMatch')
        showTargetEvents = @viewState.get('showTargetEvents')
        if target && showTargetEvents
            matchInfo = {}
            matchInfo[target] = {times: 1}
            if negative
                matchInfo[target]["negative"] = true
            @rule.set('events', matchInfo)
        else
            @rule.set('events', null)
        @render()

    ruleTargetEventName: ->
        e = @rule.get('events')
        if e
            Object.keys(e)[0]
        else
            null

    rulePositive: ->
        e = @rule.get('events')
        if e
            key = Object.keys(e)[0]
            ! e[key]["negative"]
        else
            false

    ruleNegative: ->
        e = @rule.get('events')
        if e
            key = Object.keys(e)[0]
            !! e[key]["negative"]
        else
            false

    initialize: (options={}) ->
        {@rule} = options
        @viewState = new Backbone.Model {
            ruleTargetEventName: @ruleTargetEventName()
            showTargetEvents: !!@ruleTargetEventName()
            negativeMatch: @ruleNegative()
            positiveMatch: @rulePositive()
            eventNames: []
        }

        accountId = auth.currentAccount.get('id')

        $.ajax "#{APPCUES_API_URL}/v1/accounts/#{accountId}/event_names",
            method: 'GET'
            contentType: 'application/json'
            beforeSend: (xhr) ->
                authStr = btoa(APPCUES_API_KEY + ":" + APPCUES_API_SECRET)
                xhr.setRequestHeader("Authorization", "Basic #{authStr}")
            success: (eventsData) =>
                eventNames = Object.keys(eventsData)
                @viewState.set('eventNames', eventNames)
                @render()
            error: (jqxhr, status, err) ->
                e = new Error("Couldn't get event names from Appcues API")
                Raven.captureException e, {
                    extra: {error: err}
                }

        @render()

    getContext: ->
        rule: @rule.toJSON()
        isPublished: @rule.get('published')
        ruleTargetEventName: @viewState.get('ruleTargetEventName')
        showTargetEvents: @viewState.get('showTargetEvents')
        eventNames: @viewState.get('eventNames')
        positiveMatch: @viewState.get('positiveMatch')
        negativeMatch: @viewState.get('negativeMatch')

    render: ->
        super

        @$('select[name=targetEventName]').selectize({
            closeAfterSelect: true
            selectOnTab: true
            render:
                item: (item, escape) ->
                    if item.name
                        """
                        <div class="event-option title="#{escape item.name}" style="width:98%; padding-right: 20px">
                            <span class="event-name">#{escape item.name}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
                option: (item, escape) ->
                    if item.name
                        """
                        <div class="event-option title="#{escape item.name}" style="width:98%; padding-right: 20px">
                            <span class="event-name">#{escape item.name}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
        })

