auth = require 'models/auth'
BaseComponentView = require 'views/components/base'
{CLOUDINARY_ACCOUNT_ID, CLOUDINARY_API_KEY} = window.__env


module.exports = class ImageComponentView extends BaseComponentView
    __name__: 'ImageComponentView'
    className: 'component-editor image-component appcues-style'
    template: require 'views/templates/components/image'

    events: ->
        _.extend {}, super, {
            'change [name=width]': 'setWidth'
            'change [name=linkTarget]': 'setLinkTarget'
            'change [name=position]': 'setPosition'
            'change [name=link]': 'setLink'
            'click .apc-img-wrap a': 'returnFalse'
            'click [data-action=resetForm]': 'resetForm'
        }

    initialize: ->
        super
        imageUrl = @model.get('src')
        @viewState = new Backbone.Model {
            error: null
            link: @model.get('link')
            linkTarget: @model.get('linkTarget')
            position: @model.get('position')
            rendered: false
            uploading: false
            uploadPercent: null
            rawImageUrl: imageUrl
            uploadedImageUrl: imageUrl
            width: @model.get('width')
        }
        @listenTo @viewState, 'change', (model, options={}) ->
            @render() unless options.skipRender

    getContext: ->
        _.extend {}, super, @viewState.toJSON()

    render: ->
        super
        # Prevent the settings from jumping too much.
        @$('.apc-img-wrap').css('min-height', @$el.height() - @$('.settings').height())
        @setupDropArea() if @$('.drop-area').length
        @viewState.set('rendered', true) unless @viewState.get('rendered')
        @

    updateValue: ->
        @model.set {
            link: @viewState.get('link') or null
            linkTarget: @viewState.get('linkTarget') or null
            position: @viewState.get('position') or null
            src: @viewState.get('uploadedImageUrl')
            rawSrc: @viewState.get('rawImageUrl')
            width: @viewState.get('width') or null
        }

    setWidth: (evt) ->
        @viewState.set {width: @$(evt.currentTarget).val()}, {skipRender: true}

    setPosition: (evt) ->
        @viewState.set {position: @$(evt.currentTarget).val()}, {skipRender: true}

    setLink: (evt) ->
        @viewState.set {link: @$(evt.currentTarget).val()}, {skipRender: true}

    setLinkTarget: (evt) ->
        @viewState.set {linkTarget: @$(evt.currentTarget).val()}, {skipRender: true}

    setupDropArea: ->
        $dropArea = @$('.drop-area').addClass('dropzone')
        @dropzone = new Dropzone($dropArea.get(0), {
            acceptedFiles: 'image/*'
            clickable: $dropArea.find('.dropzone-btn').get(0)
            previewsContainer: false
            uploadMultiple: false
            url: "https://api.cloudinary.com/v1_1/#{CLOUDINARY_ACCOUNT_ID}/image/upload"
        })
        @dropzone.on 'dragover', (evt) ->
            $dropArea.find('.message').text('Drop to upload')
        @dropzone.on 'dragenter', (evt) ->
            $dropArea.find('.message').text('Drop to upload')
        @dropzone.on 'dragend', (evt) ->
            $dropArea.find('.message').text('Drag an image here')
        @dropzone.on 'dragleave', (evt) ->
            $dropArea.find('.message').text('Drag an image here')
        @dropzone.on 'sending', (file, xhr, formData) =>
            formData.append 'api_key', CLOUDINARY_API_KEY
            formData.append 'timestamp', (Date.now() / 1000 | 0)

            # Tag the image with the account ID and user ID.
            accountId = auth.currentAccount.id
            userId = auth.id
            formData.append 'tags', ["account-#{accountId}", "user-#{userId}"].join(',')
            formData.append 'folder', accountId

            # Transforming GIFs can have unintended consequences for Cloudinary,
            # like causing the GIF to become to large if it's scaled up, or dropping
            # frames if a transformation doesn't support the full resolution.
            if file.type is 'image/gif'
                uploadPreset = 'gif_preset'
            else
                uploadPreset = 'sdruqnxi'

            formData.append 'upload_preset', uploadPreset
            @viewState.set {uploading: true}
        @dropzone.on 'uploadprogress', (evt, percentage) =>
            @viewState.set {uploadPercent: percentage}
        @dropzone.on 'success', (file, response) =>
            processedUrl = rawUrl = response.secure_url
            if response.eager?
                processedUrl = response.eager[0]?.secure_url

            @viewState.set {
                error: null
                uploading: false
                uploadPercent: null
                rawImageUrl: rawUrl
            }
            # We used the unprocessed image URL to render immediately, but we
            # want to save the process/resized URL for future use.
            @viewState.set {uploadedImageUrl: processedUrl}, {silent: true}

    resetForm: ->
        @viewState.set {
            uploading: false
            uploadPercent: null
            uploadedImageUrl: null
            rawImageUrl: null
        }

    returnFalse: (evt) -> return false
