ModalView = require 'views/modal'
{confirm} = require 'views/alerts'

module.exports = class ManageFontsModalView extends ModalView
    __name__: 'ManageFontsModalView'
    template: require 'views/templates/manage-fonts-modal'


    events:
        'submit form.add-font-form': 'processGoogleUrl'
        'click a.clear-current-font': 'clearCurrentFont'
        'click .change-current-font': 'resetVals'
        'click .save-fonts': 'saveCustomFonts'


    initialize: (options={}) ->
        @addGoogleFont = options.addGoogleFont ? ->
        @getFontFamilies = options.getFontFamilies ? ->
        @addTypekitId = options.addTypekitId ? ->
        @saveToStylingPage = options.saveToStylingPage ? ->

        super
        @model = new Backbone.Model {
            customUrl: options.customFontUrl || ''
            customFonts: options.fonts || []
            typekitId: options.typekitId || ''
        }

        @listenTo @model, 'change', @render

    getURL: ->
        @$('.add-font-form input[name=googleLink]').val()

    getTypekitId: ->
        @$('input[name=typekitId]').val()

    processGoogleUrl: (evt)->
        evt.preventDefault()

        url = @getURL()
        customFonts = @getFontFamilies(url)

        if !customFonts
            return @$('.form-error-msg').removeClass('hidden')

        @model.set 'customUrl', url
        @model.set 'customFonts', customFonts

    clearCurrentFont: ->
        confirm('Clear custom fonts?', {
            text: "This will remove this font from your stylesheet."
            confirmButtonText: "Clear font"
            cancelButtonText: "Cancel"
        }).then((res) =>
            if res
                @resetVals()
                @addGoogleFont(null, [])
        )

    resetVals: () ->
        @model.set 'googleLink', ''
        @model.set 'customUrl', ''
        @model.set 'customFonts', []


    saveCustomFonts: () ->
        if @model.get('customUrl').length
            @addGoogleFont(@model.get('customUrl'), @model.get('customFonts'))
        else
            @addGoogleFont(null, [])

        if @getTypekitId().length
            @addTypekitId(@getTypekitId())
        else
            @addTypekitId(null)

        @saveToStylingPage()
        @hide()

    getContext: -> @model.toJSON()
