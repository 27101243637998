helpers = require 'lib/helpers'
AppView = require 'views/app'
UserAccountsCollection = require 'collections/user-accounts'
trackEvent = require 'lib/track-event'

APPCUES_SAFARI_ISSUE_FLOW_ID = "-LVz7WbxbtW6I73UL7cX"

ua = navigator.userAgent
isSafari = ->
    if ua.indexOf('Safari') != -1 and ua.indexOf('Chrome') == -1 and ua.indexOf('Chromium') == -1
        return true
    return false

module.exports = class LoginView extends AppView
    __name__: 'LoginView'
    template: require 'views/templates/auth-form'
    model: require 'models/auth'

    initialize: (options) ->
        redirectUrl = options?.redirectUrl or '/'

        # Temporarily store context.
        @context = new Backbone.Model
            email: options?.email or ''
            forgotPassword: false
            error: ''
            success: ''
            auth: options?.auth
            uid: options?.uid
            wasInvited: options?.wasInvited
            showRedirectBackdrop: false
            isGoogleProvider: false
            baseUrl: __env.BETA_HOST

        @listenTo @context, 'change', @render

        # if we're not logged in on my.appcues, let's make sure
        # reactcues is logged out to match
        if window isnt window.top
            window.parent.postMessage(JSON.stringify({
                type: "logout"
            }), "*")

        @listenTo @model, 'loggedInAndSynced', (user, accountId) ->
            email = user.email
            userId = user.id

            afterLoggedInAndSynced = () ->
                if window is window.top
                    window.location.href = redirectUrl
                else
                    helpers.sendAuthToParentFrame(user.firebaseUser, accountId)

            # NOTE: Segment's `track` callback won't fire if tracking is not enabled.
            if __env.TRACKING_ENABLED
                userData =
                    userId: userId
                    email: email

                # Try to track these events and redirect when done.
                trackEvent 'Logged in', userData, {}, ->
                    afterLoggedInAndSynced()

            else
                afterLoggedInAndSynced()

        # Failed to login.
        @listenTo @model, 'error', (error) ->
            @$('form [type=submit]').removeClass 'disabled'
            @$('.validation-error').removeClass 'hidden'

    render: ->
        super

        unless @firstRenderReported
            window.parent.postMessage(JSON.stringify({
                type: "render.auth.login"
            }), "*")
            @firstRenderReported = true

        if isSafari()
            Appcues.show(APPCUES_SAFARI_ISSUE_FLOW_ID)

    events:
        'submit form.login-form': 'login'
        'submit form.forgot-password': 'sendResetEmail'
        'click .toggle-form': 'toggleForms'
        'click .google-auth': 'loginWithGoogle'
        'click .reset-login': 'resetLoginForm'

    login: ->
        $form = @$ 'form.login-form'
        $form.find('[type=submit]').addClass 'disabled'
        data = $form.serializeObject()

        # Save data on the temp context model in case they need help
        # resetting.
        @context.set {email: data.email}, {silent: true}
        context = @context.attributes
        data = $.extend(data, {uid: context.uid, wasInvited: context.wasInvited})

        # Actually log them in.
        @model.login 'password', data
        return false

    loginWithGoogle: ->
        @context.set {showRedirectBackdrop: true}
        @model.login 'google.com'
        return false

    toggleForms: (e) ->
        @context.set 'forgotPassword', @$(e.currentTarget).data('forgot')

    getContext: -> @context.toJSON()

    sendResetEmail: ->
        $form = @$ 'form.forgot-password'
        $form.find('[type=submit]').addClass 'disabled'
        email = $form.find('input[name=email]').val()

        # Save for convenience.
        @context.set 'email', email

        @model.sendResetEmail email, (err, success) =>
            if err
                @context.set
                    success: false
                    error: true
            else
                @context.set
                    success: true
                    error: false

        return false

    resetLoginForm: ->
        @context.set
            success: false
            error: false
            showRedirectBackdrop: false
