auth = require 'models/auth'
AppView = require 'views/app'
CoachmarksCollection = require 'collections/coachmarks'
CoachmarksFrameView = require 'views/build-steps/design/coachmark-group/frame'
CoachmarksSummaryView = require 'views/build-steps/design/coachmark-group/coachmarks-summary'
ShareModalView = require 'views/share-modal'
page = require 'models/page'
helpers = require 'lib/helpers'
{confirmUnpublishContent} = require 'lib/build-helpers'


module.exports = class BuildCoachmarkGroupView extends AppView
    __name__: 'BuildCoachmarkGroupView'
    className: 'build-coachmark-group'
    template: require 'views/templates/build-steps/design/coachmark-group'

    events:
        'click [data-action=unpublishContent]': '_unpublishContent'
        'click [data-action=placeCoachmark]': 'placeCoachmark'
        'click [data-action=initExtensionBuilder]': 'initExtensionBuilder'
        'click [data-action=share]': 'share'

    initialize: (options={}) ->
        {@ruleId, @rule, @content, @coachmarkGroup} = options
        @listenTo @rule, 'change:published', @render

        @useEager = if localStorage.getItem('useEager') is 'false' then false else true

        @coachmarks = new CoachmarksCollection [], {coachmarkGroupId: @coachmarkGroup.id}

        page.set
            title: @content.get('name')
            isEditable: true
        , skipContent: true
        @listenTo page, 'change:title', (page, model, opts) ->
            if opts.skipContent isnt true
                @content.set 'name', page.get('title')

    getContext: ->
        rule = @rule.toJSON()
        coachmarkGroup = @coachmarkGroup.toJSON()

        return {
            buildStep: 'design'
            @ruleId
            rule
            coachmarkGroup
            urlPrefix: @content.urlPrefix()
            isAdmin: auth.user.get 'isAdmin'
            cannotPublish: auth.user.get 'cannotPublish'
        }

    render: ->
        @removeChildViews()
        super

        @coachmarksSummaryView = new CoachmarksSummaryView
            isEditable: not @rule.get('published')
            model: @coachmarkGroup
            collection: @coachmarks
            useEager: @useEager
        @renderChild @coachmarksSummaryView
        @$('.main-row .container .row').html @coachmarksSummaryView.el

        @coachmarksFrameView = new CoachmarksFrameView
            model: @coachmarkGroup
            collection: @coachmarks
            rule: @rule
            useEager: @useEager
        @renderChild @coachmarksFrameView
        @$('#build-preview-frame').html @coachmarksFrameView.el

    _unpublishContent: confirmUnpublishContent

    share: ->
        shareModel = new Backbone.Model({
            flowId: @content.id
            previewUrl: @rule.get('previewUrl')
        })
        v = new ShareModalView
            show: true
            model: shareModel

        @renderChild(v)

    placeCoachmark: (evt)->
        @$(evt.currentTarget).closest('.placeCoachmark').addClass('selected').siblings().removeClass('selected')
        previewFrame = @coachmarksFrameView.previewFrame
        if previewFrame.sendMessage?
            previewFrame.sendMessage {action: 'placeCoachmark'}
        else
            # Eager, inject a script to postMessage it's own window :/
            previewFrame.inject {
                type: 'application/javascript'
                content: """
                    (function () {
                        window.postMessage({action: 'placeCoachmark'}, '*');
                    })();
                """
            }

    initExtensionBuilder: ->
        @coachmarksFrameView?.initExtensionBuilder()


