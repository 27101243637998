BaseView = require 'views/base'


module.exports = class CategoryAreasView extends BaseView
    __name__: 'CategoryAreasView'
    className: 'category-areas'
    template: require 'views/templates/content-list/category-areas'

    initialize: (options={}) ->
        {@categories, @content} = options

    getContext: ->
        categories: @categories.toJSON()

    render: ->
        super

        @$('.category-area').each (i, area) =>
            $area = @$(area)
            new Sortable(area,
                group: 'content-categories'
                pull: false
                put: true
                onAdd: (evt) =>
                    itemData = @$(evt.item).data()
                    contentId = itemData.flowId ? itemData.hotspotGroupId ? itemData.coachmarkGroupId
                    @content.get(contentId).set('category', $area.data('category'))
                    lytics.trackWithUser "Categorized content", {
                        categoryName: @categories.get($area.data('category'))?.get('name')
                        contentType: if itemData.hotspotGroupId? then 'hotspots' else 'flow'
                        ruleId: contentId
                    }
                    @content.trigger('categorize')
            )
