AppView = require 'views/app'
articles = require 'lib/articles'
trackEvent = require 'lib/track-event'

WELCOME_MODAL_ID = '-Kr_uLHsx5qnqvXJBh6J'

module.exports = class InspirationView extends AppView
    __name__: 'InspirationView'
    template: require 'views/templates/inspiration'

    events:
        'click .left-button': 'scrollSlideShowLeft'
        'click .right-button': 'scrollSlideShowRight'
        'click .quickstart-menu': 'clickContinueQuickstart'
        'click .blog-link': 'trackBlogClick'

    initialize: (options = {}) ->
        @context = new Backbone.Model
            examples: articles.inspiration

        if window.analytics
            analyticsTrack = @analyticsTrack.bind(this)
            analytics.ready(analyticsTrack)

    getContext: -> @context.toJSON()

    analyticsTrack: (trackedEvent, example) ->
        if trackedEvent is 'quickstart'
            trackEvent 'Clicked Inspiration Page - Return to Welcome Modal', {article: example}
        else if trackedEvent is 'blog'
            trackEvent 'Clicked Inspiration Page - Read More... Blog', {article: example}

    scrollSlideShowLeft: (e) ->
        examples = @context.get('examples')
        article = e.currentTarget.dataset.article
        imgData = @getImageData(article)
        { img, index, total } = imgData

        if index is 0
            img.setAttribute('data-index', total-1)
            img.src = examples[article].pics[total-1]
        else
            img.setAttribute('data-index', index-1)
            img.src = examples[article].pics[index-1]

    scrollSlideShowRight: (e) ->
        examples = @context.get('examples')
        article = e.currentTarget.dataset.article
        imgData = @getImageData(article)
        { img, index, total } = imgData

        if index is total-1
            img.setAttribute('data-index', 0)
            img.src = examples[article].pics[0]
        else
            img.setAttribute('data-index', index+1)
            img.src = examples[article].pics[index+1]

    getImageData: (name) ->
        query = '[data-imgArticle=' + name + ']'
        img = document.querySelectorAll(query)[0]
        dataset = img.dataset
        imgData =
            img: img
            index: parseInt(dataset.index)
            total: parseInt(dataset.total)

        return imgData

    clickContinueQuickstart: (e) ->
        article = e.currentTarget.dataset.article
        @analyticsTrack('quickstart', article)
        Appcues.show(WELCOME_MODAL_ID)

    trackBlogClick: (e) ->
        article = e.currentTarget.dataset.article
        @analyticsTrack('blog', article)
