{url} = require 'lib/firebase'
auth = require 'models/auth'
BaseRuleModel = require 'models/rule-base'


RuleModel = _.extend {}, BaseRuleModel, {
    initialize: (attributes, options={}) ->
        BaseRuleModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        "/accounts/#{accountId}/rules"

    extraPaths: ->
        return [
            "/__/rules/#{@id}"
        ]
}

module.exports = Backbone.Firebase.Model.extend RuleModel
