{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'

Integration = require 'models/integration'
AccountIntegrations = require 'collections/account-integrations'


module.exports = class IntegrationsCollection extends Backbone.Firebase.Collection
    model: Integration

    url: -> firebaseRef.child 'integrations'

    initialize: ->
        @once 'sync', -> @syncd = true

    createIntegration: (attrs, options={}) ->
        # Generate the id.
        accountId = auth.currentAccount.id
        if attrs.provider and accountId?
            attrs.id = "#{accountId}:#{attrs.provider}"
        else
            throw new Error('A provider and authenticated account ID are required to save an integration.')

        success = options.success or ->
        options.success = (args...) ->
            # Bind success to context.
            success = _.bind success, @

            # Update the duplicate entry for the account.
            # NOTE: This is stored using the provider as the key.
            accountAttrs =
                id: @provider
                integrationId: @id
                isEnabled: true

            integrations = new AccountIntegrations

            # Pass along the original callback and options.
            integrations.create accountAttrs, _.extend {}, options, {success}

        @create attrs, options
        lytics.trackWithUser "Activated integration", {"provider": attrs.provider}

    removeIntegration: (model, options={}) ->
        success = options.success or ->
        options.success = (args...) ->
            success = _.bind success, @

            # Remove duplicate entry.
            integrations = new AccountIntegrations
            remove = ->
                _options = _.extend {}, options, {success}
                # account integrations are keyed by the provider ID.
                id = model.provider or model.id.replace(/^\d+:/, '')
                integrations.remove {id}, _options

            if integrations.syncd
                remove()
            else
                integrations.once 'sync', remove

        provider = model.provider or model.id.replace(/^\d+:/, '')
        @remove model, options
        lytics.trackWithUser "Disabled integration", {provider}
