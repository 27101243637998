BaseView = require 'views/base'


module.exports = class SettingPanelView extends BaseView
    __name__: 'SettingPanelView'
    className: 'panel panel-default setting-panel'
    template: require 'views/templates/build-steps/settings/setting-panel'

    events:
        'click .panel-heading': 'toggle'

    initialize: (options={}) ->
        {@title, @summary, @childView, @open} = options

    getContext: ->
        title: @title
        summary: @summary
        open: @open

    render: ->
        @removeChildViews()
        super

        if @open
            @$el.addClass('open')

        if @childView?
            @renderChild(@childView)
            @$('.panel-body').append(@childView.el)

    setTitle: (title) ->
        @title = title
        @$('.title').html title

    setSummary: (summary) ->
        @summary = summary
        @$('.summary').html summary

    toggle: ->
        @$el.toggleClass('open')
        @open = @$el.hasClass('open')

