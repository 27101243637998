ContentItemView = require 'views/content-list/item'

module.exports = class CoachmarkGroupItemView extends ContentItemView
    __name__: 'CoachmarkGroupItemView'
    className: -> super + ' coachmark-group-item'
    template: require 'views/templates/content-list/coachmark-group'
    labels: {
        model: 'these coachmarks'
        cloneEvent: 'Cloned Coach Marks'
        deleteEvent: 'Deleted Coach Marks'
        archiveEvent: 'Archived Coach Marks'
        unarchiveEvent: 'Unarchived Coach Marks'
    }

    getElementId: -> "coachmark-group-#{@model.id}"

    attributes: ->
        'id': @getElementId()
        'data-coachmark-group-id': @model.id

