ModalView = require 'views/modal'
{propertyDisplay, operatorDisplay, valueDisplay} = require 'lib/rule-helpers'


module.exports = class NewSegmentView extends ModalView
    __name__: 'NewSegmentView'
    className: 'new-segment modal fade'
    template: require 'views/templates/new-segment'

    events:
        'click [data-action=save]': 'save'
        'keyup [name=name]': 'saveOnEnter'

    initialize: (options={}) ->
        super
        {@segmentAttrs, @rule, @segments} = options

    getContext: ->
        attrs: @segmentAttrs
        propertyDisplay: propertyDisplay
        operatorDisplay: operatorDisplay
        valueDisplay: valueDisplay

    render: ->
        super
        # Wait for the modal to show, then focus the name input
        _.delay(=>
            @$('[name=name]').first().focus()
        , 500)

    save: ->
        @segmentAttrs.name = @$('[name=name]').val()
        if s.trim(@segmentAttrs.name)
            @$('.form-group').removeClass('with-error')
            @listenToOnce @segments, 'add', (segment) ->
                @rule.set segmentId: segment.id
                @hide()
                _.delay _.bind(@remove, @), 500
            @segments.add @segmentAttrs
        else
            @$('.form-group').addClass('has-error').find('input').focus()

    saveOnEnter: (evt) ->
        @save() if evt.which is 13


