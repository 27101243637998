FlowModel = require 'models/flow'
SlideoutModel = require 'models/slideout'
HotspotGroupModel = require 'models/hotspot-group'
TooltipGroupModel = require 'models/tooltip-group'
CoachmarkGroupModel = require 'models/coachmark-group'


FlowsCollection = require 'collections/flows'
SlideoutCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
TooltipGroupsCollection = require 'collections/hotspot-groups'
CoachmarkGroupsCollection = require 'collections/coachmark-groups'

# Helpers for looking up by URL parameter content types.

module.exports = {
    ruleContentTypes:
        flows:
            singular: 'flow'
            plural: 'flows'
        slideouts:
            singular: 'flow'
            plural: 'flows'
        hotspots:
            singular: 'hotspot-group'
            plural: 'hotspot-groups'
        tooltips:
            singular: 'hotspot-group'
            plural: 'hotspot-groups'
        coachmarks:
            singular: 'coachmark-group'
            plural: 'coachmark-groups'

    groupNouns:
        flows: 'Modal'
        slideouts: 'Slideouts'
        hotspots: 'Hotspots'
        tooltips: 'Tooltips'
        coachmarks: 'Coach Marks'

    typeIcons:
        flows: '/images/content-list/flow-single.png'
        slideouts: '/images/content-list/slide-out.png'
        hotspots: '/images/content-list/hotspots-single.png'
        tooltips: '/images/content-list/tooltips-single.png'
        coachmarks: '/images/content-list/coachmarks-single.png'

    modelClasses:
        flows: FlowModel
        slideouts: SlideoutModel
        hotspots: HotspotGroupModel
        tooltips: TooltipGroupModel
        coachmarks: CoachmarkGroupModel

    collectionClasses:
        flows: FlowsCollection
        slideouts: SlideoutCollection
        hotspots: HotspotGroupsCollection
        tooltips: TooltipGroupsCollection
        coachmarks: CoachmarkGroupsCollection
}
