AppView = require 'views/app'


module.exports = class IndexView extends AppView
    __name__: 'IndexView'
    template: require 'views/templates/index'
    collection: new (require('collections/flows'))

    initialize: ->
        @listenTo @collection, 'sync', @render

    getContext: ->
        context =
            published: 0
            unpublished: 0
            # unfinished: 0
            total: 0

        @collection.each (flow) ->
            if flow.get 'published'
                context.published += 1
            else
                context.unpublished += 1

            context.total += 1

        return context
