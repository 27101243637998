{firebaseRef} = require 'lib/firebase'
urls = require 'lib/urls'
flattenObject = require 'lib/flatten'
helpers = require 'lib/helpers'

{ACCOUNT_SIGNUP_URL, FIREBASE_URL} = window.__env
TRIAL_LENGTH_DAYS = parseInt(window.__env.TRIAL_LENGTH_DAYS, 10) or 14
FLOWS_SHOWN_LIMIT = parseInt(window.__env.FLOWS_SHOWN_LIMIT, 10) or 100


module.exports = class AccountModel extends Backbone.Firebase.Model
    defaults:
        lastSeenTimestamp: firebase.database.ServerValue.TIMESTAMP
        createdAt: firebase.database.ServerValue.TIMESTAMP

        # Set anonymous to false since true has looser security.
        isTrial: null
        trialLengthDays: TRIAL_LENGTH_DAYS
        isTrialExpired: false
        flowsShownLimit: FLOWS_SHOWN_LIMIT

        gates: null
        npsScore: null

        # Keep track of whether the SDK is installed.
        isInstalled: null

        # TODO: Move these to a shared account model.
        company: ''
        logoUrl: ''

        # Stored account-wide setting. Used in rules.
        domains: ''
        buffer: null

        # Website URL. Mostly for our record keeping.
        siteUrl: ''

        # Account's Stripe information.
        stripeId: ''
        stripePlanId: ''
        stripeSubCreatedAt: null
        stripeSubEndedAt: null
        stripeEmail: null

        testUrl: null
        testUsername: null
        testPassword: null

        # Account UUID. Can be used as API key (no secret).
        uuid: null

    initialize: ->
        @once 'sync', ->
            @syncd = true

            # Set up timing.
            now = moment()
            createdAt = moment @get('createdAt')

            attrs = @toJSON()
            isTrial = attrs.isTrial
            trialLengthDays = attrs.trialLengthDays ? TRIAL_LENGTH_DAYS
            expirationDate = createdAt.add trialLengthDays, 'days'
            daysSinceCreation = createdAt.diff(now, 'days')

            data = {}

            unless isTrial?
                data.isTrial = true
                isTrial = data.isTrial

            if _.isFunction analytics.user
                # Merge account data into user data and track with Segment.
                traits = analytics.user().traits()
                _.each attrs, (val, trait) ->
                    # Prepend account.
                    traits["account_#{trait}"] = val
                traits['account_id'] = @id

                # Remove duplicate traits.
                hasDupes = false
                _.each traits, (val, trait) ->
                    dupe = traits[trait.replace(/^_+/, '')]
                    if /^_+/.test(trait) and dupe?
                        hasDupes = true
                        delete traits[trait]

                traits = flattenObject(traits)

                # Reset user traits if there were dupes found.
                if hasDupes
                    analytics.user().traits {}

                analytics.identify traits

            try
                analytics.group(@id)

            # Identify user with Google Analytics.
            window.ga? 'set', '&uid', @id

            # Track customer in ProfitWell.
            stripeId = @get('stripeId')
            if stripeId and _.isFunction(window.profitwell)
                window.profitwell('user_stripe_id', stripeId)

            @deprecate()

    url: -> firebaseRef.child "accounts/#{@id}/meta"

    accountMetaPath: -> "#{FIREBASE_URL}/accounts/#{@id}/meta.json"

    toJSON: ->
        _.pick @attributes, _.keys(@defaults)

    updateTimestamps: ->
        currentTimestamp = new Date().getTime()
        unless (currentTimestamp - @get('lastSeenTimestamp')) < (30 * 60 * 1000)
            @set("lastSeenTimestamp", currentTimestamp)

    addDomain: (domain) ->
        # Remove protocol if necessary
        if /^https?/.test domain
            match = domain.match(/^https?:\/\/(.*?)(?=\/|\?|$)/, '$1')
            domain = match and match[1]

        domainStr = @get('domains')
        domains = if domainStr then domainStr.split(',') else []

        unless domain in domains
            domains.push(domain)
            @set('domains', domains.join(','))

    validate: ({siteUrl}) ->
        if siteUrl
            # Check protocol.
            if not /^https?:\/\//.test(siteUrl)
                return {siteUrl: 'Site domain must start with http:// or https://.'}

            # Ensure it's only the domain.
            loc = urls.toLocation siteUrl
            if siteUrl isnt loc.origin
                return {siteUrl: 'Site domain should be just protocol and domain. E.g. "http://appcues.com".'}

    isInGeneralBeta: ->
        @get('gates')?['isBeta'] is true

    isInSDKBeta: ->
        @get('gates')?['isSDKBeta'] is true

    isInNpsBeta: ->
        @get('gates')?['isNPSBeta'] is true

    isInChecklistBeta: ->
        @get('gates')?['isChecklistBeta'] is true

    shouldSeeMobileLink: ->
        @get('gates')?['shouldSeeMobileLink'] is true

    hasFeatureGate: (gate) ->
        @get('gates')?[gate] is true

    deprecate: ->
        gates = @get 'gates'

        # Consolidate beta flags.
        unless gates
            gates = {}
            _.each @attributes, (val, key) =>
                if /^is(.*)Beta$/.test key
                    gates[key] = val
                    @set key, null

        @set 'gates', gates

    @createAccount: (userId, email, callback) ->
        if ACCOUNT_SIGNUP_URL
            $.ajax ACCOUNT_SIGNUP_URL, {
                method: "POST"
                contentType: "application/json"
                data: JSON.stringify({user_id: userId, email: email})
                success: -> callback(null)
                error: (xhr, status, error) ->
                    errorMessage = error || "unknown error"
                    console.log("error running signup hook: #{errorMessage}")
                    callback(errorMessage)
            }
        else
            console.log("ACCOUNT_SIGNUP_URL is not set, skipping signup hook.")
