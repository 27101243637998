auth = require 'models/auth'
mixins = require 'models/mixins'
urls = require 'lib/urls'
CONTENT_STATES = require 'lib/content-states'


BaseRuleModel =
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        state: CONTENT_STATES.DRAFT
        published: false

        # Rule state.
        shown: false

        previewUrl: ''
        bookmarkId: null

        # Actual rules.
        contents: null  # content-based targeting
        domains: ''
        events: null    # event-based targeting
        for: 0  # Everyone
        when: 0  # First time.
        where: '/'  # Everything in the URL after window.location.origin
        whereHash: SparkMD5.hash '/'
        isRegex: false
        languages: ''
        properties: null  # Key-value matches.
        frequency: 'once'
        startDate: null
        endDate: null
        nextContent: null # Deprecated
        nextContentId: null
        redirectUrl: null
        redirectNewTab: false

        # In-app widget options.
        widgetFilter: 'page'


    initialize: (attrs={}) ->
        # TODO: Move this to a view.
        # This is kinda a bad pattern because this model will never be removed
        # from memory. It'll also keep listening for remote changes indefinitely.
        @once 'sync', ->
            @syncd = true
            @deprecate()

            # Needs editing.
            unless @get('where') or (@get('editing') and not @get('published'))
                @set 'editing', true
            else if @get('published')
                @set 'editing', false

            watch = @cloneKeys.slice(0)
            events = ("change:#{v}" for v in watch).join ' '
            # Wait until changes from the "sync" event clear.

            @on 'change:where change:isRegex', @updateWhereHash, @
            @on 'change:whereString change:whereOperator', @setWhereFromOperator, @
            @once 'destroy remove', -> @off(null, @updateWhereHash)

    updateWhereHash: (model) ->
        if model.get('isRegex')
            whereHash = 'regex'
        else
            where = model.get('where') ? ''
            whereHash = SparkMD5.hash where

        model.set {whereHash}, {silent: true}

    setWhereFromOperator: (model) ->
        whereOperator = model.get('whereOperator')
        whereString = model.get('whereString')

        if whereOperator and whereString
            where = @applyRegex(whereOperator, whereString)
            model.set {where: where}, {silent: true}
        else
            model

    applyRegex: (operator, string) ->
        string = @escapeCharacters(string)

        if operator is "*"
            return string
        else if operator is "^"
            return '^' + string
        else if operator is "$"
            return string + '$'

    escapeCharacters: (string) ->
        # Borrowed from https://github.com/sindresorhus/escape-string-regexp
        matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g

        string.replace(matchOperatorsRe, '\\$&')

    validate: (attrs) ->
        {where, previewUrl, isRegex, startDate, endDate, whereOperator, whereString} = attrs

         # Preview URL should match the where value.
        loc = urls.toLocation(previewUrl)
        err = "That URL doesn't match the page you are previewing on."

        # Invalidate @where properties that don't look like URL paths.
        if not isRegex and not /^\//.test(where)
            delete @validationWarning
            return {"where": "That URL must start with a forward slash."}

        if isRegex
            @validationWarning = {}
            if whereOperator
                re = new RegExp(@applyRegex(whereOperator, whereString), 'i')
            else
                re = new RegExp(where, 'i')

            if whereOperator then errorKey = "simpleRegex" else errorKey = "regex"

            if re.test(loc.fullpath)
                delete @validationWarning
            else if whereOperator == '^' and whereString.charAt(0) != '/'
                @validationWarning[errorKey] = "Must start with a forward slash"
            else
                # There are so many crazy edge cases for regexes and previews that
                # we can't reliably deny an update. Instead, warn the user.
                @validationWarning[errorKey] = "Warning: #{err}"
            return
        else
            if where isnt loc.fullpath
                @validationWarning = {"where": err}

        # The startDate must be before the endDate.
        if startDate? and endDate? and moment(startDate).isAfter(moment(endDate))
            return {"timeframe": "The start date must be before the end date."}

    deprecate: ->
        attrs = @toJSON()
        # Deprecate whereRegex.
        whereRegex = attrs.whereRegex
        if whereRegex
            # Only migrate if there is no existing where value.
            unless attrs.where
                @set
                    where: whereRegex
                    isRegex: true

            @unset 'whereRegex'

        return

    cloneKeys: [
        'contents'
        'domains'
        'events'
        'for'
        'when'
        'where'
        'whereHash'
        'whereString'
        'whereOperator'
        'isRegex'
        'languages'
        'properties'
        'frequency'
        'startDate'
        'endDate'
        'nextContent'
        'nextContentId'
        'redirectUrl'
        'redirectNewTab'
        'contentType'
        'previewUrl'
        'widgetFilter',
        'migratedAt',
        'conditions'
    ]

    getPreviewUrl: ->
        previewUrl = @get 'previewUrl'

        unless previewUrl
            # If there is no previewUrl, try to build.
            siteUrl = auth.currentAccount.get 'siteUrl'
            if siteUrl and not @get('isRegex')
                previewUrl = siteUrl + @get('where')

        return previewUrl


module.exports = _.extend BaseRuleModel
