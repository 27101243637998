urls = require 'lib/urls'
ENV = window.__env

# This class assumes that the Appcues chrome extension is available.
class PreviewFrame

    PREVIEW_SETUP_PATH = '/preview-setup'

    constructor: (options) ->
        console.log "Creating PreviewFrame."
        @frameSrc = options.frameSrc
        @el = options.el
        @showingMixedContentWarning = false

    initialize: ->
        # First things first, let's make sure the chrome extension is installed.
        if _.isFunction window.chrome?.runtime?.sendMessage
            window.chrome.runtime.sendMessage ENV.CHROME_EXTENSION_ID, {
                action: 'appcues:extension-check'
            }, {}, (response) =>
                if response isnt true
                    # Extension isn't installed! Tell the user!
                    showExtensionInstallMessage()
                else
                    # Continue with initialization.
                    @iframe = document.createElement 'iframe'
                    _.extend @iframe, {sandbox: 'allow-same-origin allow-forms allow-scripts allow-popups', allowTransparency: 'true', src: PREVIEW_SETUP_PATH, width: '100%', className: 'preview-frame build-frame'}

                    # Once the preview frame is ready, set the src to @frameSrc and
                    # trigger the 'load' event. Trigger the 'navigate' event when we
                    # receive the appcues:page-navigated postMessage.
                    @_onPostMessage = (event) =>
                        switch event.data.action
                            when 'appcues:preview-frame-ready'
                                return unless event.origin.match(/https?:\/\/.*my\.appcues\.(com|dev).*/) or event.origin.match(/https?:\/\/appcues-[^.]+\.firebaseapp\.com.*/)

                                if @frameSrc
                                    @iframe.src = @frameSrc
                                    # Trigger the load event once we've loaded the frameSrc, if there was one.
                                    @iframe.onload = =>
                                        @iframe.onload = null
                                        @trigger 'load'
                                else
                                    # Trigger the load event now if there's no frameSrc.
                                    @trigger 'load'

                            when 'appcues:page-navigated'
                                return if stripTrailingSlash(event.data.url) is stripTrailingSlash(urls.toLocation(PREVIEW_SETUP_PATH).href) or event.data.url is 'about:blank'
                                @checkForMixedContent event.data.url
                                @trigger 'navigate', shownUrl: event.data.url
                                # Negate any frame-breaking stuff we can find through the extension's contentscript.
                                @sendMessage { action: 'appcues:protect-frame' }

                    window.addEventListener 'message', @_onPostMessage

                    # Append the iframe as a child element of the target element.
                    @el.appendChild @iframe

                    if @frameSrc
                        @checkForMixedContent @frameSrc

            # A promise to indicate internally when the PreviewFrame is setup/ready.
            @ready = $.Deferred()
            @once 'load', ->
                @ready.resolve()


    inject: (options) ->
        message =
            action: 'appcues:inject'
        message = _.extend message, options
        @sendMessage message

    navigate: (url) ->
        @checkForMixedContent(url).then (mixed) =>
            if mixed
                # If there is a mixed content loading error, we still want to save
                # the previewUrl value, so trigger a navigate event.
                @trigger 'navigate', shownUrl: url
            else
                # Otherwise actually perform the navigation.
                @ready.then =>
                    @iframe.src = url

    sendMessage: (message) ->
        @ready.then =>
            @iframe.contentWindow?.postMessage message, '*'

    reload: ->
        @sendMessage { action: 'appcues:reload' }

    remove: ->
        window.removeEventListener 'message', @_onPostMessage
        if @iframe
            @iframe.src = 'about:blank'
            @iframe.remove()
        els = document.getElementsByClassName 'appcues-preview-frame-message-container'
        _.each els, (el) ->
            document.body.removeChild el

    # This will trigger the mixed-content shield if trying to load http
    # content on an https site. We catch the error and show the warning
    # popup to tell users to allow mixed content. We return a promise that
    # resolves to true if there's a mixed content error.
    checkForMixedContent: (url) ->
        deferred = $.Deferred()
        parsedUrl = urls.toLocation url
        if parsedUrl.protocol is 'http:'
            reqUrl = urls.toLocation('/mixedcontent.js').href
            script = document.createElement('script')
            script.src = reqUrl.replace 'https:', 'http:'
            script.onerror = (errMsg) =>
                showMixedContentWarning.call(@)
                document.head.removeChild script
                deferred.resolve true
            script.onload = ->
                document.head.removeChild script
                deferred.resolve false
            document.head.appendChild script
        else
            if @showingMixedContentWarning
                hideMixedContentWarning.call(@)
            deferred.resolve false
        return deferred

    buildScriptProtocolChecker: ->
        return ->
            # Navigates to #appcues-init-error if any of the scripts will load insecurely
            if document.location.protocol is 'http:'
                patterns = [/^\/\//, /^http:/]
            else
                patterns = [/^http:/]
            for pattern in patterns
                for node in document.querySelectorAll('script[src], link[href]')
                    if pattern.test(node.src || node.href || '')
                        window.location.hash = 'appcues-init-error'
                        return

    ###*
     * Adds in screen recording software that we can leverage.
    ###
    @buildRecorderScript: ->
        try
            userId = analytics.user().id()
            userTraits = analytics.user().traits()
        catch e
            userId = ''
            userTraits = {}

        userTraits = JSON.stringify(userTraits)

        return """
            window['_fs_run_in_iframe'] = true;
            window['_fs_debug'] = true;
            window['_fs_host'] = 'www.fullstory.com';
            window['_fs_org'] = '#{ENV.FULLSTORY_ID}';
            (function(m,n,e,t,l,o,g,y){
              g=m[e]=function(a,b){g.q?g.q.push([a,b]):g._api(a,b);};g.q=[];
              o=n.createElement(t);o.async=1;o.src='https://'+_fs_host+'/s/fs.js';
              y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
              g.identify=function(i,v){g(l,{uid:i});if(v)g(l,v)};g.setUserVars=function(v){FS(l,v)};
              g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;FS(o,v)};
              g.clearUserCookie=function(d,i){d=n.domain;while(1){n.cookie='fs_uid=;domain='+d+
              ';path=/;expires='+new Date(0);i=d.indexOf('.');if(i<0)break;d=d.slice(i+1)}}
            })(window,document,'FS','script','user');
            FS.identify('#{userId}', #{userTraits});
        """

    injectRecorder: ->
        @inject {
            type: 'application/javascript'
            content: @constructor.buildRecorderScript()
        }

    checkScriptProtocols: ->
        @inject
            type: 'application/javascript'
            content: "(#{@buildScriptProtocolChecker().toString()})()"

    showMixedContentWarning = ->
        unless @showingMixedContentWarning
            template = require 'views/templates/preview-frame-content-warning'
            document.body.insertAdjacentHTML 'beforeend', template()
            @showingMixedContentWarning = true

    hideMixedContentWarning = ->
        _.each document.getElementsByClassName('appcues-preview-frame-message-container'), (el) ->
            el.remove()
        @showingMixedContentWarning = false

    showExtensionInstallMessage = ->
        template = require 'views/templates/preview-frame-install-extension'
        document.body.insertAdjacentHTML 'beforeend', template(link: ENV.CHROME_EXTENSION_LINK)

    stripTrailingSlash = (url) ->
        url.replace /\/$/, ''

# Mixin the Backbone.Events support for 'on' and 'off'.
_.extend PreviewFrame::, Backbone.Events
module.exports = PreviewFrame
