auth = require 'models/auth'
{firebase} = require 'lib/firebase'
hubspot = require 'lib/hubspot'


module.exports = class PropertiesCollection extends Backbone.Collection
    model: require 'models/property'

    # Populates collection with data from an object.
    setFromObj: (obj) ->
        @set _.map obj, (property, name) ->
            attrs = {name}

            if _.isObject property
                _.extend attrs, {
                    value: property.value
                    valuesList: property.valuesList
                    type: typeof property.value
                    operator: property.operator
                    isSensitive: property.isSensitive
                }
            else  # Assume it's the old school string syntax.
                _.extend attrs, {
                    value: property
                    type: typeof property
                }

            return attrs

    # This is pretty much the opposite of @setFromObj.
    # This packages the collection for use in a rule.
    toObject: ->
        obj = {}
        @each (model) ->
            name = model.get 'name'
            value = model.get 'value'
            valuesList = model.get 'valuesList'
            operator = model.get 'operator'
            isSensitive = model.get 'isSensitive'

            # Unset the value when evaluating with an existential operator.
            isExistential = operator in ['?', '!?']
            if isExistential
                value = null

            # NOTE: This upgrades the previous string-based syntax to the more verbose
            # object syntax.
            if name and (value or valuesList or isExistential)
                obj[name] = {operator, value, valuesList, isSensitive}

        return obj

    toJSON: (options) ->
        @map (model, index) ->
            data = model.toJSON(options)
            # Create a temporary ID
            data.id = model.cid
            return data
