auth = require 'models/auth'


module.exports = class BookmarkModel extends Backbone.Model
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        name: 'Unnamed bookmark'
        isRegex: false
        where: ''

    initialize: (attrs={}) ->
        @once 'sync', -> @syncd = true

