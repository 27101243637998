ModalView = require 'views/modal'
executeCopy = require('lib/copy')

module.exports = class ShareModalView extends ModalView
    __name__: 'ShareModalView'
    template: require 'views/templates/share-modal'

    events: ->
        'click .copy': 'copy'

    copy: (e) ->
        # duplicate code in code-snippet.coffee
        $el = @$ e.currentTarget
        $link = $el[0].previousSibling
        executeCopy($link)

    getContext: -> @model.toJSON()
