AccountModel = require 'models/account'
UserModel = require 'models/user'
AccountUsersModel = require 'models/accountUsers'
AccountMembershipsCollection = require 'collections/account-memberships'
UserAccountsCollection = require 'collections/user-accounts'

{firebase, firebaseRef, invitedUserFirebase} = require 'lib/firebase'
helpers = require 'lib/helpers'
hubspot = require 'lib/hubspot'

# Global :(.
docCookies = window.docCookies

class AuthModel extends Backbone.Model
    defaults:
        provider: null
        uid: null
        token: null
        displayName: ''

    getAndRemoveQueryParam: (param) ->

        queryStringObject = helpers.queryStrToObj(window.location.search)
        value = queryStringObject[param]

        if value
            delete queryStringObject[param]
            queryString = helpers.objToQueryStr queryStringObject
            helpers.updateQueryString queryString

        value

    initAccount: (accountId) ->
        @currentAccount = new AccountModel {id: accountId}
        @currentAccount.once 'sync', =>
            @trigger 'check'

        @accountUsers = new AccountUsersModel accountId
        @accountUsers.once 'sync', => @trigger 'check'
        #set accountId for the 'only Me' user rule
        localStorage?.setItem('accountId', accountId)
        @trigger 'loggedInAndSynced', @toJSON(), accountId


    initialize: ->
        { USE_AUTH_DOT_APPCUES } = window.__env

        @resetAuthModels()

        @on 'login', (user) ->
            # Identify user in our analytics systems.
            userId = user.uid.replace('simplelogin:', '')
            userEmail = user.email
            @set(id: userId, email: userEmail)
            @trackAnalytics(userId, userEmail)

            @user = new UserModel(id: userId, track: true)
            @user.once 'sync', =>

                @userAccounts = new UserAccountsCollection [], {userId}
                @userAccounts.once 'sync', =>

                    # Support for authenticating as another user.
                    viewAsId = @getAndRemoveQueryParam("view_as")
                    authWithAccountId = viewAsId or localStorage?.getItem('authWithAccountId')

                    # Googe authentication automatically logs you in.
                    # We will bypass that first login from registration.
                    if user.provider is 'google.com' && @userAccounts.length is 0
                        return

                    else if authWithAccountId and @user.get('isAdmin')

                        localStorage?.setItem('authWithAccountId', authWithAccountId)

                        window.spoofing = true
                        @set spoofing: window.spoofing

                        @initAccount localStorage?.getItem('authWithAccountId')

                        if window isnt window.top
                            window.parent.postMessage(JSON.stringify({
                                type: "set_spoofing_in_reactcues",
                                spoofAccountId: authWithAccountId
                            }), "*")

                    else if @userAccounts.length > 0
                        if @user.get('isAdmin') and window isnt window.top
                            window.parent.postMessage(JSON.stringify({
                                type: "set_spoofing_in_reactcues",
                                spoofAccountId: null
                            }), "*")

                        # Pull down the account data once we know what account the user is logging into.
                        # TODO For now just pull the one at index 0.
                        # First, check that the account is enabled on both sides. If so, then initialize.
                        accountId = @userAccounts.at(0).get('accountId')
                        accountMemberships = new AccountMembershipsCollection [], {accountId: accountId}

                        accountMemberships.whenSyncd =>
                            membership = accountMemberships.get(userId)
                            if membership
                                if not @userAccounts.at(0).get('enabled')
                                    # If the account wasn't enabled on the user
                                    # side, run through the old method of accepting
                                    @userAccounts.at(0).set('enabled', true)
                                    @user.set({
                                        isInvitedUser: true
                                        fullname: membership.get("name")
                                        role: membership.get("role")
                                        id: userId
                                        email: userEmail
                                    })
                                else if membership.get('status') is 'pending'
                                    membership.set {
                                        status: 'enabled',
                                        enabledAt: firebase.database.ServerValue.TIMESTAMP
                                    }
                                @initAccount accountId

                            else
                                @trigger 'check', null, @toJSON(), @

        @on 'check', ->
            @authChecked = true

        if USE_AUTH_DOT_APPCUES
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
                .then () =>
                    window.apc_auth.loginUser({
                        reportError: console.log
                        })
                        .then (user) =>
                            if window isnt window.top
                                $app = $("#app")[0]
                                if $app
                                    documentHeight = $app.getBoundingClientRect().height
                                    window.parent.postMessage(JSON.stringify({ type: "set_dimensions", dimensions: { minHeight: documentHeight }}), "*")

                            # user returned by auth client is not firebase user obj
                            firebaseUser = firebase.auth().currentUser
                            @set(firebaseUser: firebaseUser, uid: user.getUserId(), provider: "Customer API", customToken: user.getToken())
                            @trigger 'login', firebaseUser, @
                .catch (err) =>
                    @trigger 'check', null, @
                    console.error err




        else
            firebase.auth().onAuthStateChanged (user) =>
                if user
                    user.provider = user.providerData[0].providerId

                    currentLocation = window.location.pathname

                    if user.provider is 'google.com' and currentLocation is '/login' or currentLocation is '/register'
                        userId = user.uid
                        @userAccounts = new UserAccountsCollection [], {userId}

                        # Double check if a g-auth user has the right account data.
                        @userAccounts.once 'sync', =>

                            if @userAccounts.length is 0 and currentLocation is '/login'
                                googleData = {}
                                googleData.email = user.email
                                googleData.provider = user.provider

                                # Add this query parameter to keep user from being
                                # autologged to the dashboard.
                                googleData.google = 'register'

                                queryStringGoogleData = helpers.objToQueryStr(googleData)

                                # Create the account behind the scenes.
                                AccountModel.createAccount user.uid, user.email, (err) ->
                                    if err
                                        console.log(err)
                                        e = new Error("Lazy account creation failed")
                                        Raven.captureException e, {
                                            extra: {
                                                userId: userId,
                                                error: err
                                            }
                                        }
                                    else
                                        # Redirect to registration page to finish account.
                                        window.location.href = '/register?' + queryStringGoogleData

                            else if @userAccounts.length > 0 and currentLocation is '/register'
                                @set(firebaseUser: user, uid: userId, provider: user.provider)
                                @trigger 'check', null, @toJSON(), @

                            else
                                # Allow for G-Auth regular login flow
                                @set(firebaseUser: user, uid: userId, provider: user.provider)
                                @trigger 'login', user, @

                    else
                        # Login flow for traditional signup
                        @set(firebaseUser: user, uid: userId, provider: user.provider)
                        @trigger 'login', user, @

                else
                    @logout()
                    @trigger 'check', null, user, @

    login: (provider, options) ->
        { USE_AUTH_DOT_APPCUES } = window.__env

        # login in isn't really a concept in the post-auth-upgrade world --
        # either we fail to initialize & thus get redirected, or we auth

        unless USE_AUTH_DOT_APPCUES
            provider ?= 'anonymous'

            if provider is 'password'
                firebase.auth().signInWithEmailAndPassword(options.email, options.password).catch (error) =>
                    if error
                        @trigger 'error', error, @
                        @trigger 'check', error, null, @
            if provider is 'google.com'
                googleProvider = new firebase.auth.GoogleAuthProvider()
                googleProvider.addScope('email')
                firebase.auth().signInWithPopup(googleProvider).then((result) ->
                    return
                ).catch (error) =>
                    @trigger 'error', error, @
                    @trigger 'check', error, null, @
                    return

            return

    logout: ->
        { USE_AUTH_DOT_APPCUES } = window.__env

        @stopSpoofing()
        # Also clear the stored Appcues user ID
        window.Appcues?.reset?()
        localStorage?.clear()

        if USE_AUTH_DOT_APPCUES
            window.apc_auth.logoutUser({
                reportError: console.error
            }).then(() =>
                @resetAuthModels()
                @trigger 'logout'
            )
        else
            firebase.auth().signOut().then(() =>
                @resetAuthModels()
                @trigger 'logout'
            )

    resetAuthModels: ->
        # Keep a linked model that will store user-specific
        # stuff, but not related to Firebase's auth.
        @user = new Backbone.Model
        # Keep model and collection tracking the accounts available to
        # the authenticated user and which account the user is acting within.
        @currentAccount = new Backbone.Model
        @userAccounts = null
        @accountUsers = new Backbone.Model

    sendResetEmail: (email, callback) ->
        { USE_AUTH_DOT_APPCUES } = window.__env
        callback ?= ->

        if USE_AUTH_DOT_APPCUES
            window.apc_auth.resetPassword(email)
                .then(callback)
                .catch(console.log)
        else
            firebase.auth().sendPasswordResetEmail(email).then(callback)

        return

    onAuth: (callback) ->
        callback ?= ->
        if @authChecked
            callback(@toJSON())
        else
            @once 'check', callback

    stopSpoofing: ->
        delete window.spoofing
        @set 'spoofing', null
        localStorage?.removeItem 'authWithAccountId'
        # Remove cookie just in case it's there.
        if docCookies?.hasItem 'apc_auth_with_accountid'
            docCookies.removeItem 'apc_auth_with_accountid', '/'

    trackAnalytics: (userId, userEmail) ->
        try
            hubspot.identify {
                email: userEmail
                appcues_id: userId
            }

    inviteUser: (inviter, name, email, role, job, onSuccess, onFail, onEmailSendFail, onEmailTakenFail) ->
        if /@qq\.com/i.test(email)
            onFail()
            return

        # Create a random-ish password and an invite link.
        password = SparkMD5.hash("#{email}#{new Date().getTime()}").substring(0, 12)

        createInviteLink = @createInviteLink
        sendEmail = @sendEmail

        # Create the user in Firebase and add their /user data.
        @createUser(email, password, {name, job})
            .done (user) =>
                invitedUserFirebase.auth().signOut()
                # Add the account links for this user.
                $.when(@populateUser(user), @populateMembership(user, role))
                    .then ->
                        # Create invite link with tracking params.
                        params = {
                            email: email
                            ajs_event: 'Started Invite Confirmation'
                            ajs_uid: user.id
                            ajs_prop_email: email
                        }
                        link = createInviteLink(params)

                        # Send the invite email.
                        sendEmail(inviter, user, password, link)
                    .fail ->
                        onEmailSendFail()
                    .done (res) ->
                        if res?[0]?.status in ['rejected', 'invalid']
                            onEmailSendFail()
                        else
                            onSuccess()
            .fail (err) ->
                switch err.code
                    when 'auth/email-already-in-use'
                        onEmailTakenFail()
                    else
                        onFail()


    sendEmail: (inviter, user, password, link) ->
        {ACCOUNT_INVITE_URL} = window.__env

        if ACCOUNT_INVITE_URL
            data = {
                id: user.id
                name: user.name
                email: user.email
                inviter: inviter
                temp_password: password
                invite_link: link
            }

            $.ajax ACCOUNT_INVITE_URL, {
                method: "POST"
                contentType: "application/json"
                data: JSON.stringify(data)
                success: -> console.log "#{user.email} invited to account."
                error: (xhr, status, error) ->
                    console.log("error running account invite hook: #{error}")
                    console.log status
                    console.log xhr
            }


    createInviteLink: (params) ->
        link = "#{window.__env.SITE_ORIGIN}/login"
        queryStr = $.param(params)
        return "#{link}?#{queryStr}"

    populateUser: (user) ->
        deferred = $.Deferred()
        data = {
            "users/#{user.id}/meta": {
                createdAt: firebase.database.ServerValue.TIMESTAMP
                fullname: user.name
                job: user.job
                email: user.email
                isInvitedUser: true
            }
            "users/#{user.id}/accounts/#{@currentAccount.id}": true
        }
        firebaseRef.update data, (err) ->
            if err
                deferred.reject err
            else
                deferred.resolve()

        return deferred

    populateMembership: (user, role) ->
        deferred = $.Deferred()
        data =
            createdAt: firebase.database.ServerValue.TIMESTAMP
            activatedAt: null
            role: role
            status: 'pending'
        firebaseRef.child("accounts/#{@currentAccount.id}/users/#{user.id}").set data, (err) ->
            if err
                deferred.reject err
            else
                deferred.resolve()

        return deferred

    createUser: (email, password, extra={}) ->
        deferred = $.Deferred()
        invitedUserFirebase.auth().createUserWithEmailAndPassword(email, password).then((credential) ->
            id = credential.user.uid.replace 'simplelogin:', ''
            deferred.resolve _.extend {id, email}, extra, credential.user
        ).catch((error) ->
            deferred.reject error
        )
        return deferred

    transferUser: (email, role, onSuccess, onFail, onAlreadyOnTeamFail) ->
        sls = require 'lib/sls-helpers'
        sls.inviteExistingUserToTeam(email, role)
            .done (response) ->
                onSuccess()
            .fail (error) ->
                switch error.code
                    when 'USER_IS_ALREADY_ON_TEAM'
                        onAlreadyOnTeamFail()
                    else
                        onFail()

module.exports = new AuthModel
