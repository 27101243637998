{APPCUES_SLS_URL, APPCUES_SLS_API_KEY, APPCUES_SLS_API_SECRET} = window.__env
auth = require 'models/auth'
RuleModel = require 'models/rule-firebase'

_postContent = (action, content, fromAccountId) ->
    deferred = $.Deferred()

    rule = new RuleModel(id: content.id, { accountId: fromAccountId })

    $.when(auth.currentAccount.whenSyncd(), content.whenSyncd(), rule.whenSyncd())
        .then (account, content, rule) ->
            data = {
                "account_id": account.id
                "account_api_key": account.get("uuid")
                "content_id": content.id
                "content_type": rule.get("contentType")
                "user_id": auth.id
            }

            if fromAccountId
                data["from_account_id"] = fromAccountId

            if action == "revert"
                data["to_version_id"] = content.get("updatedAt")

            $.ajax({
                url: "#{APPCUES_SLS_URL}/contents/#{action}"
                method: "POST"
                contentType: "application/json"
                headers: {
                    'Authorization': "Basic " +
                        btoa("#{APPCUES_SLS_API_KEY}:#{APPCUES_SLS_API_SECRET}")
                }
                data: JSON.stringify(data)
                error: (jqxhr, status, error) ->
                    console.log("Error hitting Appcues SLS #{action} function:", error)
                    if window.Raven
                        Raven.captureException("SLS Error", { extra: data })
                    deferred.reject(status)
                success: (data, status) ->
                    deferred.resolve(data)
            })

    deferred

_post = (path, data) ->
    $.when(auth.currentAccount.whenSyncd()).then (account) ->
        _makePost(path, data, account)

_makePost = (path, data, account, additionalAuthData) ->
    deferred = $.Deferred()
    authData = {
        "account_id": account.id
        "account_api_key": account.get("uuid")
    }

    postData = _.extend({}, data, authData, additionalAuthData)

    $.ajax({
        url: "#{APPCUES_SLS_URL}/#{path}"
        method: "POST"
        contentType: "application/json"
        headers: {
            'Authorization': "Basic " + btoa("#{APPCUES_SLS_API_KEY}:#{APPCUES_SLS_API_SECRET}")
        }
        data: JSON.stringify(postData)
        error: (jqxhr, status, error) ->
            Raven.captureException("SLS Error", { extra: data })
            deferred.reject({
                code: jqxhr.responseJSON && jqxhr.responseJSON.code || error
                message: jqxhr.responseJSON && jqxhr.responseJSON.message || '',
                status
            })
        success: (data, status) ->
            deferred.resolve(data)
    })

    deferred

module.exports =
    publish: (content) -> _postContent("publish", content)
    unpublish: (content) -> _postContent("unpublish", content)
    clone: (content, fromAccountId) -> _postContent("clone", content, fromAccountId)
    archive: (content) -> _postContent("archive", content)
    unarchive: (content) -> _postContent("unarchive", content)
    delete: (content) -> _postContent("delete", content)
    revert: (content) -> _postContent("revert", content)
    inviteExistingUserToTeam: (email, role) -> _post("accounts/invite-existing-user", { email, role })
