{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'
hubspot = require 'lib/hubspot'
JourneyModel = require 'models/journey-firebase'
helpers = require 'lib/helpers'
sls = require 'lib/sls-helpers'

module.exports = class JourneysCollection extends Backbone.Firebase.Collection
    model: require 'models/journey'

    initialize: (models, options) ->
        @accountId = options?.accountId ? auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{@accountId}/journeys"

        @once 'sync', ->
            @syncd = true


    cloneModel: (flowId, options={}) ->
        {fromAccountId, flowName, noCopyOf} = options
        if fromAccountId?
            # fromAccountId is used to clone template flows from the `sample` account on `/start`
            flow = new JourneyModel {id: flowId}, {accountId: fromAccountId}
        else
            flow = @get(flowId)
        return unless flowId and flow

        sls.clone(flow)
