BaseView = require 'views/base'
{confirm} = require 'views/alerts'


module.exports = class CoachmarksSummaryView extends BaseView
    __name__: 'CoachmarksSummaryView'
    className: 'coachmarks-summary'
    template: require 'views/templates/build-steps/design/coachmark-group/coachmarks-summary'

    events:
        'click [data-action=selectItem]': 'selectItem'
        'click [data-action=deleteItem]': 'deleteItem'

    initialize: (options={}) ->
        {@isEditable, @useEager} = options
        @isEditable ?= true
        @listenTo @collection, 'add remove sort select change:expanded', @render

    getContext: ->
        isEditable: @isEditable
        model: @model.toJSON()
        collection: @collection.toJSON()
        useEager: @useEager

    selectItem: (evt) ->
        selectedId = @$(evt.currentTarget).attr('data-id')
        @collection.get(selectedId)?.set('expanded', true) if selectedId?

    deleteItem: (evt) ->
        item = @collection.get(@$(evt.currentTarget).closest('li.thumbnail-item').attr('data-id'))
        return unless item?
        confirm('Are you sure you want to delete this coachmark?').then (res) =>
            @collection.remove(item) if res
        return false

