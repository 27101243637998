AppView = require 'views/app'
Cocoon = require 'lib/cocoon'
StyleModel = require 'models/style'
StylesCollection = require 'collections/styles'
FlowsCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
ManageFontsModalView = require 'views/manage-fonts-modal'
auth = require 'models/auth'
{confirm} = require 'views/alerts'
ModalContentList = require 'views/modal-content-list'
bundler = require 'lib/bundler'
publisher = require 'lib/publish'
helpers = require 'lib/helpers'

module.exports = class StylingView extends AppView
    __name__: 'StylingView'
    template: require 'views/templates/styling'

    events:
        'click .save-btn': 'save'
        'click a[role="tab"]': 'clickNavTab'
        'change form.styling input': 'setBasic'
        'change form.styling select': 'setBasic'
        'blur form.styling input': 'setBasic'
        'click #refresh': 'renderPreviewContent'
        'click .delete-btn': 'deleteStyle'
        'click .create-btn': 'createStyle'
        'click .associated-content-btn': 'openAssociatedContentModal'
        'click a.expand': 'toggleStyleEditor'
        'click .default-theme-btn': 'setCurrentThemeAsDefault'
        'click .add-google-font-btn': 'openManageFontsModal'
        'click .update-theme-version-btn': 'updateThemeVersion'
        "change [name='defaultFont']": 'setDefaultFontUrl'
        "change [name='titleFont']": 'setTitleFontUrl'

    fontStacks = [
        { name: 'Helvetica Neue', value: "'Helvetica Neue', Helvetica, Arial, sans-serif" }
        { name: 'Arial', value: "Arial, 'Helvetica Neue', Helvetica, sans-serif" }
        { name: 'Futura', value: "Futura, 'Trebuchet MS', Arial, sans-serif" }
        { name: 'Tahoma', value: "Tahoma, Verdana, Segoe, sans-serif" }
        { name: 'Verdana', value: "Verdana,Geneva,sans-serif" }
        { name: 'Georgia', value: "Georgia, Times, 'Times New Roman', serif" }
        { name: 'Lucida Bright', value: "'Lucida Bright', Georgia, serif" }
        { name: 'Palatino', value: "Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif" }
        { name: 'Open Sans', value: "'Open Sans', sans-serif", importUrl: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i'}
        { name: 'Libre Baskerville', value: "'Libre Baskerville', serif", importUrl: 'https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700'}
        { name: 'Josefin Slab', value: "'Josefin Slab', serif", importUrl: 'https://fonts.googleapis.com/css?family=Josefin+Slab:300,300i,400,400i,700,700i'}
        { name: 'Lato', value: "'Lato', sans-serif", importUrl: 'https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i'}
        { name: 'Droid Sans', value: "'Droid Sans', sans-serif", importUrl: 'https://fonts.googleapis.com/css?family=Droid+Sans:400,700'}
        { name: 'Arvo', value: "'Arvo', serif", importUrl: 'https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i'}
    ]

    hotspotAnimations = [
        { name: 'None', value: 'hotspot-animation-none' }
        { name: 'Pulse', value: 'hotspot-animation-pulse' }
    ]

    beaconIcons = [
        { name: 'Hotspot', value: 'hotspot' }
        { name: 'Question mark', value: 'question' }
        { name: 'None', value: 'hidden' }
    ]

    initialize: (options={}) ->
        accountId = options.accountId or auth.currentAccount.id

        # For now, create an empty model and wait for collection to sync.
        # TODO: Make `render` tolerant of null model.
        @model = new StyleModel({name: "placeholder", accountId: accountId})

        # This collection and its models will not sync automotically to Firebase, unlike just
        # about every other piece of our app. This is because changes to these models will
        # effect the styling of LIVE content.
        NonAutoSyncingStylingCollection = StylesCollection.extend {autoSync: false}
        @collection = new NonAutoSyncingStylingCollection
        @collection.whenSyncd =>
            @model = @collection.getDefault()
            @model.set('accountId', accountId)
            if @model.get('customFontUrl')
                fontStacks = fontStacks.concat(@getFontFamilies(@model.get('customFontUrl')))
            @render()

        @collection.fetch()

        @flows = new FlowsCollection
        @hotspotGroupsCollection = new HotspotGroupsCollection
        @hotspotGroupsCollection.whenSyncd =>
            @flows.whenSyncd =>
                @render()
        @modalContentList = new ModalContentList({title: "Flows Using This Theme"})

        # Cocoons for wrapping flow and hotspot previews.
        @flowCocoon = new Cocoon
            css: {
                border: 'none'
            }
            src: '/styling-embed'
            # height: '1250px'
        @hotspotsCocoon = new Cocoon
            css: {
                border: 'none'
            }
            src: '/styling-embed'
            width: '315px'
            height: '415px'
        @slideoutCocoons = new Cocoon
            css: {
                border: 'none'
                'padding-left': '30px'
            }
            src: '/styling-embed'
            height: '500px'

        @wasEdited = false
        @colorTimer = null
        @previousVersion = null

        @renderPreviewContent()

    getContext: ->
        @associatedContent = @_getAssociatedContent(@flows).concat(@_getAssociatedContent(@hotspotGroupsCollection))

        _.extend @model.toJSON(), {
            styles: @collection.toJSON()
            associatedContentCount: @associatedContent.length
            fontStacks: fontStacks
            hasPublishedFlows: @flows.hasPublishedFlows()
            hotspotAnimations: hotspotAnimations
            beaconIcons: beaconIcons
            isLoading: not @collection.syncd && not @flows.syncd && not @hotspotGroupsCollection.syncd
            cannotPublish: auth.user.get('cannotPublish')
            styleId: @model.get("id")
            isStyleObjectVersionOutdated: @model.get("version") != appcues_theme_utilities.getLatestStyleObjectVersion()
            previousVersion: @previousVersion
        }

    getStylingData: =>
        globalStyling: @model.get('globalCss')
        globalHotspotStyling: @model.get('globalHotspotCss')
        globalBeaconColor: @model.get('beaconColor')
        globalBeaconStyle: @model.get('beaconStyle')
        globalHotspotAnimation: @model.get('hotspotAnimation')
        typekitId: @model.get('typekitId')

    render: ->
        super

        accountId = @model.attributes.accountId
        stylingChanges = [
            'globalCss'
            'globalHotspotCss'
            'beaconColor',
            'beaconStyle',
            'hotspotAnimation'
        ].map((prop) -> "change:#{prop}").join(' ')
        @listenTo @model, stylingChanges, @updateStyling
        @listenTo @model, 'change', -> @updateSave(false)
        @listenTo @model, 'sync', ->
            # Update state of save button.
            @updateSave(true)
            # Rebundle when styling changes.
            bundler.sync accountId

        @$('select.style-selector').selectize
            onChange: _.bind((value) ->
                @confirmChangeSelectedStyle(value)
            , @)

        @$('.input-colorpicker-group').colorpicker()
            .on('hidePicker.colorpicker', (evt) =>
                $input = @$(evt.target).find('input')
                @model.set $input.attr('name'), $input.val()
            )
            .on('changeColor.colorpicker', (evt) =>
                $addon = @$(evt.target).find('.input-group-addon')
                if evt.value is ""
                    $addon.css "background-color": ''
                    $addon.find('i.fa-eyedropper').css "background-color": ''
                else
                    $addon.css "background-color": evt.color.toHex()

                if @colorTimer
                    clearTimeout @colorTimer
                @colorTimer = window.setTimeout(() =>
                    @$(evt.target).colorpicker('hide')
                , 1700)
            )
        @$('input[type="range"]').rangeslider(
            polyfill: false
            rangeClass: 'rangeslider'
            fillClass: 'rangeslider__fill'
            handleClass: 'rangeslider__handle'
        )

        @renderEditor()
        @renderPreviewFrames()

        unless @firstRenderReported
            window.parent.postMessage(JSON.stringify({
                type: "render.theme.editor"
            }), "*")
            @firstRenderReported = true

        if window isnt window.top
            $app = $("#app")[0]
            if $app
                documentHeight = $app.getBoundingClientRect().height
                window.parent.postMessage(JSON.stringify({ type: "set_dimensions", dimensions: { minHeight: documentHeight }}), "*")

        @

    remove: ->
        @editor?.destroy()
        super

    confirmChangeSelectedStyle: (id) ->
        if @wasEdited
            confirm('Discard changes?', {
                text: "You've made some edits to #{@model.get 'name'}. Switching to a different theme will discard them."
                confirmButtonText: "Yup, proceed"
                cancelButtonText: "Oops! Wait I'm not finished"
            }).then((res) =>
                if res
                    @wasEdited = false
                    @model.fetch() # Clear changes to the model by pulling the old state from Firebase
                    @_changeSelectedStyle(id)
                else
                    @_changeSelectedStyle()
                    @updateSave(false) # Re-enable Save & Publish button
            )
        else
            @_changeSelectedStyle(id)

    _changeSelectedStyle: (id) ->
        @model = @collection.get(id) if id
        @rerender()

    setCurrentThemeAsDefault: () ->
        confirm("Set #{@model.get('name')} as default theme?", {
            text: "This will not affect any of your existing flows."
            confirmButtonText: "Set as Default"
            cancelButtonText: "Cancel"
        })
        .then((res) =>
            if res
                @updateSave(true)
                @collection.changeDefault(@model)
                @initialize()
        )

    createStyle: (e) ->
        $formGroup =  $(".form-group.create-form-group")
        $input = $("input[name=newStyleName]")
        if $input.val()
            @updateSave(true)
            attrs = _.extend @model.toJSON(), {"name": $input.val(), id: null, isDefault: null}
            newStyle = @collection.create(attrs)
            @model.fetch() # Clear changes to cloned-from model
            @_changeSelectedStyle(newStyle)
            lytics.trackWithUser "Created a Style", { styleId: newStyle.get("id") }
        else
            $formGroup.addClass("has-error")

    deleteStyle: ->
        unless @model.get("isDefault")
            confirm("Delete #{@model.get('name')}?", {
                text: "All of the flows using this theme will begin using your default theme instead."
                confirmButtonText: "Delete theme"
                cancelButtonText: "Not now"
            }).then((res) =>
                if res
                    styleToDelete = @collection.remove(@model)
                    if styleToDelete
                        lytics.trackWithUser "Deleted a Style", { styleId: styleToDelete.get("id") }
                        styleToDelete.destroy()
                    @_combForDeletedStyles()
                    @_changeSelectedStyle(@collection.getDefault())
            ).then(publisher.API_PUBLISH_HOOK)

    _combForDeletedStyles: ->
        validStyleIds = []
        for style in @collection.models
            validStyleIds.push style.get("id")

        for flow in @flows.models
            unless validStyleIds.includes flow.get("style")
                flow.set "style", null

        for hotspotGroup in @hotspotGroupsCollection.models
            unless validStyleIds.includes hotspotGroup.get("style")
                hotspotGroup.set "style", null


    renderEditor: ->
        if @$('#css-editor').length
            @editor = ace.edit 'css-editor'
            @editor.setTheme 'ace/theme/monokai'
            @editor.setFontSize '14px'
            @editor.getSession().setMode 'ace/mode/css'
            @editor.getSession().setUseWrapMode true
            @editor.getSession().on 'changeAnnotation', (event, session) =>
                # Only update the custom CSS with the contents of the editor
                # if there are no errors.
                errors = _.where session.getAnnotations(), { type: 'error' }
                if _.size(errors) is 0
                    @setAdvanced()

    renderPreviewFrames: ->
        @$('.styling-preview-frame-flow').append @flowCocoon.$frame
        @$('.styling-preview-frame-hotspots').append @hotspotsCocoon.$frame
        @$('.styling-preview-frame-slideout').append @slideoutCocoons.$frame

    renderPreviewContent: ->
        @flowCocoon.ready =>
            @flowCocoon.trigger 'appcues:render-flow', @getStylingData()
        @hotspotsCocoon.ready =>
            @hotspotsCocoon.trigger 'appcues:render-hotspots', @getStylingData()
        @slideoutCocoons.ready =>
            @slideoutCocoons.trigger 'appcues:render-slideout', @getStylingData()

    rerender: ->
        if @model.get('customFontUrl')
            fontStacks = fontStacks.concat(@getFontFamilies(@model.get('customFontUrl')))
        else
            fontStacks = fontStacks.filter((font) -> font.source != 'Google Fonts')
        @flowCocoon.rerendering()
        @hotspotsCocoon.rerendering()
        @slideoutCocoons.rerendering()
        @render()
        @renderPreviewContent()

    setBasic: (e) ->
        $input = @$(e.target)
        if $input.attr('type') is 'checkbox'
            val = $input[0].checked
        else
            val = $input.val()
        @model.set $input.attr('name'), val

    setAdvanced: ->
        @model.set 'customCss', @editor.getSession().getValue()

    save: ->
        # We don't save to Firebase until the user clicks save. We do update
        # model automatically as the user makes changes, so that we update
        # the previews immediately, but these aren't persisted until save.
        # This gives the user a chance to retain their old styling options.
        # Also, let's allow the user to confirm what's about to happen.

        saveChanges = (confirmed) =>
            deferred = $.Deferred()
            if confirmed
                @updateSave(true)
                @removeUnecessaryFonts()
                @previousVersion = null
                @model.save()
                lytics.trackWithUser "Published custom styling", { styleId: @model.get("id") }

                deferred.resolve(auth.currentAccount.id)
            else
                deferred.resolve()
            return deferred

        if @flows.hasPublishedFlows()
            confirm("Push #{@model.get('name')} live?", {
                text: "Just to be clear: styling changes will be applied to all published flows that have the theme applied."
                confirmButtonText: "Publish theme"
                cancelButtonText: "Not yet"
            }).then(saveChanges).then(publisher.hitApiPublishHook)
        else
            saveChanges(true).then(publisher.hitApiPublishHook)


    clickNavTab: (evt) ->
        targetNode = @$(evt.target)
        if targetNode.hasClass('click-through-link')
            # update navbar to reflect current tab when click-through-links are clicked
            @$('li.active').removeClass('active')
            @$("li a[href='#{targetNode[0].hash}']").parent().addClass('active')
        else if @$('.style-editor-column').hasClass('col-md-12')
            # collapse CSS editor if it is expanded
            @toggleStyleEditor(null, true)


    toggleStyleEditor: (evt, forceClose = false) ->
        shouldCollapse = @$('.style-editor-column').hasClass('col-md-12')

        if shouldCollapse || forceClose
            @$('a.expand').text('Expand')

            @$('.flow-preview-column').removeClass('col-md-12').addClass('col-md-7')
            @$('.style-editor-column').removeClass('col-md-12').addClass('col-md-5')
        else
            @$('a.expand').text('Collapse')

            @$('.flow-preview-column').removeClass('col-md-7').addClass('col-md-12')
            @$('.style-editor-column').removeClass('col-md-5').addClass('col-md-12')


    updateStyling: ->
        @flowCocoon.ready =>
            @flowCocoon.trigger 'appcues:update-styling', @getStylingData()
        @hotspotsCocoon.ready =>
            @hotspotsCocoon.trigger 'appcues:update-styling', @getStylingData()
        @slideoutCocoons.ready =>
            @slideoutCocoons.trigger 'appcues:update-styling', @getStylingData()

    updateSave: (syncd) ->
        if syncd
            @$('.unsaved-changes').addClass 'hidden'
            @$('.save-btn').addClass 'disabled'
            @$('.default-theme-btn').removeClass 'disabled'
            @$('.previewing-upgraded-styling-container')?.addClass 'hidden'
            @wasEdited = false
        else
            @$('.unsaved-changes').removeClass 'hidden'
            @$('.save-btn').removeClass 'disabled'
            @$('.default-theme-btn').addClass 'disabled'
            @wasEdited = true

    _getAssociatedContent: (collection) ->
        c = []
        for flow in collection?.models
            if flow.get("style") == @model.get("id")
                c.push flow.toJSON()
            unless flow.get("style")
                if @model.get("isDefault")
                    c.push flow.toJSON()
        c

    openAssociatedContentModal: ->
        @modalContentList.setContent @associatedContent
        @modalContentList.show()


    setDefaultFontUrl: (evt) ->
        selectedFont = _.find(fontStacks, (font) -> font.value == evt.target.value)

        if selectedFont.importUrl
            @model.set('defaultFontUrl', selectedFont.importUrl)
        else
            @model.set('defaultFontUrl', null)

    setTitleFontUrl: (evt) ->
        selectedFont = _.find(fontStacks, (font) -> font.value == evt.target.value)

        if selectedFont.importUrl
            @model.set('titleFontUrl', selectedFont.importUrl)
        else
            @model.set('titleFontUrl', null)


    removeUnecessaryFonts: ->
        # safety check to remove unused import statements
        defaultFont = _.find(fontStacks, (font) => font.value == @model.get('defaultFont')) || {}
        titleFont = _.find(fontStacks, (font) => font.value == @model.get('titleFont')) || {}

        if !defaultFont.customFontUrl && !titleFont.customFontUrl
            @model.set 'customFontUrl', null

        if !defaultFont.importUrl
            @model.set 'defaultFontUrl', null

        if !titleFont.importUrl
            @model.set 'titleFontUrl', null

    openManageFontsModal: () ->
        v = new ManageFontsModalView
            show: true
            fonts: fontStacks.filter((font) -> font.source == 'Google Fonts')
            customFontUrl: @model.get('customFontUrl')
            typekitId: @model.get('typekitId')
            getFontFamilies: @getFontFamilies
            saveToStylingPage: =>
                @rerender()
                @updateSave(false)
            addTypekitId:(typekitId) =>
                @model.set('typekitId', typekitId)
            addGoogleFont: (fontUrl, formattedFonts) =>
                if fontUrl != @model.get('customFontUrl')
                    fontStacks = fontStacks.filter((font) -> font.source != 'Google Fonts').concat(formattedFonts)
                    @model.set('customFontUrl', fontUrl)
                    @updateSave(false)

        @renderChild(v)

    getFontFamilies: (url, fallbackStyle="sans-serif") ->
        #  Performs a regex to create a proper font object for each font included in a google url
        #  So the output of 'https://fonts.googleapis.com/css?family=Roboto|Spectral:500' would be
        #  an array of size two, one for Roboto and one for Spectral

        #Regex (and subsequent string manipulation) gets a proper font name from a url,
        # so 'https://fonts.googleapis.com/css?family=Roboto|Spectral:500' returns ['Roboto', 'Spectral']
        regex = new RegExp("[?&]family(=([^&#]*)|&|#|$)")
        results = regex.exec url

        return null if not results
        return '' if not results[2]

        decodeURIComponent(results[2].replace(/\+/g, " "))
        .split('|')
        .map((fontName) ->
            customFontUrl: url
            source: 'Google Fonts'
            name: fontName.split(':')[0]
            fallback: fallbackStyle
            value: "'#{fontName.split(':')[0]}', #{fallbackStyle}"
        )

    updateThemeVersion: () ->
        @previousVersion = @model.get('version')
        @model.set 'version', appcues_theme_utilities.getLatestStyleObjectVersion()
        @rerender()
        @updateSave(false)
