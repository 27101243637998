mergeKeep = require 'lib/merge-keep'
flattenObject = require 'lib/flatten'

# Initialize Router.
main = require 'routers/main'
auth = require 'models/auth'
{spoofAsAccountId, getJwtToken} = require 'lib/helpers'
{APPCUES_REACT_URL, HEALTH_REPORT_URL, APPCUES_CHECKLIST_URL, TRACKING_ENABLED, CDN_DOMAIN, APPCUES_ID, INTERNAL_TACO_EMBED_URL} = window.__env

start = ->
    # Initialize Backbone History
    Backbone.history.start {
        pushState: true
        hashChange: false
    }

    keyMap =
        16: 'shift'
        17: 'control'
        18: 'option'
        91: 'command'

    # Monitor modifier key presses.
    modifierKeysDown = {}
    $(document).on 'keyup keydown', (e) ->
        modifier = keyMap[e.which]
        if modifier?
            if e.type is 'keydown'
                modifierKeysDown[modifier] = true
            else
                delete modifierKeysDown[modifier]

        return

    # Route catcher.
    if Backbone.history?._hasPushState
        $(document).on 'click', 'a[href]', (evt) ->
            # Let the browser handle the link if modifiers are pressed.
            return if _.size(modifierKeysDown)

            href = $(@).attr 'href'
            target = $(@).attr 'target'
            protocol = @protocol + '//'

            return if target

            if href.indexOf('#') isnt 0 and /^https?:/.test(protocol) and href.slice(0, protocol.length) isnt protocol
                evt.preventDefault()
                main.navigate href, {trigger: true}

loadAsync = (src, attrs, callback) ->
    d = document
    t = 'script'
    s = d.createElement(t)
    s.type = 'text/javascript'
    s.src = src
    s.async = true

    for k, v of attrs
        s.setAttribute k, v

    if callback
        fn = (e) -> callback e
        s.addEventListener 'load', fn, false

    d.body.appendChild s

loadAsyncCSS = (href) ->
    d = document
    t = 'link'
    s = d.createElement(t)
    s.type = 'text/css'
    s.rel = 'stylesheet'
    s.href = href
    d.body.appendChild s

loadAppcuesEmbed = ->
    scripts = [
        INTERNAL_TACO_EMBED_URL
    ]

    # Keep track of embed scripts loading.
    promises = []
    for url in scripts
        promise = new $.Deferred
        promises.push promise
        loadAsync url, {}, promise.resolve

    # Once segment's ready event comes in the
    # embed should be good to go.
    segmentPromise = new $.Deferred
    promises.push(segmentPromise)
    analytics.ready(segmentPromise.resolve)

    $.when.apply($, promises)

loadReactcues = (accountId) ->
    # Hit the Health Report Lambda to trigger re-calculation of stats.  Discards the response.
    $.getJSON "#{HEALTH_REPORT_URL}?accountId=#{accountId}"

    if window is window.top
        # Load Appcues Injectable React Routes
        $.getScript "#{APPCUES_REACT_URL}/injectable.js", (data) ->
            getJwtToken(auth.get('firebaseUser')).then((token) ->
                window.apcInjectable = new Reactcues.Injectable(token)
            )

$ ->

    if window isnt window.top
        $("body").addClass "isIFramed"

        timer = null

        receiveReactcuesMessage = (event) ->
            sendInviteResponse = (requestId, response) ->
                () ->
                    event.source.postMessage(JSON.stringify({
                        type: "invite_user_response",
                        requestId,
                        response
                    }), event.origin)

            if event.source is window.parent
                try
                    data = JSON.parse(event.data)
                    if data && data.type
                        switch data.type
                            when "set_is_spoofing_in_my_appcues"
                                spoofAsAccountId(data.accountId or "")
                                return
                            when "invite_user"
                                inviter = data.inviter
                                inviteeName = data.inviteeName
                                inviteeEmail = data.inviteeEmail
                                inviteeRole = data.inviteeRole
                                inviteeJob = data.inviteeJob
                                requestId = data.requestId

                                onSuccess = sendInviteResponse(requestId, "SUCCESS")
                                onFail = sendInviteResponse(requestId, "INVITE_FAILED")
                                onEmailSendFail = sendInviteResponse(requestId, "EMAIL_SEND_FAILED")
                                onEmailTakenFail = sendInviteResponse(requestId, "EMAIL_TAKEN")

                                auth.inviteUser(inviter, inviteeName, inviteeEmail, inviteeRole, inviteeJob, onSuccess, onFail, onEmailSendFail, onEmailTakenFail)
                                return

                            when "transfer_user"
                                transfereeEmail = data.email
                                transfereeRole = data.role
                                requestId = data.requestId

                                onSuccess = sendInviteResponse(requestId, "SUCCESS")
                                onFail = sendInviteResponse(requestId, "INVITE_FAILED")
                                onAlreadyOnTeamFail = sendInviteResponse(requestId, "TRANSFER_FAILED_INVITEE_ALREADY_ON_TEAM")

                                auth.transferUser(transfereeEmail, transfereeRole, onSuccess, onFail, onAlreadyOnTeamFail)
                                return

                            when "navigate_to_path"
                                afterNavigate = () ->
                                    document.body.removeEventListener("navigated", afterNavigate)
                                    event.source.postMessage(JSON.stringify({ type: "message_received" }), event.origin)

                                performNavigation = () ->
                                    document.body.addEventListener("navigated", afterNavigate)
                                    main.navigate(data.path, {trigger: true})

                                navigateIfReady = () ->
                                    window.clearTimeout timer
                                    if main.isRouterReady()
                                        performNavigation()
                                    else
                                        timer = window.setTimeout navigateIfReady, 200

                                navigateIfReady()

        window.addEventListener("message", receiveReactcuesMessage, false)


    # Require auth to proceed.
    auth.onAuth ->
        # Start the router.
        start()

        # Get user data.
        userData = auth.toJSON()

        # Load account properties.
        require 'models/account-properties'

        # Send user data to third-party services.
        cleanUserData =
            id: auth.get('id')
            anonymous: auth.get('providerId') is 'anonymous'

        if window.Raven
            Raven.setUser cleanUserData

        # Initialize CRX helper.
        require 'models/crx-helper'

        # Load Appcues.js directly if we're not getting it from Segment and we're not spoofing
        if !TRACKING_ENABLED && !window.isSpoofed && window.top is window
            loadAsync "//#{CDN_DOMAIN}/#{APPCUES_ID}.js", {}

        # Load Appcues embed scripts.
        loadAppcuesEmbed().then ->

            onSync = (userModel, accountModel, accountUsersModel) ->
                if auth.user?.syncd and auth.currentAccount?.syncd and auth.accountUsers?.syncd
                    # Initialize Appcues.
                    userId = userModel.id
                    userProps = userModel.toJSON()

                    if Appcues? and _.isFunction(Appcues.initMixpanel)
                        Appcues.initMixpanel()

                        # Initialize Taco.
                        window.appcuesWidget = widget = AppcuesWidget Appcues.user()
                        do window.appcuesWidgetInit = ->
                            appcuesWidget.init '#taco', {
                                position: 'left'
                            }

            if auth.user?.syncd and auth.currentAccount?.syncd and auth.accountUsers?.syncd
                onSync auth.user, auth.currentAccount, auth.accountUsers
            else
                auth.user?.once 'sync', ->
                    onSync auth.user, auth.currentAccount, auth.accountUsers
                auth.currentAccount?.once 'sync', ->
                    onSync auth.user, auth.currentAccount, auth.accountUsers
                auth.accountUsers?.once 'sync', ->
                    onSync auth.user, auth.currentAccount, auth.accountUsers

            return

        if auth.currentAccount?.syncd
            loadReactcues auth.currentAccount.get("id")
        else
            auth.currentAccount?.once 'sync', ->
                loadReactcues auth.currentAccount.get("id")
