BaseView = require 'views/base'


module.exports = class RuleFrequencyView extends BaseView
    __name__: 'RuleFrequencyView'
    className: 'rule-frequency'
    template: require 'views/templates/build-steps/settings/rule-frequency'

    events:
        'change [name=frequency]' : '_setFrequency'

    initialize: (options={}) ->
        {@rule} = options
        @listenTo @rule, 'change:frequency', (model, value, options) ->
            @render() unless options.skipRender

    getContext: ->
        rule: @rule.toJSON()

    _setFrequency: ->
        $selected = @$('[name=frequency]:checked')
        @rule.set {frequency: $selected.attr('value')}, {skipRender: true}
        lytics.trackWithUser 'Chose rule frequency',
            ruleId: @rule.id
            frequency: $selected.attr('value')

