AppView = require 'views/app'
ContentListView = require 'views/content-list/content-list'
ListFiltersView = require 'views/content-list/filters'
FloatingCTAViews = require 'views/floating-ctas'
AccountMembersCollection = require 'collections/account-members'
JourneysCollection = require 'collections/journeys'
FlowsCollection = require 'collections/flows'
AnnouncementsCollection = require 'collections/announcements'
HotspotGroupsCollection = require 'collections/hotspot-groups'
CoachmarkGroupsCollection = require 'collections/coachmark-groups'
CategoriesCollection = require 'collections/categories'
RulesCollection = require 'collections/rules'
ViewsCollection = require 'collections/views'
auth = require 'models/auth'
crxHelperModel = require 'models/crx-helper'
CreateContentWithDomainView = require 'views/create-content-with-domain'
trackEvent = require 'lib/track-event'
helpers = require 'lib/helpers'
{firebaseRef} = require 'lib/firebase'

WELCOME_MODAL_ID = '-L2qDWM1CL-NmtVuC-50'

module.exports = class ListAllContentView extends AppView
    __name__: 'ListAllContentView'
    template: require 'views/templates/list-all'

    events:
        'click [data-action=createContentWithDomain]': 'clickedCreate'
        'click [data-action=clearFilterState]': 'clearFilterState'
        'click [data-action=cloneWelcomeTemplate]': 'cloneWelcomeTemplate'
        'click [data-action=cloneAnnouncementTemplate]': 'cloneAnnouncementTemplate'
        'click [data-action=clonePageTourTemplate]': 'clonePageTourTemplate'
        'click .create-flow-btn': 'launchWelcomeModal'

    initialize: (options={}) ->

        {@installed, @isAnnouncementsBeta, @isNPSProductTrial, @isNPSProductOnlyUser} = options
        @journeysCollection = new JourneysCollection
        @flowsCollection = new FlowsCollection
        @announcementsCollection = new AnnouncementsCollection
        @hotspotGroupsCollection = new HotspotGroupsCollection
        @coachmarkGroupsCollection = new CoachmarkGroupsCollection
        @rulesCollection = new RulesCollection
        @accountMembersCollection = new AccountMembersCollection
        @categoriesCollection = new CategoriesCollection
        @currentAccount = auth.currentAccount
        @accountId = @currentAccount.id
        @userId = auth.user.id
        @viewsCollection = new ViewsCollection
        @listenTo auth.user, 'change:hasCrxInstalled', @render

        @filterState = new Backbone.Model
            type: null
            state: null
            sortBy: 'default'
            nameFilter: null


        @listenTo crxHelperModel, 'change:hasCrxInstalled change:checkedForCrx', @render

        @viewsCollection.whenSyncd =>
            @journeysCollection.whenSyncd =>
                @flowsCollection.whenSyncd =>
                    @hotspotGroupsCollection.whenSyncd =>
                        @coachmarkGroupsCollection.whenSyncd =>
                            @render()

        @dismissedChromeNotice = localStorage?.getItem('dismissedChromeNotice') == 'true'

        @checkForValidAccount()
    render: ->
        return if @createContentWithDomainView
        @removeChildViews()
        super
        @renderChildren()
        return @

    getContext: ->
        isTrial: auth.currentAccount.get('isTrial')
        accountId: @accountId
        shouldShowTemplates: @installed or not @_hasContent()
        hasCrxInstalled: crxHelperModel.get('hasCrxInstalled')
        checkedForCrx: crxHelperModel.get('checkedForCrx')
        onChrome: crxHelperModel.onChrome()
        isAnnouncementsBeta: @isAnnouncementsBeta
        shouldHideFlowsDashboard: @isNPSProductTrial or @isNPSProductOnlyUser

    renderChildren: ->
        shouldHideFlowsDashboard = @getContext().shouldHideFlowsDashboard

        if shouldHideFlowsDashboard
            @renderReactFlowsDashboardModal()
        else
            view = new ListFiltersView
                filterState: @filterState
                categories: @categoriesCollection
            @renderChild view
            if @$('.filters-bar').children().length is 0
                @$('.filters-bar').append(view.el)

            view = new ContentListView {
                @journeysCollection
                @announcementsCollection
                @flowsCollection
                @hotspotGroupsCollection
                @coachmarkGroupsCollection
                @rulesCollection
                @accountMembersCollection
                categories: @categoriesCollection
                @filterState
                clearFilterState: @clearFilterState
            }
            @renderChild view
            @$('.main-content').append view.el

    renderReactFlowsDashboardModal: ->
        if window.apcInjectable and window.apcInjectable.renderFlowDashboardModal
            window.apcInjectable.renderFlowDashboardModal @accountId, "apc-react-root"
        else
            setTimeout(@renderReactFlowsDashboardModal.bind(@), 300)

    checkForValidAccount: () ->
        user = auth.user.attributes
        userEmail = user.email
        isInvitedUser = user.isInvitedUser

        account = @currentAccount.attributes
        provider = account.provider

        data = $.extend({}, {
            accountId: @accountId
            userId: @userId
            isInvitedUser
            provider
        })

        if window.Raven
            Raven.setUser(data)

        if !@accountId || !userEmail || !@userId
            try
                trackEvent('Logged in with incomplete account', data)

            e = new Error('Logged in with incomplete account')
            Raven.captureException e, {
                level: 'info'
                extra: {
                    userId: @userId,
                    provider: provider,
                    referrer: window.document.referrer
                }
            }

    clickedCreate: () ->
        @createContentWithDomain()

    createContentWithDomain: (installed, contentKey) ->
        _domainsList = auth.currentAccount.get('domains')
        domains = if _domainsList then _domainsList.split(',') else []

        router = require 'routers/main'
        router.navigate('/build', trigger: false)
        @createContentWithDomainView = new CreateContentWithDomainView {
            show: true
            domains: domains
            installed: installed
            contentKey: contentKey
        }
        @renderChild(@createContentWithDomainView)

    clearFilterState: ->
        @filterState.set
            type: null
            state: null
            sortBy: 'default'
            nameFilter: null

    cloneWelcomeTemplate: ->
        @_cloneTemplate 'example-welcome-message', 'flow'

    cloneAnnouncementTemplate: ->
        @_cloneTemplate 'example-slideout-announcement', 'flow'

    clonePageTourTemplate: ->
        @_cloneTemplate 'example-page-tour', 'hotspot-group'

    _cloneTemplate: (templateName, contentType) ->
        if !crxHelperModel.get('hasCrxInstalled')
            crxHelperModel.showCrxInstallModal()
        else
            try
                trackEvent("Clicked Dashboard Template", { templateName: templateName })

            switch contentType
                when "journey" then _collection = @journeysCollection
                when "flow" then _collection = @flowsCollection
                when "hotspot-group" then _collection = @hotspotGroupsCollection
                else _collection = null

            if _collection
                @_createContentWithDomainOnClone(_collection)
                _collection.cloneModel(templateName, { fromAccountId: 'sample', noCopyOf: true })

    _createContentWithDomainOnClone: (collection) ->
        @listenToOnce collection, 'clone', (collectionGroup, cloneId) =>
            @createContentWithDomain(null, cloneId)

    _hasContent: ->
        if @journeysCollection.syncd && @flowsCollection.syncd && @hotspotGroupsCollection.syncd && @coachmarkGroupsCollection.syncd
            @journeysCollection.length || @flowsCollection.length || @hotspotGroupsCollection.length || @coachmarkGroupsCollection.length
        else
            true

    launchWelcomeModal: ->
        button = $('.create-flow-btn')
        buttonOriginalInnerText = button[0].innerText
        button[0].innerText = 'Warming up...'
        button.prop("disabled",true)
        Appcues.show(WELCOME_MODAL_ID)

        window.setTimeout(() ->
            button[0].innerText = buttonOriginalInnerText
            button.prop("disabled",false)
        , 4000)
