auth = require 'models/auth'
bundler = require 'lib/bundler'
{STRIPE_EMBED_URL, STRIPE_PUBLIC_KEY} = window.__env

module.exports =

    fetchCustomer: ->
        stripeEmail = auth.currentAccount.get('stripeEmail') or auth.get('email')
        stripeId = auth.currentAccount.get 'stripeId'

        if stripeId
            $.getJSON "#{STRIPE_EMBED_URL}/customers/#{stripeId}", {
                email: stripeEmail
            }
        else
            # Return a resolved promise.
            return (new $.Deferred()).resolve({})

    fetchPlans: -> $.getJSON "#{STRIPE_EMBED_URL}/plans"

    addCard: (callback) ->
        # Update the current customers card if a customer exists.
        if auth.currentAccount.get('stripeId')
            return @updateCard callback

        # If no customer exists, create one with a card attached.
        email = auth.get 'email'
        addHandler = StripeCheckout.configure {
            key: STRIPE_PUBLIC_KEY
            image: '/images/mark.png'
            token: (token, args) ->
                data = $.extend({}, token, {
                    email
                    metadata: {
                        accountId: auth.currentAccount.id
                        userId: auth.id
                    }
                })
                $.ajax({
                    url: "#{STRIPE_EMBED_URL}/customers"
                    data: data
                    method: 'post'
                    dataType: 'json'
                }).then (resp) ->
                    auth.currentAccount.once 'change:stripeId', ->
                        # Allow the callback to return a promise.
                        p = callback?()
                        if _.isFunction p?.then
                            p.then ->
                                _.delay ->
                                    window.location.reload()
                                , 200
                        else
                            _.delay ->
                                window.location.reload()
                            , 200

                    auth.currentAccount.set 'stripeId', resp.stripeId
        }

        addHandler.open
            name: 'Appcues'
            description: 'Enter a new card'
            panelLabel: 'Add a card'
            billingAddress: true
            email: email

    updateCard: (callback) ->
        stripeId = auth.currentAccount.get 'stripeId'
        updateHandler = StripeCheckout.configure {
            key: STRIPE_PUBLIC_KEY
            image: '/images/mark.png'
            token: (token, args) ->
                data = {
                    stripeId
                    email: auth.get 'email'
                    metadata: {
                        accountId: auth.currentAccount.id
                        userId: auth.id
                    }
                }
                $.ajax({
                    url: "#{STRIPE_EMBED_URL}/cards"
                    data: $.extend({}, token, data)
                    method: 'post'
                    dataType: 'json'
                }).then ->
                    # Allow the callback to return a promise.
                    p = callback?()
                    if _.isFunction p?.then
                        p.then ->
                            window.location.reload()
                    else
                        window.location.reload()
        }

        updateHandler.open
            name: 'Appcues'
            description: 'Enter a new card'
            panelLabel: 'Update card'
            billingAddress: true
            email: auth.get('email')

    purchase: (e) ->
        $el = @$ e.currentTarget
        stripeId = auth.currentAccount.get 'stripeId'
        email = auth.get 'email'
        planId = $el.data 'plan'
        planName = $el.data 'plan-name'
        accountId = auth.currentAccount.id

        chargeHandler = StripeCheckout.configure {
            key: STRIPE_PUBLIC_KEY
            image: '/images/mark.png'
            token: (token) ->
                data = $.extend({}, token, {
                    stripeId
                    planId
                    email
                    metadata: {
                        accountId: accountId
                        userId: auth.id
                    }
                })

                $.ajax({
                    url: "#{STRIPE_EMBED_URL}/subscriptions"
                    data: data
                    method: 'post'
                    dataType: 'json'
                }).then (resp) ->
                    # Update the account with the stripeId and a paid account.
                    props = {
                        isTrial: false
                    }
                    if resp.stripeId?
                        _.extend props, {stripeId: resp.stripeId}

                    auth.currentAccount.set props

                    bundler.sync accountId

                    window.location.reload()
        }

        chargeHandler.open
            name: "Appcues"
            description: "Sign up for Appcues #{planName}."
            billingAddress: true
            panelLabel: "Sign up"
            email: email
