BaseView = require 'views/base'
StepView = require 'views/step'
StepSettingsView = require 'views/step-settings'
auth = require 'models/auth'
SandboxContainerView = require 'views/sandbox-container'

POWERED_BY_PLAN_IDS = ["99-bootstrap"]

module.exports = class StepsView extends BaseView
    __name__: 'StepsView'
    className: 'steps'
    template: require 'views/templates/steps'

    initialize: (options={}) ->
        super

        {@isEditable} = options

        @collection.whenSyncd =>
            # Migrate any old step types to the new component structure.
            @collection.invoke '_migrateToComponents'
            @collection.invoke '_migrateToStepButtons'
            @_renderStepViews()

        @listenTo @collection, 'select sort', =>
            if @model.get('sandbox')
                @_updateCurrentStep()
            else
                @_renderStepViews()
        @listenTo @model, 'change:patternType', @updatePattern
        @listenTo @model, 'change:position', @updatePosition
        @listenTo @model, 'change:backdrop', @updateBackdrop
        @listenTo @model, 'change:style', @_updateStyle
        @listenTo @model, 'change:skippable', =>
            if @model.get('sandbox')
                @_updateCurrentStep()
            else
                @render()
        @listenTo @model, 'change:sandbox', @render

    getContext: ->
        skippable: @model.get('skippable')
        sandbox: @model.get('sandbox')

    render: ->
        super
        @_renderStepViews()

    _renderStepViews: (model, i) ->
        @removeChildViews()
        stepId = @collection.getSelectedId()
        step = @collection.get(stepId)
        return unless step?

        if @model.get('sandbox')
            @sandboxContainer = new SandboxContainerView
                src: '/step'
                collectionId: @model.get('id')
                modelId: stepId
                attrs: @_getSandboxViewAttributes(stepId)
                cocoonOptions: {height: '100%'}
                styleId: @model.get('style')
            @renderChild @sandboxContainer
            @$('.preview-area').append @sandboxContainer.el
        else
            stepIndex = @_getStepIndex stepId
            stepView = new StepView
                model: step
                isFirst: stepIndex is 0
                isLast: stepIndex is (@collection.length - 1)
                showPoweredByText: @shouldShowPoweredBy() and (@model.get('patternType') == "shorty")
                isEditable: @isEditable
            @renderChild stepView
            @$('.flow-steps').append stepView.el
            @updatePattern()

            if @isEditable
                stepSettingsView = new StepSettingsView {model: step}
                @renderChild stepSettingsView
                @$('.step-settings-container').append(stepSettingsView.el)

    updatePattern: ->
        if @model.get('sandbox')
            @_updateCurrentStep()
        else
            # @todo is this necessary? (bcrowell)
            @$el.removeClass('fullscreen-pattern modal-pattern left-pattern shorty-pattern')
                .addClass(@model.get('patternType') + '-pattern')

    updatePosition: ->
        if @model.get('sandbox')
            @_updateCurrentStep()

    updateBackdrop: ->
        if @model.get('sandbox')
            @_updateCurrentStep()

    _updateCurrentStep: ->
        # It appears we can get this called before the render() is complete, which
        # can cause the @sandboxContainer ref to be undefined. Protect against that.
        if @sandboxContainer
            stepId = @collection.getSelectedId()
            @sandboxContainer.updateModel stepId, @_getSandboxViewAttributes(stepId)

    _updateStyle: ->
        styleId = @model.get('style')
        stepId = @collection.getSelectedId()
        @sandboxContainer.updateStyle styleId, @_getSandboxViewAttributes(stepId)

    _getStepIndex: (stepId) ->
        _.indexOf(@collection.map((step) ->
            step.get 'id'
        ), stepId)

    _getSandboxViewAttributes: (stepId) ->
        stepIndex = @_getStepIndex stepId
        return {
            stepNumber: stepIndex
            isFirst: stepIndex is 0
            isLast: stepIndex is (@collection.length - 1)
            showPoweredByText: @shouldShowPoweredBy() and (@model.get('patternType') == "shorty")
            patternType: @model.get('patternType')
            position: @model.get('position')
            backdrop: @model.get('backdrop')
            totalSteps: @collection.size()
            isEditable: @isEditable
            skippable: @model.get('skippable')
        }

    shouldShowPoweredBy: ->
        account = auth.currentAccount.attributes
        (account.stripePlanId in POWERED_BY_PLAN_IDS and not account.hidePoweredBy) or
          (account.isTrial and account.stripeId is '')