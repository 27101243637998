auth = require 'models/auth'
StepsCollection = require 'collections/steps'
StylesCollection = require 'collections/styles'
AppView = require 'views/app'
BuildFlowSettingsView = require 'views/build-steps/design/flow/settings'
PreviewFrameView = require 'views/preview-frame'
StepsView = require 'views/steps'
ShareModalView = require 'views/share-modal'
page = require 'models/page'
helpers = require 'lib/helpers'
{confirmUnpublishContent} = require 'lib/build-helpers'


module.exports = class BuildFlowView extends AppView
    __name__: 'BuildFlowView'
    className: 'build-flow'
    template: require 'views/templates/build-steps/design/flow'

    events:
        'click [data-action=unpublishContent]': '_unpublishContent'
        'click [data-action=share]': 'share'

    initialize: (options={}) ->
        {@ruleId, @rule, @content, @contentType, @flow} = options
        @listenTo @rule, 'change:published', @render

        @useEager = if localStorage.getItem('useEager') is 'false' then false else true

        @steps = new StepsCollection [], flowId: @ruleId
        @listenToOnce @steps, 'sync', ->
            @steps.addEmptyStep() if @steps.isEmpty()

        @styles = if options.styles then options.styles else new StylesCollection

        page.set
            title: @content.get('name')
            isEditable: true
        , skipContent: true
        @listenTo page, 'change:title', (page, model, opts) ->
            if opts.skipContent isnt true
                @content.set 'name', page.get('title')

    getContext: ->
        rule = @rule.toJSON()
        flow = @flow.toJSON()

        return {
            buildStep: 'design'
            @ruleId
            rule
            flow
            urlPrefix: @content.urlPrefix()
            isAdmin: auth.user.get 'isAdmin'
            cannotPublish: auth.user.get 'cannotPublish'
        }

    render: ->
        @removeChildViews()

        super

        @flowSettingsView = new BuildFlowSettingsView {@flow, @rule, @steps, @styles}
        @$('#build-flow-settings').append @flowSettingsView.el
        @renderChild @flowSettingsView

        @previewFrameView = new PreviewFrameView
            rule: @rule
            contentType: @contentType
            useEager: @useEager
            flow: @flow
            styles: @styles

        @$('#build-preview-frame').append @previewFrameView.el
        @renderChild @previewFrameView
        @previewFrameView.on 'eager:navigate', ->
            _.result @previewFrame, 'checkScriptProtocols'
            _.result @previewFrame, 'injectRecorder'

        @stepsView = new StepsView
            model: @flow
            collection: @steps
            rule: @rule
            isEditable: not @rule.get('published')
            viewState: (new Backbone.Model)
        @$('#build-preview-frame').append @stepsView.el
        @renderChild @stepsView

    _unpublishContent: confirmUnpublishContent

    share: ->
        shareModel = new Backbone.Model({
            flowId: @content.id
            previewUrl: @rule.get('previewUrl')
        })
        v = new ShareModalView
            show: true
            model: shareModel

        @renderChild(v)
