AppView = require 'views/app'
auth = require 'models/auth'


module.exports = class BillingCanceled extends AppView
    __name__: 'BillingCanceled'
    template: require 'views/templates/billing-canceled'

    render: ->
        super

        if window.apcInjectable?
            window.apcInjectable.renderSubscriptionCanceled(auth.currentAccount.id, "apc-react-root")
        else
            window.setTimeout(@render.bind(@), 300)
