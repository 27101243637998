# This module will receive messages from an external frame and render a hotspot
# or a flow, as well as update their styles.

buildSdkCss = (data) ->

    stylePageCss = """
        appcues > .appcues-skip, appcues > .appcues-progress, appcues > cue {
            max-width: 90%;
        }
        appcues > cue {
            min-height: 300px;
        }
        appcues {
            overflow: hidden;
        }

        appcues .appcues-skip a, .appcues-backdrop[data-pattern-type=left], .appcues-backdrop[data-pattern-type=modal] {
            pointer-events: none !important;
        }
    """

    userAccountStyles = {
        globalStyling: data.globalStyling + stylePageCss
    }

    if(data.typekitId)
        userAccountStyles.typekitId = data.typekitId

    if(data.globalBeaconColor)
        userAccountStyles.globalBeaconColor = data.globalBeaconColor

    if(data.globalHotspotAnimation)
        userAccountStyles.globalHotspotAnimation = data.globalHotspotAnimation

    if(data.globalBeaconStyle)
        userAccountStyles.globalBeaconStyle = data.globalBeaconStyle

    return userAccountStyles

renderHotspots = () ->
    Appcues.injectContent {
        version_id: 1
        type: "hotspot-group"
        redirect_url: null
        redirect_new_tab: false
        next_content_id: null
        name: "Demo Hotspots"
        id: "appcues_global_styling"
        attributes:
            sequential: true
            skippable: true
            hotspots: [
              tooltip_alignment: null
              skip_text: null
              selector: "body"
              pattern: "beacon"
              offset_y_percentage: 0.5
              offset_x_percentage: 0.5
              next_text: null
              index: null
              id: "appcues_global_styling_hotspot_a"
              html: "<p>This is a pretty cool looking hotspot. Nice work!</p>"
            ]
    }


renderFlow = () ->
    Appcues.injectContent {
        version_id: 1
        type: "modal"
        redirect_url: null
        redirect_new_tab: false
        next_content_id: null
        name: "Demo Modal"
        id: "appcues_global_styling"
        attributes:
            steps: [
                {
                    'step_number': 1
                    'step_buttons': [
                        {
                            'type': 'NEXT'
                            'text': 'Advanced Styling'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_a"
                    'html': """
                        <h2>This is your preview flow for styling.</h2>
                        <ol>
                            <li>Set your colors and fonts on the left so your flows and hotspots will blend natively with your product :)</li>
                            <li>Once you have your coloring right, <strong>go on to the next step</strong> for more advanced styling.</li>
                        </ol>
                        <p><em>P.S. The "Back" button isn't showing here because this is step 1 of the flow. You'll see the "Standard button color" applied to it in the next step.</em></p>
                    """
                }
                {
                    'step_number': 2
                    'step_buttons': [
                        {
                            'type': 'BACK'
                            'text': 'Back'
                            'style': null
                            'align': 'LEFT'
                        }
                        {
                            'type': 'NEXT'
                            'text': 'Even more!'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_b"
                    'html': """
                        <h1>Advanced stying [Header 1]</h1>
                        <p>Flip over to the CSS Editor tab to <strong>add custom CSS for styling headers, paragraphs and lists</strong>. We've put a variety of elements for you to play with on this flow.</p>
                        <ul>
                            <li>Item in a list</li>
                            <li><a href="#">Another item in a list (this one's a link)</a></li>
                        </ul>
                        <h2>Next steps [Header 2]</h2>
                        <p>When you're happy with these, move on to see a few more stylable elements.</p>
                    """
                }
                {
                    'step_number': 2
                    'step_buttons': [
                        {
                            'type': 'BACK'
                            'text': 'Back'
                            'style': null
                            'align': 'LEFT'
                        }
                        {
                            'type': 'DONE'
                            'text': 'You look fantastic!'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_c"
                    'html': """
                        <h3>Advanced styling continued [Header 3]</h3>
                        <div style="text-align:center; padding-bottom: 5px">
                            <img src="/images/mc-hammer.jpg" style="height:125px">
                        </div>
                        <h4>These headers keep getting smaller [Header 4]</h4>
                        <pre>
                            // This is some pre-formatted code.
                        </pre>
                        <h5>And smaller... [Header 5]</h5>
                    """
                }
            ]
            'skippable': false
            'position': 'center'
            'pattern_type': 'modal'
            'backdrop': true

    }

renderTooltips = () ->
    Appcues.injectContent {
        version_id: 1
        type: "modal"
        redirect_url: null
        redirect_new_tab: false
        next_content_id: null
        name: "Demo Slideout"
        id: "appcues_global_styling"
        attributes:
            steps: [
                {
                    'step_number': 1
                    'step_buttons': [
                        {
                            'type': 'NEXT'
                            'text': 'Advanced Styling'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_a"
                    'html': """
                        <h2>This is your preview flow for styling.</h2>
                        <ol>
                            <li>Set your colors and fonts on the left so your flows and hotspots will blend natively with your product :)</li>
                            <li>Once you have your coloring right, <strong>go on to the next step</strong> for more advanced styling.</li>
                        </ol>
                        <p><em>P.S. The "Back" button isn't showing here because this is step 1 of the flow. You'll see the "Standard button color" applied to it in the next step.</em></p>
                    """
                }
                {
                    'step_number': 2
                    'step_buttons': [
                        {
                            'type': 'BACK'
                            'text': 'Back'
                            'style': null
                            'align': 'LEFT'
                        }
                        {
                            'type': 'NEXT'
                            'text': 'Even more!'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_b"
                    'html': """
                        <h1>Advanced stying [Header 1]</h1>
                        <p>Flip over to the CSS Editor tab to <strong>add custom CSS for styling headers, paragraphs and lists</strong>. We've put a variety of elements for you to play with on this flow.</p>
                        <ul>
                            <li>Item in a list</li>
                            <li><a href="#">Another item in a list (this one's a link)</a></li>
                        </ul>
                        <h2>Next steps [Header 2]</h2>
                        <p>When you're happy with these, move on to see a few more stylable elements.</p>
                    """
                }
                {
                    'step_number': 2
                    'step_buttons': [
                        {
                            'type': 'BACK'
                            'text': 'Back'
                            'style': null
                            'align': 'LEFT'
                        }
                        {
                            'type': 'DONE'
                            'text': 'You look fantastic!'
                            'style': null
                            'align': 'RIGHT'
                        }
                    ]
                    'prev_text': 'Back'
                    'next_text': 'Next'
                    'id': "appcues_global_styling_modal_c"
                    'html': """
                        <h3>Advanced styling continued [Header 3]</h3>
                        <div style="text-align:center; padding-bottom: 5px">
                            <img src="/images/mc-hammer.jpg" style="height:125px">
                        </div>
                        <h4>These headers keep getting smaller [Header 4]</h4>
                        <pre>
                            // This is some pre-formatted code.
                        </pre>
                        <h5>And smaller... [Header 5]</h5>
                    """
                }
            ]
            skippable: true
            position: 'center'
            pattern_type: 'shorty'
            backdrop: false

        }

window.onload = ->

    # Listen for render messages from the parent frame.
    window.addEventListener 'message', ({data, source}) ->

        return unless source is parent

        if typeof data is 'string'
            try
                data = JSON.parse data
            catch
                return

        action = data.action

        switch action
            when 'appcues:render-flow'
                Appcues.injectStyles buildSdkCss(data)
                renderFlow data

            when 'appcues:render-hotspots'
                Appcues.injectStyles buildSdkCss(data)
                renderHotspots data

            when 'appcues:render-slideout'
                Appcues.injectStyles buildSdkCss(data)
                renderTooltips data

            when 'appcues:update-styling'
                Appcues.injectStyles buildSdkCss(data)

    , false

    # Listen for flow and hotspots skip/complete events and re-show the
    # content so that the preview is never empty.
    Appcues.on 'flow_skipped flow_finished', ->
        window.setTimeout renderFlow, 500, latestStylingData
    Appcues.on 'hotspots_skipped hotspots_completed', ->
        window.setTimeout renderHotspots, 500, latestStylingData

    # Tell the parent frame this cocoon is ready.
    parent.postMessage JSON.stringify({action: 'ready'}), '*'
