BaseView = require 'views/base'

module.exports = class StepView extends BaseView
    __name__: 'StepView'
    className: 'step step-content panel panel-default'
    model: new (require('models/step'))
    template: require 'views/templates/step'

    _actionViews:
        default: require 'views/step-actions/default'
        form: require 'views/step-actions/form'
        image: require 'views/step-actions/image'
        video: require 'views/step-actions/video'
        social: require 'views/step-actions/social'
        components: require 'views/step-actions/components'

    initialize: (options={}) ->
        {@isEditable, @isFirst, @isLast, @showPoweredByText} = options
        @$el.attr 'data-step-id', @model.cid
        @listenTo @model, 'change:stepType', @render

    events:
        'click .show-step-action': 'setStepType'

    setStepType: (e) ->
        stepType = @$(e.currentTarget).data 'action-type'
        @model.set 'stepType', stepType

        try
            lytics.trackWithUser 'Chose a Step Type', {
                stepType: stepType
                flowId: @model.collection?.flowId
            }

    render: ->
        @removeChildViews()
        super
        @$el.addClass('first') if @isFirst
        @$el.addClass('last') if @isLast
        @renderStepType()

    renderStepType: ->
        stepType = @model.get('stepType')
        view = new (@_actionViews[stepType] ? @_actionViews.default)
            model: @model
            isEditable: @isEditable
            isFirst: @isFirst
            isLast: @isLast

        @$el.append view.el
        @renderChild view

        # Clear the model's attributes when the view is removed.
        @listenToOnce view, 'clear', => @model.clear()
