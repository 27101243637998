BaseView = require 'views/base'


# This puts an interface around a rule that lets users select AB testing.
# For the moment, this is actually hold-back testing, but we'll eventually
# support true A/B testing.

module.exports = class ABTestingView extends BaseView
    __name__: 'ABTestingView'
    template: require 'views/templates/build-steps/settings/ab-testing'

    events:
        'change input[name=abtest]': 'update'
        'change select[name=abgroup]': 'setGroup'

    initialize: ->
        # @listenTo @model, 'change:properties', @render

    getContext: ->
        props = @model.get('properties')
        return {
            isPublished: @model.get('published')
            isABTested: _.isObject(props) and _.isObject(props._ABGroup)
            ABGroup: props?._ABGroup?.value
        }

    update: (e) ->
        return unless e
        prev = @model.get 'properties'
        return unless @isSettableProperties(prev)

        if e.currentTarget.checked
            @model.set 'properties', _.extend {}, prev, {
                _ABGroup:
                    operator: '=='
                    value: 1
            }
        else
            @model.set 'properties', _.omit(prev, '_ABGroup')

        @render()

    setGroup: (e) ->
        return unless e
        prev = @model.get 'properties'
        return unless @isSettableProperties(prev)

        switch e.currentTarget.value
            when "1"
                group = 1
            when "2"
                group = 2

        if prev._ABGroup and group
            @model.set 'properties', _.extend {}, prev, {
                _ABGroup:
                    operator: '=='
                    value: group
            }

    isSettableProperties: (obj) ->
        # Properties should either not exist or exist as an object to
        # be considered settable. Otherwise, GTFO.
        return not obj? or _.isObject(obj)
