ContentItemView = require 'views/content-list/item'

module.exports = class AnnouncementItemView extends ContentItemView
    __name__: 'AnnouncementItemView'
    className: -> super + ' announcement-item'
    template: require 'views/templates/content-list/announcement'
    labels: {
        model: 'this Announcement'
        cloneEvent: 'Cloned an Announcement'
        deleteEvent: 'Deleted an Announcement'
        archiveEvent: 'Archived an Announcement'
        unarchiveEvent: 'Unarchived an Announcement'
    }

    getElementId: -> "announcement-#{@model.id}"

    attributes: ->
        'id': @getElementId()
        'data-announcement-id': @model.id

