auth = require 'models/auth'


module.exports = class CategoryModel extends Backbone.Model
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        name: null
        index: null

    initialize: (attrs={}) ->

