module.exports = class BaseView extends Backbone.View
    template: ->

    constructor: ->
        @_childViews = []
        @timer = null
        super

    renderChild: (view) ->
        # console.log "Rendering child #{view.__name__} of #{@__name__}"
        view.render()
        @_childViews.push view
        return view

    close: ->
        @stopListening()
        @undelegateEvents()
        @removeChildViews()

    removeChildViews: ->
        _(@_childViews).invoke 'remove'
        @_childViews = []
        return @

    remove: ->
        @undelegateEvents()
        @removeChildViews()
        super

    render: ->
        context = _.result(@, 'getContext', {})
        window.clearTimeout @timer

        ABRITRARY_TIME_TO_WAIT_AFTER_RENDER_IN_MS = 300

        @$el.html @template context
        @timer = window.setTimeout @afterRender, ABRITRARY_TIME_TO_WAIT_AFTER_RENDER_IN_MS

        @$el

    afterRender: ->
        if window isnt window.top
            $app = $("#app")[0]
            if $app
                documentHeight = $app.getBoundingClientRect().height
                window.parent.postMessage(JSON.stringify({ type: "set_dimensions", dimensions: { minHeight: documentHeight }}), "*")
        @

    getContext: {}
