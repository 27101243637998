auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'

Model = Backbone.Model.extend {
    defaults:
        integrationId: null
        isEnabled: true
}


# Keeps track of which integrations a user has active.
# Simple reference that can be used to look up more data.
module.exports = class AccountIntegrationsCollection extends Backbone.Firebase.Collection
    model: Model

    url: ->
        accountId = auth.currentAccount.id
        firebaseRef.child("accounts/#{accountId}/integrations")

    initialize: ->
        @once 'sync', -> @syncd = true
