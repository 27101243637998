defaultTemplate = require 'views/templates/components/default'

ComponentRenderer = do ->
    renderers = {
        editor: (data) ->
            data.html or null

        image: (data) ->
            return null unless data.src
            hrefAttr = if data.link then "href='#{data.link}'" else ''
            """<div class="apc-img-wrap" style="text-align: #{data.position || 'center'};"><a #{hrefAttr} target="#{data.linkTarget || '_self'}"><img style="width: #{data.width || 'auto'}" src="#{data.src}"></a></div>"""

        hero: (data) ->
            return null unless data.src
            hrefAttr = if data.link then "href='#{data.link}'" else ''

            # Snabbdom virtualize doesn't handle colons in style attributes very well.
            imgSrc = data.src.replace(/^https?:/ig, '')
            res = "<div class=\"apc-hero\" style=\"background-image: url(#{imgSrc})\">"
            if data.heading
                res += "<h1>#{data.heading}</h1>"
            if data.subheading
                res += "<h2>#{data.subheading}</h2>"
            res += "</div>"

            return res

        video: (data) ->
            return null unless (code = data.embedCode)
            code = code.replace(/height=[\"\'][^\'\"]*[\"\']/, '')
            code = code.replace(/width=[\"\'][^\'\"]*[\"\']/, '')
            return """<div class="appcues-responsive-embed">#{code}</div>"""

        form: (data) ->
            data.html or null

        pacman: (data) ->
            """
            <div style="text-align: center">
                <iframe src="#{window.__env.SITE_ORIGIN}/pacman"
                    height="660px" width="510px" />
            </div>"""


        social: (data) ->
            return null
    }

    return {
        renderComponent: (data, options={})->
            if data instanceof Backbone.Model
                data = data.toJSON()

            {isEditable} = options
            render = renderers[data.componentType]
            if render?
                render(data)
            else if isEditable
                defaultTemplate()
            else
                null

        renderComponents: (components, options) ->
            return components.map((component) ->
                ComponentRenderer.renderComponent(component, options)
            ).join('')
    }


module.exports = ComponentRenderer
