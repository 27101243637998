BaseView = require 'views/base'
FormFieldView = require 'views/form-fields/field'
auth = require 'models/auth'

module.exports = class FormFieldsView extends BaseView
    __name__: 'FormFieldsView'
    template: require 'views/templates/form-fields'

    events:
        'click [data-action=add-field]': 'addField'

    initialize: (options={}) ->
        {@collection, @isEditable} = options
        if @isEditable
            @collection?.whenSyncd =>
                @listenTo @collection, 'add remove sort', ->
                    @render()

    addField: ->
        @collection.add {}

    _addFieldView: (model) ->
        view = new FormFieldView
            model: model
            isEditable: @isEditable
        @renderChild(view)
        @$('.fields').append(view.el)

    getContext: ->
        isEditable: @isEditable

    render: ->
        @removeChildViews()
        super

        @collection.each (model) => @_addFieldView(model)

        if @isEditable
            new Sortable(@$('div.fields').get(0), {
                draggable: '.field'
                handle: '.field-drag-handle'
                onUpdate: (evt) =>
                    @updateOrder()
            })
        @

    updateOrder: ->
        @$('div.fields').find('.field').each (i, field) =>
            fieldId = $(field).data('field-id')
            @collection.get(fieldId).set('fieldNumber', i)
        @collection.sort()

