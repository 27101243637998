ModalView = require 'views/modal'
helpers = require 'lib/helpers'
crxHelperModel = require 'models/crx-helper'
auth = require 'models/auth'
RuleModel = require 'models/rule-firebase'

module.exports = class CreateContentWithDomainView extends ModalView
    __name__: 'CreateContentWithDomainView'
    className: 'create-content-with-domain modal fade'
    template: require 'views/templates/create-content-with-domain'

    events:
        'click [data-action=done]': 'done'
        'change [name=chooseDomain]': 'getDomain'
        'click [data-action=close]': 'close'

    initialize: (options={})->
        super
        @domains = options.domains
        @installed = options.installed
        @contentKey = options.contentKey

        if @contentKey
            @rule = new RuleModel(id: @contentKey)

        if not @domains?[0]
            @selectizeDomains = {}
        else
            @selectizeDomains = @domains.map((x) -> { domain: x })

    getContext: ->
        domains: @domains
        installed: @installed

    render: ->
        super

        $select = @$('[name=chooseDomain]').selectize
            create: true
            maxItems: 1
            options: @selectizeDomains
            labelField: "domain"
            valueField: "domain"
            searchField: ["domain"]
        selectize = $select[0].selectize

        # user input is saved even if they don't click
        # selectize 'add'
        selectize.on 'type', (input) =>
            @input = input


    getDomain: (evt) ->
        @setDomain(evt.currentTarget.value)

    setDomain: (url) ->
        domain = Fuzzyurl.fromString url
        domain.protocol = 'http' unless domain.protocol?
        @domain = domain.toString()
        if not @domains.includes(@domain)
            auth.currentAccount.addDomain(@domain)
        if @rule
            @rule.set 'previewUrl', @domain
            @rule.set 'where', '/'
            @rule.updateWhereHash(@rule)
        return @domain

    done: (evt) ->
        unless @domain
            @domain = @setDomain(@input)

        crxHelperModel.designAtUrlOrShowInstallModal @domain, @contentKey


    close: ->
        # Reset the url, fade out the overlay and remove the view when we're done.
        router = require 'routers/main'
        _.delay(=>
            @remove()
            $(".modal-backdrop.in").hide()
            $('body').removeClass('modal-open')
        , 100)
