ModalView = require 'views/modal'

defaultOptions =
    backdrop: true
    keyboard: true
    show: false

module.exports = class ModalContentListView extends ModalView
    __name__: 'ModalContentListView'
    className: 'modal fade'
    template: require 'views/templates/modal-content-list'

    initialize: (options={}) ->
        {@title, @contentList} = options
        @modalOptions = _.extend {}, defaultOptions, options
        Backbone.$('body').append @el
        @$el.on 'hidden.bs.modal', _.bind(@remove, @)

    getContext: ->
        return _.extend {@contentList}, super

    render: ->
        @$el.html(@template(@getContext()))
            .modal(@modalOptions)

    setContent: (contentList=[]) ->
        @contentList = contentList
        @render()
