auth = require 'models/auth'
redactorPlugins = require 'lib/redactor-plugins'
_.extend (@RedactorPlugins ?= {}), redactorPlugins

standardButtons = [
    'bold'
    'italic'
    'underline'
    'fontcolor'
    'backcolor'

    'formatting'

    'alignment'
    'outdent'
    'indent'

    'unorderedlist'
    'orderedlist'
    'horizontalrule'

    'link'
    'filepicker'

    'html'
]

videoButtons = [
    'video'

    'bold'
    'italic'
    'underline'
    'link'
    'fontcolor'
    'backcolor'
    'filepicker'

    'alignment'

    'unorderedlist'
    'orderedlist'
    'outdent'
    'indent'
    'horizontalrule'

    'formatting'

    'html'
]

defaultOptions =
    # This allows the 'style' tag.
    deniedTags: ['html', 'head', 'body', 'meta', 'script', 'applet']
    buttons: standardButtons
    replaceDivs: false
    buttonSource: true
    toolbarFixed: false


initializeRedactor = (element, options) ->
    if element.data('redactor-initialized')
        element.redactor 'core.destroy'

    element.redactor options
    element.data('redactor-initialized', true)


module.exports =
    simple: (target, options={}) ->
        opts = _.extend {plugins: ['fontcolor', 'userdata']}, defaultOptions, options
        initializeRedactor($(target), opts)

    standard: (target, options={}) ->
        opts = _.extend {plugins: ['fontcolor', 'filepicker', 'userdata']}, defaultOptions, options
        initializeRedactor($(target), opts)

    video: (target, options) ->
        opts = _.extend {plugins: ['fontcolor', 'filepicker', 'video', 'userdata']}, defaultOptions, {buttons: videoButtons}, options
        initializeRedactor($(target), opts)

    remove: (target) ->
        $el = $ target
        if $el.data('redactor-initialized')
            $el.redactor 'core.destroy'
