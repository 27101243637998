AppView = require 'views/app'
auth = require 'models/auth'
StripeMixin = require 'views/mixins/stripe'
trackEvent = require 'lib/track-event'

TRIAL_EXTENSION_DAYS = 5

class ExtendTrialView extends AppView
    __name__: 'ExtendTrialView'
    template: require 'views/templates/extend-trial'

    events:
        'click .add-card': 'onAddCard'

    onAddCard: ->
        @addCard ->
            deferred = $.Deferred()

            # If we now have a stripeId, let's extend their trial.
            createdAt = moment auth.currentAccount.get('createdAt')
            daysFromNow = moment().add TRIAL_EXTENSION_DAYS, 'days'
            newTrialLength = daysFromNow.diff(createdAt, 'days') + 1
            auth.currentAccount.set 'trialLengthDays', newTrialLength
            auth.currentAccount.set 'isTrialExpired', false
            auth.currentAccount.set 'trialExtended', true
            # Let's also track an event for this.
            if __env.TRACKING_ENABLED
                data =
                    accountId: auth.currentAccount.id
                    email: auth.user.get 'email'
                    userId: auth.id
                trackEvent 'Extended trial', data, ->
                    deferred.resolve()
            else
                deferred.resolve()

            return deferred

    getContext: ->
        isTrial: auth.currentAccount.get 'isTrial'
        isTrialExpired: auth.currentAccount.get 'isTrialExpired'
        trialExtended: auth.currentAccount.get 'trialExtended'
        hasStripeAccount: not _.isEmpty auth.currentAccount.get('stripeId')
        trialExtensionDays: TRIAL_EXTENSION_DAYS
        trialExpiryReadable: moment(auth.currentAccount.get('createdAt')).add(auth.currentAccount.get('trialLengthDays'), 'days').format("MMMM Do")

_.extend ExtendTrialView::, StripeMixin
module.exports = ExtendTrialView
