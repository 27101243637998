BaseView = require 'views/base'
auth = require 'models/auth'


module.exports = class RuleDomainsView extends BaseView
    __name__: 'RuleDomainsView'
    className: 'rule-domains'
    template: require 'views/templates/build-steps/settings/rule-domains'

    events: ->
        'change [name=toggleDomains]': 'toggleAllDomains'
        'change [name=domains]': 'saveDomains'

    getContext: ->
        rule: @model.toJSON()
        domains: @getAllDomains()
        ruleHasDomains: !_.isEmpty(@getRuleDomains())
        accountHasDomains: !_.isEmpty(@getAllDomains())
        isPublished: @model.get('published')

    getAccountDomains: ->
        return [] unless auth.currentAccount.get('domains')
        _.uniq(auth.currentAccount.get('domains')?.split(',') ? [])

    getRuleDomains: ->
        return [] unless @model.get('domains')
        _.uniq(@model.get('domains')?.split(',') ? [])

    getAllDomains: ->
        ruleDomains = @getRuleDomains()
        domains = _.uniq @getAccountDomains().concat(ruleDomains)
        _(domains.sort()).map (domain) ->
            name: domain
            selected: domain in ruleDomains

    toggleAllDomains: ->
        enableAll = @$('[name=toggleDomains]:checked').attr('value') is 'all'

        if enableAll
            @$('[name=domains]').prop('checked', false)
            @$('.select-domains').hide()
        else
            @$('.select-domains').show()

        @saveDomains()

    saveDomains: ->
        domains = []
        @$('.select-domains [name=domains]').each (i, el) ->
            domains.push(el.value) if el.checked
        @model.set 'domains', domains.join(',')
        if domains.length > 0
            lytics.trackWithUser 'Targeted whitelisted domains',
                ruleId: @model.id

