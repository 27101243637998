{firebaseRef} = require 'lib/firebase'
urls = require 'lib/urls'

module.exports = class ContentStatusModel extends Backbone.Firebase.Model

    # we won't ever be changing the content-status model, the cloud function owns that.  so short circuit doing so to avoid...
    # FIREBASE WARNING: update at /accounts/${accountId}/content-status/${flowId} failed: permission_denied
    _listenLocalChange: () ->

    initialize: (attributes) ->
        {@accountId, @id} = attributes
        @once 'sync', ->
            @syncd = true

    url: ->
        firebaseRef.child "accounts/#{@accountId}/content-status/#{@id}"
