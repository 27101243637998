auth = require 'models/auth'
BaseComponentView = require 'views/components/base'
{CLOUDINARY_ACCOUNT_ID, CLOUDINARY_API_KEY} = window.__env


module.exports = class HeroComponentView extends BaseComponentView
    __name__: 'HeroComponentView'
    className: 'component-editor hero-component image-component appcues-style'
    template: require 'views/templates/components/hero'

    events: ->
        _.extend {}, super, {
            'input [name=heading]': 'update'
            'input [name=subheading]': 'update'
            'click .apc-img-wrap a': 'returnFalse'
            'click [data-action=resetForm]': 'resetForm'
        }

    initialize: ->
        super
        imageUrl = @model.get('src')
        @viewState = new Backbone.Model {
            error: null
            rendered: false
            uploading: false
            uploadPercent: null
            rawImageUrl: imageUrl
            uploadedImageUrl: imageUrl
            heading: @model.get('heading')
            subheading: @model.get('subheading')
        }
        @listenTo @viewState, 'change', (model, options={}) ->
            @render() unless options.skipRender

    getContext: ->
        _.extend {}, super, @viewState.toJSON()

    render: ->
        super
        # Prevent the settings from jumping too much.
        @$('.apc-img-wrap').css('min-height', @$el.height() - @$('.settings').height())
        @setupDropArea() if @$('.drop-area').length
        @viewState.set('rendered', true) unless @viewState.get('rendered')
        @

    updateValue: ->
        @model.set {
            src: @viewState.get('uploadedImageUrl')
            rawSrc: @viewState.get('rawImageUrl')
            heading: @viewState.get('heading') or null
            subheading: @viewState.get('subheading') or null
        }

    update: (evt) ->
        $el = @$(evt.currentTarget)

        data = {}
        data[$el.attr('name')] = $el.text()

        @viewState.set data, {skipRender: true}

    setupDropArea: ->
        $dropArea = @$('.drop-area').addClass('dropzone')
        @dropzone = new Dropzone($dropArea.get(0), {
            acceptedFiles: 'image/*'
            clickable: $dropArea.find('.dropzone-btn').get(0)
            previewsContainer: false
            uploadMultiple: false
            url: "https://api.cloudinary.com/v1_1/#{CLOUDINARY_ACCOUNT_ID}/image/upload"
        })
        @dropzone.on 'dragover', (evt) ->
            $dropArea.find('.message').text('Drop to upload')
        @dropzone.on 'dragenter', (evt) ->
            $dropArea.find('.message').text('Drop to upload')
        @dropzone.on 'dragend', (evt) ->
            $dropArea.find('.message').text('Drag an image here')
        @dropzone.on 'dragleave', (evt) ->
            $dropArea.find('.message').text('Drag an image here')
        @dropzone.on 'sending', (file, xhr, formData) =>
            formData.append 'api_key', CLOUDINARY_API_KEY
            formData.append 'timestamp', (Date.now() / 1000 | 0)

            # Tag the image with the account ID and user ID.
            accountId = auth.currentAccount.id
            userId = auth.id
            formData.append 'tags', ["account-#{accountId}", "user-#{userId}"].join(',')
            formData.append 'folder', accountId

            # Transforming GIFs can have unintended consequences for Cloudinary,
            # like causing the GIF to become to large if it's scaled up, or dropping
            # frames if a transformation doesn't support the full resolution.
            if file.type is 'image/gif'
                uploadPreset = 'gif_preset'
            else
                uploadPreset = 'sdruqnxi'

            formData.append 'upload_preset', uploadPreset
            @viewState.set {uploading: true}
        @dropzone.on 'uploadprogress', (evt, percentage) =>
            @viewState.set {uploadPercent: percentage}
        @dropzone.on 'success', (file, response) =>
            processedUrl = rawUrl = response.secure_url
            if response.eager?
                processedUrl = response.eager[0]?.secure_url

            @viewState.set {
                error: null
                uploading: false
                uploadPercent: null
                rawImageUrl: rawUrl
            }
            # We used the unprocessed image URL to render immediately, but we
            # want to save the process/resized URL for future use.
            @viewState.set {uploadedImageUrl: processedUrl}, {silent: true}

    resetForm: ->
        @viewState.set {
            uploading: false
            uploadPercent: null
            uploadedImageUrl: null
            rawImageUrl: null
        }

    returnFalse: (evt) -> return false
