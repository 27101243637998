BaseView = require 'views/base'
CodeSnippetView = require 'views/code-snippet'


module.exports = class InstallInstructionsView extends BaseView
    __name__: 'InstallInstructionsView'
    className: 'install-instructions'
    template: require 'views/templates/install-instructions'

    initialize: (options={}) ->
        {@accountId, @apiKey} = options
        @selected = 'nothing'

    render: ->
        @removeChildViews()
        super
        view = new CodeSnippetView {@accountId, @apiKey}
        @renderChild view
        @$('.code-snippet').append view.el
