{firebase} = require 'lib/firebase'
helpers = require 'lib/helpers'
analytics = require 'lib/analytics'
BaseView = require 'views/base'
auth = require 'models/auth'
userId = auth.get 'id'
FlowModel = require 'models/flow'
HotspotGroupModel = require 'models/hotspot-group'
FlowsCollection = require 'collections/flows'
AnnouncementsCollection = require 'collections/announcements'
RulesCollection = require 'collections/rules'
AggregateCollection = require 'lib/aggregate-collection'
ContentGroupView = require 'views/content-list/group'
CategoryAreasView = require 'views/content-list/category-areas'
CONTENT_STATES = require 'lib/content-states'
{getRouterContentTypeForStepType} = require 'lib/flow-helpers'


module.exports = class ContentListView extends BaseView
    __name__: 'ContentListView'
    className: 'content-list'
    template: require 'views/templates/content-list/content-list'
    events:
        'click .clear-filters': 'clearFilterState'

    initialize: (options={}) ->
        {
            @filterState
            @journeysCollection
            @flowsCollection
            @announcementsCollection
            @hotspotGroupsCollection
            @coachmarkGroupsCollection
            @rulesCollection
            @accountMembersCollection
            @categories
            @clearFilterState
        } = options

        @listenTo @filterState, 'change', @render
        @listenTo @accountMembersCollection, 'sync', @render

        @categories.whenSyncd =>
            @listenTo @categories, 'sync change remove', @render
            @render()

        # This is an aggregate-collection of all the content
        @collection = new AggregateCollection
            collections:
                journey: @journeysCollection
                announcement: @announcementsCollection
                flow: @flowsCollection
                hotspotGroup: @hotspotGroupsCollection
                coachmarkGroup: @coachmarkGroupsCollection
            comparator: (model1, model2) -> if model1.get('updatedAt') > model2.get('updatedAt') then -1 else 1

        auth.onAuth =>
            # Rerender on important auth changes.
            @listenTo auth.user, 'change:id', @render
            @listenTo auth.currentAccount, 'change:id', @render

        @collection.whenSyncd =>
            @render()
            @listenTo @collection, 'change:published change:state remove add categorize', @render

        # Remove rule when content is removed
        @rulesCollection.whenSyncd =>
            @listenTo @collection, 'remove', (content) ->
                rule = @rulesCollection.get content.get('id')
                @rulesCollection.remove(rule) if rule

    render: ->
        @removeChildViews()
        super

        if @_hasContent() and not @_isLoading()
            groupedContent = @groupedContent()
            categoryTotals = @getCategoryTotals()
            @categories.each (category) =>
                groupInfo = {
                    name: category.get('name')
                    totals: categoryTotals[category.id]
                }
                @renderContentGroup(groupInfo, groupedContent[category.id], category.id)
            @renderContentGroup({
                name: 'Uncategorized'
                totals: categoryTotals[null]
            }, groupedContent[null], 'uncategorized')
            @renderCategoryAreas()

            if @_isFiltered()
                @$el.append $ """
                    <div class="text-center text-caption">Some flows may be filtered
                        <a class="clear-filters">(clear filters)</a></div>
                    """

        return @

    getCategoryTotals: ->
        totals = {}
        @collection.forEach (content) ->
            isChildFlow = content.get('type') != 'journey' and !!content.get('parentId')
            return if isChildFlow
            totals[content.get('category')] ?= {
                total: 0
                archived: 0
                active: 0
            }

            if content.get('state') is CONTENT_STATES.ARCHIVED
                totals[content.get('category')].archived += 1
            else
                totals[content.get('category')].active += 1

            totals[content.get('category')].total += 1

        return totals

    renderContentGroup: (info, models, categoryId) ->
        return unless @collection.syncd
        view = new ContentGroupView
            model: new Backbone.Model info
            collection: new Backbone.Collection models
            filterState: @filterState
            isFiltered: @_isFiltered()
            journeysCollection: @journeysCollection
            announcementsCollection: @announcementsCollection
            flowsCollection: @flowsCollection
            hotspotGroupsCollection: @hotspotGroupsCollection
            coachmarkGroupsCollection: @coachmarkGroupsCollection
            rulesCollection: @rulesCollection
            accountMembersCollection: @accountMembersCollection
            categoryId: categoryId

        @renderChild view
        @$el.append view.el

    renderCategoryAreas: ->
        view = new CategoryAreasView
            categories: @categories
            content: @collection
        @renderChild view
        @$el.append view.el

    filteredContent: ->
        filterState = @filterState.toJSON()
        contentStatus = filterState.state

        content = []
        switch filterState.type
            when 'journeys'
                # Only multi-step journeys
                content.push @journeysCollection.models.filter((journey) -> _.size(journey.get("steps")) > 1)...
                break
            when 'flows'
                # Old-style modals and single-step journeys where the first step is a modal.
                content.push @flowsCollection.models...
                content.push @journeysCollection.models.filter((journey) ->
                    (_.size(journey.get("steps")) is 1) and getRouterContentTypeForStepType(journey.getStepAt(0)?.stepType) is 'flows'
                )...
                break
            when 'slideouts'
                # Old-style slideouts and single-step journeys where the first step is a slideout.
                content.push @flowsCollection.where({ patternType: 'shorty' })...
                content.push @journeysCollection.models.filter((journey) ->
                    (_.size(journey.get("steps")) is 1) and getRouterContentTypeForStepType(journey.getStepAt(0)?.stepType) is 'slideouts'
                )...
                break
            when 'hotspotGroups'
                # Old-style hotspot-groups and single-step journeys where the first step is a hotspot-group.
                content.push @hotspotGroupsCollection.where({sequential: false})...
                content.push @journeysCollection.models.filter((journey) ->
                    (_.size(journey.get("steps")) is 1) and getRouterContentTypeForStepType(journey.getStepAt(0)?.stepType) is 'hotspots'
                )...
                break
            when 'tooltipGroups'
                # Old-style tooltip-groups and single-step journeys where the first step is a tooltip-group.
                content.push @hotspotGroupsCollection.where({sequential: true})...
                content.push @journeysCollection.models.filter((journey) ->
                    (_.size(journey.get("steps")) is 1) and getRouterContentTypeForStepType(journey.getStepAt(0)?.stepType) is 'tooltips'
                )...
                break
            when 'coachmarkGroups'
                # Coachmarks are never part of a journey.
                content.push @coachmarkGroupsCollection.models...
                break
            when 'announcements'
                # Announcements are never part of a journey.
                content.push @announcementsCollection.models...
                break
            else
                content.push @journeysCollection.models...
                content.push @announcementsCollection.models...
                content.push @flowsCollection.models...
                content.push @hotspotGroupsCollection.models...
                content.push @coachmarkGroupsCollection.models...

        content = _.filter content, (item) ->
            nameFilter = filterState.nameFilter
            contentStatus = filterState.state
            isArchived = item.get('state') is CONTENT_STATES.ARCHIVED
            isPublished = item.get('published') is true
            isJourneyChild = item.get('parentId')
            isTestFlow = item.get('testVersionOf')
            isValidStatus = true
            isValidName = true

            # Status filtering.
            if contentStatus is 'archived'
                isValidStatus = isArchived
            else if contentStatus is 'published'
                isValidStatus = isPublished and not isArchived
            else if contentStatus is 'draft'
                isValidStatus = not isPublished and not isArchived
            else
                # Don't show archived unless explicitly asked.
                isValidStatus = not isArchived

            if isTestFlow
                isValidStatus = false

            # Name search.
            if nameFilter
                isValidName = item.get('name').toLowerCase().match(nameFilter.toLowerCase())

            return isValidStatus and isValidName and not isJourneyChild

        switch filterState.sortBy
            when 'default'
                content = _.sortBy content, (item) ->
                    sortVal = -(item.get('updatedAt') or item.get('createdAt') or 0)
                    # Prioritize published flows.
                    if item.get('published')
                        sortVal = sortVal * 10
                    return sortVal
                break
            when 'updatedAt'
                content = _.sortBy content, (item) -> -(item.get('updatedAt') or item.get('createdAt') or 0)
                break
            when 'createdAt'
                content = _.sortBy content, (item) -> -(item.get('createdAt') or 0)
                break
            when 'name'
                content = _.sortBy content, (item) -> item.get('name')
                break
            when 'users'
                content = _.sortBy content, (item) -> -(item.get('totalUniqueViews') or 0)
                break

        return content

    groupedContent: ->
        return {} unless @categories.syncd
        categoryIds = @categories.map (category) -> category.id
        _.groupBy @filteredContent(), (item) ->
            if item.get('category') in categoryIds
                return item.get('category')
            else
                item.set('category', null)
                return null

    getContext: ->
        return {
            hasContent: @_hasContent()
            isFiltered: @_isFiltered()
            loading: @_isLoading()
            categories: @categories.toJSON()
            filterState: @filterState.toJSON().state
            createView: @createView
        }

    _isLoading: ->
        not (@collection.syncd and auth.user.syncd and auth.currentAccount.syncd and @categories.syncd and @accountMembersCollection.syncd)

    _hasContent: ->
        @filteredContent().length isnt 0

    _isFiltered: ->
        f = @filterState.toJSON()
        f.nameFilter or (f.type and f.type isnt 'all') or (f.state and f.state isnt 'any')

