InstallGuideView = require 'views/install-guide'
CodeSnippetView = require 'views/code-snippet'


module.exports = class InstallGuideTraditionalView extends InstallGuideView
    __name__: 'InstallGuideTraditionalView'
    className: 'install-guide-traditional'
    template: require 'views/templates/install-guide-traditional'

    events:
        'click #next' : 'showVerify'
        'click a.choose-install-type' : 'selectInstallType'
        'click .panel-choose-install' : 'chooseInstallType'
        'click [data-action=inviteUser]' : 'showInviteUserModal'

    render: ->
        # Code snippet view.
        super
        @installType = 'Basic Javascript'
        view = new CodeSnippetView {@accountId}
        @renderChild view
        @$('.code-snippet').append view.el
        @$('#instructions').show()
        @$('#installChoice').removeClass('in')
