{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'

module.exports = class ViewsCollection extends Backbone.Firebase.Collection
    firebasePath: ""

    initialize: (models, options={}) ->
        @accountId = auth?.currentAccount?.id
        @url = firebaseRef.child "accounts/#{@accountId}/views/users"

        @once 'sync', -> @syncd = true

    getUserView: (userId) ->
        @get(userId)?.attributes
