module.exports =
    applyGlobalStyling: (options={}) ->
        if options.accountId?
            GlobalStylingModel = require 'models/style'
            @globalStyling = new GlobalStylingModel options
        else
            @globalStyling = new Backbone.Model(options.stylingData or {})

        @listenTo @globalStyling, 'change:globalCss', =>
            @$('.appcues-global-styling').text(@globalStyling.get 'globalCss')

    updateGlobalStyling: (stylingData) ->
        @globalStyling.set stylingData
