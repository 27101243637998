auth = require 'models/auth'
firebase = require 'lib/firebase'
BaseCoachmarkGroupModel = require 'models/coachmark-group-base'


CoachmarkGroupModel = _.extend {}, BaseCoachmarkGroupModel, {
    initialize: (attributes, options={}) ->
        BaseCoachmarkGroupModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        firebase.url + "/accounts/#{accountId}/coachmark-groups"
}

module.exports = Backbone.Firebase.Model.extend CoachmarkGroupModel

