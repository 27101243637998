StepActionView = require 'views/step-actions/step-action'
ComponentPreviewView = require 'views/components/preview'

{renderComponents} = require 'lib/component-renderer'
auth = require 'models/auth'


module.exports = class StepActionComponentsView extends StepActionView
    __name__: 'StepActionComponentsView'
    template: require 'views/templates/step-actions/components'

    events: ->
        _.extend super, {
            'click [data-action=showComponentTypes]': 'showComponentTypes'
            'mouseleave .add-component': 'hideComponentTypes'
            'click [data-action=addComponent]': 'addComponent'
            'mouseleave .colorpicker': 'hideColorpicker'
            'mouseleave .hover-controls': 'saveEditableValue'
            'change .color-picker-button-container .editableValue': 'onChangeColorHex'
            'click [data-action=removeButton]': 'removeButton'
            'click [data-action=addButton]': 'addButton'
        }

    initialize: (options={}) ->
        super
        @components = @model.getComponents()
        @buttons = @model.getButtons()

        @listenTo @buttons, 'change:isVisible add remove', (model, options) ->
            @render()

        @listenTo @model, 'change:components', @setContent
        @listenTo @components, 'add', (component) ->
            @addComponentView component
            @checkEmptyState()
        @listenTo @components, 'remove', (component, collection, options) ->
            @removeComponentView options.index
            @checkEmptyState()
        @listenTo @components, 'change', (model, options) ->
            @render() unless options.skipRender

    render: ->
        @removeChildViews()
        @leftButtons = @buttons.getLeftButtons()
        @rightButtons = @buttons.getRightButtons()

        super
        @components.each (component) => @addComponentView component, false
        @checkEmptyState()
        @initColorPicker()

        new Sortable @$('.components').get(0), {
            handle: '.component-drag-handle'
            onUpdate: (evt) =>
                @components.models.splice(evt.newIndex, 0, @components.models.splice(evt.oldIndex, 1)[0])
                @components.trigger('update')
                # Keep childViews array in sync too, so we can remove later
                @_childViews.splice(evt.newIndex, 0, @_childViews.splice(evt.oldIndex, 1)[0])
                auth.user.set('firstUsedComponents', auth.user.get('firstUsedComponents') ? Date.now())
                lytics?.trackWithUser 'Reordered step components', {componentCount: @components.length}
        }

    initColorPicker: ->
        @$('.color-picker-button').colorpicker({ container: true })
            .on('showPicker.colorpicker', (e) =>
                @$(e.target)
                    .next('.editableValue')
                    .addClass('show')
            )
            .on('changeColor.colorpicker', (e) =>
                $el = @$(e.target)
                attributeValue= e.color.toHex()

                @setButtonAttributeFromElement($el, 'color-attribute', attributeValue)
            )

    saveEditableValue: (e) ->
        @$('.editableValue.show').each (i, editableValue) =>
            $editableValue = $(editableValue)
            attributeValue = $editableValue.text()

            @setButtonAttributeFromElement($editableValue, 'color-attribute', attributeValue)
            $editableValue.removeClass('show')

    onChangeColorHex: (e) ->
        $el = @$(e.target)
        attributeValue = $el.text()

        @setButtonAttributeFromElement($el, 'color-attribute', attributeValue)

    getButtonFromElement: ($el) ->
        $button = $el.parents('[data-button-cid]').first()
        buttonCID = $button.data('button-cid')
        button = @model.stepButtons.get(buttonCID)
        return { button: button, $button: $button }

    setButtonAttributeFromElement: ($el, attributeType, attributeValue) ->
        { button, $button, attributeName } = @getButtonFromElement($el)
        attributeName = $button.data(attributeType)

        buttonStyle = JSON.parse(button.get('style'))
        buttonStyle ?= {}
        buttonStyle[attributeName] = attributeValue
        @model.setButtonStyle(button.cid, JSON.stringify(buttonStyle))

        $button = @$(".step-button[data-button-cid=#{button.cid}]").first()
        $button.css(attributeName, attributeValue)

        $editableValue = $button.next('.hover-controls').find("[data-color-attribute=#{attributeName}] .editableValue").first()
        $editableValue.text(attributeValue)

    removeButton: (e) ->
        $el = @$(e.target)
        { button, $button } = @getButtonFromElement($el)
        @model.setButtonVisible(button.cid, false)

    addButton: (e) ->
        $el = @$(e.target)
        type = $el.data('type')
        alignment = $el.data('alignment')
        defaultText = $el.data('default-text')

        @model.createButton(type, alignment, defaultText)

    hideColorpicker: ->
        @$('.color-picker-button').colorpicker('hide')

    showComponentTypes: (evt) ->
        @$('.add-component').addClass('open')

    hideComponentTypes: (evt) ->
        @$('.add-component').removeClass('open')

    addComponent: (evt) ->
        @hideComponentTypes()
        componentType = @$(evt.currentTarget).attr('data-component-type') || 'editor'
        @components.add {componentType: componentType}
        auth.user.set('firstUsedComponents', auth.user.get('firstUsedComponents') ? Date.now())
        lytics?.trackWithUser 'Added a flow step component', {componentType: componentType, componentCount: @components.length}

    addComponentView: (component, openEditor=true) ->
        v = new ComponentPreviewView {model: component, isEditable: @isEditable}
        @renderChild v
        @$('.components').append(v.el)
        v.edit() if openEditor

    removeComponentView: (index) ->
        view = @_childViews.splice(index, 1)[0]
        view.remove()
        auth.user.set('firstUsedComponents', auth.user.get('firstUsedComponents') ? Date.now())
        lytics?.trackWithUser 'Removed a flow step component', {}

    checkEmptyState: ->
        if @components.length is 0
            @$('.empty-state').show()
        else
            @$('.empty-state').hide()

    setContent: ->
        @model.set('content', renderComponents(@components, isEditable: false))

    getContext: ->
        _.extend(@model.toJSON(), {@isEditable, @isFirst, @isLast, @showPoweredByText, @isTrial, @leftButtons, @rightButtons, @hasBetaSDK})

