BaseView = require 'views/base'
analyticsUtils = require 'lib/analytics'
auth = require 'models/auth'
trackEvent = require 'lib/track-event'
{EXTRACTION_API_URL} = window.__env

module.exports = class CSVExportView extends BaseView
    __name__: 'CSVExportView'
    template: require 'views/templates/csv-export'

    events:
        'submit form': 'sendCSV'

    initialize: (content) ->
        @model = new Backbone.Model({
            stepId: content.get('id')
            parentId: content.get('parentId')
            createdAt: content.get('createdAt')
            showCSVMigrationBanner: auth.currentAccount.get("createdAt") < new Date("2017-04-19").getTime()
        })
        @listenTo @model, 'change:sent change:sending', @render

    getContext: -> @model.toJSON()

    sendExtractionCsv: (options) ->
        data = {
            account_id: options.accountId,
            start_date: options.startDate,
            filter: {
                step_id: options.stepId
            },
            email: options.email
        }

        $.ajax({
            url: EXTRACTION_API_URL,
            method: 'POST',
            contentType: "application/json",
            headers: {
                'Authorization': "Basic " + btoa("#{auth.currentAccount.get('uuid')}:")
            },
            data: JSON.stringify(data),
            error: (jqxhr, status, error) =>
                Raven.captureException err
                @model.set 'error', true
                @model.set 'sending', false
            success: (data, status) =>
                @model.set {
                    sent: Date.now()
                }
                @model.set 'sending', false
        })

    sendCSV: ->
        $form = @$ 'form'
        {email, raw} = $form.serializeObject()
        isRaw = raw is 'on'

        # Update the UI state.
        @model.set {
            email
            isRaw
            sending: true
            error: false
        }

        options = {
            accountId: auth.currentAccount.id
            uuid: auth.currentAccount.get('uuid')
            email: email
            isRaw: isRaw
            stepId: @model.get('stepId')
            parentId: @model.get('parentId')
        }

        # Track
        try
            trackEvent 'Requested CSV export', {
                to_email: options.email
                stepId: options.stepId
                parentId: options.parentId
                isRaw: options.isRaw
                accountId: options.accountId
            }

        createdAt = @model.get('createdAt')
        # createdAt minus one day's worth of milliseconds
        # this is easier than finagling with the Date object to get it in
        # UTC, and guarantees no data is left out
        oneDayInMs = 8.64e+7
        createdAtDate = new Date(createdAt - oneDayInMs)
        options.startDate = createdAtDate.toISOString().split('T')[0]
        @sendExtractionCsv options
        return false
