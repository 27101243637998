BaseView = require 'views/base'
FlowsCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
auth = require 'models/auth'
CoachmarkGroupsCollection = require 'collections/coachmark-groups'
helpers = require 'lib/helpers'


module.exports = class RuleRedirectView extends BaseView
    __name__: 'RuleRedirectView'
    className: 'rule-redirect'
    template: require 'views/templates/build-steps/settings/rule-redirect'

    events:
        'change [name=pageRedirect]': 'togglePageRedirect'
        'change [name=contentTrigger]': 'toggleContentTrigger'
        'change [name=redirectUrl]': '_updateRedirectValue'
        'change [name=redirectNewTab]': '_updateRedirectNewTabValue'
        'change [name=nextContent]': '_updateNextContentValue'

    defaultScrollPadding: 15

    scrollElementIntoView: (el, padding = 0) ->

        # scrolls the page such that the jquery element provided is fully in view

        targetHeight = el.height()
        pageYOffset = $(window).scrollTop()
        targetYOffset = el.offset().top
        clientHeight = $(window).height()

        lowestVisibleYPosn = pageYOffset + clientHeight
        targetBottom = targetYOffset + targetHeight + padding

        if(targetBottom > lowestVisibleYPosn)
            $("html, body").animate({ scrollTop: (targetBottom - clientHeight) + "px" }, "slow")

    initialize: (options={}) ->
        {@rule, @flow} = options
        @viewState = new Backbone.Model {
            nextContentDraftWarning: false
            showNextContent: false
            showRedirectUrl: false
        }
        @listenTo @viewState, 'change', @render

        @flows = new FlowsCollection()
        @hotspotGroups = new HotspotGroupsCollection()
        @coachmarkGroups = new CoachmarkGroupsCollection()
        @flows.whenSyncd => @render()
        @hotspotGroups.whenSyncd => @render()
        @coachmarkGroups.whenSyncd => @render()

        # Check if the selected content is published once everything has syncd.
        $.when(@rule.whenSyncd(), @flows.whenSyncd(), @hotspotGroups.whenSyncd(), @coachmarkGroups.whenSyncd()).done =>
            @_updateNextContentWarning()

        @rule.whenSyncd =>
            @_updateSettingFormat()

            @viewState.set {
                showNextContent: !!@rule.get('nextContentId')
                showRedirectUrl: !!@rule.get('redirectUrl')
            }

        @listenTo @rule, 'change:nextContentId change:redirectUrl', (model, options={}) ->
            unless options.skipRender
                @viewState.set {
                    showNextContent: !!@rule.get('nextContentId')
                    showRedirectUrl: !!@rule.get('redirectUrl')
                }
                @render()

    getContext: ->
        rule: @rule.toJSON()
        flows: helpers.filterOutArchived @flows
        hotspotGroups: helpers.filterOutArchived @hotspotGroups
        coachmarkGroups: helpers.filterOutArchived @coachmarkGroups
        siteUrl: auth.currentAccount.get('siteUrl')
        nextContentDraftWarning: @viewState.get('nextContentDraftWarning')
        showNextContent: @viewState.get('showNextContent')
        showRedirectUrl: @viewState.get('showRedirectUrl')
        isPublished: @rule.get('published')

    render: ->
        super

        @$('select[name=nextContent]').selectize({
            closeAfterSelect: true
            selectOnTab: true
            onDropdownOpen: (dropdown) =>
                @scrollElementIntoView(dropdown, @defaultScrollPadding)
            render:
                item: (item, escape) ->
                    if item.text
                        """
                        <div class="content-option title="#{escape item.text}" style="width:98%; padding-right: 20px">
                            <span class="content-name">#{escape item.text}</span>
                            <span class="content-status #{if item.published then 'text-success' else 'text-muted'}">#{if item.published then 'Live' else 'Draft'}</span>
                            <span class="content-id">#{escape item.value}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
                option: (item, escape) ->
                    if item.text
                        """
                        <div class="content-option" title="#{escape item.text}">
                            <span class="content-name">#{escape item.text}</span>
                            <span class="content-status #{if item.published then 'text-success' else 'text-muted'}">#{if item.published then 'Live' else 'Draft'}</span>
                            <span class="content-id">#{escape item.value}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
        })

    togglePageRedirect: (evt) ->
        if @$(evt.currentTarget).is(':checked')
            @viewState.set {showRedirectUrl: true}
        else
            @rule.set {redirectUrl: null}, {skipRender: true}
            @viewState.set {showRedirectUrl: false}

    toggleContentTrigger: (evt) ->
        if @$(evt.currentTarget).is(':checked')
            @viewState.set {showNextContent: true}
            @scrollElementIntoView($('select[name=nextContent] + div.selectize-control'), @defaultScrollPadding)
            @_updateNextContentWarning()
        else
            @rule.set {nextContentId: null}, {skipRender: true}
            @viewState.set {showNextContent: false}

    _updateRedirectValue: (evt) ->
        value = @$(evt.currentTarget).val()
        @rule.set {redirectUrl: value or null}, {skipRender: true}

    _updateRedirectNewTabValue: (evt) ->
        value = @$(evt.currentTarget).is(':checked')
        @rule.set {redirectNewTab: value}, {skipRender: true}

    _updateNextContentValue: (evt) ->
        value = @$(evt.currentTarget).val()
        @rule.set {nextContentId: value or null}, {skipRender: true}
        @_updateNextContentWarning()

    _updateNextContentWarning: ->
        nextContentId = @rule.get('nextContentId')
        if nextContentId
            content = @flows.get(nextContentId) or @hotspotGroups.get(nextContentId) or @coachmarkGroups.get(nextContentId)

        if content? and not content.get('published')
            @viewState.set(nextContentDraftWarning: true)
        else
            @viewState.set(nextContentDraftWarning: false)

    _updateSettingFormat: ->
        # Move the redirectUrl setting onto the rule.
        if @flow?.get('redirectUrl')
            @rule.set('redirectUrl', @flow.get('redirectUrl'))
            @flow.set('redirectUrl', null)

        # Flatten nextContent attribute from old object format
        if @rule.get('nextContent')?.id
            @rule.set {nextContentId: @rule.get('nextContent').id, nextContent: null}

