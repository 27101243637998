auth = require 'models/auth'
{APPCUES_API_URL, APPCUES_API_KEY, APPCUES_API_SECRET} = window.__env
PropertyModel = require 'models/property'

module.exports = class PropertiesModel extends Backbone.Model
    initialize: (attrs, options={}) ->
        @accountId = options.accountId or auth.currentAccount.id
        @once 'sync', -> @syncd = true

    url: ->
        "#{APPCUES_API_URL}/v1/accounts/#{@accountId}/profile_attributes"

    fetch: (options) ->
        opts = _.extend {}, options, {
            beforeSend: (xhr) ->
                authStr = btoa(APPCUES_API_KEY + ":" + APPCUES_API_SECRET)
                xhr.setRequestHeader("Authorization", "Basic #{authStr}")
        }
        super opts

    # Extracts only "custom" properties of the account.
    filterCustomProperties: ->
        _.filter @toJSON(), (v, k) ->
            PropertyModel.isPrivate(k) or
                PropertyModel.isInternal(k)

    filterUsableProperties: ->
        _.omit @toJSON(), (v, k) ->
            PropertyModel.isABProperty(k) or
                PropertyModel.isInternal(k) or
                PropertyModel.isLanguage(k) or
                PropertyModel.isMyAppcuesId(k)
