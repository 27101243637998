ENV = window.__env
CRX_INSTALL_MODAL_ID = '-KXvdcCjv7CkiMVASqH7'
CHROME_INSTALL_MODAL_ID = '-KYGGfDfHu3Lm0BgsPE5'


class CrxHelperModel extends Backbone.Model
    defaults:
        checkedForCrx: false
        hasCrxInstalled: false
        version: null
        crxId: window.__env.CRX_ID

    initialize: ->
        @beginCrxHeartbeat()

    onChrome: ->
        window.chrome && window.chrome.runtime && /Chrome/.test(window.navigator.userAgent)

    beginCrxHeartbeat: ->
        beat = () =>
            messageCrx().done (version) =>
                @set 'version', version
                @set 'hasCrxInstalled', true
            .fail =>
                @set 'hasCrxInstalled', false
                setTimeout beat, 1000
            .always =>
                @set 'checkedForCrx', true

        messageCrx = () =>
            promise = $.Deferred()

            window.chrome.runtime.sendMessage @get('crxId'), { action: "get-version" }, {}, (reply) ->
                if reply && reply.version
                    promise.resolve reply.version

            setTimeout () ->
                # If no response for 3 seconds, ignore possible future response and mark crx uninstalled.
                promise.reject()
            , 3000

            return promise

        @set 'checkedForCrx', false

        if @get('crxId') && @onChrome()
            beat()
        else
            @set 'hasCrxInstalled', false
            @set 'checkedForCrx', true

    designAtUrlOrShowInstallModal: (url, flowKey, options = {}) ->
        # can manually pass in a crxId specification for testing
        if @get 'hasCrxInstalled'
            @_designAtUrl url, flowKey, options
        else
            if @onChrome()
                @showCrxInstallModal()
            else
                @showChromeInstallModal()

    _designAtUrl: (url, flowKey, options={}) ->
        injectionData =
            url: url
            contentKey: flowKey
            options: options

        chrome.runtime.sendMessage @get('crxId'), { action: "add-builder", value: JSON.stringify(injectionData) }

    showCrxInstallModal: ->
        Appcues.show(CRX_INSTALL_MODAL_ID)

    showChromeInstallModal: ->
        Appcues.show(CHROME_INSTALL_MODAL_ID)

crxHelperModel = new CrxHelperModel
module.exports = crxHelperModel
