operatorLookup =
    "==": "is exactly"
    "!=": "isn't exactly"
    ">": "is greater than"
    "<": "is less than"
    "*": "contains"
    "!*": "doesn't contain"
    "^": "starts with"
    "$": "ends with"
    "?": "exists"
    "!?": "doesn't exist"
    "in": "is one of"
    "not in": "isn't one of"
    "> ago": "is more than"
    "< ago": "is less than"
    "within": "will occur within"

languageLookup = [
  {
    'desc': 'Abkhaz'
    'code': 'ab'
  }
  {
    'desc': 'Afar'
    'code': 'aa'
  }
  {
    'desc': 'Afrikaans'
    'code': 'af'
  }
  {
    'desc': 'Akan'
    'code': 'ak'
  }
  {
    'desc': 'Albanian'
    'code': 'sq'
  }
  {
    'desc': 'Amharic'
    'code': 'am'
  }
  {
    'desc': 'Arabic'
    'code': 'ar'
  }
  {
    'desc': 'Aragonese'
    'code': 'an'
  }
  {
    'desc': 'Armenian'
    'code': 'hy'
  }
  {
    'desc': 'Assamese'
    'code': 'as'
  }
  {
    'desc': 'Avaric'
    'code': 'av'
  }
  {
    'desc': 'Avestan'
    'code': 'ae'
  }
  {
    'desc': 'Aymara'
    'code': 'ay'
  }
  {
    'desc': 'Azerbaijani'
    'code': 'az'
  }
  {
    'desc': 'Bambara'
    'code': 'bm'
  }
  {
    'desc': 'Bashkir'
    'code': 'ba'
  }
  {
    'desc': 'Basque'
    'code': 'eu'
  }
  {
    'desc': 'Belarusian'
    'code': 'be'
  }
  {
    'desc': 'Bengali, Bangla'
    'code': 'bn'
  }
  {
    'desc': 'Bihari'
    'code': 'bh'
  }
  {
    'desc': 'Bislama'
    'code': 'bi'
  }
  {
    'desc': 'Bosnian'
    'code': 'bs'
  }
  {
    'desc': 'Breton'
    'code': 'br'
  }
  {
    'desc': 'Bulgarian'
    'code': 'bg'
  }
  {
    'desc': 'Burmese'
    'code': 'my'
  }
  {
    'desc': 'Catalan'
    'code': 'ca'
  }
  {
    'desc': 'Chamorro'
    'code': 'ch'
  }
  {
    'desc': 'Chechen'
    'code': 'ce'
  }
  {
    'desc': 'Chichewa, Chewa, Nyanja'
    'code': 'ny'
  }
  {
    'desc': 'Chinese'
    'code': 'zh'
  }
  {
    'desc': 'Chuvash'
    'code': 'cv'
  }
  {
    'desc': 'Cornish'
    'code': 'kw'
  }
  {
    'desc': 'Corsican'
    'code': 'co'
  }
  {
    'desc': 'Cree'
    'code': 'cr'
  }
  {
    'desc': 'Croatian'
    'code': 'hr'
  }
  {
    'desc': 'Czech'
    'code': 'cs'
  }
  {
    'desc': 'Danish'
    'code': 'da'
  }
  {
    'desc': 'Divehi, Dhivehi, Maldivian'
    'code': 'dv'
  }
  {
    'desc': 'Dutch'
    'code': 'nl'
  }
  {
    'desc': 'Dzongkha'
    'code': 'dz'
  }
  {
    'desc': 'English'
    'code': 'en'
  }
  {
    'desc': 'Esperanto'
    'code': 'eo'
  }
  {
    'desc': 'Estonian'
    'code': 'et'
  }
  {
    'desc': 'Ewe'
    'code': 'ee'
  }
  {
    'desc': 'Faroese'
    'code': 'fo'
  }
  {
    'desc': 'Fijian'
    'code': 'fj'
  }
  {
    'desc': 'Finnish'
    'code': 'fi'
  }
  {
    'desc': 'French'
    'code': 'fr'
  }
  {
    'desc': 'Fula, Fulah, Pulaar, Pular'
    'code': 'ff'
  }
  {
    'desc': 'Galician'
    'code': 'gl'
  }
  {
    'desc': 'Ganda'
    'code': 'lg'
  }
  {
    'desc': 'Georgian'
    'code': 'ka'
  }
  {
    'desc': 'German'
    'code': 'de'
  }
  {
    'desc': 'Greek (modern)'
    'code': 'el'
  }
  {
    'desc': 'Guaraní'
    'code': 'gn'
  }
  {
    'desc': 'Gujarati'
    'code': 'gu'
  }
  {
    'desc': 'Haitian, Haitian Creole'
    'code': 'ht'
  }
  {
    'desc': 'Hausa'
    'code': 'ha'
  }
  {
    'desc': 'Hebrew (modern)'
    'code': 'he'
  }
  {
    'desc': 'Herero'
    'code': 'hz'
  }
  {
    'desc': 'Hindi'
    'code': 'hi'
  }
  {
    'desc': 'Hiri Motu'
    'code': 'ho'
  }
  {
    'desc': 'Hungarian'
    'code': 'hu'
  }
  {
    'desc': 'Icelandic'
    'code': 'is'
  }
  {
    'desc': 'Ido'
    'code': 'io'
  }
  {
    'desc': 'Igbo'
    'code': 'ig'
  }
  {
    'desc': 'Indonesian'
    'code': 'id'
  }
  {
    'desc': 'Interlingua'
    'code': 'ia'
  }
  {
    'desc': 'Interlingue'
    'code': 'ie'
  }
  {
    'desc': 'Inuktitut'
    'code': 'iu'
  }
  {
    'desc': 'Inupiaq'
    'code': 'ik'
  }
  {
    'desc': 'Irish'
    'code': 'ga'
  }
  {
    'desc': 'Italian'
    'code': 'it'
  }
  {
    'desc': 'Japanese'
    'code': 'ja'
  }
  {
    'desc': 'Javanese'
    'code': 'jv'
  }
  {
    'desc': 'Kalaallisut, Greenlandic'
    'code': 'kl'
  }
  {
    'desc': 'Kannada'
    'code': 'kn'
  }
  {
    'desc': 'Kanuri'
    'code': 'kr'
  }
  {
    'desc': 'Kashmiri'
    'code': 'ks'
  }
  {
    'desc': 'Kazakh'
    'code': 'kk'
  }
  {
    'desc': 'Khmer'
    'code': 'km'
  }
  {
    'desc': 'Kikuyu, Gikuyu'
    'code': 'ki'
  }
  {
    'desc': 'Kinyarwanda'
    'code': 'rw'
  }
  {
    'desc': 'Kirundi'
    'code': 'rn'
  }
  {
    'desc': 'Komi'
    'code': 'kv'
  }
  {
    'desc': 'Kongo'
    'code': 'kg'
  }
  {
    'desc': 'Korean'
    'code': 'ko'
  }
  {
    'desc': 'Kurdish'
    'code': 'ku'
  }
  {
    'desc': 'Kwanyama, Kuanyama'
    'code': 'kj'
  }
  {
    'desc': 'Kyrgyz'
    'code': 'ky'
  }
  {
    'desc': 'Lao'
    'code': 'lo'
  }
  {
    'desc': 'Latin'
    'code': 'la'
  }
  {
    'desc': 'Latvian'
    'code': 'lv'
  }
  {
    'desc': 'Limburgish, Limburgan, Limburger'
    'code': 'li'
  }
  {
    'desc': 'Lingala'
    'code': 'ln'
  }
  {
    'desc': 'Lithuanian'
    'code': 'lt'
  }
  {
    'desc': 'Luba-Katanga'
    'code': 'lu'
  }
  {
    'desc': 'Luxembourgish, Letzeburgesch'
    'code': 'lb'
  }
  {
    'desc': 'Macedonian'
    'code': 'mk'
  }
  {
    'desc': 'Malagasy'
    'code': 'mg'
  }
  {
    'desc': 'Malay'
    'code': 'ms'
  }
  {
    'desc': 'Malayalam'
    'code': 'ml'
  }
  {
    'desc': 'Maltese'
    'code': 'mt'
  }
  {
    'desc': 'Manx'
    'code': 'gv'
  }
  {
    'desc': 'Marathi (Marāṭhī)'
    'code': 'mr'
  }
  {
    'desc': 'Marshallese'
    'code': 'mh'
  }
  {
    'desc': 'Mongolian'
    'code': 'mn'
  }
  {
    'desc': 'Montenegrin'
    'code': ''
  }
  {
    'desc': 'Māori'
    'code': 'mi'
  }
  {
    'desc': 'Nauruan'
    'code': 'na'
  }
  {
    'desc': 'Navajo, Navaho'
    'code': 'nv'
  }
  {
    'desc': 'Ndonga'
    'code': 'ng'
  }
  {
    'desc': 'Nepali'
    'code': 'ne'
  }
  {
    'desc': 'Northern Ndebele'
    'code': 'nd'
  }
  {
    'desc': 'Northern Sami'
    'code': 'se'
  }
  {
    'desc': 'Norwegian'
    'code': 'no'
  }
  {
    'desc': 'Norwegian Bokmål'
    'code': 'nb'
  }
  {
    'desc': 'Norwegian Nynorsk'
    'code': 'nn'
  }
  {
    'desc': 'Nuosu'
    'code': 'ii'
  }
  {
    'desc': 'Occitan'
    'code': 'oc'
  }
  {
    'desc': 'Ojibwe, Ojibwa'
    'code': 'oj'
  }
  {
    'desc': 'Old Church Slavonic, Church Slavonic,Old Bulgarian'
    'code': 'cu'
  }
  {
    'desc': 'Oriya'
    'code': 'or'
  }
  {
    'desc': 'Oromo'
    'code': 'om'
  }
  {
    'desc': 'Ossetian, Ossetic'
    'code': 'os'
  }
  {
    'desc': 'Panjabi, Punjabi'
    'code': 'pa'
  }
  {
    'desc': 'Pashto, Pushto'
    'code': 'ps'
  }
  {
    'desc': 'Persian (Farsi)'
    'code': 'fa'
  }
  {
    'desc': 'Polish'
    'code': 'pl'
  }
  {
    'desc': 'Portuguese'
    'code': 'pt'
  }
  {
    'desc': 'Pāli'
    'code': 'pi'
  }
  {
    'desc': 'Quechua'
    'code': 'qu'
  }
  {
    'desc': 'Romanian'
    'code': 'ro'
  }
  {
    'desc': 'Romansh'
    'code': 'rm'
  }
  {
    'desc': 'Russian'
    'code': 'ru'
  }
  {
    'desc': 'Samoan'
    'code': 'sm'
  }
  {
    'desc': 'Sango'
    'code': 'sg'
  }
  {
    'desc': 'Sanskrit (Saṁskṛta)'
    'code': 'sa'
  }
  {
    'desc': 'Sardinian'
    'code': 'sc'
  }
  {
    'desc': 'Scottish Gaelic, Gaelic'
    'code': 'gd'
  }
  {
    'desc': 'Serbian'
    'code': 'sr'
  }
  {
    'desc': 'Shona'
    'code': 'sn'
  }
  {
    'desc': 'Sindhi'
    'code': 'sd'
  }
  {
    'desc': 'Sinhala, Sinhalese'
    'code': 'si'
  }
  {
    'desc': 'Slovak'
    'code': 'sk'
  }
  {
    'desc': 'Slovene'
    'code': 'sl'
  }
  {
    'desc': 'Somali'
    'code': 'so'
  }
  {
    'desc': 'Southern Ndebele'
    'code': 'nr'
  }
  {
    'desc': 'Southern Sotho'
    'code': 'st'
  }
  {
    'desc': 'Spanish'
    'code': 'es'
  }
  {
    'desc': 'Sundanese'
    'code': 'su'
  }
  {
    'desc': 'Swahili'
    'code': 'sw'
  }
  {
    'desc': 'Swati'
    'code': 'ss'
  }
  {
    'desc': 'Swedish'
    'code': 'sv'
  }
  {
    'desc': 'Tagalog'
    'code': 'tl'
  }
  {
    'desc': 'Tahitian'
    'code': 'ty'
  }
  {
    'desc': 'Tajik'
    'code': 'tg'
  }
  {
    'desc': 'Tamil'
    'code': 'ta'
  }
  {
    'desc': 'Tatar'
    'code': 'tt'
  }
  {
    'desc': 'Telugu'
    'code': 'te'
  }
  {
    'desc': 'Thai'
    'code': 'th'
  }
  {
    'desc': 'Tibetan Standard, Tibetan, Central'
    'code': 'bo'
  }
  {
    'desc': 'Tigrinya'
    'code': 'ti'
  }
  {
    'desc': 'Tonga (Tonga Islands)'
    'code': 'to'
  }
  {
    'desc': 'Tsonga'
    'code': 'ts'
  }
  {
    'desc': 'Tswana'
    'code': 'tn'
  }
  {
    'desc': 'Turkish'
    'code': 'tr'
  }
  {
    'desc': 'Turkmen'
    'code': 'tk'
  }
  {
    'desc': 'Twi'
    'code': 'tw'
  }
  {
    'desc': 'Ukrainian'
    'code': 'uk'
  }
  {
    'desc': 'Urdu'
    'code': 'ur'
  }
  {
    'desc': 'Uyghur'
    'code': 'ug'
  }
  {
    'desc': 'Uzbek'
    'code': 'uz'
  }
  {
    'desc': 'Venda'
    'code': 've'
  }
  {
    'desc': 'Vietnamese'
    'code': 'vi'
  }
  {
    'desc': 'Volapük'
    'code': 'vo'
  }
  {
    'desc': 'Walloon'
    'code': 'wa'
  }
  {
    'desc': 'Welsh'
    'code': 'cy'
  }
  {
    'desc': 'Western Frisian'
    'code': 'fy'
  }
  {
    'desc': 'Wolof'
    'code': 'wo'
  }
  {
    'desc': 'Xhosa'
    'code': 'xh'
  }
  {
    'desc': 'Yiddish'
    'code': 'yi'
  }
  {
    'desc': 'Yoruba'
    'code': 'yo'
  }
  {
    'desc': 'Zhuang, Chuang'
    'code': 'za'
  }
  {
    'desc': 'Zulu'
    'code': 'zu'
  }
  {
    'desc': 'Arabic - Algeria'
    'code': 'ar-dz'
  }
  {
    'desc': 'Arabic - Bahrain'
    'code': 'ar-bh'
  }
  {
    'desc': 'Arabic - Egypt'
    'code': 'ar-eg'
  }
  {
    'desc': 'Arabic - Iraq'
    'code': 'ar-iq'
  }
  {
    'desc': 'Arabic - Jordan'
    'code': 'ar-jo'
  }
  {
    'desc': 'Arabic - Kuwait'
    'code': 'ar-kw'
  }
  {
    'desc': 'Arabic - Lebanon'
    'code': 'ar-lb'
  }
  {
    'desc': 'Arabic - Libya'
    'code': 'ar-ly'
  }
  {
    'desc': 'Arabic - Morocco'
    'code': 'ar-ma'
  }
  {
    'desc': 'Arabic - Oman'
    'code': 'ar-om'
  }
  {
    'desc': 'Arabic - Qatar'
    'code': 'ar-qa'
  }
  {
    'desc': 'Arabic - Saudi Arabia'
    'code': 'ar-sa'
  }
  {
    'desc': 'Arabic - Syria'
    'code': 'ar-sy'
  }
  {
    'desc': 'Arabic - Tunisia'
    'code': 'ar-tn'
  }
  {
    'desc': 'Arabic - United Arab Emirates'
    'code': 'ar-ae'
  }
  {
    'desc': 'Arabic - Yemen'
    'code': 'ar-ye'
  }
  {
    'desc': 'Azeri - Cyrillic'
    'code': 'az-az'
  }
  {
    'desc': 'Azeri - Latin'
    'code': 'az-az'
  }
  {
    'desc': 'Chinese - China'
    'code': 'zh-cn'
  }
  {
    'desc': 'Chinese - Hong Kong SAR'
    'code': 'zh-hk'
  }
  {
    'desc': 'Chinese - Macau SAR'
    'code': 'zh-mo'
  }
  {
    'desc': 'Chinese - Singapore'
    'code': 'zh-sg'
  }
  {
    'desc': 'Chinese - Taiwan'
    'code': 'zh-tw'
  }
  {
    'desc': 'Dutch - Belgium'
    'code': 'nl-be'
  }
  {
    'desc': 'Dutch - Netherlands'
    'code': 'nl-nl'
  }
  {
    'desc': 'English - Australia'
    'code': 'en-au'
  }
  {
    'desc': 'English - Belize'
    'code': 'en-bz'
  }
  {
    'desc': 'English - Canada'
    'code': 'en-ca'
  }
  {
    'desc': 'English - Caribbean'
    'code': 'en-cb'
  }
  {
    'desc': 'English - Great Britain'
    'code': 'en-gb'
  }
  {
    'desc': 'English - India'
    'code': 'en-in'
  }
  {
    'desc': 'English - Ireland'
    'code': 'en-ie'
  }
  {
    'desc': 'English - Jamaica'
    'code': 'en-jm'
  }
  {
    'desc': 'English - New Zealand'
    'code': 'en-nz'
  }
  {
    'desc': 'English - Phillippines'
    'code': 'en-ph'
  }
  {
    'desc': 'English - Southern Africa'
    'code': 'en-za'
  }
  {
    'desc': 'English - Trinidad'
    'code': 'en-tt'
  }
  {
    'desc': 'English - United States'
    'code': 'en-us'
  }
  {
    'desc': 'French - Belgium'
    'code': 'fr-be'
  }
  {
    'desc': 'French - Canada'
    'code': 'fr-ca'
  }
  {
    'desc': 'French - France'
    'code': 'fr-fr'
  }
  {
    'desc': 'French - Luxembourg'
    'code': 'fr-lu'
  }
  {
    'desc': 'French - Switzerland'
    'code': 'fr-ch'
  }
  {
    'desc': 'Gaelic - Ireland'
    'code': 'gd-ie'
  }
  {
    'desc': 'German - Austria'
    'code': 'de-at'
  }
  {
    'desc': 'German - Germany'
    'code': 'de-de'
  }
  {
    'desc': 'German - Liechtenstein'
    'code': 'de-li'
  }
  {
    'desc': 'German - Luxembourg'
    'code': 'de-lu'
  }
  {
    'desc': 'German - Switzerland'
    'code': 'de-ch'
  }
  {
    'desc': 'Italian - Italy'
    'code': 'it-it'
  }
  {
    'desc': 'Italian - Switzerland'
    'code': 'it-ch'
  }
  {
    'desc': 'Malay - Brunei'
    'code': 'ms-bn'
  }
  {
    'desc': 'Malay - Malaysia'
    'code': 'ms-my'
  }
  {
    'desc': 'Norwegian - Bokml'
    'code': 'no-no'
  }
  {
    'desc': 'Norwegian - Nynorsk'
    'code': 'no-no'
  }
  {
    'desc': 'Portuguese - Brazil'
    'code': 'pt-br'
  }
  {
    'desc': 'Portuguese - Portugal'
    'code': 'pt-pt'
  }
  {
    'desc': 'Romanian - Moldova'
    'code': 'ro-mo'
  }
  {
    'desc': 'Russian - Moldova'
    'code': 'ru-mo'
  }
  {
    'desc': 'Serbian - Cyrillic'
    'code': 'sr-sp'
  }
  {
    'desc': 'Serbian - Latin'
    'code': 'sr-sp'
  }
  {
    'desc': 'Spanish - Argentina'
    'code': 'es-ar'
  }
  {
    'desc': 'Spanish - Bolivia'
    'code': 'es-bo'
  }
  {
    'desc': 'Spanish - Chile'
    'code': 'es-cl'
  }
  {
    'desc': 'Spanish - Colombia'
    'code': 'es-co'
  }
  {
    'desc': 'Spanish - Costa Rica'
    'code': 'es-cr'
  }
  {
    'desc': 'Spanish - Dominican Republic'
    'code': 'es-do'
  }
  {
    'desc': 'Spanish - Ecuador'
    'code': 'es-ec'
  }
  {
    'desc': 'Spanish - El Salvador'
    'code': 'es-sv'
  }
  {
    'desc': 'Spanish - Guatemala'
    'code': 'es-gt'
  }
  {
    'desc': 'Spanish - Honduras'
    'code': 'es-hn'
  }
  {
    'desc': 'Spanish - Mexico'
    'code': 'es-mx'
  }
  {
    'desc': 'Spanish - Nicaragua'
    'code': 'es-ni'
  }
  {
    'desc': 'Spanish - Panama'
    'code': 'es-pa'
  }
  {
    'desc': 'Spanish - Paraguay'
    'code': 'es-py'
  }
  {
    'desc': 'Spanish - Peru'
    'code': 'es-pe'
  }
  {
    'desc': 'Spanish - Puerto Rico'
    'code': 'es-pr'
  }
  {
    'desc': 'Spanish - Spain (Traditional)'
    'code': 'es-es'
  }
  {
    'desc': 'Spanish - Uruguay'
    'code': 'es-uy'
  }
  {
    'desc': 'Spanish - Venezuela'
    'code': 'es-ve'
  }
  {
    'desc': 'Swedish - Finland'
    'code': 'sv-fi'
  }
  {
    'desc': 'Swedish - Sweden'
    'code': 'sv-se'
  }
  {
    'desc': 'Uzbek - Cyrillic'
    'code': 'uz-uz'
  }
  {
    'desc': 'Uzbek - Latin'
    'code': 'uz-uz'
  }
]

module.exports =
    operatorLookup: operatorLookup
    languageLookup: languageLookup

    propertyDisplay: (key, options) ->
        PropertyModel = require 'models/property'
        PropertyModel.getPropertyDisplayName key

    operatorDisplay: (key, options) ->
        operatorLookup[options.operator]

    valueDisplay: (key, options) ->
        if options.operator in ['in', 'not in']
            count = options.valuesList.split('\n').length
            count + ' value' + (if count is 1 then '' else 's')
        else
            options.value

