{FIREBASE_URL, FIREBASE_PROJECT_ID, FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN} = window.__env

config = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: FIREBASE_URL,
    projectId: FIREBASE_PROJECT_ID
}

firebase.initializeApp(config)

# As of Firebase SDK 3.0, users are automatically signed in
# after they are created. To allow invited users to be created
# silently, we create a second firebase app instance to manage
# them.
invitedUserFirebase = firebase.initializeApp(config, "invitedUser")

module.exports = {
    url: FIREBASE_URL,
    firebaseRef: firebase.database().ref(),
    firebase: firebase,
    invitedUserFirebase: invitedUserFirebase
}
