helpers = require 'lib/helpers'
hubspot = require 'lib/hubspot'
AppView = require 'views/app'
{firebase} = require 'lib/firebase'
{isFreemail} = require 'lib/email-utils'
{toLocation} = require 'lib/urls'
{ CONTACT_LOOKUP_URL } = window.__env
AccountModel = require 'models/account'
UserAccountsCollection = require 'collections/user-accounts'
trackEvent = require 'lib/track-event'

ACCOUNT_EXISTS_ERROR = 'ACCOUNT_EXISTS'
NO_FREEMAIL = 'NO_FREEMAIL'

VARIANTS =
    FLAT_DESIGN: 'flat-design'
    APPCUES_TOOLTIP: 'appcues-tooltip'
    CREATE_AMAZING_EXPERIENCE: 'create-amazing-experience'
    APPCUES_STATIC_TOOLTIP_2: 'appcues-static-tooltip_2'

APPCUES_SAFARI_ISSUE_FLOW_ID = "-LVz7WbxbtW6I73UL7cX"

ua = navigator.userAgent
isSafari = ->
    if ua.indexOf('Safari') != -1 and ua.indexOf('Chrome') == -1 and ua.indexOf('Chromium') == -1
        return true
    return false

module.exports = class RegisterBTestView extends AppView
    __name__: 'RegisterBTestView'
    template: require 'views/templates/registerBTest'

    events:
        'submit form.auth-form': 'submitForm'
        'change form.auth-form [name="request_demo"]':  'requestDemoChanged'
        'keyup #email': 'validateTypedEmail'
        'click .email-next': 'expandForm'
        'click .google-auth': 'registerWithGoogle'

    initialize: (options = {}) ->
        @model = new Backbone.Model()

        if options.email?
            {email} = options
            domain = email.split('@')[1]
            options = _.extend {}, options, {
                showName: false
                expandForm: true
                firstname: ''
                lastname: ''
                email: email
                emailHash: SparkMD5.hash(email)
                showRedirectBackdrop: false
                website: if not isFreemail(email) then domain
            }

            @hydrateFields(email)

        options.copyVariant = VARIANTS.CREATE_AMAZING_EXPERIENCE
        options.isGoogleProvider = options.provider is 'google.com' || false
        @model.set options, { silent: true}

        if options.requestdemo
            @model.set { expandForm: true, showName: true, requestdemo: true }

        # Failed to login.
        @listenTo @model, 'error', (error) ->
            @model.set {
                hasError: true
                error: error
            }
            $('.validation-error .alert').focus()

        @listenTo @model, 'change', @render

        @debouncedHydration = _.debounce(_.bind(@hydrateFields, @), 800)

        if window.analytics
            analyticsTrack = @analyticsTrack.bind(this)
            analytics.ready(analyticsTrack)

    getContext: -> @model.toJSON()

    render: ->
        super
        if @model.get('requestdemo')
            requestDemoField = @$(".form-group.request-demo")
            requestDemoField[0].classList.remove('hidden')

            requestDemoCheckbox = @$("form.auth-form [name='request_demo']")
            requestDemoCheckbox[0].checked = true

        unless @firstRenderReported
            window.parent.postMessage(JSON.stringify({
                type: "render.auth.register"
            }), "*")
            @firstRenderReported = true

        if isSafari()
            Appcues.show(APPCUES_SAFARI_ISSUE_FLOW_ID)

    analyticsTrack: ->
        trackEvent 'Viewed Registration Variant', {variant: VARIANTS.APPCUES_STATIC_TOOLTIP_2}

    expandForm: ->
        email = @$("form.auth-form [name='email']").val()
        @model.set {email: email, showName: true, expandForm: true}

    validateTypedEmail: (e) ->
        pattern=/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+([a-zA-Z])/
        email = e.currentTarget.value

        if e.key == 'Enter' && !@model.get('expandForm')
            @checkAndShowFields()
            @model.set {hasError: false, error: null, expandForm: true}

        if pattern.test(email)
            @model.set {hasError:false, error: null}, {silent: true}
            @showErrorOrExpand(email)
        else
            return false

    demoRequestChanged: (e) ->
        requestdemo = e.currentTarget.checked
        @model.set { requestdemo }, { silent: true }

    showErrorOrExpand: (email) ->
        if isFreemail(email)
            product = @model.get('product')
            hubspot.submitEmail(email, product)
            @showFreemailError()

        else
            @debouncedHydration(email)
            @checkAndShowFields()

            if !@model.get('expandForm')
                @model.set {email: email, hasError: false, error: null, expandForm: true}

    checkAndShowFields: ->
        fieldVisibility =
            showName: false

        currentFirstName = @$("form.auth-form [name='firstname']").val()
        currentLastName = @$("form.auth-form [name='lastname']").val()

        if !(currentFirstName && currentLastName)
            fieldVisibility.showName = true

        @model.set fieldVisibility, {silent: true}

    showFreemailError: ->
        @model.set {email: ''}, {silent: true}
        @model.trigger 'error', NO_FREEMAIL

    registerWithGoogle: ->
        trackEvent("Clicked 'Sign Up With Google'")
        googleProvider = new firebase.auth.GoogleAuthProvider()
        googleProvider.addScope('email')
        firebase.auth().signInWithPopup(googleProvider).then((authData) =>
            model = @model
            hydrateFields = @hydrateFields.bind(this)

            userId = authData.user.uid
            email = authData.user.email

            @userAccounts = new UserAccountsCollection [], {userId}
            userAccounts = @userAccounts

            firstname = authData.additionalUserInfo.profile.given_name
            lastname = authData.additionalUserInfo.profile.family_name

            provider = 'google.com'
            queryString = 'email=' + email + '&firstname=' + firstname + '&lastname=' + lastname

            if window.Raven
                Raven.setUser({
                    provider: provider,
                    id: userId
                })

            hydrateFields(email)

            userAccounts.once 'sync', ->
                # Prevent another account from being written in Firebase if one already exists.
                if userAccounts.length > 0
                    model.set {showRedirectBackdrop: true, email: null}
                    window.location.href = '/?' + queryString

                else
                    model.set {isGoogleProvider: true, showName: false, email: email, firstname: firstname, lastname: lastname}

                    # Create the account structure in Firebase.
                    AccountModel.createAccount userId, email, (err) =>
                        if err
                            e = new Error('New account creation failed')
                            Raven.captureException e, {
                                extra: {
                                    userId: userId,
                                    provider: provider,
                                    error: err
                                }
                            }

                            try
                                trackEvent('Signup Error', {provider: provider, email: email, error: err, method: 'createAccount'})
                        else
                            syncdAccounts = new UserAccountsCollection [], {userId}
                            syncdAccounts.once 'sync', ->
                                accountId = syncdAccounts.models[0].get('accountId')
                                currentAccount = new AccountModel {id: accountId}
                                currentAccount.once 'sync', ->
                                    model.set { hasError: false, error: null }, { silent: true }
        ).catch (error) ->
            console.error(error)
            e = new Error('New account creation failed')
            Raven.captureException e, {
                extra: {
                    userId: userId,
                    provider: 'google',
                    error
                }
            }

    hydrateFields: (email) ->
        source = @model.get('urlSource')
        product = @model.get('product')
        hubspot.submitEmail(email, product)

        # Look up Clearbit data.
        $.ajax CONTACT_LOOKUP_URL, {
            method: 'POST'
            contentType: 'application/json'
            data: JSON.stringify({email})
            error: (resp) =>
                modelChangeSet = {}
                modelChangeSet.showName = true

                @model.set modelChangeSet
            success: (resp) =>
                {name, employment, company} = resp
                props = { email: email, clearbitData: JSON.stringify(resp) }

                if name?
                    props.firstname = name.givenName
                    props.lastname = name.familyName

                if employment?.domain
                    props.website = employment.domain

                # Update the UI and model, but don't rerender.
                _.each props, (val, name) ->
                    $field = @$("form.auth-form [name=#{name}]")
                    $field.val(val)

                modelChangeSet =
                    showName: !(name && name.givenName && name.familyName)
                    requestdemo: @model.get('requestdemo')

                # The hubspot request demo form has a required phone input.
                # We will use Clearbit's data or set a default.
                props.phone = company?.phone || '000-000-0000'

                @model.set props, { silent: true }

                showNameChanged = modelChangeSet.showName != @model.get 'showName'
                shouldShowRequestDemo = modelChangeSet.requestdemo

                if (showNameChanged || shouldShowRequestDemo)
                    @model.set modelChangeSet
        }

    submitForm: (e) ->
        e.preventDefault()
        requestDemoCheckbox = @$("form.auth-form [name='request_demo']")
        requestDemoField = @$(".form-group.request-demo")
        isRequestDemoFieldHidden = requestDemoField.length && requestDemoField[0].classList.contains('hidden')
        requestedDemo = !isRequestDemoFieldHidden && requestDemoCheckbox.length && requestDemoCheckbox[0].checked

        # TO DO: Consolodate signup and demo request forms and re-work checkbox logic
        # In interim, use "demo request" checkbox for requestedContact property
        requestedContact = requestedDemo

        data = @$('form.auth-form').serializeObject()
        data.requestedContact = requestedDemo
        if requestedDemo
            data.request_demo = "Yes"
        else
            data.request_demo = "No"

        data.product = @model.get('product')
        @model.set _.extend({hasError: false, error: null}, data), {silent: true}

        fields = @$('form [name]').map (i, el) -> el.getAttribute('name')

        isGoogleProvider = @model.get('isGoogleProvider')

        # to prevent submissions by pressing "enter" in the short email field
        # unless we're using google auth
        if fields.length == 1 and !isGoogleProvider
            return false

        else
            # Validate the input as all non-empty.
            isValid = _.every fields, (field) -> data[field]

            unless isValid
                email = @model.get('email')
                firstname = @model.get('firstname')
                lastname = @model.get('lastname')

                signupErrorData =
                    email: email
                    firstname: firstname
                    lastname: lastname
                    # request_demo: data.request_demo
                    provider: 'password'
                    error: 'Incomplete Form'

                try
                    trackEvent('Signup Error', signupErrorData)

                    e = new Error('Incomplete Form Submitted')
                    Raven.captureException e, {
                        extra: signupErrorData
                    }

                @model.trigger 'error'
                return false

            @$('form [type=submit]')
                .addClass 'disabled'
                .text "Thanks! You'll be redirected in a sec"

            localStorage?.setItem('clearbit', @model.get('clearbitData'))

            if isGoogleProvider
                # Recapture user data before form submission.
                googleData = {}
                googleData.email = @model.get('email')
                googleData.firstname = @model.get('firstname')
                googleData.lastname = @model.get('lastname')
                googleData.requestedContact = data.requestedContact
                # googleData.request_demo = data.request_demo
                googleData.product = @model.get('product')
                googleData.provider = 'google.com'

                if requestedDemo
                    googleData.phone = @model.get('phone')

                queryStringGoogleData = helpers.objToQueryStr(googleData)
                @redirectAfterSignup(queryStringGoogleData)

            else
                # Get the sign up data.
                {email, password} = data
                data.provider = 'password'

                # if requestedDemo
                #     data.phone = @model.get('phone')

                cleanedData = _.omit(data, 'password')
                queryStringData = helpers.objToQueryStr(cleanedData)

                if isFreemail(email) or /@qq\.com/i.test(email)
                    @showFreemailError()
                else
                    @createUser email, password, (newUser) =>
                        @createAccount newUser, =>
                            @authUser newUser, =>
                                @redirectAfterSignup queryStringData

        return false

    createUser: (email, password, onSuccess) ->

        provider = 'password'
        # Create a new user in firebase and immediately log in.
        firebase.auth().createUserWithEmailAndPassword(email, password).then((credential) =>
            newUser = credential.user
            try
                analytics.alias(newUser.uid)

            Raven.setUser({
                provider: provider,
                id: newUser.uid
            })

            onSuccess(newUser)

        ).catch((error) =>
            e = new Error('Failed creating account in Firebase.')
            Raven.captureException e, {
                extra: {
                    error: error,
                    email: email,
                    provider: provider,
                    method: 'createUser'
                }
            }

            try
                trackEvent('Signup Error', {provider: provider, email: email, error: error, method: 'createUser'})

            if error.code is 'EMAIL_TAKEN'
                @model.trigger 'error', ACCOUNT_EXISTS_ERROR
            else
                @model.trigger 'error'
        )

        return

    authUser: (newUser, onSuccess) ->
        # Log in.
        syncdAccounts = new UserAccountsCollection [], {userId: newUser.uid}
        syncdAccounts.once 'sync', ->
            accountId = syncdAccounts.models[0].get('accountId')
            currentAccount = new AccountModel {id: accountId}
            currentAccount.once 'sync', ->
                onSuccess?()

    createAccount: (newUser, onSuccess) ->
        # Create the account structure in Firebase.
        AccountModel.createAccount newUser.uid, newUser.email, (err) =>
            if err
                e = new Error('New account creation failed')
                Raven.captureException e, {
                    extra: {
                        userId: newUser.uid,
                        error: err,
                        method: 'createAccount'
                    }
                }

                @model.trigger 'error'

                try
                    trackEvent('Signup Error', {email: newUser.email, error: err, method: 'createAccount'})

            else
                onSuccess?()

    isNotAppcuesEmail: (email) ->
        try
            return !/@appcues\.com/g.test email
        catch e
            console.error 'Email Invalid.'

        return true

    redirectAfterSignup: (queryParams) ->
        if window isnt window.top
            window.localStorage.setItem("signupQueryParams", queryParams)

        if queryParams.includes('product=nps-product')
            window.location.href = '/nps?' + queryParams
        else if document.referrer.indexOf("checklists") > -1
            window.location.href = '/quickstart?ref=checklist&' + queryParams
        else
            window.location.href = '/quickstart?' + queryParams
