HotspotGroupModel = require 'models/hotspot-group-firebase'
RuleModel = require 'models/rule-firebase'
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'
sls = require 'lib/sls-helpers'

module.exports = class HotspotGroupsCollection extends Backbone.Firebase.Collection
    model: require 'models/hotspot-group'

    initialize: (models, options={}) ->
        @accountId = options.accountId ? auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{@accountId}/hotspot-groups"

        @once 'sync', ->
            @syncd = true

        @on 'remove', (hotspotGroup) ->
            rule = new RuleModel(id: hotspotGroup.id)
            rule.destroy()

    cloneModel: (flowId, options={}) ->
        {fromAccountId, flowName, noCopyOf} = options

        if fromAccountId?
            # fromAccountId is used to clone template flows from the `sample` account on `/start`
            hotspotGroup = new HotspotGroupModel {id: flowId}, {accountId: fromAccountId}
        else
            hotspotGroup = @get(flowId)

        return unless flowId and hotspotGroup

        sls.clone(hotspotGroup, fromAccountId)
            .done(({new_content_id}) => @trigger 'clone', @, new_content_id)
