auth = require 'models/auth'
{firebase} = require 'lib/firebase'


updateTimestamps = (model) ->
    return unless isValidModel(model)

    attrs =
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        updatedBy: auth.id

    if model.firebase
        model.set attrs, {silent: true}
        model.sync 'update', model

multiUpdate = (model) ->
    return unless isValidModel(model)

    paths = _.result model, 'extraPaths'
    if _.isArray paths
        data = {}
        audit =
            updatedAt: firebase.database.ServerValue.TIMESTAMP
            updatedBy: auth.id

        # Copy the model to the additional paths.
        _.each paths, (path) ->
            data[path] = _.extend model.toJSON(), audit
            return

        if model.firebase
            # Copy to the model locally first to avoid cyclical sync.
            model.set audit, {silent: true}
            origPath = model.firebase.path.toString()
            data["#{origPath}/updatedAt"] = audit.updatedAt
            data["#{origPath}/updatedBy"] = audit.updatedBy

        # Push all data at once.
        firebase.update data
    else
        # Just update the timestamps per usual.
        updateTimestamps model

isValidModel = (model) ->
    # Don't update if the model only has an ID. It's a sign
    # that the model could have already been deleted.
    not (not model.isNew() and _.size(model.attributes) is 1)


module.exports =
    MultiUpdate: ->
        multiUpdate: _.debounce(multiUpdate, 400)

    Cache:
        expires: null
        isExpired: -> not @expires? and @expires > +new Date
