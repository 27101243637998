BaseView = require 'views/base'
StepModalView = require 'views/step-modal'
{confirm} = require 'views/alerts'

module.exports = class StepsSummaryView extends BaseView
    __name__: 'StepsSummaryView'
    className: 'content-thumbnails'
    template: require 'views/templates/build-steps/design/flow/steps-summary'

    initialize: (options={}) ->
        {@isEditable} = options
        @listenTo @collection, 'remove', @resetSelected
        @listenTo @collection, 'add change remove sort', @render
        @listenTo @collection, 'select', @updateSelected

    events:
        'click .list-group-item': 'select'
        'click [data-action=showStepInfo]': 'showStepInfo'
        'click [data-action=deleteStep]': 'deleteStep'
        'click .add-step': 'addStep'

    getContext: ->
        steps: @collection.toJSON()
        selectedId: @collection.getSelectedId()
        isEditable: @isEditable

    render: ->
        super

        if @isEditable
            $stepsContainer = @$ '.thumbnails-list'
            new Sortable $stepsContainer.get(0), {
                draggable: '.draggable-list-group-item'
                onUpdate: (evt) =>
                    # Get the current sort state.
                    $steps = $stepsContainer.find '.draggable-list-group-item'
                    $steps.each (i, step) =>
                        # Update the step numbers. The + button element won't have a stepId
                        stepId = $(step).attr('data-id')
                        @collection.get(stepId).set('stepNumber', i) if stepId?
                    @collection.sort()
            }

        return @

    resetSelected: ->
        @collection.select(@collection.at(0)?.get('id'))

    updateSelected: ->
        # Do it this way so we get nice animations.
        @$('li.list-group-item.selected').removeClass('selected')
        @$('li.list-group-item[data-id=' + @collection.getSelectedId() + ']').addClass('selected')

    addStep: ->
        @listenToOnce @collection, 'add', (step) ->
            @collection.select(step.id)
        @collection.addEmptyStep()

        try
            # NOTE: Consider adding this to the collection instead.
            lytics.trackWithUser 'Added a Step', {
                totalSteps: @collection.length + 1
                flowId: @collection.flowId
            }

    select: (e) ->
        el = e.currentTarget
        if el.hasAttribute 'data-id'
            @collection.select el.getAttribute('data-id')

    deleteStep: (e) ->
        step = @_getStepModel(e)
        return false unless step?
        confirm("Are you sure you want to delete this step?").then (res) =>
            if res
                @collection.remove step
        return false

    showStepInfo: (e) ->
        $link = @$(e.currentTarget)
        step = @collection.get($link.attr('data-step-id'))
        if step
            v = new StepModalView
                model: step
                show: true
            @renderChild v
        else
            return false

    _getStepModel: (e) ->
        stepId = @$(e.currentTarget).closest('[data-id]').attr('data-id')
        @collection.get(stepId)
