AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class EditInChromeView extends AppView
    __name__: 'EditInChromeView'
    className: 'editInChrome'
    template: require 'views/templates/loading-react-injectable'

    initialize: (options) ->
        {@stepGroupId, @stepChildId} = options

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderEditInChrome auth.currentAccount.id, @stepGroupId, @stepChildId, "apc-react-root"
        else
            setTimeout(() =>
                @render()
            , 300)
