getOrigin = (anchor) ->
    if anchor.origin
        anchor.origin
    else
        anchor.protocol + "//" + anchor.hostname + (if anchor.port then ':' + anchor.port else '')

module.exports =

    toLocation: (path) ->
        anchor = document.createElement 'a'
        anchor.href = path

        origin = getOrigin anchor

        return {
            hash: anchor.hash
            host: anchor.host
            hostname: anchor.hostname
            href: anchor.href
            origin: origin
            pathname: anchor.pathname
            fullpath: anchor.href.replace origin, ''
            port: anchor.port
            protocol: anchor.protocol
            search: anchor.search
        }

    createMailToLinkFromTemplate: (template={}) ->
        to = if template.to then encodeURIComponent(template.to) else ''
        params = ['subject', 'body', 'cc'].reduce((memo, next) ->
            if val = template[next]
                if memo
                    memo += "&"
                memo += "#{next}=#{encodeURIComponent(val)}"
            return memo
        , '')

        return "#{to}?#{params}"
