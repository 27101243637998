FREEMAIL_DOMAINS = [
    'test.com'
    'tester.com'
    'mailinator.com'
    'example.com'
    'fake.com'
]

module.exports =

    isFreemail: (str) ->
        domain = str.split('@')[1] ? ''
        return FREEMAIL_DOMAINS.indexOf(domain) > -1
