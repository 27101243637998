BaseView = require 'views/base'


module.exports = class FlowPrehookView extends BaseView
    __name__: 'FlowPrehookView'
    className: 'flow-prehook'
    template: require 'views/templates/build-steps/settings/flow-prehook'

    events:
        'change [name=prehook]': 'updateValue'

    initialize: (options) ->
        {@rule} = options
        @model.whenSyncd => @render()

    getContext: ->
        model: @model.toJSON()

    render: ->
        @editor?.destroy()
        super
        @setupEditor()
        @

    remove: ->
        @editor?.destroy()
        super

    setupEditor: ->
        $editor = @$('.prehook-editor')
        if $editor.length
            @editor = ace.edit $editor.get(0)
            @editor.setTheme 'ace/theme/monokai'
            @editor.setFontSize '14px'
            @editor.getSession().setMode 'ace/mode/javascript'
            @editor.on('change', =>
                @$('[name=prehook]').val(@editor.getValue()).trigger('change')
            )
            if @model.get('published')
                @editor.setReadOnly(true)

    updateValue: ->
        @model.set {prehook: @$('[name=prehook]').val() || null}
