BaseView = require 'views/base'
FlowsCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
CoachmarkGroupsCollection = require 'collections/coachmark-groups'

module.exports = class RuleContentsView extends BaseView
    __name__: 'RuleContentsView'
    className: 'rule-contents'
    template: require 'views/templates/build-steps/settings/rule-contents'

    events:
        'change [name=toggleContents]': '_updateToggleContentTargeting'
        'change [name=targetContentId]': '_updateTargetContentValue'

    _updateToggleContentTargeting: (evt) =>
        matchValue = @$(evt.currentTarget).val()
        if matchValue == 'negativeMatch'
            @viewState.set('negativeMatch', true)
            @viewState.set('positiveMatch', false)
            @viewState.set('showTargetContents', true)
        else if matchValue == 'positiveMatch'
            @viewState.set('negativeMatch', false)
            @viewState.set('positiveMatch', true)
            @viewState.set('showTargetContents', true)
        else # all
            @viewState.set('negativeMatch', false)
            @viewState.set('positiveMatch', false)
            @viewState.set('showTargetContents', false)
        @_updateRuleState()

    _updateTargetContentValue: =>
        contentId = @$('#targetContentId').val()
        if contentId and contentId != ""
            @viewState.set('ruleTargetContentId', contentId)
        else
            @viewState.set('ruleTargetContentId', null)
        @_updateRuleState()
        @_updateTargetContentDraftWarning()

    _updateRuleState: =>
        contentId = @viewState.get('ruleTargetContentId')
        negative = @viewState.get('negativeMatch')
        showTargetContents = @viewState.get('showTargetContents')
        if contentId && showTargetContents
            name = @contents?[contentId]?.name || null
            matchInfo = {}
            matchInfo[contentId] = {times: 1, name: name}
            if negative
                matchInfo[contentId]["negative"] = true
            @rule.set('contents', matchInfo)
        else
            @rule.set('contents', null)
        @render()

    _updateTargetContentDraftWarning: ->
        targetContentId = @ruleTargetContentId()
        if targetContentId
            content = @flows.get(targetContentId) or @hotspotGroups.get(targetContentId) or @coachmarkGroups.get(targetContentId)

        if content? and not content.get('published')
            @viewState.set(targetContentDraftWarning: true)
        else
            @viewState.set(targetContentDraftWarning: false)

    ruleTargetContentId: ->
        c = @rule.get('contents')
        if c
            Object.keys(c)[0]
        else
            null

    rulePositive: ->
        c = @rule.get('contents')
        if c
            key = Object.keys(c)[0]
            ! c[key]["negative"]
        else
            false

    ruleNegative: ->
        c = @rule.get('contents')
        if c
            key = Object.keys(c)[0]
            !! c[key]["negative"]
        else
            false

    initialize: (options={}) ->
        {@rule} = options
        @viewState = new Backbone.Model {
            targetContentDraftWarning: false
            ruleTargetContentId: @ruleTargetContentId()
            showTargetContents: !! @ruleTargetContentId()
            negativeMatch: @ruleNegative()
            positiveMatch: @rulePositive()
        }

        @listenTo @viewState, 'change', @render

        @flows = new FlowsCollection()
        @hotspotGroups = new HotspotGroupsCollection()
        @coachmarkGroups = new CoachmarkGroupsCollection()
        @flows.whenSyncd => @render()
        @hotspotGroups.whenSyncd => @render()
        @coachmarkGroups.whenSyncd => @render()
        @contents = {}

        # Check if the selected content is published once everything has syncd.
        $.when(@rule.whenSyncd(), @flows.whenSyncd(), @hotspotGroups.whenSyncd(), @coachmarkGroups.whenSyncd()).done =>
            flows = @flows.toJSON()
            hotspots = @hotspotGroups.toJSON()
            coachmarks = @coachmarkGroups.toJSON()
            contentsArray = flows.concat(hotspots).concat(coachmarks)
            for i of contentsArray
                content = contentsArray[i]
                @contents[content.id] = content

            @_updateTargetContentDraftWarning()
            @render()

    getContext: ->
        rule: @rule.toJSON()
        flows: @flows.toJSON()
        hotspotGroups: @hotspotGroups.toJSON()
        coachmarkGroups: @coachmarkGroups.toJSON()
        isPublished: @rule.get('published')
        ruleTargetContentId: @viewState.get('ruleTargetContentId')
        showTargetContents: @viewState.get('showTargetContents')
        targetContentDraftWarning: @viewState.get('targetContentDraftWarning')
        positiveMatch: @viewState.get('positiveMatch')
        negativeMatch: @viewState.get('negativeMatch')

    render: ->
        super

        @$('select[name=targetContent]').selectize({
            closeAfterSelect: true
            selectOnTab: true
            render:
                item: (item, escape) ->
                    if item.text
                        """
                        <div class="content-option title="#{escape item.text}" style="width:98%; padding-right: 20px">
                            <span class="content-name">#{escape item.text}</span>
                            <span class="content-status #{if item.published then 'text-success' else 'text-muted'}">#{if item.published then 'Live' else 'Draft'}</span>
                            <span class="content-id">#{escape item.value}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
                option: (item, escape) ->
                    if item.text
                        """
                        <div class="content-option" title="#{escape item.text}">
                            <span class="content-name">#{escape item.text}</span>
                            <span class="content-status #{if item.published then 'text-success' else 'text-muted'}">#{if item.published then 'Live' else 'Draft'}</span>
                            <span class="content-id">#{escape item.value}</span>
                        </div>
                        """
                    else
                        """<div></div>"""
        })


