ContentItemView = require 'views/content-list/item'

module.exports = class FlowItemView extends ContentItemView
    __name__: 'FlowItemView'
    className: -> super + ' flow-item'
    template: require 'views/templates/content-list/flow'
    labels: {
        model: 'this flow'
        cloneEvent: 'Cloned a Flow'
        deleteEvent: 'Deleted a Flow'
        archiveEvent: 'Archived a Flow'
        unarchiveEvent: 'Unarchived a Flow'
    }

    getElementId: -> "flow-#{@model.id}"

    attributes: ->
        'id': @getElementId()
        'data-flow-id': @model.id

