auth = require 'models/auth'

module.exports = class SetupActivityModel extends Backbone.Model
    defaults:
        name: ''
        description: null
        destinationUrl: ''
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: null
        updatedBy: null
        status: null

    complete: -> @updateStatus 'completed'
    skip: -> @updateStatus 'skipped'

    updateStatus: (status) ->
        @set {
            updatedAt: firebase.database.ServerValue.TIMESTAMP
            updatedBy: auth.id
            status: status
        }
