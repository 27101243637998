# Aggregates multiple Backbone.Firebase collections into one read-only collection.
# Note that IDs should still be unique among all the models.

module.exports = class AggregateCollection extends Backbone.Collection

    constructor: (options) ->
        super null, options

    # Should pass a collections option that is an objects with the key
    # being the 'type' of the collection and the value being
    # the actual collection itself. The type is appended as a
    # '__type__' property on each model to help differentiate them.
    initialize: (models, options={}) ->
        @collections = options.collections

        # Setup initial sync event.
        # If the collections aren't syncd already, then listen once
        # for the sync on each and create a promise for it. Once all
        # those promises are resolved, we can trigger the overall sync.
        # For subsequent syncs, we'll just fire them as they come in.
        initialSyncPromises = []
        _.each @collections, (collection, type) =>
            unless collection.syncd
                deferred = $.Deferred()
                initialSyncPromises.push deferred
                @listenToOnce collection, 'sync', ->
                    deferred.resolve()

            # Proxy events (other than ones we're treating that specially below).
            @listenTo collection, 'all', (evt, args...) =>
                unless evt in ['sync', 'add', 'remove', 'change', 'reset' ]
                    @trigger evt, args...

            # Internally save some of the write methods for use below
            _add = _.bind AggregateCollection::add, @
            _remove = _.bind AggregateCollection::remove, @
            _reset = _.bind AggregateCollection::reset, @

            # Overridge write methods
            @add = -> throw new Error 'AggregateCollection is read-only.'
            @remove = -> throw new Error 'AggregateCollection is read-only.'
            @reset = -> throw new Error 'AggregateCollection is read-only.'
            @push = -> throw new Error 'AggregateCollection is read-only.'
            @pop = -> throw new Error 'AggregateCollection is read-only.'
            @shift = -> throw new Error 'AggregateCollection is read-only.'
            @unshift = -> throw new Error 'AggregateCollection is read-only.'

            # When things are added/removed from the collection,
            # then we should update this aggregate collection.
            @listenTo collection, 'add', (model, coll) ->
                model.__type__ = type
                _add model
            @listenTo collection, 'remove', (model, coll) ->
                _remove model
            @listenTo collection, 'change', (model) ->
                _add model, merge: true
            @listenTo collection, 'reset', (models) ->
                _reset models, {type}

            # If the collection was initialized with some data, set that here.
            if collection.length > 0
                _add collection.models, silent: true
                # Set the __type__ on each of these models as well.
                _.each collection.models, (model) =>
                    newModel = @get id: model.get('id')
                    newModel.__type__ = type


        @on 'sync', =>
            @syncd = true

        # Don't trigger initial sync until all collections have syncd the first time.
        if _.size(initialSyncPromises) > 0
            $.when.apply($, initialSyncPromises).then =>
                @trigger 'sync'
                # Now that all 3 collections have been initially syncd
                # start relaying subsequent syncs.
                _.each @collections, (collection) =>
                    @listenTo collection, 'sync', =>
                        @trigger 'sync'
        else
            @trigger 'sync'


    add: (models, options) -> super
    remove: (models, options) -> super
    reset: (models, options) ->
        # Set should really only set the models on one of the underlying collections.
        # So we look for the type option.
        unless options.type?
            throw new Error 'Must specify a type option when calling AggregateCollection.reset'
        # Collect all the models that aren't in the target collection.
        otherModels = []
        targetType = options.type
        _.each @collections, (collection, type) ->
            unless type is targetType
                otherModels = _.union otherModels, collection.where(__type__: type)
        # Join these models from the other collections with the incoming ones and
        # then do a real set.
        delete options.type
        super _.union(otherModels, models), options

