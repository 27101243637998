{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class RulesCollection extends Backbone.Firebase.Collection
    model: require 'models/rule'
    url: ->
        accountId = @accountId ? auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/rules"

    initialize: (models, options={}) ->
        {@accountId} = options
        @once 'sync', ->
            @syncd = true
        super

