# Just overrides the default name of the Flow model
BaseFlowModel = require 'models/flow-base'
baseModel = _.clone BaseFlowModel
baseModel.defaults = _.extend {}, baseModel.defaults, {
    name: 'Untitled Slideout'
    patternType: 'shorty'
    backdrop: false
    position: 'bottomLeft'
}
module.exports = Backbone.Model.extend baseModel

