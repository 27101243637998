CategoryModel = require 'models/category'
{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class CategoriesCollection extends Backbone.Firebase.Collection
    model: CategoryModel
    url: ->
        accountId = @accountId ? auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/categories"

    defaultAttrs = [
        {index: 0, name: 'New User Onboarding'},
        {index: 1, name: 'Announcements'},
        {index: 2, name: 'Feature Tutorials'},
        {index: 3, name: 'User Surveys'},
    ]

    initialize: (attributes, options={})->
        {@accountId} = options

        @once 'sync', ->
            modified = false
            oldCategories = @filter (c) ->
                c.get('identifier') and not c.get('name')
            if oldCategories.length isnt 0
                @remove oldCategories, {silent: true}
                modified = true

            if @length is 0
                @add defaultAttrs, {silent: true}
                modified = true

            @syncd = true
            @trigger('sync') if modified

    comparator: 'index'

