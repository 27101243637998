{firebaseRef} = require 'lib/firebase'
urls = require 'lib/urls'

module.exports = class AccountStatsModel extends Backbone.Firebase.Model

    # This model is updated from a lambda, so we shouldn't be updating it.
    autoSync: false

    initialize: (attrs, options={}) ->
        {@accountId} = options

    url: ->
        firebaseRef.child "accounts/#{@accountId}/account-stats"

    hasStats: ->
        return _.size(@attributes) and _.reduce(@attributes, (memo, val) ->
            return memo + val
        , 0)
