{firebaseRef} = require 'lib/firebase'
flattenObject = require 'lib/flatten'
auth = require 'models/auth'


module.exports = class AccountUsersModel extends Backbone.Firebase.Model
    defaults:
        users: {}

    url: -> firebaseRef.child "accounts/#{@accountId}/users"

    initialize: (@accountId) ->

        @once 'sync', ->
            @syncd = true
            @users = @attributes

    isAccountAdmin: (userId) ->
        userOnAccount = @users[userId]

        if (userOnAccount)
            userOnAccount.isAccountAdmin || false
        else
            false
