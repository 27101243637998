# Merges objects and keeps all keys and values by adding a prefix.
mergeKeep = (prefix, target, objs...) ->
    return unless prefix

    for obj in objs
        for k, val of obj
            key = getUniqueName(target, k, prefix)
            target[key] = val

    return target

getUniqueName = (target, key, prefix) ->
    if target[key]
        newKey = prefix + key
        # Keep prepending underscores.
        return getUniqueName(target, newKey, '_')
    return key


module.exports = mergeKeep
