StepActionView = require 'views/step-actions/step-action'
editor = require 'lib/editor'


module.exports = class StepActionFormView extends StepActionView
    __name__: 'StepActionFormView'
    template: require 'views/templates/step-actions/form'
    editorTarget: ".redactor"

    initialize: (options={}) ->
        super

        @formId = @model.get('formId') ? null
        unless @formId?
            forms = new (require('collections/forms'))
            formAttrs = forms.create {}
            @formId = formAttrs.id
            @model.set('formId', @formId)

        FormFieldsCollection = require 'collections/form-fields'
        @fields = new FormFieldsCollection([], {formId: @formId})
        @fields.whenSyncd =>
            @render()
            @fields.on 'add change sort', => @setContent()

    defaultContent: -> ''

    getContext: ->
        _.extend(super, {@formId, @fields})

    renderContent: (isEditable=false) ->
        $content = $ @template _.extend(@getContext(), {isEditable})
        FormFieldsView = require 'views/form-fields'
        view = new FormFieldsView
            collection: @fields
            isEditable: isEditable
        $content.find('.fields-container').append(view.el)
        @renderChild(view)
        $content

    render: ->
        # Remove pre-existing editor.
        editor.remove @$(@editorTarget)
        @removeChildViews()

        @$el.html(@renderContent(@isEditable))

        save = _.bind(@updateModel, @)

        editor.standard @$(@editorTarget), {
            # Save changes in content.
            changeCallback: save
            codeKeyupCallback: -> save @code.get()
            sourceCallback: save
            visualCallback: save

            toolbarExternal: '#step-editor-toolbar'
        }

    updateModel: ->
        @model.set('description', @$(@editorTarget).val())
        @setContent()

    setContent: ->
        $content = @renderContent(false)
        $content.find('.step-action-buttons').remove()
        @model.set('content', $content.html())

    remove: ->
        # Remove redactor editor if it exists.
        editor.remove @$(@editorTarget)
        super
