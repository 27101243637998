{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'
hubspot = require 'lib/hubspot'
AnnouncementModel = require 'models/announcement-firebase'
RuleModel = require 'models/rule-firebase'
helpers = require 'lib/helpers'
sls = require 'lib/sls-helpers'

module.exports = class AnnouncementsCollection extends Backbone.Firebase.Collection
    model: require 'models/announcement'

    initialize: (models, options) ->
        @accountId = options?.accountId ? auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{@accountId}/announcements"

        @once 'sync', ->
            @syncd = true

            # TO DO: Uncomment this section after Announcements edit from dashboard is up.

            # data =
            #     totalAnnouncements: @length
            #     totalAnnouncementsPublished: @where({published: true}).length
            # try
            #     analytics.identify data
            # try
            #     hubspot.identify {
            #         num_announcements: data.numAnnouncements
            #         num_announcements_published: data.numPublished
            #     }

    cloneModel: (announcementId, options={}) ->
        {announcementName, noCopyOf} = options
        announcement = @get(announcementId)
        return unless announcementId and announcement

        sls.clone(announcement)
            .done(({new_content_id}) => @trigger 'clone', @, new_content_id)
