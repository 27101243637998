BaseView = require 'views/base'
ModalView = require 'views/modal'
HintModalView = require 'views/hint-modal'


module.exports = class StepSettingsView extends BaseView
    __name__: 'StepSettingsView'
    className: 'step-settings'
    template: require 'views/templates/step-settings'

    initialize: (options) ->
        @isEditable = options.isEditable

        @listenTo @model, 'change:stepType', @render

    events: ->
        'click .show-video-hint': 'showVideoHint'
        'click .show-social-settings': 'showSocialSettings'

    render: ->
        @$el.html @template {
            step: @model.toJSON()
            isEditable: @isEditable
        }

    showVideoHint: ->
        hintTemplate = require 'views/templates/hint-video'
        view = new HintModalView
            title: 'Our Favorite Video Resources'
            content: hintTemplate()
            show: true
        view.render()

    showSocialSettings: ->
        modalTemplate = require 'views/templates/social-modal'
        view = new ModalView
            title: 'Configure your social media buttons'
            content: modalTemplate(@model.toJSON())
            show: true
        @listenToOnce view, 'save', @saveSettings, @
        view.render()

    saveSettings: ($modal) ->
        data = $modal.find('form').serializeObject()
        @model.set data

