{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'

# Bootstrapped activities
defaultSetupActivities = [
    name: 'Create an account'
    description: "Create a username and password so you don't lose your work."
    id: 'create_account'
    status: 'completed'
,
    name: 'Create content'
    description: "Start off by creating a flow that will help your users understand what your product's all about."
    destinationUrl: '/create'
    destinationText: 'Try it now'
    id: 'create_content'
,
    name: 'Install your SDK'
    description: "Install the Appcues snippet to start onboarding users. We've compiled a step-by-step guide to make things easy for your developers."
    destinationUrl: '/install'
    destinationText: 'View instructions'
    id: 'install_sdk'
,
    name: 'Review with a colleague'
    description: "A better user experience is just a few clicks away. How about sharing your work with a colleague before releasing it into the wild?"
    destinationUrl: '/?invite_user'
    destinationText: 'Invite a teammate'
    id: 'invite_user'
]


module.exports = class SetupActivitiesCollection extends Backbone.Firebase.Collection
    model: require 'models/setup-activity'

    initialize: ->
        @once 'sync', ->
            @syncd = true

            unless @length
                @add defaultSetupActivities, {silent: true}

    url: ->
        accountId = auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/setup-activities"
