module.exports = (function() {
    var auth, properties, propertyNames;

    auth = require('models/auth');
    properties = require('models/account-properties');
    propertyNames = [];
    var onSync = function () {
        _.each(_.keys(properties.toJSON()), function (propertyName) {
            if (propertyName[0] !== '_') {
                propertyNames.push(propertyName);
            }
        });
    }
    properties.syncd ? onSync() : properties.once('sync', onSync);

    return {
        filepicker: function () {
            var pickerOptions, pickerSuccess;

            pickerOptions = {
                multiple: false,
                mimetype: 'image/*',
                services: ['COMPUTER', 'DROPBOX', 'GOOGLE_DRIVE'],
                maxSize: 20971520
            };

            pickerSuccess = function(images) {
                var image, _i, _len, _results;
                _results = [];
                for (_i = 0, _len = images.length; _i < _len; _i++) {
                    image = images[_i];
                    _results.push(this.image.insert({
                        filelink: image.url
                    }, false));
                }
                return _results;
            };

            return {
                init: function() {
                    var button = this.button.addAfter('link', 'filepicker', 'File Picker');
                    this.button.addCallback(button, this.filepicker.show);
                },
                show: function() {
                    var userId = auth.get('id');
                    return filepicker.pickAndStore(pickerOptions, {
                        path: "/" + userId + "/"
                    }, _.bind(pickerSuccess, this), Raven.captureException);
                }
            };
        },
        userdata: function () {
            var createInserter = function (redactor, propertyName) {
                return function () {
                    redactor.insert.text(' {{' + propertyName + '}} ');
                }
            }

            return {
                init: function () {
                    var button, redactor, dropdown;
                    redactor = this;
                    button = this.button.add('userdata', 'Insert User Data');
                    this.button.setAwesome('userdata', 'fa-user');
                    dropdown = _.map(propertyNames, function (propertyName) {
                        return {
                            title: propertyName,
                            func: createInserter(redactor, propertyName)
                        };
                    });
                    this.button.addDropdown(button, dropdown);
                }
            };
        }
    };
})();
