BaseComponentView = require 'views/components/base'


module.exports = class VideoView extends BaseComponentView
    __name__: 'VideoView'
    className: 'component-editor video-component appcues-style'
    template: require 'views/templates/components/video'

    events: ->
        _.extend {}, super, {
            'change [name=embedCode]': 'updateEmbedCode'
            'click [data-action=toggleTips]': 'toggleTips'
        }

    initialize: ->
        super
        @viewState = new Backbone.Model {
            showingTips: false
        }
        @listenTo @viewState, 'change', @render

    getContext: ->
        _.extend {}, super, @viewState.toJSON()

    focus: ->
        @$('[name=embedCode]').focus()

    updateValue: ->
        @model.set {embedCode: @$('[name=embedCode]').val()}, {skipRender: true}

    toggleTips: ->
        @viewState.set {showingTips: !@viewState.get('showingTips')}
