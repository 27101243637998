{firebaseRef} = require 'lib/firebase'
crxHelperModel = require 'models/crx-helper'

TRIAL_LENGTH_DAYS = window.__env.TRIAL_LENGTH_DAYS or 30
CRX_V2_VERSION = '0.1.0'
ENV = window.__env

module.exports = class UserModel extends Backbone.Firebase.Model
    defaults:
        id: null
        fullname: ''
        email: ''
        tel: ''
        role: null
        lastSeenTimestamp: firebase.database.ServerValue.TIMESTAMP
        createdAt: firebase.database.ServerValue.TIMESTAMP
        avatarUrl: ''
        crxVersion: ''

        isInvitedUser: false
        isAdmin: null

        firstUsedComponents: null

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true

            if attrs.track
                try
                    analytics.identify @id, @toJSON()

        @on 'change:email', ->
            @set avatarUrl: @avatarUrl()

        @listenTo crxHelperModel, 'change:version', () =>
            @set('crxVersion', crxHelperModel.get('version'))

    url: -> firebaseRef.child "users/#{@id}/meta"

    toJSON: ->
        h = _.pick(@attributes, _.keys(@defaults))
        h.avatarUrl ?= @avatarUrl()
        h

    avatarUrl: ->
        return unless @get('email')
        emailHash = SparkMD5.hash(@get('email').toLowerCase())
        "//www.gravatar.com/avatar/#{emailHash}?d=https%3A%2F%2Fmy.appcues.com%2Fimages%2Favatar.png&s=256"

    isAdmin: ->
        @get('isAdmin') ? false

    updateTimestamps: () ->
        currentTimestamp = new Date().getTime()
        unless (currentTimestamp - @get('lastSeenTimestamp')) < (30 * 60 * 1000)
            @set("lastSeenTimestamp", currentTimestamp)
