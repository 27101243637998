BaseView = require 'views/base'
RuleModel = require 'models/rule-firebase'
FlowStatsModel = require 'models/flow-stats'
publisher = require 'lib/publish'
{confirm} = require 'views/alerts'
CONTENT_STATES = require 'lib/content-states'
auth = require 'models/auth'
helpers = require 'lib/helpers'
crxHelperModel = require 'models/crx-helper'
sls = require 'lib/sls-helpers'

module.exports = class ContentItemView extends BaseView
    __name__: 'ContentItemView'
    tagName: 'tr'
    className: -> 'content-item'
    labels: {}

    events: ->
        'click [data-action=clone]': 'cloneModel'
        'click [data-action=delete]': 'deleteModel'
        'click [data-action=archive]': 'archiveModel'
        'click [data-action=unarchive]': 'unarchiveModel'
        'click [data-action=design]': 'designModel'

    initialize: (options={}) ->
        {@rule, @user, @lastEditedBy, @createdBy} = options

        # Make sure we have a rule
        @rule = new RuleModel(id: @model.id) unless @rule

        # Re-render once everything is synced
        promises = [@model.whenSyncd(), @rule.whenSyncd()]
        promises.push(@user.whenSyncd()) if @user?

        $.when(promises...).then () =>

            # bring in the flow stats at this point
            if(@model.get('type') == "journey")

                if _.keys(@model.get('steps')).length == 1 and @model.get('migratedFromStepId')

                    # if this is a 'migrated' flow, we'll want to reference its `migratedFromStep` stats until
                    # the health lambda is updated to handle the id-change caused by the migration
                    @stats = new FlowStatsModel(accountId: auth.currentAccount.id, id: @model.get('migratedFromStepId'))

            unless @stats

                # if we haven't set a `stats` model yet, set one now
                @stats = new FlowStatsModel(accountId: auth.currentAccount.id, id: @model.id)

            @stats.whenSyncd().then(=> @render())
            @listenTo @stats, 'change', @render

            @render()

        @listenTo @model, 'change', @render
        @listenTo auth.user, 'change:hasCrxInstalled', @render

    getContext: ->
        modelAttrs = @model.toJSON()
        ruleAttrs = @rule.toJSON()
        statsAttrs = if @stats then @stats.toJSON() else {}
        if modelAttrs.type == "journey"
            journeyAttrs =
                stepType: if _.keys(modelAttrs.steps).length == 1 then @model.getStepAt(0)?.stepType else "journey"

        {
            model: modelAttrs
            rule: ruleAttrs
            stats: statsAttrs
            prefix: @model.urlPrefix()
            isUserTargeted: not _.isEmpty(ruleAttrs.properties ? {})
            targetedBy: _.keys(ruleAttrs.properties ? {})
            user: @user?.toJSON()
            lastEditedBy: @lastEditedBy?.toJSON()
            createdBy: @createdBy?.toJSON()
            isInstalled: auth.currentAccount.get('isInstalled')
            editUrl: @editUrl()
            detailsUrl: @detailsUrl()
            journeyAttrs: journeyAttrs
        }

    archiveModel: (evt) ->
        @$el.addClass("archiving")
        sls.archive(@model)
        try
            lytics.trackWithUser @labels.archiveEvent, {id: @model.id}

    unarchiveModel: (evt) ->
        @$el.addClass("unarchiving")
        sls.unarchive(@model)
        try
            lytics.trackWithUser @labels.unarchiveEvent, {id: @model.id}

    deleteModel: (evt) ->
        _delete = =>
            @$el.addClass("deleting")
            sls.delete(@model)
            try lytics.trackWithUser @labels.deleteEvent, {id: @model.id}

        if evt?.shiftKey
            _delete()
        else
            confirm("Are you sure you want to delete #{@labels.model}?").then (res) ->
                _delete() if res
        return false

    cloneModel: ->
        @$el.addClass('cloning')
        @collection.cloneModel @model.id
        try lytics.trackWithUser @labels.cloneEvent, {id: @model.id}
        return false

    editUrl: ->
        flowId = @model.get('id')
        contentType = @model.urlPrefix()

        return "/#{contentType}/#{flowId}/edit"

    detailsUrl: ->
        flowId = @model.get('id')
        contentType = @model.urlPrefix()

        return "/#{contentType}/#{flowId}/analytics"

    designModel: (evt) ->
        evt.preventDefault()

        if @model.urlPrefix() != 'coachmarks'
            flowId = @model.get('id')
            contentType = @model.urlPrefix()
            crxHelperModel.designAtUrlOrShowInstallModal @rule.get('previewUrl'), @model.get('id')
