{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'


module.exports = class BookmarksCollection extends Backbone.Firebase.Collection
    model: require 'models/bookmark'
    url: ->
        accountId = auth.currentAccount.id
        firebaseRef.child "accounts/#{accountId}/bookmarks"

    initialize: (attrs={}) ->
        @once 'sync', -> @syncd = true
        {@accountId} = attrs

    comparator: 'name'
