module.exports = class HotspotModel extends Backbone.Model
    defaults:
        content: ''
        selector: ''
        event: 'click'
        patternType: 'beacon'

    toJSON: ->
        # Remove things that shouldn't be persisted.
        @omit [
            'removeable'
            'editable'
            'isVisible'
            'node'
            'sequential'
            'skippable'
            'edgeCombo'
            'isLastHotspot'
        ]
