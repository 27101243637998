AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class ChecklistsEditView extends AppView
    __name__: 'ChecklistAnalyticsView'
    className: 'checklist-analytics'
    template: require 'views/templates/checklist-analytics'

    initialize: (options) ->
        {@checklistId} = options

    afterInitialize: () ->

    render: ->

        super

        if(window.apcInjectable)
            window.apcInjectable.renderChecklistAnalytics auth.currentAccount.id,
            @checklistId,
            "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)