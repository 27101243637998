AppView = require 'views/app'
auth = require 'models/auth'
bundler = require 'lib/bundler'
AccountMembersCollection = require 'collections/account-members'
AccountMembershipsCollection = require 'collections/account-memberships'
InviteUsersModalView = require 'views/invite-modal'
UserProfileModalView = require 'views/user-profile-modal'
{switchUser} = require 'lib/helpers'
{ACCOUNTDOCK_KEY} = window.__env

SHOW_ALERT_TIMEOUT = 3000

MS_PER_HOUR = 3.6e+6

module.exports = class AccountView extends AppView
    __name__: 'AccountView'
    template: require 'views/templates/account'

    events:
        'submit form.account-form': 'updateAccount'
        'submit form.support-form': 'updateSupport'
        'keyup form.saveable-form input': 'activateSaveButton'
        'click [data-action=editProfile]': 'showUserProfileModal'
        'submit form.su': 'spoofUser'
        'change input.toggle-buffer': 'setBuffer'
        'click [data-action=openAccountDock]': 'openAccountDock'

    initialize: ->
        @viewState = new Backbone.Model {
            isSavingAccount: false
            isSavingUser: false
            savedAccount: false
            savedUser: false
            isBufferEnabled: auth.currentAccount.get('buffer')?
        }

        @accountMembers = new AccountMembersCollection [], {accountId: auth.currentAccount.id}

        @listenTo @viewState, 'change', @render
        @listenTo @accountMembers, 'sync', @render

        auth.onAuth =>
            @listenTo auth.user, 'change:id change:isAdmin', @render
            @listenTo auth.currentAccount, 'change:id change:stripeId', @render
            @listenTo auth.currentAccount, 'invalid', =>
                @viewState.set {isSavingAccount: false}
            @accountdockHandler = AccountDock.configure({
                key: ACCOUNTDOCK_KEY,
                customer: auth.currentAccount.get('stripeId')
            })

    activateSaveButton: (e) ->
        @$(e.currentTarget)
            .parents('form')
            .find('button[type=submit]')
                .removeClass('disabled')
                .addClass('btn-primary')

    openAccountDock: ->
        @accountdockHandler.open?()

    spoofUser: ->
        switchUser(@$ 'form.su')

    updateAccount: ->
        @stopListening auth.currentAccount, 'sync'

        $form = @$ 'form.account-form'
        data = $form.serializeObject()

        # Sanitize domains.
        domains = data.domains
            .replace(/\s/g, '')  # Whitespace.
            .replace(/,$/, '')   # Trailing comma.
            .split ','           # Convert to array.

        _domains = []
        for domain in domains
            # Get domain only.
            if /^https?/.test domain
                match = domain.match(/^https?:\/\/(.*?)(?=\/|\?|$)/, '$1')
                domain = match and match[1]

            # Remove protocol.
            domain = domain.split(':')[0]

            _domains.push(domain) if domain

        data.domains = _domains.join ','

        # Clean up siteUrl.
        if data.siteUrl
            data.siteUrl = data.siteUrl.trim()
                .replace /\/$/, ''

        # Set or unset buffer.
        if data.buffer
            # Only buffers north of 0 are allowed.
            data.buffer = parseInt(data.buffer, 10) * MS_PER_HOUR
            unless data.buffer > 0
                data.buffer = null
        else
            data.buffer = null

        changes = false
        _.each data, (val, key) ->
            if val isnt auth.currentAccount.attributes[key]
                changes = true

        if changes
            @viewState.set {isSavingAccount: true}

            @listenToOnce auth.currentAccount, 'sync', ->
                # Trigger a rebundle.
                bundler.sync auth.currentAccount.id

                @viewState.set {isSavingAccount: false, savedAccount: true}
                @render()
                _.delay(=>
                    @viewState.set {savedAccount: false}
                    @render()
                , SHOW_ALERT_TIMEOUT)

            auth.currentAccount.set data, {validate: true}

        return false

    updateSupport: ->
        @stopListening auth.currentAccount, 'sync'

        $form = @$ 'form.support-form'
        data = $form.serializeObject()

        changes = false
        _.each data, (val, key) ->
            if val isnt auth.currentAccount.attributes[key]
                changes = true

        if changes
            @viewState.set {isSavingSupport: true}

            @listenToOnce auth.currentAccount, 'sync', ->
                # Trigger a rebundle.
                bundler.sync auth.currentAccount.id

                @viewState.set {isSavingSupport: false, savedSupport: true}
                @render()
                _.delay(=>
                    @viewState.set {savedSupport: false}
                    @render()
                , SHOW_ALERT_TIMEOUT)

            auth.currentAccount.set data

        return false


    toggleChangePassword: ->
        @viewState.set('changingPassword', !@viewState.get('changingPassword'))
        return false

    setBuffer: (e) ->
        @viewState.set 'isBufferEnabled', e.currentTarget.checked

    getContext: ->
        account = auth.currentAccount.toJSON()
        user = auth.user.toJSON()
        currentMember = _.findWhere @accountMembers.toJSON(), {id: user.id}


        if _.isNumber(account.buffer)
            buffer = account.buffer / MS_PER_HOUR

        return _.extend @viewState.toJSON(), {
            auth: auth.toJSON()
            user: user
            accountId: auth.currentAccount.id
            account: account
            stripeId: auth.currentAccount.stripeId
            isBeta: auth.currentAccount?.isInGeneralBeta?()
            accountValidationError: auth.currentAccount.validationError or {}
            buffer: buffer
            isIFramed: window isnt window.top
            currentMember: currentMember
            hideInviteUser: auth.currentAccount?.hasFeatureGate("hideInviteUser")
            hideApiKey: auth.currentAccount?.hasFeatureGate("hideApiKey")
        }

    showUserProfileModal: ->
        v = new UserProfileModalView
            model: auth.user
            show: true
            onSuccess: => @render()
        @renderChild(v)
