AppView = require 'views/app'
auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'

module.exports = class SatisfactionSurveysView extends AppView
    __name__: 'SatisfactionSurveysView'
    className: 'satisfaction-surveys'
    template: require 'views/templates/satisfaction-surveys'


    initialize: (options = {}) ->

        isNPSProductTrial = auth?.currentAccount?.get("isNPSProductTrial")
        if options.product is 'nps-product' and !isNPSProductTrial
            accountId = auth.currentAccount.id
            firebaseRef.child("accounts/#{accountId}/meta").update {
                isNPSProductTrial: true
                npsTrialLengthDays: 30
            }

    afterInitialize: () ->

    render: ->
        super

        if(window.apcInjectable)
            window.apcInjectable.renderSatisfaction auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
