AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class ExperienceSettings extends AppView
    __name__: 'Experience Settings'
    className: 'experience-settings-view'
    template: require 'views/templates/reactcues-container'

    initialize: (options) ->
        {@experienceId} = options

    afterInitialize: () ->

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderExperienceSettings auth.currentAccount.id, @experienceId, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
