BaseView = require 'views/base'
auth = require 'models/auth'
{confirm} = require 'views/alerts'

module.exports = class FormFieldView extends BaseView
    __name__: 'FormFieldView'
    className: 'field'
    model: new (require('models/form-field'))
    addButtonTemplate: require 'views/templates/form-fields/field-type-buttons'
    addDropdownTemplate: require 'views/templates/form-fields/field-type-dropdown'
    popoverTemplate: require 'views/templates/form-fields/field-options'
    _typeViews:
        default: require 'views/form-fields/field'
        text: require 'views/form-fields/text'
        textarea: require 'views/form-fields/textarea'
        choice: require 'views/form-fields/choice'
        rating: require 'views/form-fields/rating'

    initialize: (attrs={}) ->
        {@isEditable} = attrs
        @listenTo @model, 'change:fieldType change:required change:validationType change:ratingType', @render

    attributes: ->
        'data-field-id': @model.cid

    events: ->
        'click .choose-field-type': 'setFieldType'
        'click [data-action=removeField]': 'removeField'
        'change .field-type-dropdown': 'setFieldTypeDropdown'
        'change input[name*=required]': 'setRequired'
        'change input[name*=validationType]': 'setValidationType'
        'change input[name*=ratingType]': 'setRatingType'

    setFieldType: (e) ->
        @model.set 'fieldType', @$(e.currentTarget).data 'field-type'
        @$el.find('input:visible, textarea:visible').first().focus()

    setFieldTypeDropdown: (e) ->
        @model.set 'fieldType', @$(e.currentTarget).val()
        @$el.find('input:visible, textarea:visible').first().focus()

    _updateSetting: (e, fieldName) ->
        $selected = @$("input[name*=#{fieldName}]:checked")
        $label = $selected.closest('label')
        $label.siblings().addClass('btn-default').removeClass('btn-primary')
        $label.addClass('btn-primary').removeClass('btn-default')
        @model.set(fieldName, $selected.val())

    setRequired: (e) ->
        # Doesn't use _updateSetting because it needs to convert to boolean.
        $selected = @$("input[name*=required]:checked")
        $label = $selected.closest('label')
        $label.siblings().addClass('btn-default').removeClass('btn-primary')
        $label.addClass('btn-primary').removeClass('btn-default')
        @model.set('required', $selected.val() is 'true')

    setValidationType: (e) ->
        @_updateSetting(e, 'validationType')

    setRatingType: (e) ->
        @_updateSetting(e, 'ratingType')

    removeField: (e) ->
        e?.preventDefault()
        confirm("Are you sure you want to delete this field?").then (res) =>
            if res
                @remove()
                # Work-around for https://github.com/firebase/backfire/issues/49. Bleh.
                @model.collection.remove @model

    render: ->
        @removeChildViews()
        if fieldType = @model.get('fieldType')
            @renderFieldType(fieldType)
        else if @isEditable

            if $('appcues cue').width() > 400

                @$el.html(@addButtonTemplate())

            else

                @$el.html(@addDropdownTemplate())

                @$('.field-type-dropdown').selectize
                    maxItems: 1,
                    labelField: 'name',
                    valueField: 'value',
                    placeholder: 'Select a form component...'
                    options: [
                        { name: 'Text', icon: 'fa-font', value: 'text' }
                        { name: 'Textarea', icon: 'fa-align-left', value: 'textarea' }
                        { name: 'Choice', icon: 'fa-list-ul', value: 'choice' }
                        { name: 'Rating', icon: 'fa-star', value: 'rating' }
                    ]
                    render:
                        item: (item, escape) ->
                            return "<div><div class=\"icon\"><i class=\"fa #{item.icon}\"/></div><div class=\"text\">#{escape item.name}</div></div>"
                        option: (item, escape) ->
                            return "<div><div class=\"icon\"><i class=\"fa #{item.icon}\"/></div><div class=\"text\">#{escape item.name}</div></div>"

        @

    renderFieldType: (fieldType) ->
        view = new @_typeViews[fieldType]
            model: @model
            isEditable: @isEditable

        @renderChild(view)
        @$el.html(view.el)

        @$('a.field-options').popover({
            content: @popoverTemplate @model.toJSON()
            html: true
            trigger: 'focus'
            placement: 'bottom'
        })

