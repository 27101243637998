StepView = require 'views/step'
StepSettingsView = require 'views/step-settings'
GloballyStylable = require 'views/mixins/global-styling'

class SandboxedStepView extends StepView
    __name__: 'SandboxedStepView'
    template: require 'views/templates/sandboxed-step'
    className: null

    initialize: (options={}) ->
        {patternType, position, backdrop, stepNumber, totalSteps, skippable, isFirst, isLast, showPoweredByText, isEditable} = options
        @viewState = new Backbone.Model {patternType, position, backdrop, stepNumber, totalSteps, skippable, isFirst, isLast, showPoweredByText, isEditable}
        @applyGlobalStyling {stylingData: options.stylingData}
        super

    events:
        'click .appcues-skip': 'clickedSkip'
        'mousemove .editable-buttons [contenteditable]': 'mouseEnteredNextBtn'
        'mouseleave .editable-buttons [contenteditable]': 'mouseLeftNextBtn'

    clickedSkip: (e) ->
        position = {
            clientX: e.clientX,
            clientY: e.clientY
        }
        messageData = { action: 'clicked skip', position }
        parent.postMessage JSON.stringify(messageData), '*'

    mouseEnteredNextBtn: (e) ->
        position = {
            clientX: e.clientX,
            clientY: e.clientY
        }
        messageData = { action: 'mouse entered next button', position }
        parent.postMessage JSON.stringify(messageData), '*'

    mouseLeftNextBtn: () ->
        messageData = { action: 'mouse left next button' }
        parent.postMessage JSON.stringify(messageData), '*'

    render: ->
        @removeChildViews()
        @$el.html @template @getContext()
        @renderStepType()
        @renderStepSettings()

    renderStepType: ->
        stepType = @model.get('stepType')
        attrs = {
            model: @model
            isEditable: @viewState.get('isEditable')
            isFirst: @viewState.get('isFirst')
            isLast: @viewState.get('isLast')
            showPoweredByText: @viewState.get('showPoweredByText')
        }
        view = new (@_actionViews[stepType] ? @_actionViews.default)(attrs)

        # adding a new structure for shorties and slideouts, as we want progress/etc to behave as a unit
        switch @viewState.get('patternType')
            when "shorty"
                @$('modal-container').append view.el
            else
                @$('appcues').append view.el

        @renderChild view

        # Clear the model's attributes when the view is removed.
        @listenToOnce view, 'clear', => @model.clear()

    renderStepSettings: ->
        stepSettingsView = new StepSettingsView {model: @model}
        @renderChild stepSettingsView
        @$('.step-settings-container').append(stepSettingsView.el)

    getContext: ->
        c = @viewState.toJSON()
        c.globalStyling = @globalStyling.get 'globalCss'
        return c

    updateModel: (model) ->
        return unless model?
        @stopListening @model, 'change:stepType', @render
        @model = model
        @listenTo @model, 'change:stepType', @render

    updateStyle: (style) ->
        return unless style?
        @applyGlobalStyling style

_.extend SandboxedStepView::, GloballyStylable
module.exports = SandboxedStepView
