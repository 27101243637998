BaseView = require 'views/base'
trackEvent = require 'lib/track-event'

module.exports = class UninstallCRXView extends BaseView
    __name__: 'UninstallCRXView'
    className: 'uninstall-crx-view'
    template: require 'views/templates/uninstall/uninstallCRX'

    initialize: (options={}) ->
        {@userId} = options

        window.analytics?.ready(() =>
            user = analytics.user()
            userId = @userId || user.id() || user.anonymousId()

            window.analytics.identify(@userId)
            trackEvent("Uninstalled CRX")
        )

    render: ->
        @removeChildViews()
        super

