auth = require 'models/auth'
helpers = require 'lib/helpers'
mixins = require 'models/mixins'
CONTENT_STATES = require 'lib/content-states'


BaseFlowModel =
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        state: CONTENT_STATES.DRAFT
        published: false

        sortIndex: null

        name: 'Untitled Modal'
        description: ''
        isValid: true
        skippable: true
        patternType: 'modal'
        position: 'top'
        backdrop: true
        redirectUrl: null # Deprecated
        goalId: null
        sandbox: true
        prehook: null
        style: null
        testVersionOf: null

        categories: []

        # Analytics.
        totalUniqueViews: null
        totalDuration: null
        completionPercentage: null
        stepsAnalytics: []
        goalAnalytics: []

    patterns: ['left', 'modal', 'fullscreen', 'shorty']

    getPatterns: ->
        _.map @patterns, @getPattern

    getPattern: (patternName) ->
        _.clone helpers.patternLookup[patternName]

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true
            @deprecate()

            if @get('isSample') and auth.get('id') isnt 'sample'
                # An updatedAt change signifies tutorial completion.
                @once 'change:updatedAt', @_removeIsSampleFlag()

    deprecate: ->
        attrs = @toJSON()

        propertiesToMigrate =
            completionRate: 'completionPercentage'
            completionStatus: null
            userCount: 'totalUniqueViews'
            totalTime: 'totalDuration'

        for from, to of propertiesToMigrate
            if attrs[from]?
                @set(to, attrs[from]) if to
                @unset from

    validate: (attrs, options) ->
        return @_validateUrl attrs.redirectUrl

    _validateUrl: (url) ->
        # TODO: Do some URL validation.
        # @stephenhay regex from http://mathiasbynens.be/demo/url-regex
        # validUrlRegex = /^(https?|ftp):\/\/[^\s\/\$\.\?#].[^\s]*$/

        # For now, just don't allow whitespaces.
        if url and not /^[\S]*$/.test url
            return 'This URL can\'t contain whitespace.'

    _removeIsSampleFlag: ->
        @set 'isSample', false

    urlPrefix: ->
        if @get('patternType') == 'shorty'
            'slideouts'
        else
            'flows'


module.exports = _.extend BaseFlowModel
