auth = require 'models/auth'
helpers = require 'lib/helpers'
CONTENT_STATES = require 'lib/content-states'

BaseJourneyModel =
    defaults:

        createdAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        updatedBy: auth.id
        versionId: firebase.database.ServerValue.TIMESTAMP

        state: CONTENT_STATES.DRAFT
        published: false

        name: 'Untitled Flow'
        type: "journey"

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true

    getStepAt: (index) ->
        _.find(@get("steps"), (it) -> it.index == index)

    urlPrefix: ->
        'journeys'

module.exports = _.extend BaseJourneyModel
