PropertiesModel = require 'models/properties-api'
trackActivities = require 'lib/setup-activities'
auth = require 'models/auth'

# We want a singleton of PropertiesModel that's tied to the properties for the
# currently logged-in account specifically.
class AccountProperties extends PropertiesModel
    initialize: (attrs,options={}) ->
        super

        # Report account properties details to Segment.
        @once 'sync', @trackAnalytics

    trackAnalytics: ->
        attrs = @toJSON()
        customProperties = @filterCustomProperties()


properties = new AccountProperties()
properties.fetch()

module.exports = properties
