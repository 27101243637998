ModalView = require 'views/modal'


module.exports = class NewBookmarkView extends ModalView
    __name__: 'NewBookmarkView'
    className: 'new-bookmark modal fade'
    template: require 'views/templates/new-bookmark'

    events:
        'click [data-action=save]': 'save'
        'keyup [name=name]': 'saveOnEnter'

    initialize: (options={}) ->
        super
        {@bookmarkAttrs, @rule, @bookmarks} = options

    getContext: ->
        attrs: @bookmarkAttrs

    render: ->
        super
        # Wait for the modal to show, then focus the name input
        _.delay(=>
            @$('[name=name]').first().focus()
        , 500)

    save: ->
        @bookmarkAttrs.name = @$('[name=name]').val()
        if s.trim(@bookmarkAttrs.name)
            @listenToOnce @bookmarks, 'add', (bookmark) ->
                @$el.on 'hidden.bs.modal', =>
                    @rule.set bookmarkId: bookmark.id
                @hide()
            @bookmarks.add @bookmarkAttrs
        else
            @$('.form-group').addClass('has-error').find('input').focus()

    saveOnEnter: (evt) ->
        @save() if evt.which is 13

