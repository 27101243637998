ModalView = require 'views/modal'
{firebase} = require 'lib/firebase'
{userRoles} = require 'lib/helpers'

SHOW_ALERT_TIMEOUT = 1000

module.exports = class UserProfileModalView extends ModalView
    __name__: 'UserProfileModalView'
    template: require 'views/templates/user-profile-modal'

    events: {
        'click [data-action=changePassword]': 'changePassword'
        'submit form': 'save'
        'click button[type=button]': 'save'
    }

    initialize: (options={}) ->
        @onSuccess = options.onSuccess ? ->
        super
        @viewState = new Backbone.Model {
            savingMeta: false
            saved: false,
            passwordResetStatus: null
        }
        @listenTo @viewState, 'change', @render

    getContext: ->
        _.extend @viewState.toJSON(), {
            user: @model.toJSON()
            userRoles: userRoles
        }

    onChangePasswordSuccess: =>
        @viewState.set {passwordResetStatus: "success"}
        return

    onChangePasswordFailure: =>
        @viewState.set {passwordResetStatus: "failed"}
        return

    changePassword: ->
        { USE_AUTH_DOT_APPCUES } = window.__env

        email = @model.get('email')
        @viewState.set {passwordResetStatus: "pending"}

        if USE_AUTH_DOT_APPCUES
            window.apc_auth.resetPassword(email)
                .then(@onChangePasswordSuccess)
                .catch(@onChangePasswordFailure)

        else
            firebase.auth().sendPasswordResetEmail(email)
                .then @onChangePasswordSuccess
                .catch @onChangePasswordFailure


    save: ->
        data = @$('form').serializeObject()
        # Just close if nothing has changed
        if data.fullname is @model.get('fullname') and data.role is @model.get('role') and not data.currentPassword and not data.newPassword
            @hide()
            return false

        if data.fullname isnt @model.get('fullname') or data.role isnt @model.get('role')
            @viewState.set {savingMeta: true}
            @listenToOnce @model, 'sync', ->
                @viewState.set {savingMeta: false, saved: true}
                _.delay(=>
                    @hide()
                    @onSuccess()
                , SHOW_ALERT_TIMEOUT)
            @model.set _.pick(data, ['fullname', 'role'])

        return false
