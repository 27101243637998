StepActionView = require 'views/step-actions/step-action'
editor = require 'lib/editor'
auth = require 'models/auth'


module.exports = class StepActionDefaultView extends StepActionView
    __name__: 'StepActionDefaultView'
    template: require 'views/templates/step-actions/default'

    render: ->
        @$el.html @template {@isFirst, @isLast, @showPoweredByText, isAdmin: auth.user.get('isAdmin')}
