BaseView = require 'views/base'
PropertiesCollection = require 'collections/properties'
SegmentsCollection = require 'collections/segments'
PropertyFilterView = require 'views/property-filter'
NewSegmentView = require 'views/new-segment'
auth = require 'models/auth'
{propertyDisplay, operatorDisplay, valueDisplay} = require 'lib/rule-helpers'


module.exports = class RulePropertiesView extends BaseView
    __name__: 'RulePropertiesView'
    className: 'rule-properties'
    template: require 'views/templates/build-steps/settings/rule-properties'

    events:
        'change [name=usersOption]': 'changeUsersOption'
        'change [name=segmentId]': 'chooseSegment'
        'change [name=me]': 'chooseOnlyMe'
        'click [data-action=addRuleProperty]': 'addRuleProperty'
        'click [data-action=saveSegment]': 'saveSegment'

    initialize: (options) ->
        {@ruleId, @rule, @content, @contentType} = options
        @viewState = new Backbone.Model(usersOption: null)
        @segments = new SegmentsCollection
        # Collection of property filter objects for this rule.
        @ruleProperties = new PropertiesCollection
        # Object hash of properties for this account.
        @accountProperties = require 'models/account-properties'

        $.when(@accountProperties.whenSyncd(), @segments.whenSyncd(), @rule.whenSyncd()).then =>
            @setPropertiesFromRule()
            @viewState.set(usersOption: @getUsersOption())

            @listenTo @viewState, 'change:usersOption', @render
            @listenTo @rule, 'change:published', @render
            @listenTo @ruleProperties, 'add update remove', @render
            @listenTo @ruleProperties, 'add change remove', ->
                @rule.set('properties', @ruleProperties.toObject())
                @toggleSegmentSaveButton()

            @render()

    getContext: ->
        customProps = @getCustomProperties()
        accountProps = @accountProperties.toJSON()
        segments = @segments.get(@rule.get('segmentId'))

        return {
            ruleId: @ruleId
            rule: @rule.toJSON()
            usersOption: @viewState.get('usersOption')
            accountHasProperties: not _.isEmpty(accountProps)
            accountId: auth.currentAccount.id
            segments: @segments.toJSON()
            selectedSegment: segments?.toJSON()
            segmentProperties: _.omit(segments?.attributes?.properties, "_ABGroup")
            propertyDisplay: propertyDisplay
            operatorDisplay: operatorDisplay
            valueDisplay: valueDisplay
            isPublished: @rule.get('published')
        }

    render: ->
        @removeChildViews()

        super

        @ruleProperties.each (propertyFilter, index, collection) =>
            @addPropertyFilterView(propertyFilter) unless (propertyFilter.isABProperty() or propertyFilter.isMyAppcuesId())
        @$('select[name=segmentId]').selectize {
            render: {
                item: (data, escape) =>
                    el = $("<div style=\"width: 98%; padding-right: 1.3em\">#{data.text}</div>")
                    if properties = _.omit(@segments.get(data.value)?.get('properties'), "_ABGroup")
                        filterCount = _.keys(properties).length
                        if filterCount is 1
                            el.append("<span class=\"text-muted pull-right\">#{propertyDisplay(_.keys(properties)[0])}</span>")
                        else
                            el.append("<span class=\"text-muted pull-right\">#{filterCount} filter#{if filterCount is 1 then '' else 's'}</span>")
                    el.prop('outerHTML')
                option: (data, escape) =>
                    el = $("<div>#{data.text}</div>")
                    #myAppcuesId is an auto property so we have to omit that & the private property _ABGroup
                    if properties = _.omit(@segments.get(data.value)?.get('properties'), "_ABGroup")
                        filterCount = _.keys(properties).length
                        if filterCount is 1
                            el.append("<span class=\"text-muted pull-right\">#{propertyDisplay(_.keys(properties)[0])}</span>")
                        else
                            el.append("<span class=\"text-muted pull-right\">#{filterCount} filter#{if filterCount is 1 then '' else 's'}</span>")
                    el.prop('outerHTML')
            }
        }
        @

    getABProperty: ->
        (p.toJSON() for p in @ruleProperties.models when p.isABProperty())

    getCustomProperties: ->
        (p.toJSON() for p in @ruleProperties.models when !p.isABProperty())

    getInternalProperties: ->
        (p.toJSON() for p in @ruleProperties.models when p.isInternal())
    getMyAppcuesId: ->
        (p.toJSON() for p in @ruleProperties.models when p.isMyAppcuesId())

    getNonMyAppcuesIdProperties: ->
        (p.toJSON() for p in @ruleProperties.models when !p.isMyAppcuesId())

    setPropertiesFromRule: ->
        if (@rule.get('properties') is undefined)
            properties = {}
        else
            properties = @rule.get('properties')

        @ruleProperties.setFromObj properties

    addRuleProperty: ->
        @ruleProperties.add {}
        lytics.trackWithUser 'Added a property filter',
            ruleId: @rule.id

    addPropertyFilterView: (propertyFilter) ->
        unless @$('.properties .property-filter').length is 0
            @$('.properties').append('<div class="form-well text-center"><div>and</div></div>')
        v = new PropertyFilterView
            model: propertyFilter
            userProperties: @accountProperties
            isEditable: not @rule.get('published')
        @renderChild v
        @$el.find('.properties').append(v.el)

    getUsersOption: ->
        if @rule.get('segmentId')? and @segments.get(@rule.get('segmentId'))?
            'segment'
        else if _.isEmpty(@getCustomProperties())
            'all'
        else if @getMyAppcuesId().length is 1
            'me'
        else
            'properties'

    changeUsersOption: ->
        $input = @$('[name=usersOption]:checked')
        @viewState.set('usersOption', $input.attr('value'))
        #call set properties from rule to keep a-b property in sync
        @setPropertiesFromRule()
        @ruleProperties.set @getABProperty()
        switch @viewState.get('usersOption')
            when 'all'
                @rule.set {segmentId: null}, {skipRender: true}
            when 'me'
                @rule.set {segmentId: null}, {skipRender: true}
                @ruleProperties.push({name: "_myAppcuesId", operator: "==", value: @accountProperties.accountId, valuesList: ""})
            when 'properties'
                @rule.set {segmentId: null}, {skipRender: true}
                @ruleProperties.add {}

        @render()
        @$('input[name=usersOption]:checked').focus()

    chooseOnlyMe: ->
        properties = @getABProperty()
        properties.push({name: "_myAppcuesId", operator: "==", value: @accountProperties.accountId, valuesList: ""})
        @ruleProperties.set properties

    chooseSegment: (evt) ->
        segment = @segments.get(@$(evt.currentTarget).val())
        newAttrs = segment.pick('properties')
        newAttrs.segmentId = segment.id
        if abGroupCondition = @rule.attributes.properties?._ABGroup
            newAttrs.properties._ABGroup = abGroupCondition

        @rule.set newAttrs
        @setPropertiesFromRule()

    saveSegment: ->
        attrs = @rule.pick('properties')
        delete attrs.properties["_ABGroup"]
        attrs.name = attrs.where
        @listenToOnce @rule, 'change:segmentId', ->
            _.delay =>
                @viewState.set(usersOption: @getUsersOption())
            , 500

        v = new NewSegmentView {
            segmentAttrs: attrs
            segments: @segments
            rule: @rule
            show: true
        }
        @renderChild(v)

    toggleSegmentSaveButton: ->
        # Only show the "Save as a segment" button when there are valid
        # properties on the rule.
        if _.isEmpty(@rule.get('properties'))
            @$('[data-action=saveSegment]').addClass('hidden')
        else
            @$('[data-action=saveSegment]').removeClass('hidden')
