{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'
DefaultStyleFirebaseModel = require 'models/style-default'

module.exports = class StylesCollection extends Backbone.Firebase.Collection
    model: require 'models/style'

    initialize: (options) ->
        accountId = options?.accountId or auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{accountId}/styles"
        @defaultStyleFirebaseModel = new DefaultStyleFirebaseModel({accountId})

        @once 'sync', =>
            @syncd = true
            @defaultStyleFirebaseModel.whenSyncd =>
                # If there are no Styles created, create one with the legacy styling properties.
                # This will also create a new default Style if no legacy properties are present.
                unless @models.length
                    defaultStyle = @create _.extend {"name": "Your First Theme"}, _.clone(@defaultStyleFirebaseModel.attributes), {"isDefault": true}

                @watchDefault(defaultStyle)

    getDefault: ->
        for style in @models
            if style.get "isDefault"
                defaultStyle = style
                break
        defaultStyle || @models[0]

    changeDefault: (newDefault) ->
        if newDefault && not newDefault.get('isDefault') && newDefault in @models
            oldDefault = @getDefault()
            oldDefault.set "isDefault", null
            oldDefault.save()
            newDefault.set "isDefault", true
            newDefault.save()
            @watchDefault newDefault

    # Find the default Style model in the collection and watch it for changes as to update
    # the DefaultStylingModel in Firebase. The distinction here is that `defaultStyle` is
    # the primary data source within the Styles collection, and 'defaultStyleFirebaseModel'
    # is a clone that needs to be synchronized separately.
    watchDefault: (defaultStyle) ->
        defaultStyle = @getDefault() unless defaultStyle

        defaultStyle.on 'sync', =>
            if defaultStyle.get "isDefault"
                @defaultStyleFirebaseModel.set(_.clone defaultStyle.attributes)
