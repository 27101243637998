auth = require 'models/auth'
{firebase} = require 'lib/firebase'


# Abstract integrations connector.
module.exports = class Integration extends Backbone.Model
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: null
        updatedAt: null
        updatedBy: null

        access_token: null
        refresh_token: null
        expires_at: null

        isEnabled: null

    initialize: ->
        @set {createdBy: auth.get('id')}, {silent: true}

    isEnabled: -> @get('isEnabled') or false
