BaseView = require 'views/base'
BookmarksCollection = require 'collections/bookmarks'
NewBookmarkView = require 'views/new-bookmark'
auth = require 'models/auth'
urls = require 'lib/urls'
{operatorLookup} = require 'lib/where-helpers'


module.exports = class RuleWhereView extends BaseView
    __name__: 'RuleWhereView'
    className: 'rule-where'
    template: require 'views/templates/build-steps/settings/rule-where'

    events: ->
        'change [name=where]': 'saveWhere'
        'change [name=whereString]': 'saveWhereString'
        'change [name=whereOperator]': 'saveWhereOperator'
        'change [name=pageOption]': 'changePageOption'
        'change [name=bookmarkId]': 'chooseBookmark'
        'click [data-action=saveBookmark]': 'saveBookmark'
        'click [data-action=toggleRegexHelp]': 'toggleRegexHelp'
        'click .regexHelp a[data-pattern]': 'appendPattern'
        'change [name=widgetFilter]': 'saveWidgetFilter'

    initialize: (options) ->
        @bookmarks ?= new BookmarksCollection

        # Set default where value to "/".
        unless @model.get('where') or @model.get('bookmarkId')
            @model.set('where', '/')

        @listenToOnce @bookmarks, 'sync', ->
            @viewState.set('pageOption', @getPageOption())
            @render()
        @listenTo @model, 'change:bookmarkId change:isRegex change:where change:whereString change:whereOperator invalid', (model, value, options) ->
            @viewState.set('pageOption', @getPageOption())
            @render() unless options.skipRender
        @showWhitelistedDomains = true

        @viewState = new Backbone.Model {
            pageOption: @getPageOption()
        }

        # Listen for user or whitelist domains list changing.
        auth.onAuth =>
            @listenTo auth.user, 'change:id', @render
            @listenTo auth.currentAccount, 'change:id change:domains', @render

    getContext: ->
        _.extend @model.toJSON(), {
            bookmarks: @bookmarks.toJSON()
            selectedBookmark: @bookmarks.get(@model.get('bookmarkId'))?.toJSON()
            pageOption: @viewState.get('pageOption')
            validationError: @model.validationError
            validationWarning: @model.validationWarning
            isPublished: @model.get('published')
            isBeta: auth.currentAccount?.isInGeneralBeta?()
        }


    render: ->
        @removeChildViews()
        super

        @$('select[name=bookmarkId]').selectize {
            render: {
                option: (data, escape) =>
                    el = $("<div>#{data.text}</div>")
                    if where = @bookmarks.get(data.value)?.get('where')
                        el.append("<span class=\"text-muted pull-right\">#{where}</span>")
                    el.prop('outerHTML')
            }
        }

        @$('select.where-operator').selectize {
            persist: false
            lockOptgroupOrder: true
            options: @getOperators()
        }

    saveWhereString: (evt) ->
        $input = @$(evt.currentTarget)
        @model.set {whereString: $input.val(), isRegex: true}, {validate: true}

    saveWhereOperator: (evt) ->
        $input = @$(evt.currentTarget)
        @model.set {whereOperator: $input.val(), isRegex: true}, {validate: true}

    saveWhere: (evt) ->
        $input = @$(evt.currentTarget)
        @model.set {where: $input.val()}, {validate: true}

    saveWidgetFilter: ->
        $selected = @$('[name=widgetFilter]:checked')
        @model.set {
            widgetFilter: $selected.val()
        }, {skipRender: true}

    getPageOption: ->
        if @model.get('bookmarkId')? and @bookmarks.get(@model.get('bookmarkId'))?
            'bookmark'
        else if @model.get('isRegex')
            if @model.get('where') is '.*'
                'any'
            else if @model.get('whereOperator')
                'simpleRegex'
            else
                'regex'
        else
            'exact'

    changePageOption: ->
        $input = @$('[name=pageOption]:checked')
        @viewState.set('pageOption', $input.attr('value'))
        switch @viewState.get('pageOption')
            when 'any'
                @model.set {bookmarkId: null, isRegex: true, where: '.*', whereOperator: '*', whereString: null}, {skipRender: true}
            #when 'bookmark'
            when 'exact'
                newAttrs = {bookmarkId: null, isRegex: false, whereOperator: null, whereString: null}
                # Change `where` value back if we're going from regex to exact
                if @model.get('isRegex')
                    loc = urls.toLocation @model.get('previewUrl')
                    newAttrs.where = loc.fullpath
                @model.set newAttrs, {skipRender: true}
            when 'regex'
                @model.set {bookmarkId: null, isRegex: true, whereString: null, whereOperator: null, where: ''}, {skipRender: true}
            when 'simpleRegex'
                @model.set {bookmarkId: null, isRegex: true, whereOperator: '*', whereString: '', where: ''}, {skipRender: true}
        @render()
        @$('input[name=pageOption]:checked').focus()

    chooseBookmark: (evt) ->
        @bookmarks.whenSyncd =>
            bookmark = @bookmarks.get(@$(evt.currentTarget).val())
            if bookmark
                newAttrs = bookmark.pick('where', 'isRegex')
                newAttrs.bookmarkId = bookmark.id
                @model.set newAttrs

    saveBookmark: ->
        attrs = @model.pick('where', 'isRegex')
        attrs.name = attrs.where
        v = new NewBookmarkView {
            bookmarkAttrs: attrs
            bookmarks: @bookmarks
            rule: @model
            show: true
        }
        @renderChild(v)

    getOperators: ->
        ops = operatorLookup
        _.map ops, (val, key) ->
            group = 'normal'
            return { text: val, value: key, optgroup: group}

    toggleRegexHelp: ->
        @$('.regexHelp').slideToggle(200)

    appendPattern: (evt) ->
        $link = @$(evt.currentTarget)
        $input = @$('[name=where]')
        $input.val($link.attr('data-pattern')).change().focus()
