firebase = require 'lib/firebase'
auth = require 'models/auth'
BaseFlowModel = require 'models/flow-base'


FlowModel = _.extend {}, BaseFlowModel, {
    initialize: (attributes, options={}) ->
        BaseFlowModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        firebase.url + "/accounts/#{accountId}/flows"

    extraPaths: ->
        return [
            "/__/flows/#{@id}"
        ]
}

module.exports = Backbone.Firebase.Model.extend FlowModel
