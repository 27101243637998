auth = require 'models/auth'
CONTENT_STATES = require 'lib/content-states'


BaseCoachmarkGroupModel =
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        state: CONTENT_STATES.DRAFT
        published: false

        name: 'Untitled Coach Marks'
        description: ''

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true

            @on 'change:published', (model, value) -> if value then @resetHotspotWarning()
            @on 'change:totalUniqueErrorVersioned change:totalUniqueShownVersioned', @render

    urlPrefix: ->
        'coachmarks'

    resetHotspotWarning: ->
        @.set({totalUniqueErrorVersioned: 0, totalUniqueShownVersioned: 0, hotspotWarning: false})

module.exports = _.extend BaseCoachmarkGroupModel
