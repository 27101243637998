InviteUsersModalView = require 'views/invite-modal'
page = require 'models/page'
auth = require 'models/auth'
AccountStatsModel = require 'models/account-stats'
{switchUser} = require 'lib/helpers'

module.exports = class AuthView extends Backbone.View
    __name__: 'AuthView'
    template: require 'views/templates/auth'
    model: auth
    className: 'auth-banner'

    events:
        'click .stop-spoofing': 'stopSpoofing'
        'click .invite-user-link': 'showInviteUserModal'
        'submit form.spoof': 'spoofUser'

    initialize: (options={}) ->
        @showInviteModal = options.showInviteModal

        @accountStats = new AccountStatsModel {}, {accountId: auth.currentAccount.id}
        @statusModel = new Backbone.Model

        @listenTo page, 'change', @render
        @listenTo @model, 'login logout error rerender', @render
        @listenTo @model.user, 'change:id change:isAdmin', @render
        @listenTo @model.currentAccount, 'change:id', @render
        @listenTo @statusModel, 'change', @render
        @listenTo @accountStats, 'sync', @render

        # This model is non-syncing, so we have to fetch it manually.
        @accountStats.fetch()

        if StatusPage?
            sp = new StatusPage.page({page: 'wrjfc0mvsnss'})
            sp.summary({
                success: ({status}) =>
                    attrs = _.pick(status, 'description', 'indicator')
                    console.log attrs
                    @statusModel.set attrs
            })

    render: ->
        @$el.html @template @getContext()

        if @showInviteModal
            # Only show this once
            @showInviteModal = false
            @showInviteUserModal()

    getContext: ->
        return {
            page: page.toJSON()
            auth: @model.toJSON()
            user: @model.user.toJSON()
            status: @statusModel.toJSON()
            accountId: @model.currentAccount.id
            account: @model.currentAccount.toJSON()
            stats: @accountStats.toJSON()
            hasStats: @accountStats.hasStats()
            isBeta: @model.currentAccount?.isInGeneralBeta?()
            isInNpsBeta: @model.currentAccount?.isInNpsBeta?()
            isInChecklistBeta: @model.currentAccount?.isInChecklistBeta?()
            shouldSeeMobileLink: @model.currentAccount?.shouldSeeMobileLink?()
            isNPSProductTrial: @model.currentAccount.get("isNPSProductTrial")
            isNPSProductTrialExpired: @model.currentAccount.get("isNPSProductTrialExpired")
            isNPSProductOnlyUser: @model.currentAccount?.get("isNPSProductOnlyUser")
            # Returns true for strings that start with "account/"
            isAccountSubpage: /^account\//.test Backbone.history.getFragment()
            hideInviteUser: @model.currentAccount.id && @model.currentAccount?.hasFeatureGate("hideInviteUser")
        }

    spoofUser: ->
        switchUser(@$ 'form.spoof')

    stopSpoofing: ->
        @model.stopSpoofing()
        # Don't stay on same page.
        window.location.href = "/"

    expandDropdown: ->
        @$('.nav li.dropdown').addClass 'open'

    collapseDropdown: ->
        @$('.nav li.dropdown').removeClass 'open'

    showInviteUserModal: ->
        v = new InviteUsersModalView
            show: true
        v.render()
