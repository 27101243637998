BaseView = require 'views/base'
AccountModel = require 'models/account'
auth = require 'models/auth'
platforms = require 'lib/platforms'
trackEvent = require 'lib/track-event'

module.exports = class InstallMyselfView extends BaseView
    __name__: 'InstallMyselfView'
    className: 'install-myself'
    template: require 'views/templates/install-myself'

    events:
        'click .install-guide .install-option-button': 'selectInstallOption'
        'click .install-guide button.btn-sm.copy': 'clickCopySnippet'

    initialize: (options={}) ->
        {@accountId} = options
        @account = new AccountModel(id: @accountId)

        @model = new Backbone.Model
            accountId: @accountId or '<YOUR_APPCUES_ID>'
            cdnDomain: window.__env.CDN_DOMAIN
            isInstalled: @account.get('isInstalled')
            hasAuth: auth.currentAccount.id?
            platforms: platforms
            selectedInstall: ''
            selectedPartner: platforms["traditional"]
            isSPA: false

        @listenTo @account, 'sync', @render
        @listenTo @account, 'change:isInstalled', @render
        @listenTo @model, 'change', @render

    getContext: -> @model.toJSON()

    render: ->
        super
        @$('pre code').each (i, block) ->
            Prism.highlightElement(block)

    selectInstallOption: (e) ->
        selectedInstall = e.currentTarget.dataset.install
        trackEvent 'Clicked Installation Type',
            name: selectedInstall

        isSPA = e.currentTarget.dataset.framework == 'spa'
        @model.set {selectedInstall: selectedInstall, isSPA: isSPA, selectedPartner: platforms[selectedInstall]}

    clickCopySnippet: (e) ->
        copyText = e.currentTarget.previousElementSibling.innerText

        placeholder = $('textarea.clipboard')
        placeholder.text(copyText)
        placeholder[0].select()

        document.execCommand("copy")
        placeholder.text('')
