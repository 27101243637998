BaseView = require 'views/base'
{confirm} = require 'views/alerts'
auth = require 'models/auth'

module.exports = class StepActionView extends BaseView
    __name__: 'StepActionView'
    className: 'active step-action'
    tagName: 'cue'
    model: new (require('models/step'))
    template: require 'views/templates/step-actions/step-action'

    # Have to keep this as a function so it can be extended.
    events: ->
        'blur .step-action-buttons div[contenteditable=true]': 'setButtons'

    initialize: (attrs={}) ->
        @_defaultContent = @defaultContent()
        {@isFirst, @isLast, @showPoweredByText, @isEditable, @showTextBadge} = attrs
        @isTrial = auth?.currentAccount?.get 'isTrial'
        @hasBetaSDK = auth?.currentAccount?.isInSDKBeta?()
        super

    defaultContent: ->
        $(@template()).find('.step-action-content').html()

    _remove: (e) ->
        e.stopPropagation()
        confirm("You will lose your work by switching to a different step type. Are you sure you want to do that?").then (res) =>
            if res
                @trigger 'clear'
                @remove()

    getContext: ->
        _.extend(@model.toJSON(), {@isEditable, @isFirst, @isLast, @showPoweredByText, @isTrial})

    render: ->
        super
        @setContent(@_defaultContent) unless @model.get('content')

    setContent: (value) ->
        @model.set 'content', value

    previewStep: ->
        # TODO: Remove the controls and just render the content.

    setButtons: (e) ->
        $el = @$(e.currentTarget)
        newButtonText = $el.text()
        defaultButtonText = $el.data('default-text')

        changedButtonCID = $el.data('button-cid')

        # Reset defaults if empty or using default text.
        buttonText = newButtonText

        if !newButtonText or newButtonText is defaultButtonText
            buttonText = defaultButtonText
            $el.text buttonText

        @model.setButtonText(changedButtonCID, buttonText)


