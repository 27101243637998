AppView = require 'views/app'
auth = require 'models/auth'
InviteUsersModalView = require 'views/invite-modal'

module.exports = class TeamView extends AppView
    __name__: 'TeamView'
    className: 'team-view'
    template: require './templates/team'

    initialize: () ->
        window.showInviteUserModal = @showInviteUserModal.bind(@)
        @

    render: ->
        super

        if(window.apcInjectable)
            window.apcInjectable.renderTeam auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(() =>
                @render()
            , 300)

    showInviteUserModal: ->
        hideInviteUser = auth.currentAccount.id? and auth.currentAccount?.hasFeatureGate("hideInviteUser")
        if !hideInviteUser
            v = new InviteUsersModalView
                show: true

            @renderChild(v)
