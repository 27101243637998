AppView = require 'views/app'
auth = require 'models/auth'
FlowsCollection = require 'collections/flows'
HotspotGroupsCollection = require 'collections/hotspot-groups'
CoachmarkGroupsCollection = require 'collections/coachmark-groups'

crxHelperModel = require 'models/crx-helper'
CreateContentWithDomainView = require 'views/create-content-with-domain'
CreateFlowFromTemplate = require 'views/list-all'

CRX_INSTALL_MODAL_ID = '-KXvdcCjv7CkiMVASqH7'
CHROME_INSTALL_MODAL_ID = '-KYGGfDfHu3Lm0BgsPE5'

module.exports = class StartView extends AppView
    __name__: 'StartView'
    template: require 'views/templates/start'

    events:
        'click [data-action=cloneWelcomeTemplate]': 'cloneWelcomeTemplate'
        'click [data-action=cloneAnnouncementTemplate]': 'cloneAnnouncementTemplate'
        'click [data-action=clonePageTourTemplate]': 'clonePageTourTemplate'

    initialize: (options) ->
        if !auth.currentAccount.id
            window.location.href = '/register'

        @flowsCollection = new FlowsCollection
        @hotspotGroupsCollection = new HotspotGroupsCollection
        @coachmarkGroupsCollection = new CoachmarkGroupsCollection

        @flowsCollection.whenSyncd => @hotspotGroupsCollection.whenSyncd => @coachmarkGroupsCollection.whenSyncd => @render()
        @createFlowFromTemplate = new CreateFlowFromTemplate
        @

    render: ->
        return if @createContentWithDomainView
        super
 
    getContext: ->
        accountId: auth.currentAccount.id

    cloneWelcomeTemplate: ->
        @createFlowFromTemplate.cloneWelcomeTemplate()

    cloneAnnouncementTemplate: ->
        @createFlowFromTemplate.cloneAnnouncementTemplate()

    clonePageTourTemplate: ->
        @createFlowFromTemplate.clonePageTourTemplate()


