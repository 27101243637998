AccountMembershipsCollection = require 'collections/account-memberships'
UserModel = require 'models/user'
auth = require 'models/auth'

# Adjust user model to not auto-sync, otherwise we'll try updating users when
# we don't have permissions to do that.
ManualUserModel = UserModel.extend {autoSync: false}

module.exports = class AccountMembersCollection extends Backbone.Collection
    model: ManualUserModel

    initialize: (models, options={}) ->
        {@accountId} = options
        @accountId ?= auth?.currentAccount?.id

        @once 'sync', -> @syncd = true

        @memberships = new AccountMembershipsCollection [], {accountId: @accountId}
        @listenTo @memberships, 'sync', =>
            users = []
            promises = []
            @memberships.each (membership) =>
                if membership.id
                    model = new @model {id: membership.id}
                    promises.push model.whenSyncd()
                    users.push model
                    model.fetch()

            $.when(promises...).then =>
                @set users
                @trigger 'sync'

