
module.exports = class PropertyModel extends Backbone.Model
    defaults:
        name: ''
        value: ''
        valuesList: ''
        type: null
        operator: '=='
        isSensitive: null

    values: ->
        # Split `valuesList` on newlines. It should already be trimmed and unique.
        (@get('valuesList') or '').split('\n')

    valueCount: ->
        @values().length

    toJSON: (options) ->
        _.extend({}, super, {
            valueCount: @valueCount()
        })

    getDisplayName: ->
        PropertyModel.getPropertyDisplayName @get('name')

    isPrivate: ->
        PropertyModel.isPrivate @get('name')

    isInternal: ->
        PropertyModel.isInternal @get('name')

    isABProperty: ->
        PropertyModel.isABProperty @get('name')

    isMyAppcuesId: ->
        PropertyModel.isMyAppcuesId @get('name')

    isLanguage: ->
        PropertyModel.isLanguage @get('name')

    @isMyAppcuesId: (name) ->
        name in ['_myAppcuesId']

    @isLanguage: (name) ->
        name in ['_language']

    @isPrivate: (name) ->
        name.charAt(0) is '_'

    @isInternal: (name) ->
        internalProps = ['uuid', 'appcuesId', 'updatedAt', '_updatedAt', '_hostname']
        name in internalProps

    @isABProperty: (name) ->
        name in ['_ABGroup', '_variantGroup']

    @getPropertyDisplayName = (prop) ->
        prop
            # Remove custom prefixes.
            .replace /^_appcuesForm_/g, ""
            # Replace - _ . + with spaces.
            .replace /[\-_\.\+]/g, " "
            # Replace "abcD" with "abc D", "abcDEF" with "abc DEF".
            .replace /([a-z]+)([A-Z]+)/g, (m, g1, g2) -> "#{g1} #{g2}"
            # Replace "Abcd" with " Abcd", discarding a starting space if it's there.
            .replace /(\s?)([A-Z])([a-z]+)/g, (m, g1, g2, g3) -> " #{g2}#{g3}"
            # Take common all caps acronyms and capitalize them.
            .replace /\b(id|url|os|ip|eid|fb|crm|uuid)\b/gi, (m, g1) -> "#{g1.toUpperCase()}"
            # Upper case the first character of each space separated word.
            .replace /(\w)(\w*)/g, (m, g1, g2) -> "#{g1.toUpperCase()}#{g2}"
            .trim()
