BaseView = require 'views/base'
auth = require 'models/auth'
{operatorLookup} = require 'lib/rule-helpers'
PropertyModel = require 'models/property'

module.exports = class PropertyFilterView extends BaseView
    __name__: 'PropertyFilterView'
    className: 'property-filter row'
    template: require 'views/templates/property-filter'
    modalTemplate: require 'views/templates/property-filter-value'

    DATE_OPS = ['> ago', '< ago', 'within']
    FINITE_PROPS = {
        '_deviceType': ['desktop', 'mobile'],
        '_operatingSystem': ['Mac', 'Windows', 'iOS', 'Android', 'other']
    }

    events:
        'change .rule-property-value': 'save'
        'click .remove-rule-property': 'removeProperty'
        'click .show-modal': 'showModal'

    initialize: (options={}) ->
        {@model, @userProperties, @isEditable} = options

    getContext: ->
        filter: @model.toJSON()
        isEmbedV1: auth.currentAccount.get('isEmbedV1')
        isEditable: @isEditable
        isDateFilter: @model.get('operator') in DATE_OPS
        isFiniteProp: FINITE_PROPS.hasOwnProperty(@model.get('name'))
        getPropertyDisplayName: PropertyModel.getPropertyDisplayName

    render: ->
        super

        propsObj = @userProperties.filterUsableProperties()
        # If the account has no properties yet (i.e. embed script isn't
        # installed yet), then add our default auto-properties.
        if _.isEmpty propsObj
            _.extend propsObj,
                _isAnonymous: true
                _lastPageTitle: 'Example.com - Billing Page'
                _lastPageUrl: 'http://example.com/billing'
                _sessionPageviews: 17
                _userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_10_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/44.0.2403.155 Safari/537.36',
                _deviceType: 'desktop'
                _operatingSystem: 'Mac'

        @$('select.rule-property-name').selectize
            preload: true
            persist: true
            create: false
            lockOptgroupOrder: true
            optgroups: [
                { label: 'Auto-Properties', value: 'auto' }
                { label: 'Device Properties', value: 'device' }
                { label: 'Form Responses', value: 'form' }
                { label: 'Your Properties', value: 'normal' }
            ]

            load: (query, callback) ->
                # Convert to something selectize expects.
                data = []
                isBetaUser = auth.currentAccount?.isInGeneralBeta?()
                for k, v of propsObj
                    group = getOptGroup k
                    name = PropertyModel.getPropertyDisplayName k
                    placeholder = getPlaceholder(v)
                    data.push {text: name, value: k, placeholder: placeholder, optgroup: group}
                callback data

            onInitialize: (value) ->
                # Set up missing attributes for selected item.
                if item = @items[0]
                    @options[item].optgroup = getOptGroup item
                    @options[item].text = PropertyModel.getPropertyDisplayName item
                    @options[item].placeholder = getPlaceholder(propsObj[item])

            onChange: _.bind (value) ->
                @save()
                placeholder = getPlaceholder(propsObj[@model.get('name')])
                @model.set 'placeholder', "e.g. #{placeholder}"
                @render()
            , @

            render:
                option: (item, escape) ->
                    """<div class="option" title="#{escape item.value}  (e.g. #{escape item.placeholder})">#{escape item.text}</div>"""

        @$('select.rule-property-operator').selectize
            persist: false
            lockOptgroupOrder: true
            options: getOperators(getPlaceholder(@userProperties.get(@model.get 'name')))
            optgroups: [
                { label: 'Date Operators', value: 'date'}
                { label: 'Standard Operators', value: 'normal'}
            ]
            onChange: _.bind((value) ->
                @save()
                @render()
            , @)

        # If we know of a finite list of options for this prop, use selectize
        if FINITE_PROPS.hasOwnProperty(@model.get('name'))
            finiteOptions = FINITE_PROPS[@model.get('name')]
            @$('select.rule-property-value').selectize
                persist: true
                options: _.map finiteOptions, (x) ->
                    { text: x, value: x }
                onChange: _.bind((value) ->
                    @save()
                    @render()
                , @)

        else
            # if we have an array of suggestions, implement auto-complete for the rule value field
            suggestions = []
            storedValue = propsObj[@model.get('name')]

            if _.isObject(storedValue)
                suggestions = Object.keys(storedValue)
            else
                if storedValue != undefined
                    suggestions.push(String(storedValue))

            if suggestions.length > 0
                @$('input.rule-property-value').autocomplete
                    lookup: suggestions
                    triggerSelectOnValidInput: false
                    onSelect: (() =>
                        @save()
                        @render()
                    )

    showModal: (e) ->
        ModalView = require 'views/modal'
        filterModel = @model.toJSON()
        filterModel.valuesList = filterModel.valuesList.replace(/,/g, '\\,')
        v = new ModalView
            show: true
            title: 'Property filter value'
            saveButton: false
            content: @modalTemplate {
                isEditable: @isEditable
                filter: filterModel
                filterId: @model.cid
            }
        v.render()
        @listenToOnce v, 'save', @saveModal

    saveModal: ($modal) ->
        $textarea = $modal.find('textarea')
        $checkbox = $modal.find('input[type=checkbox]')
        $hiddenField = @$('input.rule-property-values-list')
        $hiddenSensitiveField = @$('input.rule-property-is-sensitive')
        $hiddenField.val($textarea.val())
        $hiddenSensitiveField.val $checkbox.prop('checked')
        @save()
        @render()

    save: ->
        $rulePropertyValue = @$('.rule-property-value')
        if $rulePropertyValue.length
            @model.set
                name: @$('select.rule-property-name').val()
                operator: @$('select.rule-property-operator').val()
                value: $rulePropertyValue.val()
                type: $rulePropertyValue.attr('data-type')
                valuesList: ""
                isSensitive: null
        else
            values = @$('.rule-property-values-list').val().replace(/\\,/g, '&#44;').split(/[,\n]+/)
            isSensitive = @$('.rule-property-is-sensitive').val() is "true"
            valuesList = _.compact(_.uniq(_.map(values, (value) ->
                $.trim(value).replace(/&#44;/g, ',')
            ))).join('\n')

            @model.set
                name: @$('select.rule-property-name').val()
                operator: @$('select.rule-property-operator').val()
                valuesList: valuesList
                isSensitive: if isSensitive then true else null
                value: ""

    removeProperty: ->
        collection = @model.collection
        @model.destroy()
        collection.trigger('change')

    getPlaceholder = (property) ->
        if _.isObject(property) then Object.keys(property)[0] else property

    getOperators = (filterValue) ->
        # Check if the filter value could be a Date or not.
        ops = operatorLookup
        unless isDate(filterValue)
            # Filter out the date operators if not a Date.
            ops = _.omit operatorLookup, DATE_OPS
        _.map ops, (val, key) ->
            group = 'normal'
            if key in DATE_OPS
                group = 'date'
            return { text: val, value: key, optgroup: group}

    isDate = (val) ->
        if /^(\-|\+)?([0-9]+|Infinity)$/.test(val)
            val = Number val
        switch typeof val
            when 'string'
                not _.isNaN(new Date(Date.parse(val)).getTime())
            when 'number'
                # Verify the number is most likely a timestamp in
                # seconds/milliseconds (i.e. has 8 or more digits).
                String(val).length >= 8
            else
                false

getOptGroup = (propName) ->
    if /^(_deviceType|_operatingSystem|_browser)/.test(propName)
        'device'
    else if /^_appcuesForm_/.test(propName)
        'form'
    else if /^_/.test(propName)
        'auto'
    else
        'normal'
