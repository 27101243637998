BaseGoalModel = require 'models/goal-base'
FlowModel = require 'models/flow-firebase'

auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'

module.exports = GoalModel = Backbone.Model.extend(_.extend({}, _.clone(BaseGoalModel), {
    collectionPath: ->
        "accounts/#{auth.currentAccount.id}/goals"

    initialize: (attrs={})->
        @source = attrs.source
        if @source.get('goalId')?
            @ref = firebaseRef.child("#{@collectionPath()}/#{@source.get('goalId')}")
            @ref.once('value', _.bind((snap) ->
                @set(snap.val())
                @trigger 'sync'
            , this))

        @on 'change:where change:isRegex', ->
            if @isValid()
                if @ref
                    @ref.set(@toJSON())
                else
                    @ref = firebaseRef.child(@collectionPath()).push(@toJSON())
                    @source.set('goalId', @ref.name())
            else
                @source.unset('goalId')
                @ref.remove() if @ref
                @ref = null

            @trigger 'sync'

        if @source instanceof FlowModel
            @set(sourceType: 'flow', sourceId: attrs.source.get('id'))

    isValid: ->
        @get('sourceType')? and @get('sourceId')? and @get('published')? and $.trim(@get('where')) isnt ''

    toJSON: () ->
        _.pick(@attributes, _.keys(@defaults))
}))

