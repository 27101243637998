BaseView = require 'views/base'
FormFieldModel = require 'models/form-field'


module.exports = class FormFieldTextareaView extends BaseView
    __name__: 'FormFieldTextareaView'
    className: 'form-field form-field-textarea'
    model: new FormFieldModel
    template: require 'views/templates/form-fields/textarea'

    initialize: (options={}) ->
        super
        @isEditable = options.isEditable

    attributes: ->
        'data-appcues-required': @model.get 'required'
        'data-appcues-validation': @model.get 'validationType'

    events: ->
        'blur input': 'updateModel'
        'blur textarea': 'updateModel'

    getContext: ->
        context = @model.toJSON()
        context.isEditable = @isEditable
        context

    updateModel: ->
        @model.set('label', @$el.find('input.label-value').val())
        @model.set('placeholder', @$el.find('textarea.placeholder-value').val())

