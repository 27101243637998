StepActionView = require 'views/step-actions/step-action'
editor = require 'lib/editor'

module.exports = class StepActionImageView extends StepActionView
    __name__: 'StepActionImageView'
    template: require 'views/templates/step-actions/video'
    editorTarget: ".redactor"

    defaultContent: ->
        $(@template()).find('.step-action-content > textarea').html()

    render: ->
        # Remove pre-existing editor.
        editor.remove @$(@editorTarget)

        super

        save = _.bind(@setContent, @)

        editor.video @$(@editorTarget), {
            # Save changes in content.
            changeCallback: save
            codeKeyupCallback: -> save @code.get()
            sourceCallback: save
            visualCallback: save

            toolbarExternal: '#step-editor-toolbar'
        }

        unless @model.get('content')
            @setContent @$(@editorTarget).val()

    setContent: (value) ->
        unless _(value).isString()
            value = @$(@editorTarget).val()
        @model.set 'content', value

    remove: ->
        # Remove redactor editor if it exists.
        editor.remove @$(@editorTarget)
        super
