module.exports = class StepButton extends Backbone.Model
    defaults:
        type: null
        style: null
        text: null
        align: null
        isVisible: null

    ALIGNMENT:
        LEFT: "LEFT"
        RIGHT: "RIGHT"

    DEFAULT_TEXT:
        NEXT: null
        BACK: "Back"

    TYPES:
        NEXT: "NEXT"
        BACK: "BACK"

    isVisible: -> @get('isVisible') isnt false

    isLeftAligned: -> @get('align') is @ALIGNMENT.LEFT

    isRightAligned: -> @get('align') is @ALIGNMENT.RIGHT

    initialize: ->
        unless @get('text')
            @set('text', @DEFAULT_TEXT[@get('type')])

        unless @get('align')
            _alignment = if @get('type') is @TYPES.BACK then @ALIGNMENT.LEFT else @ALIGNMENT.RIGHT
            @set('align', _alignment)

        @
