{confirm, confirmAndWait} = require 'views/alerts'
publisher = require 'lib/publish'

module.exports =
    confirmPublishContent: ->
        confirmAndWait('Are you sure you want to publish this flow?', null, "Publishing #{@content.get('name')}").then (res) =>
            if res
                publisher.publish @content
                    .done(res)
                    .fail(res)

    confirmUnpublishContent: ->
        confirmAndWait('Are you sure you want to unpublish this flow?', null, "Unpublishing #{@content.get('name')}").then (res) =>
            if res
                publisher.unpublish @content
                    .done(res)
                    .fail(res)

    confirmRevertContent: ->
        confirmAndWait('Are you sure you want to discard unpublished changes?  These changes will be lost', null, "Discarding changes to #{@content.get('name')}").then (res) =>
            if res
                publisher.revert @content
                    .done(res)
                    .fail(res)

    confirmPublishGroup: (group, collection) ->
        confirmAndWait "Are you sure you want to publish #{collection.length > 1 and 'all' or ''} #{collection.length} flow#{collection.length > 1 and 's' or ''}?",
                text: "Only visible flows will be affected.",
                "Publishing #{collection.length} flow#{collection.length > 1 and 's' or ''}"
            .then (res) ->
                if res
                    publisher.publishAll collection
                        .done(res)
                        .fail(res)

    confirmUnpublishGroup: (group, collection) ->
        confirmAndWait "Are you sure you want to unpublish #{collection.length > 1 and 'all' or ''} #{collection.length} flow#{collection.length > 1 and 's' or ''}?",
                text: "Only visible flows will be affected.",
                "Unpublishing #{collection.length} flow#{collection.length > 1 and 's' or ''}"
            .then (res) ->
                if res
                    publisher.unpublishAll collection
                        .done(res)
                        .fail(res)
