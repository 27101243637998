AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class SegmentsView extends AppView
    __name__: 'SegmentsView'
    className: 'segments'
    template: require 'views/templates/segments'

    afterInitialize: () ->

    render: ->

        super

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderSegments auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
