{firebase} = require 'lib/firebase'
sls = require 'lib/sls-helpers'
RuleModel = require 'models/rule-firebase'
{APPCUES_API_URL, APPCUES_API_KEY, APPCUES_API_SECRET} = window.__env
auth = require 'models/auth'

class Publisher

    publish: (content) ->
        deferred = $.Deferred()
        sls.publish(content)
            .done(=>
                @trackEvents(content, true)
                deferred.resolve()
            )
            .fail(deferred.reject)
        deferred

    unpublish: (content) ->
        deferred = $.Deferred()
        sls.unpublish(content)
            .done(=>
                @trackEvents(content, false)
                deferred.resolve()
            )
            .fail(deferred.reject)
        deferred

    revert: (content) ->
        deferred = $.Deferred()
        sls.revert(content)
            .done(deferred.resolve)
            .fail(deferred.reject)
        deferred

    publishAll: (collection) ->
        deferred = $.Deferred()
        $.when.apply($, collection.map (content) -> sls.publish(content))
            .done(deferred.resolve)
            .fail(deferred.reject)
        deferred

    unpublishAll: (collection) ->
        deferred = $.Deferred()
        $.when.apply($, collection.map (content) -> sls.unpublish(content))
            .done(deferred.resolve)
            .fail(deferred.reject)
        deferred

    trackEvents: (content, isPublished) ->
        rule = new RuleModel(id: content.id)
        verb = if isPublished then 'Published' else 'Unpublished'
        @trackRuleEvent(rule, verb)
        switch rule.get('contentType')
            when 'flow'
                @trackFlowEvent(content, verb)
            when 'hotspot-group'
                @trackHotspotGroupEvent(content, verb)
            when 'coachmark-group'
                @trackCoachmarkGroupEvent(content, verb)
            when 'journey'
                @trackJourneyEvent(content, rule, verb)

    trackRuleEvent: (rule, verb) ->
        data = _.omit rule.toJSON(), ['whereHash', 'for', 'when', 'shown', 'id', 'nextContent', 'contents', 'events', 'properties', 'account_gates', 'gates']
        data.ruleId = rule.id
        lytics.trackWithUser "#{verb} a Rule", data

    trackJourneyEvent: (journey, rule, verb) ->
        data = _.omit journey.toJSON(), (v, k) ->
            _.isObject(v) or _.isArray(v) or k is 'id'

        # set base values
        data = Object.assign(data, {
            abTestEnabled: false
            contentMode: 'all'
            contentConditionsCount: 0
            domainsMode: 'all'
            domainsConditionsCount: 0
            eventMode: 'all'
            eventConditionsCount: 0
            languagesMode: 'all'
            languagesConditionsCount: 0
            propertiesMode: 'all'
            propertiesConditionsCount: 0
            stepCount: 0
            tacoMode: 'off'
            targetingMode: 'all'
            urlMode: 'all'
            urlConditionsCount: false
        })

        setCondition = (conditions) ->
            _.each conditions, (condition) ->
                propName = Object.keys(condition)[0]

                #get nested property values
                if propName in ['and', 'not', 'or']
                    return setCondition(condition[propName])

                #dynamically add conditons counts in case any are missed when base values are set
                if data[propName + 'ConditionsCount'] then data[propName + 'ConditionsCount'] += 1 else data[propName + 'ConditionsCount'] = 1
                data[propName + 'Mode'] = 'conditions'


                switch propName
                    when 'content'
                        data.targetingMode = 'conditions'
                        break
                    when 'languages'
                        data.targetingMode = 'conditions'
                        break
                    when 'properties'
                        switch condition[propName].property
                            when '_ABGroup'
                                data.abTestEnabled = true
                                break
                            when '_myAppcuesId'
                                data.targetingMode = 'just_me'
                                break
                            else
                                data.targetingMode = 'conditions'
                                break
                        break


        # Set individual counts for different types of conditions
        if rule.attributes.conditions
            for container in rule.attributes.conditions.and
                combiner = if container.and then 'and' else if container.not then 'not' else 'or'
                setCondition(container[combiner])

        data.stepCount = Object.keys(journey.attributes.steps || {}).length
        data.createdAt = new Date(data.createdAt).toUTCString()
        data.unpublishedAt = new Date(data.unpublishedAt).toUTCString()
        data.updatedAt = new Date(data.updatedAt).toUTCString()
        data.frequency = rule.attributes.frequency
        data.hasRedirect = _.some(journey.attributes.steps, (step) -> step.stepType == 'action')
        data.tacoMode = rule.attributes.widgetFilter
        data.categoryId = journey.attributes.category

        lytics.trackWithUser "#{verb} a Flow", data

    trackFlowEvent: (flow, verb) ->
        data = _.omit flow.toJSON(), (v, k) ->
            _.isObject(v) or _.isArray(v) or k is 'id'
        data.flowId = flow.id
        data.totalSteps = _.size(flow.get('steps'))
        lytics.trackWithUser "#{verb} a Modal", data

    trackHotspotGroupEvent: (hotspotGroup, verb) ->
        data = _.omit hotspotGroup.toJSON(), (v, k) ->
            _.isObject(v) or _.isArray(v) or k is 'id'
        data.hotspotGroupId = hotspotGroup.id
        data.totalHotspots = _.size(hotspotGroup.get('hotspots'))
        lytics.trackWithUser "#{verb} a set of Hotspots", data

    trackCoachmarkGroupEvent: (coachmarkGroup, verb) ->
        data = _.omit coachmarkGroup.toJSON(), (v, k) ->
            _.isObject(v) or _.isArray(v) or k is 'id'
        data.coachmarkGroupId = coachmarkGroup.id
        data.totalCoachmarks = _.size(coachmarkGroup.get('hotspots'))
        lytics.trackWithUser "#{verb} a set of Coach Marks", data

    hitApiPublishHook: ->
        accountId = auth.currentAccount.id
        deferred = $.Deferred()
        if not (APPCUES_API_URL and APPCUES_API_KEY and APPCUES_API_SECRET)
            console.log("API publish hook is not configured; skipping.")
            deferred.resolve("skipped")
        else if not accountId
            console.log("accountId not available; skipping.")
            deferred.resolve("no_account")
        else
            url = APPCUES_API_URL + "/v1/accounts/" + accountId + "/publish"
            $.ajax({
                url: url
                method: "POST"
                beforeSend: (xhr) ->
                    xhr.setRequestHeader("Authorization", "Basic " +
                        btoa(APPCUES_API_KEY + ":" + APPCUES_API_SECRET))
                error: (jqxhr, status, error) ->
                    console.log("Error hitting Appcues API publish hook:", error)
                    deferred.resolve(status)
                success: (data, status, jqxhr) ->
                    deferred.resolve(status)
            })
        return deferred


module.exports = new Publisher

