auth = require 'models/auth'

AppView = require 'views/app'
ABTestingView = require 'views/build-steps/settings/ab-testing'
FlowPrehookView = require 'views/build-steps/settings/flow-prehook'
RuleDomainsView = require 'views/build-steps/settings/rule-domains'
RuleContentsView = require 'views/build-steps/settings/rule-contents'
RuleEventsView = require 'views/build-steps/settings/rule-events'
RuleLanguagesView = require 'views/build-steps/settings/rule-languages'
RuleFrequencyView = require 'views/build-steps/settings/rule-frequency'
RulePropertiesView = require 'views/build-steps/settings/rule-properties'
RuleRedirectView = require 'views/build-steps/settings/rule-redirect'
RuleWhereView = require 'views/build-steps/settings/rule-where'
SettingPanelView = require 'views/build-steps/settings/setting-panel'
ShareModalView = require 'views/share-modal'
page = require 'models/page'
helpers = require 'lib/helpers'
crxHelperModel = require 'models/crx-helper'
router = require 'routers/main'
{confirmPublishContent, confirmUnpublishContent, confirmRevertContent} = require 'lib/build-helpers'
promptInstallTemplate = require 'views/templates/prompt-install'
{alert} = require 'views/alerts'
urls = require 'lib/urls'

buildPermalink = (url, flowId) ->
    # Build the preview permalink using the previewUrl.
    permalink = document.createElement 'a'
    permalink.href = url
    if permalink.search is ''
        permalink.search = "?appcue=#{flowId}"
    else
        permalink.search = "#{permalink.search}&appcue=#{flowId}"

    return permalink.href

module.exports = class BuildSettingsView extends AppView
    __name__: 'BuildSettingsView'
    className: 'build-settings'
    template: require 'views/templates/build-steps/settings'

    events:
        'click [data-action=publishContent]': '_publishContent'
        'click [data-action=unpublishContent]': '_unpublishContent'
        'click [data-action=revertContent]': '_revertContent'
        'click [data-action=share]': 'share'
        'click [data-action=crxDesign]': 'crxDesign'
        'blur .editable-title': 'updateContentName'
        'keydown .editable-title': 'handlePageTitleEnter'

    initialize: (options) ->
        {@ruleId, @rule, @content, @contentType} = options

        page.set
            isEditable: true

        @content.whenSyncd =>
            @updatePageTitle()
            @listenTo @content, 'change:name', @updatePageTitle

        @afterInitialize()


    afterInitialize: () ->

        @rule.whenSyncd =>
            @updateDomainsSummary()
            @updateFrequencySummary()
            @updateRedirectSummary()
            @updateContentSummary()
            @updateABSummary()
            @listenTo @rule, 'change:domains', @updateDomainsSummary
            @listenTo @rule, 'change:frequency', @updateFrequencySummary
            @listenTo @rule, 'change:properties', @updateABSummary
            @listenTo @rule, 'change:languages', @updateLanguagesSummary
            @listenTo @rule, 'change:events', @updateEventSummary
            @listenTo @rule, 'change:contents', @updateContentSummary
            @listenTo @rule, 'change:nextContentId change:redirectUrl', @updateRedirectSummary

        @content.whenSyncd =>
            @listenTo @content, 'change:prehook', @updatePrehookSummary

    getContext: ->
        rule = @rule.toJSON()
        content = @content.toJSON()
        rulePreviewUrl = rule.previewUrl

        return {
            buildStep: 'settings'
            ruleId: @ruleId
            rule: rule
            content: content
            contentType: @contentType
            urlPrefix: @content.urlPrefix()
            isAdmin: auth.user.get 'isAdmin'
            cannotPublish: auth.user.get 'cannotPublish'
            standalone: helpers.queryStrToObj(window.location.search).standalone
            hasCrxInstalled: crxHelperModel.get 'hasCrxInstalled'
            onChrome: crxHelperModel.onChrome?()
            page: page.toJSON()
        }

    render: ->
        super
        @doAfterRender()

    # renderSettingsComponents will be overriden in a subclass, but that subclass must also be able to access
    # render.super.  So separate those two concerns.

    doAfterRender: ->

        @removeChildViews()

        $container = @$('.col-xs-12')

        v = new RulePropertiesView {rule: @rule}
        @propertiesPanel = new SettingPanelView {
            title: 'Choose who sees this based on user properties'
            summary: ''
            childView: v
            open: true
        }
        @renderChild @propertiesPanel
        $container.append(@propertiesPanel.el)
        v = new RuleWhereView
            model: @rule
        @wherePanel = new SettingPanelView {
            title: 'Choose where this appears'
            summary: ''
            childView: v
            open: true
        }
        @renderChild @wherePanel
        $container.append(@wherePanel.el)

        v = new RuleDomainsView
            model: @rule
        @domainsPanel = new SettingPanelView {
            title: 'Choose which environment(s) this appears on'
            summary: ''
            childView: v
        }
        @renderChild @domainsPanel
        $container.append(@domainsPanel.el)

        v = new RuleContentsView
            rule: @rule
        @contentsPanel = new SettingPanelView {
            title: "Choose who sees this based on Appcues content they've viewed"
            summary: ''
            childView: v
            show: true
        }
        @renderChild @contentsPanel
        $container.append(@contentsPanel.el)

        v = new RuleEventsView
            rule: @rule
        @eventsPanel = new SettingPanelView {
            title: "Choose who sees this based on events they've experienced"
            summary: ''
            childView: v
            show: true
        }
        @renderChild @eventsPanel
        $container.append(@eventsPanel.el)

        v = new RuleLanguagesView
            model: @rule
        @languagesPanel = new SettingPanelView {
            title: 'Choose which user language(s) this targets'
            summary: ''
            childView: v
        }
        @renderChild @languagesPanel
        $container.append(@languagesPanel.el)

        v = new RuleFrequencyView
            rule: @rule
        @frequencyPanel = new SettingPanelView {
            title: 'Choose how many times users see this'
            childView: v
        }
        @renderChild @frequencyPanel
        $container.append(@frequencyPanel.el)

        v = new ABTestingView {model: @rule}
        @abPanel = new SettingPanelView {
            title: 'Enable A/B testing'
            childView: v
        }
        @renderChild @abPanel
        $container.append(@abPanel.el)

        v = new RuleRedirectView
            rule: @rule
            flow: (if @contentType is 'flows' then @content else null)
        @redirectPanel = new SettingPanelView {
            title: 'Specify what to do upon completion'
            childView: v
        }
        @renderChild @redirectPanel
        $container.append(@redirectPanel.el)

        # Render prehook for anyone who already has it.
        if @contentType is 'flows' and @content.get('prehook')
            v = new FlowPrehookView
                model: @content
                rule: @rule
            @prehookPanel = new SettingPanelView {
                title: 'Add Javascript to run when shown'
                childView: v
            }
            $container.append(@prehookPanel.el)
            @renderChild @prehookPanel

        @updateSummaries()
        @

    showPublishConfirmation: ->
        # Should the text in the confirmation refer to singular or plural content?
        singular = switch @contentType
            when 'flows' then true
            when 'hotspots' then false
            when 'coachmarks' then false
            else true

        niceContentName = switch @contentType
            when 'flows' then 'flow'
            when 'hotspots' then 'hotspots'
            when 'coachmarks' then 'coachmarks'
            else 'content'

        # Determine if the account's embed script is installed.
        accountProps = require 'models/account-properties'
        isEmbedCodeInstalled = not _.isEmpty(accountProps.toJSON())

        content = promptInstallTemplate {
            isEmbedCodeInstalled
            singular
            niceContentName
            permalink: buildPermalink(@rule.get('previewUrl'), @ruleId)
            accountId: auth.currentAccount.id
        }

        if !isEmbedCodeInstalled
            alert {
                content: content
                buttonText: "I'll do this later"
            }

    _publishContent: confirmPublishContent

    _unpublishContent: confirmUnpublishContent

    _revertContent: confirmRevertContent

    share: ->
        shareModel = new Backbone.Model({
            flowId: @content.id
            previewUrl: buildPermalink(@rule.get('previewUrl'), @ruleId)
        })
        v = new ShareModalView
            show: true
            model: shareModel

        @renderChild(v)

    crxDesign: ->
        crxHelperModel.designAtUrlOrShowInstallModal @rule.get('previewUrl'), @content.get('id')

    updateDomainsSummary: ->
        if @rule.get('domains')
            count = _.uniq(@rule.get('domains').split(',')).length
            @domainsPanel?.setSummary "Show on #{count} domain#{if count is 1 then '' else 's'}"
        else
            @domainsPanel?.setSummary 'Show on any domain'

    updateContentSummary: ->
        c = @rule.get('contents')
        if c
            contentId = Object.keys(c)[0]
            flow = c[contentId]['name'] || "ID='#{contentId}'"
            if c[contentId]["negative"]
                @contentsPanel?.setSummary "Show to users who have not viewed: #{flow}"
            else
                @contentsPanel?.setSummary "Show to users who have viewed: #{flow}"
        else
            @contentsPanel?.setSummary "Show to users with any viewing history"

    updateEventSummary: ->
        e = @rule.get('events')
        if e
            eventName = Object.keys(e)[0]
            if e[eventName]["negative"]
                @eventsPanel?.setSummary "Show to users who have not experienced event: #{eventName}"
            else
                @eventsPanel?.setSummary "Show to users who have experienced event: #{eventName}"
        else
            @eventsPanel?.setSummary "Show to users with any event history"

    updateLanguagesSummary: ->
        if @rule.get('languages')
            count = @rule.get('languages').split(',').length
            @languagesPanel?.setSummary "Target #{count} language#{if count is 1 then '' else 's'}"
        else
            @languagesPanel?.setSummary 'Target all languages'

    updateFrequencySummary: ->
        if @rule.get('frequency') is 'every_time'
            @frequencyPanel?.setSummary 'Show every time'
        else
            @frequencyPanel?.setSummary 'Show once'

    updateABSummary: ->
        if _.isObject(@rule.get('properties')) and _.isObject(@rule.get('properties')._ABGroup)
            @abPanel?.setSummary 'Show to 50% of users'
        else
            @abPanel?.setSummary '<span class="text-muted">Disabled</span>'

    updateRedirectSummary: ->
        if @rule.get('redirectUrl') or @rule.get('nextContentId')
            upNext = []
            if @rule.get('redirectUrl')
                upNext.push 'redirect'
            if @rule.get('nextContentId')
                upNext.push 'trigger content'
            @redirectPanel?.setSummary(s.capitalize(upNext.join(' and ')))
        else
            @redirectPanel?.setSummary '<span class="text-muted">Close Appcues</span>'

    updatePrehookSummary: ->
        if @content.get('prehook')
            lineCount = @content.get('prehook').split("\n").length
            @prehookPanel?.setSummary "#{lineCount} line#{if lineCount is 1 then '' else 's'}"
        else
            @prehookPanel?.setSummary ''

    updateSummaries: ->
        @updateDomainsSummary()
        @updateFrequencySummary()
        @updateABSummary()
        @updateRedirectSummary()
        @updatePrehookSummary()
        @updateLanguagesSummary()
        @updateContentSummary()
        @updateEventSummary()

    handlePageTitleEnter: (e) ->
        if e.keyCode == 13
            console.log e
            @$('.editable-title').blur()
            return false

    updateContentName: ->
        @content.set 'name', @$('.editable-title').text()

    updatePageTitle: ->
        status = if @rule.get('published') then "| Published" else "| Draft"
        page.set 'title', "#{@content.get("name")} #{status}"
