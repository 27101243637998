BaseView = require 'views/base'
analytics = require 'lib/analytics'
auth = require 'models/auth'


module.exports = class FlowUsersView extends BaseView
    __name__: 'FlowUsersView'
    className: 'flow-users'
    template: require 'views/templates/analytics/flow-users'

    initialize: ->
        @viewState = new Backbone.Model(perPage: 50, currentPage: 1, identityKey: 'userId')
        @listenTo @viewState, 'change', @render

        @properties = require 'models/account-properties'
        @properties.whenSyncd =>
            emailProperties = _.filter(@_identityKeys(), (key) ->
                (new RegExp('email', 'i')).test(key)
            )
            @viewState.set('identityKey', _.first(emailProperties)) unless _.isEmpty(emailProperties)

        @flowEvents = []
        @_keenPromise = analytics.getFlowEvents(auth.currentAccount.id, @model.toJSON()).done (response) =>
            @flowEvents = response.result
            @render()

    events:
        'click .set-identity-key': '_setIdentityKey'
        'click tr.user': '_showUserDetails'
        'click .set-page': '_setPage'

    render: ->
        @$el.html @template {
            flow: @model
            visibleRows: @_visibleUserRows()
            totalRowCount: @_userRows().length
            identityKeys: @_identityKeys()
            identityKey: @viewState.get('identityKey')
            perPage: @viewState.get('perPage')
            currentPage: @viewState.get('currentPage')
        }

    _identityKeys: ->
        _.reject(_.keys(@properties.attributes), (key) ->
            key[0] is '_'
        )

    _showLabelSelect: (e) ->
        @$(e.currentTarget).hide()
        @$('.select-label.hidden').removeClass('hidden')

    _setIdentityKey: (e) ->
        newValue = @$(e.currentTarget).data('key')
        @viewState.set('identityKey', newValue) if newValue

    _userRows: ->
        users = {}
        _.each(_.sortBy(@flowEvents, (event) -> -1 * _parseInt(event.timestamp)), (event) ->
            userKey = event.identity.userId
            users[userKey] ?= {
                timeSpent: 0
                latestStep: 0
            }
            users[userKey].identity ?= event.identity
            users[userKey].timestamp ?= _parseInt(event.timestamp)
            timeObj = moment(users[userKey].timestamp)
            users[userKey].timeHint ?= timeObj.format('MMMM Do YYYY, h:mm:ss a')
            users[userKey].timeDisplay ?= timeObj.fromNow()

            timeSpent = parseInt(event.timeSpent, 10)
            users[userKey].timeSpent += event.timeSpent if _.isNumber(timeSpent) and !_.isNaN(timeSpent)

            stepNumber = parseInt(event.stepNumber, 10)
            users[userKey].latestStep = _.max([stepNumber, users[userKey].latestStep]) if _.isNumber(stepNumber) and !_.isNaN(stepNumber)

            if event.actionId is 'flow_form_submission'
                users[userKey].forms ?= {}
                users[userKey].forms[event.formId] ?= {}
                _.each(event.response, (fieldResponse) ->
                    if fieldResponse.value
                        users[userKey].forms[event.formId][fieldResponse.fieldId] ?= fieldResponse
                )
        )

        _.sortBy(_.values(users), (userRow) ->
            0 - userRow.timestamp
        )

    _parseInt = (val) ->
        if typeof val is 'number'
            return val
        else if /^[0-9]+$/.test(val)
            return parseInt(val, 10)
        else
            return val

    _visibleUserRows: ->
        start = (@viewState.get('currentPage') - 1) * @viewState.get('perPage')
        end = start + @viewState.get('perPage')
        @_userRows()[start...end]

    _showUserDetails: (e) ->
        userId = @$(e.currentTarget).data('user-id')
        @$("tr.details[data-user-id=#{userId}]").toggle()

    _setPage: (e) ->
        @viewState.set('currentPage', @$(e.currentTarget).data('page'))

    remove: ->
        @_keenPromise.abort() if @_keenPromise?
        super

