AppView = require 'views/app'
auth = require 'models/auth'

module.exports = class ChecklistsView extends AppView
    __name__: 'ChecklistsView'
    className: 'checklists'
    template: require 'views/templates/checklists'

    afterInitialize: () ->

    render: ->

        super

        if(window.apcInjectable)
            window.apcInjectable.renderChecklists auth.currentAccount.id, "apc-react-root"
        else
            setTimeout(@render.bind(@), 300)
