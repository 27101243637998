InstallGuideView = require 'views/install-guide'
SegmentStepView = require 'views/install-guide-step-segment'

module.exports = class InstallGuideSegmentView extends InstallGuideView
    __name__: 'InstallGuideSegmentView'
    className: 'install-guide-segment'
    template: require 'views/templates/install-guide-segment'

    events:
        'click #next' : 'showVerify'
        'click a.choose-install-type' : 'selectInstallType'
        'click .panel-choose-install' : 'chooseInstallType'
        'click [data-action=inviteUser]' : 'showInviteUserModal'

    render: ->
        super
        @installType = 'Segment'
        view = new SegmentStepView {@accountId, @apiKey}
        @renderChild view
        @$('.segment-step').append view.el
        @$('#instructions').show()
        @$('#installChoice').removeClass('in')
