{firebaseRef} = require 'lib/firebase'
auth = require 'models/auth'

module.exports = class GoalsCollection extends Backbone.Firebase.Collection
    model: require 'models/goal'

    initialize: (models, options) ->
        accountId = options?.accountId ? auth.currentAccount.id
        @url = firebaseRef.child "accounts/#{accountId}/goals"

