{ HUBSPOT_ACCOUNT_ID, HUBSPOT_SIGNUP_FORM_ID, HUBSPOT_NPS_SIGNUP_FORM_ID, HUBSPOT_EMAIL_FORM_ID, HUBSPOT_NPS_EMAIL_FORM_ID} = window.__env
ALLOWED_PROPERTIES = [
    'num_flows'
    'num_flows_published'
    'embed_code_installed'
    'appcues_id'
]

module.exports =

    mapClearbitField: (type, data) ->

        # Clearbit response data contain camelCased fields
        # To correctly map these fields to Segment (to be picked up by Hubspot), we need to snake case
        # the response fields and include the leading 'clearbit' string

        clearbitHeaderField = 'clearbit_' + type
        capitalLetter = /\.?([A-Z]+)/
        capitalLetters = /\.?([A-Z]+)/g

        checkForCaps = (string) ->
            return string.search(capitalLetter)

        returnValidFieldData = (value) ->
            if value is null
                return null
            else
                return value.toString()

        mappedData = {}

        _.each data, (val, key) ->
            mappedCbField = clearbitHeaderField + '_' + key

            if val is null
                return

            else if typeof val is "object" && val != null
                if val.constructor == Array
                    if checkForCaps(mappedCbField) > 0
                        moddedArrayField = mappedCbField.replace(capitalLetter, '_' + mappedCbField.charAt(checkForCaps(mappedCbField))).toLowerCase()
                        mappedData[moddedArrayField] = returnValidFieldData(val)
                    else
                        mappedData[mappedCbField] = returnValidFieldData(val)

                else if val.constructor == Object
                    _.each val, (x, y) ->
                        objectField = mappedCbField + '_' + y
                        if x is null
                            return
                        else if checkForCaps(y) > 0
                            moddedObjectField = objectField.replace(capitalLetters, (i,j) -> return "_" + j.toLowerCase())
                            mappedData[moddedObjectField] = returnValidFieldData(x)
                        else
                            mappedData[objectField] = returnValidFieldData(x)

            else
                if checkForCaps(mappedCbField) > 0
                    moddedStringField = mappedCbField.replace(capitalLetter, '_' + mappedCbField.charAt(checkForCaps(mappedCbField))).toLowerCase()
                    mappedData[moddedStringField] = returnValidFieldData(val)
                else
                    mappedData[mappedCbField] = returnValidFieldData(val)

        return mappedData

    getHubpotFormsUrl: (formId) ->
        return "https://forms.hubspot.com/uploads/form/v2/#{HUBSPOT_ACCOUNT_ID}/#{formId}"

    submitEmail: (email, product) ->
        data = { email }
        formId = product is "nps" && HUBSPOT_NPS_EMAIL_FORM_ID || HUBSPOT_EMAIL_FORM_ID

        # Bundle additional data HubSpot uses.
        hsContext = {
            pageUrl: window.location.href
            pageName: document.title
        }

        # Get cookie.
        hutk = document.cookie.match(/hutk=(.*?)(;|$)/ig) or document.cookie.match(/hubspotutk=(.*?)(;|$)/ig)
        if hutk?
            hutk = hutk[0].split('=')[1].replace(';', '')
            hsContext.hutk = hutk

        $.ajax @getHubpotFormsUrl(formId), {
            method: 'POST'
            contentType: 'application/x-www-form-urlencoded'
            data: _.extend({hs_context: JSON.stringify(hsContext)}, data)
            success: ->
            error: ->

                # NOTE: Since migration to Webflow, Hubspot API form
                # submissions will ALWAYS throw an error when performed
                # on a non-Hubspot page. We will temporarily disable
                # Sentry error messages here.

                # e = new Error('Failed to send email to HubSpot')
                # Raven.captureException e, {
                #     extra: {
                #         email: data.email,
                #         error: error,
                #         url: window.location.href
                #     }
                # }
        }

        # Saving this for the Demo Request form on Landing Page
        # TO DO: Consolidate Signup and Demo Request Forms

        # if data.requested_a_demo is "Yes"
        #     $.ajax "https://forms.hubspot.com/uploads/form/v2/#{HUBSPOT_ACCOUNT_ID}/#{HUBSPOT_DEMO_REQUEST_FORM_ID}", {
        #         method: 'POST'
        #         contentType: 'application/x-www-form-urlencoded'
        #         data: _.extend({hs_context: JSON.stringify(hsContext)}, data)
        #         success: ->
        #         error: (error) ->
        #             e = new Error('Failed to send demo request to HubSpot')
        #             Raven.captureException e, {
        #                 extra: {
        #                     email: data.email,
        #                     phone: data.phone,
        #                     request_demo: data.requested_a_demo,
        #                     company_size: data.company_size,
        #                     error: error,
        #                     url: window.location.href
        #                 }
        #             }
        # }

    identify: (props) ->
        allowed = _.pick props, ALLOWED_PROPERTIES
        # Append a last_login date.
        allowed.last_login = @getTimestamp()

        if not window.spoofing and window.__env.TRACKING_ENABLED
            @_identify allowed
            # Need to flush the queue by tracking an event.
            @_trackEvent 'identify'

    _identify: (props) ->
        if _.isObject(props)
            try
                _hsq.push ['identify', props]

    _trackEvent: (name) ->
        try
            _hsq.push ['trackEvent', name]

    # The HubSpot API only accepts timestamps set to midnight.
    getTimestamp: (date) ->
        d = if date then new Date(date) else new Date()
        Date.UTC d.getFullYear(), d.getMonth(), d.getDate()
