auth = require 'models/auth'
{firebaseRef} = require 'lib/firebase'
trackActivities = require 'lib/setup-activities'

module.exports = class AccountMembershipsCollection extends Backbone.Firebase.Collection
    firebasePath: ""

    initialize: (models, options={}) ->
        {@accountId} = options
        @accountId ?= auth?.currentAccount?.id

        @url = firebaseRef.child "accounts/#{@accountId}/users"
        @once 'sync', -> @syncd = true

    parseResults: (results) ->
        # Make sure we have an id attribute on each object
        _.map results, (val, key) ->
            _.extend val, id: key
