auth = require 'models/auth'
mixins = require 'models/mixins'
CONTENT_STATES = require 'lib/content-states'


BaseHotspotGroupModel =
    defaults:
        createdAt: firebase.database.ServerValue.TIMESTAMP
        updatedAt: firebase.database.ServerValue.TIMESTAMP
        createdBy: auth.id
        updatedBy: auth.id

        state: CONTENT_STATES.DRAFT
        published: false

        name: 'Untitled Hotspots'
        description: null
        category: null
        skippable: true
        sequential: false
        style: null

    initialize: (attrs={}) ->
        @once 'sync', ->
            @syncd = true
            @on 'change:published', (model, value) -> if value then @resetHotspotWarning()
            @on 'change:totalUniqueErrorVersioned change:totalUniqueShownVersioned', @render

    urlPrefix: ->
        if @get('sequential')
            'tooltips'
        else
            'hotspots'

    resetHotspotWarning: ->
        @set({totalUniqueErrorVersioned: 0, totalUniqueShownVersioned: 0, hotspotWarning: false})

module.exports = _.extend BaseHotspotGroupModel
