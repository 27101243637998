helpers = require 'lib/helpers'
{firebase} = require 'lib/firebase'
auth = require 'models/auth'
{ANALYTICS_PROJECT_ID, ANALYTICS_READ_KEY} = window.__env


# TODO: Security.
module.exports =
    url: "https://api.keen.io/3.0/projects/#{ANALYTICS_PROJECT_ID}"

    defaults: ->
        accountId = auth?.currentAccount?.id
        apiKey = auth?.currentAccount?.get('keenScopedKeyRead')
        if !apiKey
            Raven.captureException(
                new Error('keenScopedKeyRead is missing'),
                {
                    extra: {accountId: accountId}
                }
            )
            apiKey = ANALYTICS_READ_KEY

        {api_key: apiKey}

    _fetch: (path, params) ->
        paramsStr = helpers.objToQueryStr _.extend {}, @defaults(), params
        $.getJSON "#{@url}#{path}?#{paramsStr}"

    # Fetches data for the different steps in a flow and zips them together.
    fetchFlowFunnel: (accountId, flowAttrs) ->
        fetchTotalStepViews = @fetchTotalStepViews accountId, flowAttrs
        fetchMedianStepDurations = @fetchMedianStepDurations accountId, flowAttrs

        $.when(fetchTotalStepViews, fetchMedianStepDurations)
            .then (totalViews, medianDurations) ->
                views = []
                durations = []
                if totalViews[0]?
                    views = totalViews[0].result
                if medianDurations[0]?
                    # Get duration totals. Assumes proper sort order.
                    durations = _.pluck medianDurations[0].result, 'result'

                result = []
                for view, index in views
                    result.push
                        views: view
                        duration: durations[index] or null

                return result

    # Fetches the total number of people who went through each step in a flow
    # based on their session ID.
    fetchTotalStepViews: (accountId, flowAttrs) ->
        steps = []
        for step, index in flowAttrs.steps
            steps.push {
                event_collection: "account-#{accountId}"
                actor_property: 'identity.userId'
                filters: [
                    {
                        property_name: 'flowId'
                        operator: 'eq'
                        property_value: flowAttrs.id
                    }, {
                        property_name: 'stepNumber'
                        operator: 'eq'
                        property_value: index
                    }, {
                        property_name: 'flowVersionId'
                        operator: 'eq'
                        property_value: flowAttrs.updatedAt
                    }, {
                        property_name: 'actionId'
                        operator: 'ne'
                        property_value: 'step_shown'
                    }
                ]
            }

        if steps.length
            @_fetch '/queries/funnel', {steps: steps}
        else
            (new $.Deferred()).resolve []

    # Fetches the total number of people who started a flow and later reached
    # the goal URL based on their user ID.
    fetchGoalFunnel: (accountId, flowAttrs) ->
        if flowAttrs.goalId
            steps = [{
                event_collection: "account-#{accountId}"
                actor_property: 'identity.userId'
                filters: [
                    {
                        property_name: 'flowId'
                        operator: 'eq'
                        property_value: flowAttrs.id
                    }, {
                        property_name: 'actionId'
                        operator: 'exists'
                        property_value: true
                    }, {
                        property_name: 'actionId'
                        operator: 'ne'
                        property_value: 'flow_conversion'
                    },{
                        property_name: 'flowVersionId'
                        operator: 'eq'
                        property_value: flowAttrs.updatedAt
                    }
                ]
            }, {
                event_collection: "account-#{accountId}"
                actor_property: 'identity.userId'
                filters: [
                    {
                        property_name: 'actionId'
                        operator: 'eq'
                        property_value: 'flow_conversion'
                    }, {
                        property_name: 'flowId'
                        operator: 'eq'
                        property_value: flowAttrs.id
                    }
                ]
            }]

            @_fetch('/queries/funnel', {steps})
                .then (funnelResult) ->
                    unless funnelResult
                        return null
                    started = funnelResult.result[0]
                    reachedGoal = funnelResult.result[1]
                    completionPercentage =  if started > 0 then parseFloat ((reachedGoal / started) * 100).toFixed(1) else 0
                    {started, reachedGoal, completionPercentage}
        else
            (new $.Deferred()).resolve null


    # Fetches the median duration for each step in a flow.
    fetchMedianStepDurations: (accountId, flowAttrs) ->
        @_fetch '/queries/median', {
            event_collection: "account-#{accountId}"
            target_property: 'timeSpent'
            filters: [
                {
                    property_name: 'flowId'
                    operator: 'eq'
                    property_value: flowAttrs.id
                }, {
                    property_name: 'flowVersionId'
                    operator: 'eq'
                    property_value: flowAttrs.updatedAt
                }, {
                    property_name: 'actionId'
                    operator: 'ne'
                    property_value: 'step_shown'
                }, {
                    property_name: 'actionId'
                    operator: 'ne'
                    property_value: 'step_activated'
                }, {
                    property_name: 'actionId'
                    operator: 'ne'
                    property_value: 'step_deactivated'
                }
            ]
            group_by: 'stepNumber'
        }

    getFlowEvents: (accountId, flowAttrs) ->
        @_fetch '/queries/extraction', {
            event_collection: "account-#{accountId}"
            filters: [
                {
                    property_name: 'flowId'
                    operator: 'eq'
                    property_value: flowAttrs.id
                }
            ]
        }

    # Fetches the unique view count for each flow in a given account.
    getFlowUniqueViewCounts: (accountId) ->
        @_fetch '/queries/count_unique', {
            event_collection: "account-#{accountId}"
            group_by: 'flowId'
            target_property: 'identity.userId'
            timezone: 'UTC'
            filters: [
                {
                    property_name: 'flowId'
                    operator: 'exists'
                    property_value: true
                },{
                    property_name: 'VERSION'
                    operator: 'lt'
                    property_value: '3'
                }
            ]
        }

    getHotspotGroupCounts: (accountId, groupId) ->
        @_fetch '/queries/count_unique', {
            event_collection: "account-#{accountId}"
            group_by: ['groupId', 'actionId', 'versionId']
            target_property: 'identity.userId'
            timezone: 'UTC'
            filters: [
                {
                    property_name: 'groupId'
                    operator: if groupId then 'eq' else 'exists'
                    property_value: groupId or true
                },{
                    property_name: 'VERSION'
                    operator: 'lt'
                    property_value: '3'
                }
            ]
        }

    # Fetches the unique view count for each coachmark group in a given account.
    getCoachmarkGroupCounts: (accountId) ->
        @_fetch '/queries/count_unique', {
            event_collection: "account-#{accountId}"
            group_by: ['actionId', 'contentId', 'versionId']
            target_property: 'identity.userId'
            timezone: 'UTC'
            filters: [
                {
                    property_name: 'contentType'
                    operator: 'eq'
                    property_value: 'coachmark-group'
                },{
                    property_name: 'VERSION'
                    operator: 'lt'
                    property_value: '3'
                }
            ]
        }

    getGenericFlowGroupCount: (accountId) ->
        @_fetch '/queries/count_unique', {
            event_collection: "account-#{accountId}"
            group_by: ['id', 'flowId', 'flowVersion']
            target_property: 'identity.userId'
            timezone: 'UTC'
            filters: [
                {
                    property_name: 'flowId'
                    operator: 'exists'
                    property_value: true
                },{
                    property_name: 'VERSION'
                    operator: 'gte'
                    property_value: '3'
                }
            ]
        }

    getHotspotGroupTable: (accountId, contentId, displayProperty='email') ->
        # Returns an array of objects, one for each user, containing the
        # number of hotspots activated, times completed, and times errored.
        # Also includes the `displayProperty` in each applicable result.
        $.when(
            @_fetch '/queries/maximum', {
                event_collection: "account-#{accountId}"
                group_by: 'identity.userId'
                target_property: "identity.#{displayProperty}"
                filters: [
                    {
                        property_name: 'groupId'
                        operator: 'eq'
                        property_value: contentId
                    }
                ]
            }
            @_fetch '/queries/count_unique', {
                event_collection: "account-#{accountId}"
                group_by: 'identity.userId'
                target_property: 'hotspotId'
                filters: [
                    {
                        property_name: 'groupId'
                        operator: 'eq'
                        property_value: contentId
                    }
                    {
                        property_name: 'actionId'
                        operator: 'eq'
                        property_value: 'hotspot_activated'
                    }
                ]
            }
            @_fetch '/queries/count', {
                event_collection: "account-#{accountId}"
                group_by: 'identity.userId'
                filters: [
                    {
                        property_name: 'groupId'
                        operator: 'eq'
                        property_value: contentId
                    }
                    {
                        property_name: 'actionId'
                        operator: 'eq'
                        property_value: 'hotspots_completed'
                    }
                ]
            }
            @_fetch '/queries/count', {
                event_collection: "account-#{accountId}"
                group_by: 'identity.userId'
                filters: [
                    {
                        property_name: 'groupId'
                        operator: 'eq'
                        property_value: contentId
                    }
                    {
                        property_name: 'actionId'
                        operator: 'eq'
                        property_value: 'hotspots_error'
                    }
                ]
            }
        ).then (displayResult, activatedResult, completedResult, errorResult) ->
            displayByUserId = _.indexBy displayResult[0]?.result or [], 'identity.userId'
            activatedByUserId = _.indexBy activatedResult[0]?.result or [], 'identity.userId'
            completedByUserId = _.indexBy completedResult[0]?.result or [], 'identity.userId'
            errorByUserId = _.indexBy errorResult[0]?.result or [], 'identity.userId'
            allUserIds = _.uniq(_.flatten([_.keys(displayByUserId), _.keys(activatedByUserId), _.keys(completedByUserId), _.keys(errorByUserId)]))
            _.map allUserIds, (userId) ->
                userId: userId
                userDisplay: displayByUserId[userId]?.result or 0
                hotspotsActivated: activatedByUserId[userId]?.result or 0
                completedCount: completedByUserId[userId]?.result or 0
                errorCount: errorByUserId[userId]?.result or 0

    # Utility method that converts the result of #fetchFlowFunnel into useful
    # statistics for display.
    buildFunnelAnalysis: (funnel) ->
        # Bail out if there is nothing worth showing.
        # TODO - Won't reset saved values when Keen results come back empty
        #        for a just-updated flowVersionId
        if not funnel or not funnel.length or not funnel[0].views?
            return

        firstStep = _.first funnel
        lastStep = _.last funnel
        result =
            completionPercentage: 0
            totalDuration: 0
            stepsAnalytics: []

        if firstStep and lastStep
            firstStepViews = firstStep.views
            lastStepViews = lastStep.views
            totalDuration = _.reduce funnel, (memo, step) ->
                memo + (step.duration or 0)
            , 0

            result.completionPercentage = if firstStepViews > 0 then Math.round (lastStepViews / firstStepViews) * 100 else 0
            result.totalDuration = totalDuration

            # For computing fitted values.
            durations = []

            for step in funnel
                completionPercentage = if firstStepViews > 0 then Math.floor (step.views / firstStepViews) * 100 else 0
                result.stepsAnalytics.push
                    views: step.views
                    duration: step.duration ? null
                    completionPercentage: completionPercentage

        return result

    # Takes the result of analytics.getHotspotGroupCounts() and spits out the totals
    # for important actions, grouped by @groupId.
    buildHotspotActionCounts: (counts, hotspotCollection) ->
        result = {}

        _.each counts, (count) ->
            if count.groupId
                result[count.groupId] ?= {
                    hotspots_shown: 0
                    hotspot_activated: 0
                    hotspots_completed: 0
                    hotspots_error: 0
                    hotspots_error_versioned: 0
                    hotspots_shown_versioned: 0
                }
                result[count.groupId][count.actionId] += count.result

            # Find out how many hotspot errors were shown on the latest version of the hotspot_group
            # Check if we were passed a collection. In that case pull out the relevant model.
            # Otherwise assume it's a model.
            hotspotGroup = if hotspotCollection.models then hotspotCollection.findWhere({id: count.groupId}) else hotspotCollection

            if hotspotGroup and hotspotGroup.get('id') is count.groupId and hotspotGroup.get('updatedAt') is count.versionId
                if count.actionId is 'hotspots_error'
                    result[count.groupId]["hotspots_error_versioned"] += count.result
                else if count.actionId is 'hotspots_shown'
                    result[count.groupId]["hotspots_shown_versioned"] += count.result

        return result

    # Takes the result of analytics.getCoachmarkGroupCounts() and spits out the totals
    # for important actions, grouped by @contentId.
    buildCoachmarkActionCounts: (counts, coachmarkCollection) ->
        result = {}

        _.each counts, (count) ->
            if count.contentId
                result[count.contentId] ?= {
                    coachmarks_shown: 0
                    coachmarks_completed: 0
                    coachmarks_error: 0
                    coachmarks_error_versioned: 0
                    coachmarks_shown_versioned: 0
                }
                result[count.contentId][count.actionId] += count.result


            # Find out how many coachmark errors were shown on the latest version of the coachmark_group
            coachmarkGroup = coachmarkCollection.findWhere({id: count.contentId})


            if coachmarkGroup and coachmarkGroup.get('updatedAt') is count.versionId
                if count.actionId is 'coachmarks_error'
                    result[count.contentId]["coachmarks_error_versioned"] += count.result
                else if count.actionId is 'coachmarks_shown'
                    result[count.contentId]["coachmarks_shown_versioned"] += count.result

        return result


    buildGenericFlowGroupCounts: (counts, collection) ->
        result = {}
        _.each counts, (count) ->
            if count.flowId
                result[count.flowId] ?= {
                    flow_started: 0
                    flow_completed: 0
                    flow_skipped: 0
                    flow_aborted: 0
                    step_started: 0
                    step_completed: 0
                    step_skipped: 0
                    step_aborted: 0
                    step_child_activated: 0
                    step_child_deactivated: 0
                    step_interacted: 0
                    step_error: 0
                    step_child_error: 0
                    error_versioned: 0
                    shown_versioned: 0
                }
                result[count.flowId][count.id] += count.result


            flow = collection?.findWhere({id: count.flowId})

            if flow and flow.get('updatedAt') is count.flowVersion
                if count.id is 'step_child_error'
                    result[count.flowId]['error_versioned'] += count.result
                else if count.id is 'flow_started'
                    result[count.flowId]['shown_versioned'] += count.result

        return result
