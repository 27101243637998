AppView = require 'views/app'
auth = require 'models/auth'


module.exports = class BillingFeedback extends AppView
    __name__: 'BillingFeedback'
    template: require 'views/templates/billing-feedback'

    render: ->
        super

        if window.apcInjectable?
            window.apcInjectable.renderSubscriptionFeedback(auth.currentAccount.id, "apc-react-root")
        else
            window.setTimeout(@render.bind(@), 300)
