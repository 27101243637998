BaseView = require 'views/base'
auth = require 'models/auth'
{createMailToLinkFromTemplate} = require 'lib/urls'
InviteModal = require 'views/invite-modal'
trackEvent = require 'lib/track-event'

getEmailInviteBody = (accountId) ->
    bodyText = "Thanks for your help!\n\n\n\n --------------------------- \n\nWhat is Appcues? \n\n Appcues is a platform for creating walkthroughs, feature notifications, and more, with no coding required. It's styleable with CSS and fits right into your app's UX.\n\n Below are guides for installation:\n -------------\n
    ------- Appcues FAQ http://docs.appcues.com/article/159-faq\n
    ------- Installation Guide http://my.appcues.com/install/" + accountId + "\n
    ------- Technical Overview http://docs.appcues.com/article/263-installation-technical-overview\n -------------\n\nHave any questions about Appcues? Check out our docs: http://docs.appcues.com/ \nYou can also reply to this email or contact support@appcues.com, we'll be happy to answer any questions.\n\n"
    return bodyText

subject = "I'm looking at this tool to help us convert users"

module.exports = class SendInstallView extends BaseView
    template: require 'views/templates/send-install'

    events:
        'submit form#invite': 'sendInvite'
        'click .invite-dev-container .send-instructions-btn': 'createEmail'
        'click p.preview': 'previewEmail'
        'click .preview-controls .fa.fa-refresh': 'previewEmail'
        'click .preview-controls .fa.fa-compress': 'closePreview'
        'click .share button': 'clickCopyLinkBtn'

    initialize: ->
        @inviteModal = new InviteModal

    getContext: ->
        accountId: auth.currentAccount.id
        subject: subject

    clickCopyLinkBtn: ->
        context = @getContext()
        $('.invite-dev-container .share input').select()
        $('.invite-dev-container .fa-check').css('opacity', 1)
        $('.invite-dev-container .fa-check').animate({opacity: 0}, 800)

        document.execCommand("copy")
        window.setTimeout () ->
            window.getSelection().removeAllRanges()
        , 300
        trackEvent 'Developer Invite: Clicked Copy Install Link'

    previewEmail: (e) ->
        @clearError()

        fieldText = @getFieldData()
        context = @getContext()
        previewHeaderText = 'Hey ' + fieldText.firstname + ',\n\n'
        previewText = previewHeaderText + fieldText.emailBody

        $('.invite-dev-container .recipient-firstname')[0].innerText = fieldText.firstname + ' <'
        $('.invite-dev-container .recipient-email')[0].innerText = fieldText.email + '>'
        $('.invite-dev-container .preview-text')[0].innerText = previewText

        $(".preview-backdrop").removeClass('hidden')
        trackEvent 'Developer Email: Clicked Preview Email', {to: fieldText.email, subject: subject}

    closePreview: ->
        $(".preview-backdrop").addClass('hidden')

    getFieldData: () ->
        data = {}
        data.email = $("form [name='email']").val()
        data.firstname = $("form [name='firstname']").val()
        data.lastname = $("form [name='lastname']").val()
        data.emailBody = $('.email-body').get(0).innerText

        return data

    validateEmail: (email) ->
        isEmailValid = /.+@.+\..+/.test email
        return isEmailValid

    createEmail: (e) ->
        @clearError()

        fieldData = @getFieldData()
        email = fieldData.email
        firstname = fieldData.firstname
        emailBody = fieldData.emailBody
        context = @getContext()

        AppcuesHeaderText = 'Hey ' + firstname + ',\n\n'
        emailText = AppcuesHeaderText + emailBody + getEmailInviteBody(context.accountId)

        mailtoLink = createMailToLinkFromTemplate({
            to: email
            subject: subject
            body: emailText
            cc: 'support@appcues.com'
        })

        trackEvent 'Developer Email: Clicked Create Email', {to: email, subject: subject}
        window.open("mailto:#{mailtoLink}", "_self")

    clearError: ->
        $('.invite-dev-container .error-message').text('')
        $('.invite-dev-container .success-message').text('')
        $('.invite-dev-container .alert-block').addClass('hidden')
        $('.invite-dev-container .alert-block .error-default-message').addClass('hidden')

    sendInvite: (e) ->
        e.preventDefault()
        @clearError()

        role = 'Developer'
        fieldData = @getFieldData()
        email = fieldData.email
        firstname = fieldData.firstname
        lastname = fieldData.lastname
        name = firstname + ' ' + lastname

        if @validateEmail(email)

            inviteModal = @inviteModal
            createEmail = @createEmail
            context = @getContext()

            trackEvent('Invited a user', {email, role})

            # Create a random-ish password and an invite link.
            password = SparkMD5.hash("#{email}#{new Date().getTime()}").substring(0, 12)

            # Create the user in Firebase and add their /user data.
            inviteModal.createUser(email, password, {name,role})
                .done (user) ->
                    $('button.open-mailto').attr('disabled','disabled').text('Invite Sent')
                    $('[name=firstname], [name=lastname], [name=email]').attr('disabled','disabled')

                    # Add the account links for this user.
                    $.when(inviteModal.populateUser(user), inviteModal.populateMembership(user))
                        .then ->
                            # Create invite link with tracking params.
                            params = {
                                email: email
                                ajs_event: 'Started Invite Confirmation'
                                ajs_uid: user.id
                                ajs_prop_email: email
                            }
                            link = inviteModal.createInviteLink(params)

                            # Send the invite email.
                            inviteModal.sendEmail(user, password, link)
                        .fail (err) ->
                            $('.invite-dev-container .alert-block .error-default-message').removeClass('hidden')
                            $('.invite-dev-container .error-message').text(err)
                            $('.invite-dev-container .alert-block').removeClass('hidden')
                            # TO DO: Send error to Sentry

                        .done (res) ->
                            if res?[0]?.status in ['rejected', 'invalid']
                                # TO DO: Send error to Sentry

                            else
                                $('.invite-dev-container .success-message').text("An invitation to join this account will be sent to " + email + ".")
                                $('.invite-dev-container .alert-block').removeClass('hidden')

                .fail (err) ->
                    $('.invite-dev-container .alert-block .error-default-message').removeClass('hidden')
                    $('.invite-dev-container .error-message').text(err)
                    $('.invite-dev-container .alert-block').removeClass('hidden')
                    # TO DO: Send error to Sentry

