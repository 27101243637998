firebase = require 'lib/firebase'
auth = require 'models/auth'
BaseGoalModel = require 'models/goal-base'


GoalModel = _.extend {}, BaseGoalModel, {
    urlRoot: ->
        accountId = auth.currentAccount.id
        firebase.url + "/accounts/#{accountId}/goals"
}

module.exports = Backbone.Firebase.Model.extend GoalModel
