BaseView = require 'views/base'
auth = require 'models/auth'
trackEvent = require 'lib/track-event'


class FloatingCTAView extends BaseView
    __name__: 'FloatingCTAView'
    className: 'floating-cta animated slideInRight hidden-xs'
    template: require('views/templates/ctas/floating-cta')

    events:
        'click .btn-primary': 'track'
        'click .dismiss': 'dismiss'

    title: null
    cookieKey: null
    delayDays: null

    track: ->
        if @title
            try
                trackEvent("Clicked #{@title}")
        @suppress()

    dismiss: ->
        @suppress()
        if @title
            try
                trackEvent("Suppressed #{@title}")
        @remove()

    suppress: ->
        # Don't set the cookie twice.
        if @shouldShow() and @cookieKey
            cookieStr = "#{@cookieKey}=true"

            if @delayDays
                now = new Date()
                time = now.getTime()
                now.setTime(time + (@delayDays * 24 * 3600 * 1000))
                cookieStr += ";expires=#{now.toGMTString()}"

            document.cookie = cookieStr

    shouldShow: -> false


class DemoCTAView extends FloatingCTAView
    title: 'Demo CTA'
    cookieKey: 'apc_suppress_demo_cta'
    delayDays: 2
    template: require('views/templates/ctas/demo-cta')

    shouldShow: ->
        # Only show if no suppression cookie, user is in trial and not an admin.
        document.cookie.indexOf(@cookieKey) < 0 and auth.currentAccount?.get('isTrial') and not auth.user?.get('isAdmin') and @hasBrowsed()

    hasBrowsed: ->
        # Suppress until the user has browsed around a bit.
        try
            pageviews = JSON.parse(sessionStorage.getItem('apc_session_attrs'))._sessionPageviews
            if pageviews?
                return pageviews > 5

        return true

    getContext: ->
        # showDemo doesn't exist anymore on purpose, we're currently only offering group webinars.
        showDemo = auth.currentAccount?.get('showDemo')

        if showDemo
            # Private demo.
            return {
                text: "Hey! I'm John, your account rep here at Appcues. Do you have any questions I can answer? If so, you can instantly schedule time for us to chat."
                btnLink: 'https://calendly.com/bookjohn/appcues-trialer-walkthrough-in-app'
                btnText: 'Get a private demo'
            }
        else
            # Group webinar.
            return {
                text: "Hey! I'm John, your account rep here at Appcues. I'd love to show you how to get the most out of your Appcues trial. Join me for an upcoming demo on our best use cases."
                btnLink: 'http://www.appcues.com/webinars/live-demo/'
                btnText: 'Join the live demo'
            }


class UserTestingCTAView extends FloatingCTAView
    title: 'User Testing CTA'
    cookieKey: 'apc_suppress_ut_cta'
    template: require('views/templates/ctas/user-testing-cta')

    shouldShow: ->
        return false # Use the logic below to reactivate.
        # Customers only.
        # return document.cookie.indexOf(@cookieKey) < 0 and not auth.currentAccount?.get('isTrial')


module.exports = {
    DemoCTAView
    UserTestingCTAView
}
