# A global instance that manages page state.

class PageModel extends Backbone.Model
    defaults:
        title: null
        isEditable: false

    initialize: ->
        @on 'change:title', (model, title) -> @setTitle(title)

    setTitle: (title) ->
        title ?= @get 'title'
        suffix = '| Appcues'

        unless title
            title = 'Think Like a Customer'

        document.title = "#{title} #{suffix}"


page = new PageModel
page.PageModel = PageModel

module.exports = page
