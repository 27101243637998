{BUNDLER_URL} = window.__env
auth = require 'models/auth'


module.exports =
    sync: (accountId) ->
        accountId ?= auth.currentAccount.id
        # Kick off the bundler service for this account.
        $.get("#{BUNDLER_URL}/bundle/accounts/#{accountId}").fail (res) ->
            e = new Error('Failed to bundle embed script')
            if window.Raven
                Raven.captureException e, {
                    extra:
                        statusCode: res.status
                        statusText: res.statusText
                }
