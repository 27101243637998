BaseView = require 'views/base'

defaultOptions =
    backdrop: true
    keyboard: true
    show: false

module.exports = class ModalView extends BaseView
    __name__: 'ModalView'
    className: 'modal fade'
    template: require 'views/templates/modal'

    initialize: (options={}) ->
        {@content, @title, @email, @fullname} = options
        @modalOptions = _.extend {}, defaultOptions, options
        Backbone.$('body').append @el
        @$el.on 'hidden.bs.modal', _.bind(@remove, @)

    events: ->
        'click .modal-save': 'save'
        'submit form': 'submitForm'

    show: ->
        @$el.modal 'show'

    hide: ->
        @$el.modal 'hide'

    render: ->
        @$el.html(@template(@getContext()))
            .modal(@modalOptions)

    getContext: ->
        return {
            @content
            @title
            @email
            @fullname
        }

    save: ->
        @trigger('save', @$el, @).hide()

    submitForm: (e) ->
        $form = @$ e.currentTarget
        $modal = @$el

        $.post($form.attr('action'), $form.serializeObject())
            .done( ->
                $modal.find('.form-content').remove()
                $modal.find('.form-success').removeClass 'hidden'
            )
            .fail( ->
                $modal.find('.form-error').removeClass 'hidden'
            )

        return false
