BaseView = require 'views/base'
auth = require 'models/auth'
ManageCategoryModalView = require 'views/manage-category'

FILTERS_LOCAL_STORAGE_KEY = 'appcuesFilterState'

module.exports = class ListFiltersView extends BaseView
    __name__: 'ListFiltersView'
    className: 'list-filters'
    template: require 'views/templates/content-list/filters'

    events:
        'click a[data-property]': '_setProperty'
        'keyup [name=nameFilter]': '_setNameFilter'
        'click [data-action=manageCategories]': 'manageCategories'

    initialize: (options={}) ->
        {@filterState, @categories} = options
        @loadFilterState()
        @listenTo @filterState, 'change:type change:state change:sortBy', @render
        @listenTo @filterState, 'change:type change:state change:sortBy change:nameFilter', @saveFilterState
        @listenTo @filterState, 'change:nameFilter', @onChangeNameFilter

    loadFilterState: ->
        try
            if window.localStorage && window.localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY)
                @filterState.set(JSON.parse(window.localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY)))

    saveFilterState: ->
        try
            if window.localStorage
                window.localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(@filterState))

    getContext: ->
        filterState: @filterState.toJSON()
        typeDisplay: @_typeDisplay()
        stateDisplay: @_stateDisplay()
        sortDisplay: @_sortDisplay()

    manageCategories: ->
        v = new ManageCategoryModalView {
            show: true
            categories: @categories
        }
        @renderChild(v)

    onChangeNameFilter: ->
        if @filterState.get('nameFilter')
            this.$('#nameFilter').addClass('name-filter-active')
        else
            this.$('#nameFilter').removeClass('name-filter-active')
            this.$('#nameFilter').val(null)

    _setProperty: (evt) ->
        $a = @$(evt.currentTarget)
        @filterState.set($a.data('property'), $a.data('value') or null)

    _setNameFilter: (evt) ->
        $input = @$(evt.currentTarget)
        @filterState.set('nameFilter', $input.val() or null)


    _typeDisplay: ->
        switch @filterState.get('type')
            when null
                'All Patterns'
            when 'journeys'
                'Multiple'
            when 'flows'
                'Modals'
            when 'slideouts'
                'Slideouts'
            when 'hotspotGroups'
                'Hotspots'
            when 'tooltipGroups'
                'Tooltips'
            when 'coachmarkGroups'
                'Coach Marks'

    _stateDisplay: ->
        switch @filterState.get('state')
            when 'published'
                return 'Published'
            when 'draft'
                return 'Draft'
            when 'archived'
                return 'Archived'
            else
                return 'Any status'

    _sortDisplay: ->
        switch @filterState.get('sortBy')
            when 'default'
                'Relevance'
            when 'createdAt'
                'Recently created'
            when 'updatedAt'
                'Recently updated'
            when 'name'
                'Name (A-Z)'

    _openMenu: (evt) ->
        @$(evt.currentTarget).addClass('open')

    _closeMenu: (evt) ->
        @$(evt.currentTarget).removeClass('open')

