BaseView = require 'views/base'

module.exports = class SegmentStepView extends BaseView
    __name__: 'SegmentStepView'
    className: 'segment-step'
    template: require 'views/templates/segment-step'
    events:
        'click .segment-enable-button': 'onClickEnableSegment'

    initialize: (options={}) ->
        {accountId, apiKey} = options
        @meta = new Backbone.Model
            accountId: accountId
            apiKey: apiKey
            cdnDomain: window.__env.CDN_DOMAIN

    onClickEnableSegment: (e) ->
        # The "Enable Segment" button is generated by
        # Segment's JS, and loads a segment.com URL.

        # Unfortunately this URL can't be iframed.

        # We'll take the URL that's generated, stop
        # the click, and open the URL in a new tab.

        e.preventDefault()
        e.stopPropagation()

        window.open(@$('.segment-enable-button a').attr("href"), "_blank")
