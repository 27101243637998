{firebase, firebaseRef} = require 'lib/firebase'
helpers = require 'lib/helpers'
auth = require 'models/auth'


module.exports = class StepsCollection extends Backbone.Firebase.Collection
    model: require 'models/step'
    validationError: null

    initialize: (models, options={}) ->
        accountId = options.accountId ? auth.currentAccount.id

        # Expose flowId so we can use it for downstream tracking.
        @flowId = options.flowId
        unless @flowId?
            throw new Error('Please provide a valid flow id.')

        @url = firebaseRef.child "accounts/#{accountId}/flows/#{@flowId}/steps"

    _selectedId: null
    validate: ->

    isValid: (options={}) ->
        @_validate null, _.extend(options, {validate: true})

    comparator: helpers.stepComparator

    _validate: (models, options) ->
        if not options.validate or not @validate
            return true

        if models?
            models = [].concat @models, models
        else
            models = @models

        error = null
        for model in models
            break if (error = model.validate(options))
        @validationError = error

        if error
            @trigger 'invalid', @, error, _.extend(options, {validationError: error})
            return false
        else
            @trigger 'valid', @, error, options
            return true

    select: (id) ->
        @_selectedId = id
        @trigger 'select'

    getSelectedId: ->
        @_selectedId or @at(0)?.get('id')

    addEmptyStep: ->
        @add {stepType: 'components'}

