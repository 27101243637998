auth = require 'models/auth'
BuildSettingsView = require 'views/build-steps/settings'

module.exports = class BuildConditionsView extends BuildSettingsView
    __name__: 'BuildConditionsView'
    className: 'build-conditions'
    template: require 'views/templates/build-steps/conditions'

    afterInitialize: () ->

    doAfterRender: ->

        # there's almost definitely a better way to handle the below (ex. in the router directly),
        # but for now, if apcInjectable isn't ready just wait for it (auth.coffee provides)

        if(window.apcInjectable)
            window.apcInjectable.renderStepSettings auth.currentAccount.id, @ruleId, "apc-react-root"
        else
            setTimeout(() =>
                @doAfterRender()
            ,300)
