AppView = require 'views/app'
HotspotGroupsCollection = require 'collections/hotspot-groups'
analytics = require 'lib/analytics'
auth = require 'models/auth'
page = require 'models/page'


module.exports = class HotspotGroupAnalyticsView extends AppView
    __name__: 'HotspotGroupAnalyticsView'
    className: 'content-analytics hotspot-group-analytics'
    template: require 'views/templates/analytics/hotspot-group'

    events: {
        'keyup [name=searchUsers]': 'updateSearch'
        'click [data-action=setFilter]': 'setFilter'
    }
    initialize: (options={}) ->
        super
        @model = options.hotspotGroup
        @rule = options.rule
        @viewState = new Backbone.Model {filter: null, tableData: null, loading: true}
        page.set 'title', (@model.get('name') or 'Untitled')
        @refreshCounts()
        @refreshTable()
        @listenTo @model, 'change:totalUniqueViewsUpdated change:totalUniqueErrorVersioned change:totalUniqueShownVersioned', @render
        @listenTo @viewState, 'change:filter change:tableData change:loading', @render

    getContext: ->
        hotspotGroup: @model.toJSON()
        rule: @rule.toJSON()
        tableData: @viewState.get('tableData')
        filter: @viewState.get('filter')
        loading: @viewState.get('loading')

    render: ->
        super
        @dynatable = @$('table').bind('dynatable:init', (e, dynatable) ->
            dynatable.queries.functions['completed'] = (record, queryValue) ->
                if queryValue is true
                    record.completed.match(/Yes/)
                else
                    true
            dynatable.queries.functions['errors'] = (record, queryValue) ->
                if queryValue is true
                    parseInt(record.errors, 10) > 0
                else
                    true
        ).dynatable({
            features: {
                paginate: false
                search: false
                recordCount: false
                perPageSelect: false
            }
        }).addClass('dynatable').data('dynatable')
        @applyFilter()
        @

    updateSearch: (evt) ->
        query = @$(evt.currentTarget).val()
        @dynatable?.queries.runSearch(query)

    setFilter: (evt) ->
        filter = @$(evt.currentTarget).attr('data-filter')
        @viewState.set('filter', filter)

    applyFilter: ->
        switch @viewState.get('filter')
            when 'completed'
                @dynatable?.queries?.add('completed', true)
            when 'errors'
                @dynatable?.queries?.add('errors', true)
            else
                @dynatable?.queries?.remove('completed')
                @dynatable?.queries?.remove('errors')
        @dynatable?.process()

    refreshCounts: ->
        # Actually refreshes counts for *all* hotspot groups.
        analytics.getHotspotGroupCounts(auth.currentAccount.id, @model.id)
            .then (response) =>
                analytics.buildHotspotActionCounts(response.result, @model)
            .then (actionCounts) =>

                count = actionCounts[@model.id]
                if count

                    viewCountAttributes = {
                        totalUniqueViews: count.hotspots_shown
                        totalUniqueCompleted: count.hotspots_completed
                        totalUniqueError: count.hotspots_error
                    }

                    # don't update the totalUniquedViewsUpdated key if none of the data has changed
                    # this causes firebase write to cascade through all flows

                    if Object.keys(viewCountAttributes).some((attr) => return viewCountAttributes[attr] != @model.get(attr))
                        @model.set _.extend(viewCountAttributes, { totalUniqueViewsUpdated: firebase.database.ServerValue.TIMESTAMP })

    refreshTable: ->
        # Try using an auth.currentAccount.displayProperty value as the display,
        # the function will default to 'email' if we pass null/undefined.
        # This lets us override in firebase if an account is identifying with
        # `emailAddress` or something similar.
        analytics.getHotspotGroupTable(auth.currentAccount.id, @model.id, auth.currentAccount.get('displayProperty')).then (results) =>
            @viewState.set {
                tableData: results
                loading: false
            }
