{firebaseRef} = require 'lib/firebase'

auth = require 'models/auth'
BaseAnnouncementModel = require 'models/announcement-base'


AnnouncementModel = _.extend {}, BaseAnnouncementModel, {
    initialize: (attributes, options={}) ->
        BaseAnnouncementModel.initialize.call @, attributes, options
        {@accountId} = options

    urlRoot: ->
        accountId = @accountId ? auth.currentAccount.id
        firebaseRef.url + "/accounts/#{accountId}/announcements"

}

module.exports = Backbone.Firebase.Model.extend AnnouncementModel
